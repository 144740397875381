import { FC, useRef, useState } from 'react';
import styles from './time-controller.module.css';
import { Slider } from '@mui/material';
import { useCustomTimer, useTimer } from '@sqior/react/state';
import Typography from '../typography/typography';
import { format } from 'date-fns';
import { useInterval } from '@sqior/react/hooks';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const TimeController: FC<Props> = ({}) => {
  const { setTimerValue } = useTimer();
  const time = useCustomTimer(200);
  const [value, setValue] = useState<number[]>([0, 0]);
  const [factor, setFactor] = useState(0);

  const handleChange = (event: Event, newValue: number | number[]) => {
    const [val1, val2] = newValue as number[];

    let newChangedValue = 0;

    if (val1 !== value[0]) {
      newChangedValue = val1;
      setValue([val1, 0]);
    } else {
      newChangedValue = val2;
      setValue([0, val2]);
    }

    setFactor(newChangedValue);
  };

  useInterval(
    () => {
      const newTime = calculateSlidTime(time, factor);
      onChangeTime(newTime);
    },
    factor ? 50 : null
  );

  const onChangeCommitted = () => {
    setFactor(0);
    setValue([0, 0]);
  };

  const onChangeTime = (time: number) => {
    setTimerValue(time);
  };

  const onTodayClick = () => {
    const today = new Date();
    onChangeTime(today.getTime());
  };

  return (
    <div style={{ width: 300 }}>
      <div className={styles['row']}>
        <Typography>{format(time, 'dd.MMM HH:mm:ss')}</Typography>
        <Typography primary link onClick={onTodayClick}>
          Heute
        </Typography>
      </div>
      <Slider
        aria-label="Time-Slider"
        min={-50}
        max={50}
        value={value}
        onChange={handleChange}
        onChangeCommitted={onChangeCommitted}
      />
    </div>
  );
};

export default TimeController;

const calculateSlidTime = (time: number, slideValue: number) => {
  const maxValue = 1 * 60 * 60 * 1000;
  const deltaTime = (slideValue / 50) * maxValue;
  const newTime = time + deltaTime;
  return newTime;
};
