import { Entity, EntityHeader, EntityRecord } from '@sqior/js/entity';
import { EntityModel, makeTextEntity } from '@sqior/js/meta';
import { LanguageEntities } from './language-definitions';
import { Logger } from '@sqior/js/log';

/* Definition of a text entity with dynamic parameters */

export type TextTemplate = EntityHeader & { text: Entity; params: EntityRecord };
export const TextTemplateModel: EntityModel = {
  type: LanguageEntities.TextTemplate,
  props: ['text', 'params'],
};
export function makeTextTemplate(text: string | Entity, params: EntityRecord): TextTemplate {
  return {
    entityType: LanguageEntities.TextTemplate,
    text: typeof text === 'string' ? makeTextEntity(text) : text,
    params: params,
  };
}

/** Extracts the next parameter from the text */

function extractTextParam(
  text: string,
  startIndex: number
): [string | undefined, number, number, Record<string, string> | undefined] {
  /* Find insertion point */
  let options: Record<string, string> | undefined;
  for (;;) {
    startIndex = text.indexOf('<e', startIndex);
    if (startIndex < 0) return [undefined, startIndex, startIndex, undefined];
    if (text[startIndex + 2] === '>' || text[startIndex + 2] === ' ') break;
    startIndex += 2;
  }
  let startEndIndex = text.indexOf('>', startIndex + 2);
  if (startEndIndex < 0) throw new Error('Malformed template - no tag end found: ' + text);
  /* Extract optional parameter, if applicable */
  if (startEndIndex > startIndex + 2) {
    for (const str of text.substring(startIndex + 2, startEndIndex).split(' ')) {
      const option = str.trim();
      if (!option) continue;
      const parts = option.split('=');
      if (parts.length !== 2)
        throw new Error('Malformed template - options provided with unsupported format: ' + text);
      if (!options) options = {};
      options[parts[0]] = parts[1];
    }
  }
  startEndIndex++;
  const endIndex = text.indexOf('</e>', startEndIndex);
  if (endIndex < 0 || endIndex < startIndex)
    throw new Error('Malformed template - not matching start and end tags: ' + text);
  return [text.substring(startEndIndex, endIndex), startIndex, endIndex, options];
}

/** Text parameter subtitution */

export async function substituteTextParams(
  text: string,
  func: (param: string, options?: Record<string, string>) => Promise<string | undefined>
): Promise<string> {
  let startIndex = 0;
  for (;;) {
    const [param, paramStartIndex, endIndex, options] = extractTextParam(text, startIndex);
    if (param === undefined) return text;
    let subst: string | undefined;
    try {
      subst = await func(param, options);
    } catch (e) {
      Logger.warn(
        ['Exception when substituting parameter:', param, '- Exception:', Logger.exception(e)],
        [
          'Exception when substituting parameter:',
          param,
          '- Original text:',
          text,
          '- Exception:',
          Logger.exception(e),
        ]
      );
    }
    /* Use generic text if no substitution is provided */
    if (subst === undefined) subst = '...';
    const replaceLength = endIndex - paramStartIndex;
    text = text.substring(0, paramStartIndex) + subst + text.substring(endIndex + 4);
    startIndex = endIndex + subst.length - replaceLength;
  }
}

export function substituteTextParamsSync(
  text: string,
  func: (param: string, options?: Record<string, string>) => string | undefined
): string {
  let startIndex = 0;
  for (;;) {
    const [param, paramStartIndex, endIndex, options] = extractTextParam(text, startIndex);
    if (param === undefined) return text;
    let subst: string | undefined;
    try {
      subst = func(param, options);
    } catch (e) {
      Logger.warn(
        ['Exception when substituting parameter:', param, '- Exception:', Logger.exception(e)],
        [
          'Exception when substituting parameter:',
          param,
          '- Original text:',
          text,
          '- Exception:',
          Logger.exception(e),
        ]
      );
    }
    /* Use generic text if no substitution is provided */
    if (subst === undefined) subst = '...';
    const replaceLength = endIndex - paramStartIndex;
    text = text.substring(0, paramStartIndex) + subst + text.substring(endIndex + 4);
    startIndex = endIndex + subst.length - replaceLength;
  }
}
