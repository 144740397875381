import {
  ORWorkflowOverviewDashboardMarkerGroup,
  ORWorkflowOverviewDashboardMarkerType,
} from '@sqior/viewmodels/orworkflow';
import { ProcedureWrapperProps } from '@sqior/react/uiorworkflow';
import { set } from 'date-fns';

const today = new Date();
const makeDate = (hours: number, minutes: number) => set(today, { hours, minutes }).getTime();

export const fakeProcedure = {
  stateOverlay: true,
  autoScroll: false,
  motionLayout: 'position',
  timeCalcStart: makeDate(8, 0),
  timeCalcEnd: makeDate(16, 0),
  showChallenges: true,
  item: {
    entityType: 'ORWorkflowOverviewORItem',
    start: makeDate(9, 45),
    end: makeDate(14, 30),
    markers: [
      {
        label: 'S',
        timestamp: makeDate(10, 30),
        type: ORWorkflowOverviewDashboardMarkerType.Start,
        group: ORWorkflowOverviewDashboardMarkerGroup.Surgery,
      },
      {
        label: 'N',
        timestamp: makeDate(13, 45),
        type: ORWorkflowOverviewDashboardMarkerType.End,
        group: ORWorkflowOverviewDashboardMarkerGroup.Surgery,
      },
    ],
    component: {
      entityType: 'ORWorkflowOverviewProcedure',
      id: 'ProcedureId|bb02d1abe1',
      name: 'Mustermann, Max',
      sex: '<span class="symbol">♀</span>',
      dob: '29.3.1927',
      description: 'Eingriff',
      state: 'planned',
      step: 'Prozedur geplant',
      stage: 'normal',
      select: {
        type: 'add',
        path: 'patient-overview/select-patient',
        data: {
          entityType: 'ORWorkflowProcedureOverview',
          procedure: {
            entityType: 'ProcedureId',
            id: 'bb02d1abe1',
          },
        },
      },
      // indicator: {
      //   countdownType: 'emergency',
      //   countdown: makeDate(10, 30),
      //   countdownStart: makeDate(9, 45),
      //   countdownDuration: 43200000,
      // },
      duration: 120,
      team: ['Operateur', 'Assistent'],
      preSurgicalWard: 'SDS',
      postSurgicalCareLevel: 'I+',
      emergencyClassification: {
        category: 'n3',
        color: 'red',
        opacity: 0.75,
      },
      searchKeys: ['Helga', 'Müller'],
      specialty: {
        specialty: 'GYN',
        specialtyColor: 'white',
        specialtyLong: 'Gynäkologie',
      },
    },
    opaque: true,
    actions: [],
    extension: makeDate(9, 0),
  },
  contentHeight: 500,
  zoomLevel: 1,
  nextExtension: undefined,
  cardMinWidth: 208,
} as ProcedureWrapperProps;

export default fakeProcedure;
