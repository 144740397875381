import { isEqual, ValueObject } from '@sqior/js/data';
import React, { forwardRef, RefObject, useContext, useEffect, useRef, useState } from 'react';
import styles from './workflow-base-area.module.css';
import {
  AnimatedHeader,
  AnimatedHeaderLayout,
  CaveInfo,
  CaveInfoProps,
  CaveInfosFoldable,
  OnMountEffect,
} from '@sqior/react/uibase';
import { CaveSeverity, WorkflowInfoVM } from '@sqior/viewmodels/patient';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { IconButton, Menu, MenuItem, MenuList } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { WorkflowActionButtonVM } from '@sqior/viewmodels/workflow';
import { ComponentFactory, FactoryElement } from '@sqior/react/factory';
import { v4 as uuidv4 } from 'uuid';
import { positionValues, Scrollbars } from 'react-custom-scrollbars-2';

export interface WorkflowBaseAreaProps {
  children: React.ReactNode | React.ReactNode[];
  relatedId?: ValueObject;
  caveInfos?: CaveInfoProps[];
  allowUIScreen?: boolean;
  actions?: WorkflowActionButtonVM[];
  infos?: WorkflowInfoVM[];
  freeze: boolean;
}

function severityToNumber(severity?: CaveSeverity): number {
  if (severity === undefined) {
    return -1;
  }
  return {
    info: 0,
    warning: 1,
    critical: 2,
  }[severity];
}

function worstCaveInfo(infos: CaveInfoProps[]): CaveInfoProps {
  return infos.reduce((max, current) => {
    return severityToNumber(current.severity) > severityToNumber(max.severity) ? current : max;
  });
}

export const WorkflowBaseArea = forwardRef<Scrollbars | null, WorkflowBaseAreaProps>(
  (props, contentRef) => {
    const FactoryComponent = useContext(ComponentFactory);
    const { actions, infos } = props;
    const { show: showActions, overflow: overflowActions } = makeActionComponents(
      FactoryComponent,
      actions,
      2
    );

    const startY = useRef<number | null>(null); // Track the initial Y position of touch

    const [oldRelatedId, setOldRelatedId] = useState<ValueObject | undefined>(undefined);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [scrollPosition, setScrollPosition] = useState<number>(0);
    const [movedInPx, setMovedInPx] = useState<number>(0);
    const { ref, height = 1 } = useResizeObserver<HTMLDivElement>();

    // Do not display team in case the relatedId changes (which means the underlaying dato changed)
    useEffect(() => {
      if (!isEqual(oldRelatedId, props.relatedId)) {
        setOldRelatedId(props.relatedId);
      }
    }, [oldRelatedId, props.relatedId]);

    const worstCave: CaveInfoProps =
      props.caveInfos && props.caveInfos?.length > 0
        ? worstCaveInfo(props.caveInfos)
        : {
            severity: CaveSeverity.Info,
            description: '',
          };

    const sortedCaves = props.caveInfos
      ? [...props.caveInfos].sort((item1: CaveInfoProps, item2: CaveInfoProps) => {
          return severityToNumber(item2.severity) - severityToNumber(item1.severity);
        })
      : undefined;
    const severities = sortedCaves
      ? sortedCaves.map((item) => {
          return item.severity;
        })
      : [];

    let truncatedDescription = '';
    if (worstCave.description) {
      truncatedDescription = worstCave.description.substring(0, 25);
      if (truncatedDescription.length < worstCave.description.length) {
        truncatedDescription = truncatedDescription.substring(
          0,
          truncatedDescription.lastIndexOf(' ', 25)
        );
      }
    }

    const onOpenMoreActions = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const onScroll = () => {
      const ref = contentRef as RefObject<Scrollbars> | null;
      if (!ref || !ref.current) return;
      const scrollTop = ref.current.getScrollTop();
      const maxScroll = ref.current.getScrollHeight() - ref.current.getClientHeight();

      if (scrollTop > maxScroll) {
        return;
      }

      if (scrollTop < 0) {
        return;
      }
      setScrollPosition(scrollTop);
    };

    const onScrollStart = () => {
      const ref = contentRef as RefObject<Scrollbars> | null;
      if (!ref || !ref.current) return;
      startY.current = ref.current.getValues().scrollTop;
    };

    const onScrollStop = () => {
      const ref = contentRef as RefObject<Scrollbars> | null;
      if (!ref || !ref.current) return;
      const endY = ref.current.getValues().scrollTop;
      if (!startY.current) return;
      const moved = Math.abs(startY.current - endY);
      setMovedInPx(moved);
    };

    const onScrollFrame = (values: positionValues) => {
      const scrollY = values.scrollTop;
      if (startY.current === null) return;
      const moved = Math.abs(startY.current - scrollY);
      setMovedInPx(moved);
    };

    return (
      <div className={styles['container']} style={{ position: 'relative' }}>
        <AnimatedHeader
          height={height}
          position={scrollPosition}
          freeze={props.freeze}
          movedInPx={movedInPx}
        >
          <div ref={ref} className={styles['procedure-header']}>
            <AnimatedHeaderLayout items={infos} />
            <div className={styles['actions']}>
              {showActions}
              {overflowActions && overflowActions.length > 0 && (
                <>
                  <div>
                    <IconButton
                      onClick={onOpenMoreActions}
                      style={{ backgroundColor: 'rgba(255, 255, 255, 0.04)' }}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  </div>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    anchorPosition={
                      anchorEl
                        ? { top: anchorEl.offsetTop + 40, left: anchorEl.offsetLeft + 40 }
                        : undefined
                    }
                    style={{
                      zIndex: 9999999,
                    }}
                  >
                    <MenuList>
                      {overflowActions.map((action, index) => {
                        return (
                          <MenuItem key={index} onClick={handleClose}>
                            {action}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </Menu>
                </>
              )}
            </div>
          </div>
        </AnimatedHeader>
        <Scrollbars
          ref={contentRef}
          className={styles['content']}
          onScroll={onScroll}
          onScrollStart={onScrollStart}
          onScrollStop={onScrollStop}
          onScrollFrame={onScrollFrame}
        >
          <div className={styles['content-inner']} style={{ paddingTop: height + 8 }}>
            <CaveInfosFoldable severities={severities} defaultText={truncatedDescription}>
              {sortedCaves &&
                sortedCaves.map((item: CaveInfoProps) => (
                  <CaveInfo
                    key={JSON.stringify(item)}
                    severity={item.severity}
                    description={item.description}
                  />
                ))}
            </CaveInfosFoldable>
            {props.children}
          </div>
        </Scrollbars>
        <OnMountEffect />
      </div>
    );
  }
);

export default WorkflowBaseArea;

const makeActionComponents = (
  FactoryComponent: FactoryElement,
  data?: WorkflowActionButtonVM[],
  limit?: number
) => {
  if (!data) return { show: [], overflow: [] };
  const components = data.map((a, index) => {
    return (
      <FactoryComponent
        data={a}
        key={uuidv4()}
        short={limit ? data.length >= limit : undefined}
        overflow={limit ? index >= limit : undefined}
      />
    );
  });

  if (!limit)
    return {
      show: components,
      overflow: [],
    };

  const show = [...components].splice(0, limit);
  const overflow = [...components].splice(limit);

  return { show, overflow };
};
