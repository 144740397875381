import { Closable } from '@sqior/js/async';
import { Operation, OperationHandler } from '@sqior/js/operation';
import { State } from '@sqior/js/state';
import { StateHandler } from './state-handler';
import { StopListening } from '@sqior/js/event';

/** This class represents a state handler that proxies the state from another state handler and relays the operations to it */
export class ProxyStateHandler implements OperationHandler, Closable {
  constructor() {
    /* Listen for usage to update source, if applicable */
    this.useListener = this.state.onUseChanged((use) => {
      /* Update source state use, if applicable */
      if (this.source) this.source.state.updateUse(use);
    });
  }
  async close() {
    if (this.useListener) this.useListener();
    const source = this.detach();
    if (source) await source.close();
  }

  /** Handles an operation */
  handle(op: Operation, path: string) {
    /* Relay if applicable */
    if (this.source) this.source.handle(op, path);
    else op.fail();
  }

  /** Attaches a new proxy source */
  async attach(source: StateHandler) {
    /* Detach first */
    const oldSource = this.detach();
    if (oldSource) await oldSource.close();
    /* Set new source */
    this.source = source;
    /* Copy the state */
    this.state.set(source.state.getRaw());
    /* Observe the source state for changes */
    this.listener = source.state.on((value) => {
      this.state.set(value);
    });
    /* Map all substates */
    for (const subState of source.state.getSubStates()) {
      this.state.map(subState[0], subState[1]);
    }
    /* Watch for substates added later and map into state accordingly */
    this.sourceAnyChangeObs = source.state.anyChange.on((path) => {
      if (path != '/') {
        const path2 = path.substring(1);
        const subState = source.state.getSubStates().get(path2);
        if (this.state.getSubStates().get(path2) !== subState) {
          this.state.map(path2, subState);
        }
      }
    });
    /* Register the use, if applicable */
    if (this.state.used) source.state.use();
  }

  /** Detaches this from the proxy source */
  detach() {
    if (this.listener) this.listener();
    this.listener = undefined;
    if (this.sourceAnyChangeObs) this.sourceAnyChangeObs();
    this.sourceAnyChangeObs = undefined;
    const source = this.source;
    this.source = undefined;
    /* Unmap all previously mapped substates */
    for (const subState of this.state.getSubStates()) {
      this.state.map(subState[0], undefined);
    }
    /* Deregister use, if applicable */
    if (source && this.state.used) source.state.dropUse();
    return source;
  }

  protected useListener: StopListening;
  protected source?: StateHandler;
  private listener?: StopListening;
  private sourceAnyChangeObs?: StopListening;
  readonly state = new State({ onDemand: true });
}
