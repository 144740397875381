import { EntityHeader } from '@sqior/js/entity';
import { CoreEntities, CoreInterfaces } from './core-definitions';
import { EntityModel } from './entity';
import { Interface } from './interface';

/* Definition of the basic notification entity */

export type NotificationEntity = EntityHeader;
export const NotificationEntityModel: EntityModel = { type: CoreEntities.Notification, props: [] };
export function createNotificationEntity(type: string): NotificationEntity {
  return { entityType: type };
}

/** Interface for identifying notifications that are emitted to inform about an update of information */
export const UpdateNotificationModel: Interface = { type: CoreInterfaces.UpdateNotification };
