import { BiChannel } from '@sqior/js/message';
import {
  isFinal,
  Operation,
  OperationEmitter,
  OperationFactory,
  OperationType,
  StreamOperation,
} from '@sqior/js/operation';
import {
  OperationMessageType,
  OperationProgressMessage,
  OperationRequestMessage,
  OperationStateMessage,
  OperationStreamMessage,
} from './operation-message';

export class OperationReceiver extends OperationEmitter {
  constructor(channel: BiChannel) {
    super();
    this.channel = channel;
    this.ops = new Map<number, Operation>();

    /* Listen for a close of the outgoing channel */
    this.channel.onClose(() => {
      this.clear();
    });

    /* Listen for the incoming request message */
    this.channel.in.on<OperationRequestMessage>(OperationMessageType.Request, (reqMsg) => {
      /* Create operation */
      const op = OperationFactory.create(reqMsg.opType, reqMsg.data);
      /* Listen for progress updates */
      op.progressChange.on((perc) => {
        if (this.channel.out.isOpen)
          this.channel.out.send<OperationProgressMessage>({
            type: OperationMessageType.Progress,
            id: reqMsg.id,
            progress: perc,
          });
      });
      /* Listen for state updates */
      op.stateChange.on((state) => {
        /* Erase from map if final */
        if (isFinal(state)) this.ops.delete(reqMsg.id);
        if (!this.channel.out.isOpen) return;
        this.channel.out.send<OperationStateMessage>({
          type: OperationMessageType.State,
          id: reqMsg.id,
          data: op.resultToJSON(),
        });
      });
      /* Remember */
      this.ops.set(reqMsg.id, op);
      /* Emit */
      this.operation.emit(op, reqMsg.path);
    });

    /* Listen for incoming stream data */
    this.channel.in.on<OperationStreamMessage>(OperationMessageType.Stream, (streamMsg) => {
      const op = this.ops.get(streamMsg.id);
      if (op) {
        const streamOp = op as StreamOperation;
        if (streamMsg.data !== undefined) streamOp.send(streamMsg.data);
        else streamOp.close();
      }
    });
  }

  clear() {
    /* Closing all stream operations */
    for (const op of this.ops.values())
      if (op.type === OperationType.Stream) (op as StreamOperation).close();
    this.ops.clear();
  }

  private channel: BiChannel;
  private ops: Map<number, Operation>;
}
