import { NoTimestamp } from '@sqior/js/data';
import { DataResult, Dispatcher, StdOperationHandler } from '@sqior/js/operation';
import { IFilteredSetState, State } from '@sqior/js/state';
import { joinPath } from '@sqior/js/url';

export type MaxCountFilteredSetStateData = {
  maxCountDelta?: number;
  maxCount?: number;
};

export function addFilterSetStateAndHandlers(
  baseState: State,
  dispatcher: Dispatcher,
  statePath: string,
  filteredState: IFilteredSetState
) {
  dispatcher.register(
    new MaxCountFilteredSetStateHandler(filteredState),
    joinPath(statePath, 'maxCount')
  );
  baseState.map(statePath, filteredState.state);
}

export class MaxCountFilteredSetStateHandler extends StdOperationHandler<MaxCountFilteredSetStateData> {
  private filteredSetState?: IFilteredSetState;
  constructor(filteredSetState?: IFilteredSetState) {
    super();
    this.filteredSetState = filteredSetState;
  }

  SetFilteredSetState(filteredSetState: IFilteredSetState) {
    this.filteredSetState = filteredSetState;
  }

  override async add(data: MaxCountFilteredSetStateData /*, path: string */): Promise<DataResult> {
    if (this.filteredSetState !== undefined) {
      if (data.maxCountDelta !== undefined) {
        if (this.filteredSetState.maxCount !== undefined)
          this.filteredSetState.setMaxCount(this.filteredSetState.maxCount + data.maxCountDelta);
      } else if (data.maxCount !== undefined) {
        this.filteredSetState.setMaxCount(data.maxCount);
      }
    }

    return ['', NoTimestamp];
  }
}
