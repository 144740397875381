import { PluginContext } from '@sqior/js/plugin';
import { FactoryContext } from '@sqior/react/factory';
import { AddressSelectionType } from '@sqior/viewmodels/user';
import AddressSelectionControl from './address-selection-control/address-selection-control';
import { ClosableChat } from './closable-chat/closable-chat';
import { PageChatType } from '@sqior/viewmodels/communication';
import { DispatcherContext } from '@sqior/js/operation';
import { PhoneCallPath } from '@sqior/viewmodels/device';
import { PhoneCallHandler } from './phone-call-handler';

export function UIChatPlugin(context: PluginContext & FactoryContext & DispatcherContext) {
  context.factory.add(AddressSelectionType, AddressSelectionControl);
  context.factory.add(PageChatType, ClosableChat);
  context.dispatcher.register(new PhoneCallHandler(), PhoneCallPath);
}
