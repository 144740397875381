import { CoreEntities, Interface } from '@sqior/js/meta';

export enum TimeEntities {
  Date = 'Date',
  Time = 'Time',
  Timestamp = 'Timestamp',
  Duration = 'Duration',
  AddDuration = 'AddDuration',
  DurationLessThan = 'DurationLessThan',
  RelativeDate = 'RelativeDate',
  TimestampLessThan = 'TimestampLessThan',
  SetTimer = 'SetTimer',
  InSeconds = 'InSeconds',
  DurationInput = 'DurationInput',
  ProbabilisticTimestamp = 'ProbabilisticTimestamp',
  ProbabilisticDuration = 'ProbabilisticDuration',
  Dates = 'Dates', // List of dates
  TimerDuration = 'TimerDuration', // Timer duration
  TimestampDifference = 'TimestampDifference', // Function determining the difference duration of two timestamps
  TimeRange = 'TimeRange',
  Timestamps = 'Timestamps', // Array of timestamps
  Durations = 'Durations', // Array of durations
  Hours = 'Hours', // Hours (0-23)
  TimestampInput = 'TimestampInput', // Input for a timestamp
  DateRange = 'DateRange', // Date range
  Weekday = 'Weekday', // Weekday (Sunday to Saturday)
  PeriodicDate = 'PeriodicDate', // Event period like weekly, bi-weekly
  DateSelection = 'DateSelection', // Sequential date selection (prev/next)
}

export enum TimeInterfaces {
  DateOrTimestamp = 'DateOrTimestamp',
  TimestampOrStartOfDay = 'TimestampOrStartOfDay',
  TimestampOrEndOfDay = 'TimestampOrEndOfDay',
  DurationOrText = 'DurationOrText',
  DailyTime = 'DailyTime',
  ISODate = 'ISODate', // Returns the date in ISO format yyyy-mm-dd
  PeriodicTimestamp = 'PeriodicTimestamp', // Timestamp that changes based on the source of the mapping
  TextTimeOrDatetime = 'TextTimeOrDatetime', // Returns either a timestamp as text, if today only time otherwise date and time
  InTimeRange = 'InTimeRange', // Checks whether the current time is within the specified time range
  WorkDay = 'IsWorkDay', // Checks whether a specified date represents a work day
  InPast = 'InPast', // Checks whether a specified timestamp is in the past
  Weekday = 'GetWeekday', // Returns the weekday of a specified date
  SelectedDate = 'SelectedDate', // Returns the selected date
  ShowDateSelection = 'ShowDateSelection', // Checks whether to show the date selection
}

/* Interface for entities that either designate an exact date or an approximated timestamp */

export const DateOrTimestampModel: Interface = {
  type: TimeInterfaces.DateOrTimestamp,
  requires: [TimeEntities.Date, CoreEntities.Timestamp],
};

export const TimestampOrStartOfDayModel: Interface = {
  type: TimeInterfaces.TimestampOrStartOfDay,
  requires: CoreEntities.Timestamp,
};
export const TimestampOrEndOfDayModel: Interface = {
  type: TimeInterfaces.TimestampOrEndOfDay,
  requires: CoreEntities.Timestamp,
};

/* Interface for entities that either designate a duration or a replacement text */

export const DurationOrTextModel: Interface = {
  type: TimeInterfaces.DurationOrText,
  requires: [TimeEntities.Duration, CoreEntities.Text],
};

/* Interface returning the current date switching at the specified day time */

export const DailyTimeModel: Interface = {
  type: TimeInterfaces.DailyTime,
  requires: TimeEntities.Date,
};

/* Interface returning a timestamp that changes with the specified duration */

export const PeriodicTimestampModel: Interface = {
  type: TimeInterfaces.PeriodicTimestamp,
  requires: CoreEntities.Timestamp,
};

export const TextTimeOrDatetimeModel: Interface = {
  type: TimeInterfaces.TextTimeOrDatetime,
  requires: CoreEntities.Text,
};

export const InPastModel: Interface = {
  type: TimeInterfaces.InPast,
};
