import { CacheCleanup, CacheState, CacheStateType, CombinedCacheState } from '@sqior/js/cache';
import {
  addHours,
  ArraySource,
  ensureArray,
  isEqual,
  KeyPairMap,
  lastElement,
  makeImmutable,
  StdTimer,
  TimerInterface,
} from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { Logger } from '@sqior/js/log';
import { EntityRecord } from './entity';
import { EntityMappingCache, EntityMappingCacheResult } from './entity-mapping-cache';
import {
  MappingContextKeys,
  mappingContextKeysAdd,
  mappingContextKeysClone,
  mappingContextKeysToArray,
} from './entity-mapping-context-keys';
import {
  applyAfter,
  applyAfterEval,
  Closable,
  DeadlockAccessor,
  DeadlockDetector,
} from '@sqior/js/async';
import { ContextPropertyModel } from './context-property';
import { Models } from './models';
import { Undefined } from './function';

export type EntityMappingTrace = { accessors?: DeadlockAccessor[] };
type EntityMapFunc<SourceType extends Entity | Entity[] = Entity> = (
  entity: SourceType,
  context: EntityRecord,
  trace: EntityMappingTrace
) => Promise<EntityMappingCacheResult> | EntityMappingCacheResult;
type SyncEntityMapFunc = (
  entity: Entity,
  context: EntityRecord,
  trace: EntityMappingTrace
) => EntityMappingCacheResult;
type MapEntry = {
  func: EntityMapFunc;
  sync?: SyncEntityMapFunc;
  trivial: boolean;
  essentialContext?: Set<string>;
};
type RouteEntry = { weight: number; path?: string[]; map?: MapEntry };
/* Tuple versions */
type TupleMapEntry = {
  func: EntityMapFunc<Entity[]>;
  essentialContext?: Set<string>;
};
type TupleRouteEntry = { func?: EntityMapFunc<Entity[]> };
type DirectTupleMappingEntry = {
  target: string;
  others: string[];
  weight: number;
  entry: TupleMapEntry;
};

/** Helper class for mapping entities */

export class EntityMapping implements Closable {
  constructor(
    models: Models,
    contextProperties: Map<string, ContextPropertyModel>,
    timer: TimerInterface = new StdTimer()
  ) {
    this.models = models;
    this.contextProperties = contextProperties;
    this.cache = new EntityMappingCache(new CacheCleanup(addHours(1), timer));
  }

  /** Deactivates by closing the cache */
  async close() {
    await this.cache.close();
  }

  /** Creates a mapping that optionally determines the value from cache and caches the result */
  private makeCachingMapping<SourceType extends Entity | Entity[] = Entity>(
    from: string | string[],
    to: string,
    mapping: EntityMapFunc<SourceType>,
    essentialContext?: Set<string>
  ): EntityMapFunc<SourceType> {
    /* Check if the source type is cacheable at all */
    for (const ft of ensureArray(from)) if (!this.models.get(ft).keyable) return mapping;
    return (entity: SourceType, context: EntityRecord, trace: EntityMappingTrace) => {
      /* Calculate key for cache */
      const key = this.models.key(entity);
      /* Convert the values from the context to keys, only consider the essential context (= declared non-auto-forwarded properties)
         and auto-forwarded properties */
      const cacheContext: Record<string, string> = {};
      for (const contextKey in context)
        if (
          this.contextProperties.get(contextKey)?.autoForward ||
          essentialContext?.has(contextKey)
        )
          cacheContext[contextKey] = this.models.key(context[contextKey]);
      /* Try to look up from cache */
      const cacheRes = this.cache.get(key, cacheContext, to);
      if (typeof cacheRes !== 'string') {
        /* Check if a preliminary cache entry is hit, if yes register for deadlock prevention */
        if (cacheRes instanceof Array) {
          /* Register access to preliminary cache key, if this has already registered */
          if (trace.accessors) {
            const blockingChain = this.deadlockDetector.checkAccess(
              cacheRes[1] + '|>' + to,
              trace.accessors
            );
            if (blockingChain) {
              Logger.debug([
                'Circular deadlock detected in mapping from:',
                cacheRes[1],
                'to:',
                to,
                'trace:',
                trace.accessors
                  .map((acc) => {
                    return acc.id;
                  })
                  .join(', '),
                'chain:',
                blockingChain
                  .map((acc) => {
                    return acc.id;
                  })
                  .join(', '),
              ]);
              throw new Error('Deadlock detected in mapping from: ' + from + ' to: ' + to);
            }
          }
          /* Determine cache value */
          return applyAfter(cacheRes[0], EntityMappingCache.incCacheUse, undefined, () => {
            /* Release access */
            if (trace.accessors) this.deadlockDetector.releaseAccess(trace.accessors);
          });
        } else return EntityMappingCache.incCacheUse(cacheRes);
      }
      /* Register access to preliminary cache key */
      const id = cacheRes + '|>' + to;
      const accessor = { id };
      const release = this.deadlockDetector.register(accessor, id);
      if (!release) {
        Logger.debug([
          'Deadlock detected in mapping from:',
          cacheRes,
          'to:',
          to,
          'trace:',
          ensureArray(trace.accessors)
            .map((acc) => {
              return acc.id;
            })
            .join(', '),
        ]);
        throw new Error('Deadlock detected in mapping from: ' + from + ' to: ' + to);
      }
      /* Evaluate the mapping and cache the promise */
      const finalizer = (exc: unknown) => {
        /* Release cache access */
        release();
        if (exc !== undefined)
          Logger.debug([
            'Unexpected exception when evaluating cache for:',
            'entityType' in entity ? entity.entityType : entity.map((e) => e.entityType),
            'to:',
            to,
            '- exception:',
            Logger.exception(exc),
          ]);
      };
      try {
        return this.cache.set(
          key,
          cacheRes,
          cacheContext,
          to,
          mapping(entity, context, {
            accessors: trace.accessors ? [...trace.accessors, accessor] : [accessor],
          }),
          finalizer
        );
      } catch (exc) {
        finalizer(exc);
        throw exc;
      }
    };
  }

  /** Creates a mapping that does not invalidate the result immediately but rather re-evaluates
   *  and only invalidates once the value is different from the original */
  private static makeValueComparisonMapping<SourceType extends Entity | Entity[] = Entity>(
    models: Models,
    cache: EntityMappingCache,
    mapping: EntityMapFunc<SourceType>,
    to: string
  ) {
    return async (entity: SourceType, context: EntityRecord, trace: EntityMappingTrace) => {
      /* Evaluate the mapping */
      const res = await mapping(entity, context, trace);
      /* If the mapping is permanently valid, return it */
      if (!res.cache || (res.cache.valid && !res.cache.invalidated)) return res;
      /* Calculate key for cache */
      const key = models.key(entity);
      /* Convert the values from the context to keys */
      const cacheContext: Record<string, string> = {};
      for (const contextKey in context) cacheContext[contextKey] = models.key(context[contextKey]);
      /* Create an own cache state */
      const cacheState = new CacheState(CacheStateType.Closable);
      EntityMapping.handleValueComparisonResult(
        res,
        () => {
          return mapping(entity, context, trace);
        },
        cacheState,
        (res: EntityMappingCacheResult) => {
          /* Set cache entry */
          cache.setFinal(key, cacheContext, to, res);
        }
      );
      return { ...res, cache: cacheState };
    };
  }
  private static handleValueComparisonResult(
    orig: EntityMappingCacheResult,
    recalculate: () => Promise<EntityMappingCacheResult> | EntityMappingCacheResult,
    cacheState: CacheState,
    setCache: (res: EntityMappingCacheResult) => void
  ) {
    /* Define what needs to be done if the result is invalid */
    const onInvalid = () => {
      orig.cache?.decRef();
      if (cacheState.refCount)
        applyAfter(
          recalculate(),
          (res) => {
            /* Check if result changed */
            if (
              !isEqual(res.result, orig.result) ||
              !isEqual(
                mappingContextKeysToArray(res.contextKeys),
                mappingContextKeysToArray(orig.contextKeys)
              )
            ) {
              cacheState.invalidate();
              setCache({ ...res, cache: (cacheState = new CacheState(CacheStateType.Closable)) });
            }
            EntityMapping.handleValueComparisonResult(res, recalculate, cacheState, setCache); // Recurse for this result
          },
          () => {
            cacheState.invalidate();
          }
        );
    };
    /* Either trigger immediately or if invalid */
    if (orig.cache?.valid === false) onInvalid();
    else if (orig.cache?.invalidated) {
      let stopInvalid: (() => void) | undefined = undefined;
      const stopClose = cacheState.closed?.on(() => {
        stopInvalid?.();
        orig.cache?.decRef();
      });
      stopInvalid = orig.cache.invalidated.on(() => {
        stopClose?.();
        onInvalid();
      });
    } else orig.cache?.decRef();
  }

  /** Extracts the non-auto-forwarded context parameters */
  private essentialContext(props?: ArraySource<string>): Set<string> | undefined {
    let context: Set<string> | undefined;
    if (props)
      for (const prop of ensureArray(props)) {
        const meta = this.contextProperties.get(prop);
        if (!meta)
          throw new Error('Mapping registered with referencing unknown context property: ' + prop);
        if (meta.autoForward) continue;
        if (!context) context = new Set<string>([prop]);
        else context.add(prop);
      }
    return context;
  }

  /** Adds a asynchronous mapping connected two types */
  addSync(
    from: string,
    to: string,
    mapping: SyncEntityMapFunc,
    options: {
      context?: string | string[];
      weight?: number;
      trivial?: boolean;
      append?: boolean;
    }
  ) {
    this.directMappings.set(from, to, {
      weight: options.weight ?? 1,
      append: options.append ?? true,
      map: {
        func: mapping,
        sync: mapping,
        trivial: options.trivial ?? false,
        essentialContext: this.essentialContext(options.context),
      },
    });
  }

  /** Adds an (asynchronous) mapping connected two types */
  add<SourceType extends Entity | Entity[] = Entity>(
    from: string | string[],
    to: string,
    mapping: EntityMapFunc<SourceType>,
    options: {
      context?: string | string[];
      weight?: number;
      cache?: boolean;
      valueComparison?: boolean;
      append?: boolean;
    }
  ) {
    /* Reduce the context to the non-auto-forwarded properties */
    const essentialContext = this.essentialContext(options.context);
    /* Establish a caching mapping if the input is not trivial */
    if (options.cache)
      mapping = this.makeCachingMapping(
        from,
        to,
        options.valueComparison
          ? EntityMapping.makeValueComparisonMapping(this.models, this.cache, mapping, to)
          : mapping,
        essentialContext
      );
    /* Check if a single element of tuple shall be mapped */
    if (typeof from === 'string')
      this.directMappings.set(from, to, {
        weight: options.weight ?? 1,
        append: options.append ?? true,
        map: { func: mapping as EntityMapFunc, trivial: false, essentialContext },
      });
    else {
      /* Add to mappings for this argument count */
      let dtm = this.directTupleMappings.get(from.length);
      if (!dtm)
        this.directTupleMappings.set(
          from.length,
          (dtm = new KeyPairMap<string, string, DirectTupleMappingEntry>())
        );
      const others = from.slice(1);
      dtm.set(from[0], others.concat(to).join('|'), {
        others,
        target: to,
        weight: options.weight ?? 1,
        entry: { func: mapping as EntityMapFunc<Entity[]>, essentialContext },
      });
    }
  }

  /** Adds a tuple mapping connected */
  addTupleMapping(
    from: string[],
    to: string,
    mapping: EntityMapFunc<Entity[]>,
    options: {
      context?: string | string[];
      weight?: number;
      cache?: boolean;
      valueComparison?: boolean;
    }
  ) {
    /* Reduce the context to the non-auto-forwarded properties */
    const essentialContext = this.essentialContext(options.context);
    /* Establish a caching mapping if the input is not trivial */
    if (options.cache)
      mapping = this.makeCachingMapping(
        from,
        to,
        options.valueComparison
          ? EntityMapping.makeValueComparisonMapping(this.models, this.cache, mapping, to)
          : mapping,
        essentialContext
      );
    /* Add to mappings for this argument count */
    let dtm = this.directTupleMappings.get(from.length);
    if (!dtm)
      this.directTupleMappings.set(
        from.length,
        (dtm = new KeyPairMap<string, string, DirectTupleMappingEntry>())
      );
    const others = from.slice(1);
    dtm.set(from[0], others.concat(to).join('|'), {
      others,
      target: to,
      weight: options.weight ?? 1,
      entry: { func: mapping, essentialContext },
    });
  }

  mapEntity(
    entity: Entity,
    type: string,
    context: EntityRecord,
    trace: EntityMappingTrace
  ): EntityMappingCacheResult | Promise<EntityMappingCacheResult> {
    /* Check if input type is identical to the target type */
    if (entity.entityType === type) return { result: entity };
    // console.log('Mapping', entity.entityType, type);
    /* Check if this can be mapped */
    let mapper: MapEntry | undefined;
    try {
      mapper = this.ensureMapping(entity.entityType, type);
    } catch (e) {
      Logger.error([
        'Unexpected exception when calculating map route from:',
        entity.entityType,
        'to:',
        type,
        '- exception:',
        Logger.exception(e),
      ]);
      throw e;
    }
    if (mapper) {
      const theMapper = mapper;
      return applyAfterEval(
        () => {
          return theMapper.func(entity, context, trace);
        },
        (a) => {
          return a;
        },
        (e) => {
          Logger.error([
            'Unexpected exception when mapping from:',
            entity.entityType,
            'to:',
            type,
            '- exception:',
            Logger.exception(e),
          ]);
          throw e;
        }
      );
    }
    return {};
  }

  /** Maps an entity or tuple to a target type */
  map(
    entity: Entity | Entity[],
    type: string,
    context: EntityRecord,
    trace: EntityMappingTrace
  ): EntityMappingCacheResult | Promise<EntityMappingCacheResult> {
    /* Check if this is a single input entity */
    if (!(entity instanceof Array)) return this.mapEntity(entity, type, context, trace);
    return this.mapTuple(entity, type, context, trace);
  }

  /** Maps an entity or tuple to a target type */
  mapTuple(
    tuple: Entity[],
    type: string,
    context: EntityRecord,
    trace: EntityMappingTrace
  ): EntityMappingCacheResult | Promise<EntityMappingCacheResult> {
    /* Check if is a degenerated tuple */
    if (!tuple.length) return this.mapEntity(Undefined, type, context, trace);
    if (tuple.length === 1) return this.mapEntity(tuple[0], type, context, trace);
    //console.log('Mapping', tuple.map((ent) => ent.entityType).join('/'), type);
    /* Check if this can be mapped */
    const types = tuple.map((entity) => entity.entityType);
    let mapper: EntityMapFunc<Entity[]> | undefined;
    try {
      mapper = this.ensureTupleMapping(types, type);
    } catch (e) {
      Logger.error([
        'Unexpected exception when calculating tuple map route from:',
        types,
        'to:',
        type,
        '- exception:',
        Logger.exception(e),
      ]);
      throw e;
    }
    if (mapper) {
      const theMapper = mapper;
      return applyAfterEval(
        () => {
          return theMapper(tuple, context, trace);
        },
        (a) => {
          return a;
        },
        (e) => {
          Logger.error([
            'Unexpected exception when mapping tuple from:',
            types,
            'to:',
            type,
            '- exception:',
            Logger.exception(e),
          ]);
          throw e;
        }
      );
    }
    return {};
  }

  /** Finds a route to map a source to a target type */
  private findRoute(from: string, to: string): RouteEntry {
    /* Check if route is already known */
    const exRoute = this.mappings.get(from, to);
    if (exRoute) return exRoute;
    /* Check if all routes from this source are known */
    if (this.allRoutesFound.has(from)) return EntityMapping.NoRoute;
    /* Determine all routes */
    this.findAllRoutes(from);
    /* Return route, if applicable */
    return this.mappings.get(from, to) ?? EntityMapping.NoRoute;
  }

  /** Detects all paths from a source type */
  private findAllRoutes(from: string) {
    /* Check if all routes from this source are already known */
    if (this.allRoutesFound.has(from)) return;
    const candidates: RouteEntry[] = [EntityMapping.NoRoute];
    for (;;) {
      /* Inspect the next candidate */
      const candidate = candidates.shift();
      if (!candidate) break;
      /* Inspect all direct mappings from the current candidate */
      const source = lastElement(candidate.path) ?? from;
      for (const mapping of this.directMappings.map.get(source) ?? []) {
        const target = mapping[0];
        /* Do not consider routes returning to the origin or if they have not been specified to be connected */
        if (target === from || (!mapping[1].append && source !== from)) continue;
        /* Check if a mapping is already entered */
        const weight = candidate.weight + mapping[1].weight;
        const exMapping = this.mappings.get(from, target);
        /* Enter this if there is no mapping registered or the existing mapping has a less favorable weight */
        if (!exMapping || exMapping.weight > weight) {
          const newEntry = { path: candidate.path?.concat(target), weight };
          this.mappings.set(from, target, newEntry);
          /* Register this to determine all routes from this point */
          candidates.push(newEntry);
          continue;
        }
        /* Skip if the existing mapping is favorable in terms of weight */
        if (weight > exMapping.weight) continue;
        /* Emit a warning about ambiguity if the path differs */
        const path = candidate.path?.concat(target);
        if (isEqual(path, exMapping.path))
          /* This has been inserted by a prior explicit path search, still, the routes from this need to be inspected */
          candidates.push(exMapping);
        else
          Logger.warn([
            'Ambiguous sum of weights in entity mapping from:',
            from,
            'provided:',
            path,
            'vs:',
            exMapping.path,
          ]);
      }
    }
    /* Register that all routes from this source have been determined */
    this.allRoutesFound.add(from);
  }

  /** Combines the multiple mapping functions for a tuple mapping */
  private combineTupleMapping(
    from: string[],
    to: string,
    input: (MapEntry | undefined)[],
    tupleMapping: EntityMapFunc<Entity[]>,
    essentialContext: Set<string> | undefined,
    output: MapEntry | undefined
  ): EntityMapFunc<Entity[]> {
    /* Extract the non-trivial mappings of the inputs */
    const nonTrivialInputMappings = input.map((im) => (im && !im.trivial ? im.func : undefined));
    /* Check if all input mappings are trivial */
    let inputTupleMapping = tupleMapping;
    if (nonTrivialInputMappings.find((ntim) => ntim)) {
      /* Combine the input mappings with the inner tuple mapping */
      inputTupleMapping = async (tuple, context, trace) => {
        /* Map the input tuple elements */
        const mappedTuple: Entity[] = [];
        let cacheState: CacheState | undefined;
        let contextKeys: MappingContextKeys;
        for (let i = 0; i < tuple.length; i++) {
          const ntim = nonTrivialInputMappings[i];
          if (ntim) {
            const res = await ntim(tuple[i], context, trace);
            cacheState = CombinedCacheState.combine(cacheState, res.cache);
            contextKeys = mappingContextKeysAdd(
              mappingContextKeysClone(contextKeys),
              res.contextKeys
            );
            if (!res.result) return { cache: cacheState, contextKeys };
            mappedTuple.push(res.result);
          } else mappedTuple.push(tuple[i]);
        }
        /* Perform the actual tuple mapping */
        const res = await tupleMapping(mappedTuple, context, trace);
        return {
          result: res.result,
          cache: CombinedCacheState.combine(cacheState, res.cache),
          contextKeys: mappingContextKeysAdd(mappingContextKeysClone(contextKeys), res.contextKeys),
        };
      };
      /* Combine essential contexts */
      for (const im of input)
        if (im)
          essentialContext = EntityMapping.combineEssentialContexts(
            essentialContext,
            im.essentialContext
          );
    }
    /* Check if the final output mapping is not trivial */
    let resultMapping = inputTupleMapping;
    if (output && !output.trivial) {
      const outputMapFunc = output.func;
      resultMapping = async (tuple, context, trace) => {
        /* Perform tuple mapping first */
        const intRes = await inputTupleMapping(tuple, context, trace);
        if (!intRes.result) return intRes;
        /* Perform output mapping */
        const res = await outputMapFunc(intRes.result, context, trace);
        return this.combinedMappingResults(intRes, res);
      };
      essentialContext = EntityMapping.combineEssentialContexts(
        essentialContext,
        output.essentialContext
      );
    }
    return this.makeCachingMapping(from, to, resultMapping, essentialContext);
  }

  /** Trys a to find to route a tuple of types to a target type */
  private ensureTupleMapping(from: string[], to: string): EntityMapFunc<Entity[]> | undefined {
    const fromKey = from.join('|');
    /* Check if route is already known */
    const exRoute = this.tupleMappings.get(fromKey, to);
    if (exRoute) return exRoute.func;
    /* Get the tuple mappings with this argument count */
    const dtm = this.directTupleMappings.get(from.length);
    if (!dtm) {
      this.tupleMappings.set(fromKey, to, {});
      return undefined;
    }
    /* Check for all possible tuple mappings */
    const bestRoute: {
      paramRoutes?: RouteEntry[];
      mapping?: TupleMapEntry & { from: string[]; to: string };
      endRoute?: RouteEntry;
      weight: number;
    } = {
      weight: 0,
    };
    for (const startOptions of dtm.map) {
      /* Determine route for first parameter */
      const startRoute =
        startOptions[0] !== from[0] ? this.findRoute(from[0], startOptions[0]) : { weight: 0 };
      if (
        startRoute?.path?.length === 0 ||
        (bestRoute.paramRoutes && startRoute.weight > bestRoute.weight)
      )
        continue;
      /* Check the tuple mappings with this first parameter type */
      for (const entry of startOptions[1].values()) {
        /* Early exit if the weight of this mapping already exceeds the best route */
        let weight = startRoute.weight + entry.weight;
        if (bestRoute.paramRoutes && weight > bestRoute.weight) continue;
        /* Determine route to target */
        const endRoute = entry.target !== to ? this.findRoute(entry.target, to) : { weight: 0 };
        if (
          endRoute?.path?.length === 0 ||
          (bestRoute.paramRoutes && endRoute.weight + weight > bestRoute.weight)
        )
          continue;
        weight += endRoute.weight;
        /* Check the other parameters */
        let otherRoutes: RouteEntry[] | undefined = [];
        for (let i = 0; i < entry.others.length; i++) {
          const otherRoute =
            entry.others[i] !== from[i + 1]
              ? this.findRoute(from[i + 1], entry.others[i])
              : { weight: 0 };
          if (
            otherRoute?.path?.length === 0 ||
            (bestRoute.paramRoutes && weight + otherRoute.weight > bestRoute.weight)
          ) {
            otherRoutes = undefined;
            break;
          }
          otherRoutes.push(otherRoute);
          weight += otherRoute.weight;
        }
        if (otherRoutes === undefined) continue;
        if (bestRoute.paramRoutes && weight === bestRoute.weight) {
          Logger.warn([
            'Ambiguous tuple mapping',
            from,
            '->',
            to,
            'via:',
            bestRoute.mapping?.from,
            '->',
            bestRoute.mapping?.to,
            'vs.:',
            [startOptions[0]].concat(entry.others),
            '->',
            entry.target,
          ]);
          continue;
        }
        bestRoute.paramRoutes = [startRoute].concat(otherRoutes);
        bestRoute.mapping = {
          ...entry.entry,
          from: [startOptions[0]].concat(entry.others),
          to: entry.target,
        };
        bestRoute.endRoute = endRoute;
        bestRoute.weight = weight;
      }
    }
    /* Record if no route was found */
    if (!bestRoute.paramRoutes || !bestRoute.mapping || !bestRoute.endRoute) {
      this.tupleMappings.set(fromKey, to, {});
      return undefined;
    }
    /* Check if this is a direct mapping */
    const usedKey = bestRoute.mapping.from.join('|');
    if (usedKey === fromKey && bestRoute.mapping.to === to) {
      this.tupleMappings.set(fromKey, to, { func: bestRoute.mapping.func });
      return bestRoute.mapping.func;
    }
    /* Register the used tuple mapping if applicable */
    let tupleFunc = this.tupleMappings.get(usedKey, bestRoute.mapping.to)?.func;
    if (!tupleFunc)
      this.tupleMappings.set(usedKey, bestRoute.mapping.to, {
        func: (tupleFunc = bestRoute.mapping.func),
      });
    /* Determine the combined function */
    const paramMapEntries: (MapEntry | undefined)[] = [];
    for (let i = 0; i < from.length; i++)
      paramMapEntries.push(
        from[i] !== bestRoute.mapping.from[i]
          ? this.ensureMapping(from[i], bestRoute.mapping.from[i])
          : undefined
      );
    const combinedFunc = this.combineTupleMapping(
      from,
      to,
      paramMapEntries,
      tupleFunc,
      bestRoute.mapping.essentialContext,
      bestRoute.mapping.to !== to ? this.ensureMapping(bestRoute.mapping.to, to) : undefined
    );
    this.tupleMappings.set(fromKey, to, { func: combinedFunc });
    return combinedFunc;
  }

  /** Combineds two mappings results */
  private combinedMappingResults(
    first: EntityMappingCacheResult,
    second: EntityMappingCacheResult
  ) {
    return {
      result: second.result,
      cache: CombinedCacheState.combine(first.cache, second.cache),
      contextKeys: mappingContextKeysAdd(
        mappingContextKeysClone(second.contextKeys),
        first.contextKeys
      ),
    };
  }

  /** Combines two essential contexts */
  private static combineEssentialContexts(
    first: Set<string> | undefined,
    second?: Set<string>
  ): Set<string> | undefined {
    if (!first) return second;
    if (!second) return first;
    return new Set<string>([...first.keys()].concat(...second.keys()));
  }

  /** Creates a combined mappings from two legs */
  private combineMappings(from: string, to: string, first: MapEntry, second: MapEntry): MapEntry {
    /* Check if the second leg is a trivial mapping, if yes we can simply use the first leg as the complete route */
    if (second.trivial) return first;
    /* Check if the first leg is trivial */
    if (first.trivial)
      if (second.sync)
        // Check if the second leg is synchronous
        return second;
      else
        return {
          func: this.makeCachingMapping(from, to, second.func, second.essentialContext),
          trivial: false,
          essentialContext: second.essentialContext,
        };
    /* Combine the contexts */
    const essentialContext = EntityMapping.combineEssentialContexts(
      first.essentialContext,
      second.essentialContext
    );
    /* Check if the first leg is synchronous */
    const firstSync = first.sync;
    if (firstSync) {
      /* Check if the second leg is synchronous */
      const secondSync = second.sync;
      if (secondSync) {
        const func = (
          entity: Entity,
          context: EntityRecord,
          trace: EntityMappingTrace
        ): EntityMappingCacheResult => {
          /* Evaluate the first mapping */
          const firstRes = firstSync(entity, context, trace);
          if (!firstRes.result) return firstRes;
          /* Evaluate the remaining mapping */
          const res = secondSync(firstRes.result, context, trace);
          return this.combinedMappingResults(firstRes, res);
        };
        return { func, sync: func, trivial: false, essentialContext };
      } else
        return {
          func: this.makeCachingMapping(
            from,
            to,
            (
              entity: Entity,
              context: EntityRecord,
              trace: EntityMappingTrace
            ): EntityMappingCacheResult | Promise<EntityMappingCacheResult> => {
              /* Evaluate the first mapping */
              const firstRes = firstSync(entity, context, trace);
              if (!firstRes.result) return firstRes;
              /* Evaluate the remaining mapping */
              return applyAfter<EntityMappingCacheResult, EntityMappingCacheResult>(
                second.func(firstRes.result, context, trace),
                (res) => {
                  /* Combine cache states */
                  return this.combinedMappingResults(firstRes, res);
                }
              );
            },
            essentialContext
          ),
          trivial: false,
          essentialContext,
        };
    }
    /* Check if the second leg is synchronous */
    const secondSync = second.sync;
    if (secondSync)
      return {
        func: this.makeCachingMapping(
          from,
          to,
          (
            entity: Entity,
            context: EntityRecord,
            trace: EntityMappingTrace
          ): EntityMappingCacheResult | Promise<EntityMappingCacheResult> => {
            /* Evaluate the first mapping */
            const firstRes = first.func(entity, context, trace);
            if (firstRes instanceof Promise)
              return firstRes.then((intRes) => {
                if (!intRes.result) return intRes;
                /* Evaluate the remaining mapping */
                const res = secondSync(intRes.result, context, trace);
                /* Combine cache states */
                return this.combinedMappingResults(intRes, res);
              });
            if (!firstRes.result) return firstRes;
            /* Evaluate the remaining mapping */
            const res = secondSync(firstRes.result, context, trace);
            /* Combine cache states */
            return this.combinedMappingResults(firstRes, res);
          },
          essentialContext
        ),
        trivial: false,
        essentialContext,
      };

    /* Combine two pot. asynchronous mappings*/
    return {
      func: this.makeCachingMapping(
        from,
        to,
        (
          entity: Entity,
          context: EntityRecord,
          trace: EntityMappingTrace
        ): EntityMappingCacheResult | Promise<EntityMappingCacheResult> => {
          /* Evaluate the first mapping */
          const firstRes = first.func(entity, context, trace);
          if (firstRes instanceof Promise)
            return firstRes.then((intRes) => {
              if (!intRes.result) return intRes;
              /* Evaluate the remaining mapping */
              return applyAfter<EntityMappingCacheResult, EntityMappingCacheResult>(
                second.func(intRes.result, context, trace),
                (res) => {
                  /* Combine cache states */
                  return this.combinedMappingResults(intRes, res);
                }
              );
            });
          if (!firstRes.result) return firstRes;
          /* Evaluate the remaining mapping */
          return applyAfter<EntityMappingCacheResult, EntityMappingCacheResult>(
            second.func(firstRes.result, context, trace),
            (res) => {
              /* Combine cache states */
              return this.combinedMappingResults(firstRes, res);
            }
          );
        },
        essentialContext
      ),
      trivial: false,
      essentialContext,
    };
  }

  private ensureMapping(from: string, to: string): MapEntry | undefined {
    /* (Try to) find a route */
    const route = this.findRoute(from, to);
    if (!route.path || route.path.length === 0) return undefined;
    if (!route.map)
      if (route.path.length > 1) {
        /* Check if this is a direct mapping */
        /* Call recursively */
        const intermediate = route.path[route.path.length - 2];
        const firstLeg = this.ensureMapping(from, intermediate);
        /* Get the final step */
        const secondLeg = this.directMappings.get(intermediate, route.path[route.path.length - 1]);
        if (!firstLeg || !secondLeg)
          throw new Error(
            'Inconsistency in mapping from: ' +
              from +
              ' to: ' +
              route.path[route.path.length - 1] +
              ' via: ' +
              intermediate
          );
        route.map = this.combineMappings(from, to, firstLeg, secondLeg.map);
      } else {
        const mapEntry = this.directMappings.get(from, route.path[0]);
        if (!mapEntry)
          throw new Error('Inconsistency in mapping from: ' + from + ' to: ' + route.path[0]);
        route.map = mapEntry.map;
      }
    return route.map;
  }

  canBeMapped(from: string, to: string) {
    const route = this.findRoute(from, to);
    return route.path ? route.path.length > 0 : false;
  }
  canBeMappedTrivially(from: string, to: string) {
    const mapping = this.ensureMapping(from, to);
    return mapping ? mapping.trivial : false;
  }

  private static PreventRoute: RouteEntry = makeImmutable({ weight: 0 });
  private static NoRoute: RouteEntry = makeImmutable({ path: [], weight: 0 });

  private models: Models;
  private contextProperties: Map<string, ContextPropertyModel>;
  private directMappings = new KeyPairMap<
    string,
    string,
    { weight: number; append: boolean; map: MapEntry }
  >();
  private directTupleMappings = new Map<
    number,
    KeyPairMap<string, string, DirectTupleMappingEntry>
  >();
  private mappings = new KeyPairMap<string, string, RouteEntry>();
  private tupleMappings = new KeyPairMap<string, string, TupleRouteEntry>();
  private allRoutesFound = new Set<string>();
  private deadlockDetector = new DeadlockDetector();
  readonly cache;
}
