import { FC } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { useTheme } from '@sqior/react/theme';
import { edgeStyle } from '../config';
import { NodeInfoType } from '../types';
import { makePointerId } from '../utils';
import styles from './PointerNode.module.css';

const SIZE = 10;

export const PointerNode: FC<NodeProps<NodeInfoType>> = ({ data, id }) => {
  const { displayPosition, nodeIdHovered } = data;
  const {
    theme: {
      colors: {
        primary: {
          sqiorBlue: { value: sqiorBlue },
        },
      },
    },
  } = useTheme();

  const pointerID = nodeIdHovered ? makePointerId(nodeIdHovered) : '';
  const isHovered = pointerID === id;

  const handle = () => {
    switch (displayPosition) {
      case 'left':
        return (
          <>
            <Handle type="source" position={Position.Right} style={edgeStyle} />
            <Handle type="target" position={Position.Right} style={edgeStyle} />
          </>
        );
      case 'right':
        return (
          <>
            <Handle type="source" position={Position.Left} style={edgeStyle} />
            <Handle type="target" position={Position.Left} style={edgeStyle} />
          </>
        );

      default:
        return (
          <>
            <Handle type="source" position={Position.Right} style={edgeStyle} />
            <Handle type="source" position={Position.Left} style={edgeStyle} />
            <Handle type="target" position={Position.Left} style={edgeStyle} />
            <Handle type="target" position={Position.Right} style={edgeStyle} />
          </>
        );
    }
  };

  return (
    <div
      className={isHovered ? styles['ping'] : ''}
      style={{
        width: SIZE,
        height: SIZE,
        backgroundColor: sqiorBlue,
        borderRadius: '50%',
        position: 'relative',
      }}
    >
      {handle()}
    </div>
  );
};

export default PointerNode;
