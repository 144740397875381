import { ArraySource, ensureArray, Value } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { CoreEntities } from './core-definitions';
import { EntityModel, EntityRecord } from './entity';
import { Undefined } from './function';
import { Interface } from './interface';
import { MappingInterface, TypedMappingHelper, ValueMappingHelper } from './mapping-interface';

export type RelatedDataValue = [RelatedDataModel, Entity?];
export type RelatedDataSet = RelatedDataValue[];
export type RelateDataNotification = Entity & { new?: EntityRecord; old?: EntityRecord };

export class AnchorModel {
  constructor(name: string, type: string, propName?: string) {
    this.name = name;
    if (!propName) propName = name;
    this.prop = propName.toLowerCase();
    this.type = type;
    const pluralExt = name[name.length - 1] === 's' ? 'es' : 's';
    this.containerModel = { type: propName + pluralExt, props: [this.prop + pluralExt] };
    this.setDataModel = {
      type: 'Set' + name + 'Data',
      props: [this.prop, 'data'],
      extends: CoreEntities.Command,
      unclassified: true,
    };
    this.insertDataModel = {
      type: 'Insert' + name + 'Data',
      props: [this.prop, 'data'],
      extends: CoreEntities.Command,
      unclassified: true,
    };
    this.replaceDataModel = {
      type: 'Replace' + name + 'Data',
      props: [this.prop, 'data'],
      extends: CoreEntities.Command,
      unclassified: true,
    };
    this.removeDataModel = {
      type: 'Remove' + name + 'Data',
      props: [this.prop, 'data'],
      extends: CoreEntities.Command,
      unclassified: true,
    };
    this.addDataModel = {
      type: 'Add' + name + 'Data',
      props: [this.prop, 'data'],
      extends: CoreEntities.Command,
      unclassified: true,
    };
    this.dataUpdateModel = {
      type: name + 'DataUpdate',
      props: [this.prop, 'new', 'old'],
      unclassified: true,
    };
    this.deleteCommandModel = {
      type: 'Delete' + name,
      props: [this.prop],
      keys: [this.prop],
      extends: CoreEntities.Command,
      unclassified: true,
    };
    this.deleteNotificationModel = {
      type: name + 'Deleted',
      props: [this.prop],
      keys: [this.prop],
      unclassified: true,
    };
    this.deleteAllCommandModel = {
      type: 'DeleteAll' + name + 's',
      props: [],
      keys: [],
      extends: CoreEntities.Command,
      unclassified: true,
    };
  }

  setCommand(anchor: Entity, data: EntityRecord): Entity {
    const cmd: Entity = { entityType: this.setDataModel.type };
    cmd[this.prop] = anchor;
    cmd['data'] = data;
    return cmd;
  }

  private makeDataCommand(type: string, key: Entity, data: RelatedDataSet): Entity {
    const cmd: Entity = { entityType: type };
    cmd[this.prop] = key;
    const dataSet: EntityRecord = {};
    for (const rd of data) dataSet[rd[0].prop] = rd[1] ? rd[1] : Undefined;
    cmd['data'] = dataSet;
    return cmd;
  }

  set(key: Entity, data: RelatedDataSet): Entity {
    return this.makeDataCommand(this.setDataModel.type, key, data);
  }
  /** Inserts data if not already present */
  insert(key: Entity, data: RelatedDataSet): Entity {
    return this.makeDataCommand(this.insertDataModel.type, key, data);
  }
  replace(key: Entity, data: RelatedDataSet): Entity {
    return this.makeDataCommand(this.replaceDataModel.type, key, data);
  }
  add(key: Entity, data: RelatedDataSet): Entity {
    return this.makeDataCommand(this.addDataModel.type, key, data);
  }
  remove(key: Entity, data: RelatedDataSet): Entity {
    return this.makeDataCommand(this.removeDataModel.type, key, data);
  }
  delete(key: Entity): Entity {
    const command: Entity = { entityType: this.deleteCommandModel.type };
    command[this.prop] = key;
    return command;
  }
  deleteAll(): Entity {
    return { entityType: this.deleteAllCommandModel.type };
  }
  deleteNotification(key: Entity): Entity {
    const command: Entity = { entityType: this.deleteNotificationModel.type };
    command[this.prop] = key;
    return command;
  }

  makeContainer(entities: ArraySource<Entity>): Entity {
    const ent: Entity = { entityType: this.containerModel.type };
    ent[this.containerModel.props[0]] = ensureArray(entities);
    return ent;
  }

  readonly name: string;
  readonly prop: string;
  readonly type: string;

  readonly containerModel: EntityModel;
  readonly setDataModel: EntityModel;
  readonly insertDataModel: EntityModel;
  readonly replaceDataModel: EntityModel;
  readonly addDataModel: EntityModel;
  readonly removeDataModel: EntityModel;
  readonly dataUpdateModel: EntityModel;
  readonly deleteCommandModel: EntityModel;
  readonly deleteAllCommandModel: EntityModel;
  readonly deleteNotificationModel: EntityModel;
}

export class RelatedDataModel<Type extends Entity = Entity> extends TypedMappingHelper<Type> {
  constructor(
    anchor: AnchorModel,
    data: string,
    dataType?: string,
    index?: boolean,
    multiValueEntityProp?: string,
    multiValueEntityType?: string
  ) {
    super();
    this.interface = { type: anchor.name + data };
    this.setterInterface = { type: anchor.name + data + 'Setter' };
    this.historyInterface = {
      type: anchor.name + data + 'History',
      requires: CoreEntities.EntityModificationHistory,
    };
    this.anchor = anchor;
    this.prop = data.toLowerCase();
    this.keyProp = this.prop + 'Key';
    this.type = dataType;
    this.multiValueEntityProp = multiValueEntityProp;
    this.multiValueEntityType = multiValueEntityType;
    if (multiValueEntityType && !multiValueEntityProp)
      throw new Error(
        'The multi-value entity property needs to be set in order to set the corresponding type'
      );
    if (index) {
      if ((!multiValueEntityProp && !dataType) || (multiValueEntityProp && !multiValueEntityType))
        throw new Error('Only related data with defined data type is allowed to be indexed');
      this.anchorsInterface = { type: data + anchor.name + 's' };
    }
  }

  get valueType() {
    return this.interface.type;
  }
  get setterType() {
    return this.setterInterface.type;
  }
  get historyType() {
    return this.historyInterface.type;
  }
  get anchorsType() {
    if (!this.anchorsInterface)
      throw new Error('No anchors interface is defined for related data: ' + this.valueType);
    return this.anchorsInterface.type;
  }

  set(key: Entity, data: Entity): Entity {
    return this.anchor.set(key, [[this, data]]);
  }
  reset(key: Entity): Entity {
    return this.anchor.set(key, [[this]]);
  }

  add(key: Entity, data: Entity): Entity {
    return this.anchor.add(key, [[this, data]]);
  }
  remove(key: Entity, data: Entity): Entity {
    return this.anchor.remove(key, [[this, data]]);
  }

  notifyFilter(key: Entity, data: Entity): Entity {
    const obj: Entity = { entityType: this.anchor.dataUpdateModel.type, new: {} };
    obj[this.prop] = key;
    (obj['new'] as EntityRecord)[this.prop] = data;
    return obj;
  }

  get index(): boolean {
    return this.anchorsInterface ? true : false;
  }

  /** Returns the mapping target type */
  get mappingTarget() {
    return this.valueType;
  }

  readonly interface: Interface;
  readonly setterInterface: Interface;
  readonly historyInterface: Interface;
  readonly anchorsInterface?: Interface;
  readonly anchor: AnchorModel;
  readonly prop: string;
  readonly keyProp: string;
  readonly type?: string;
  readonly multiValueEntityProp?: string;
  readonly multiValueEntityType?: string;
}

/** Related data model for a type with a single member */

export class ValueRelatedDataModel<Type extends Entity = Entity, PropType extends Value = Value>
  extends RelatedDataModel<Type>
  implements ValueMappingHelper<Type, PropType>
{
  constructor(
    anchor: AnchorModel,
    data: string,
    model: ValueMappingHelper<Type, PropType>,
    index?: boolean,
    multiValueEntityType?: string
  ) {
    super(
      anchor,
      data,
      model.mappingTarget,
      index,
      multiValueEntityType ? model.propName : undefined,
      multiValueEntityType
    );
    this.propName = model.propName;
  }

  /** Returns the value of the simple property */
  value(
    mapper: MappingInterface,
    input?: Entity | Promise<Entity | undefined>,
    context?: EntityRecord
  ): Promise<PropType | undefined> | PropType | undefined {
    return this.extractProperty<PropType>(this.get(mapper, input, context), this.propName);
  }

  /** Single property */
  readonly propName: string;
}
