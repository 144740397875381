import { DetailedInfo } from '@sqior/viewmodels/analytics-dashboard';
import { Edge, Node } from 'reactflow';

export type Position = { x: number; y: number };

export interface HourPosition {
  hour: number;
  position: Position;
}

export interface ReturnNodeEdges {
  nodes: Node<NodeInfoType, NodeTypes>[];
  edges: Edge[];
}

export enum NodeTypes {
  Background = 'background',
  Procedure = 'procedure',
  Info = 'info',
  Pointer = 'pointer',
}

export type UITypes = {
  nodeIdHovered?: NodeId | null;
};

export type NodeInfoType = DetailedInfo & UITypes;

export enum NodeId {
  Background = 'background',
  Procedure = 'procedure',

  ChangeFirstPosition = 'changeFirstPosition',
  PatientStability = 'patientStability',
  EinleitungLagerung = 'einleitungLagerung',
  Team = 'team',
  OnTimeFinish = 'onTimeFinish',

  OnTimeFinishPotential = 'onTimeFinishPotential',
  OnTimeFinishDayEnd = 'onTimeFinishDayEnd',
}
