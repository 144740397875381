import { arrayTransformAsync, Closable, FunctionQueue } from '@sqior/js/async';
import { CacheHolder, TimedCacheState } from '@sqior/js/cache';
import {
  addHours,
  addMinutes,
  addSeconds,
  ClockTimestamp,
  lessThan,
  msToTimeString,
  TestTimer,
} from '@sqior/js/data';
import { DatabaseInterface, SequenceNumberName, WatchOperation } from '@sqior/js/db';
import { Entity } from '@sqior/js/entity';
import { StopListening } from '@sqior/js/event';
import { EventKey, SequencedEvent } from '@sqior/js/event-stream';
import { Logger } from '@sqior/js/log';
import {
  CoreEntities,
  CoreInterfaces,
  Domain,
  DomainInterface,
  EntityModel,
  extractText,
  Interface,
  makeTextEntity,
  makeTimestampEntity,
  ProjectionView,
  RootDomainInterface,
  TextEntity,
  TimestampEntity,
} from '@sqior/js/meta';
import { InputInterfaces } from '@sqior/plugins/input';
import {
  LanguageEntities,
  LanguageInterfaces,
  makeAnonymizedText,
  makeTexts,
  resourceTextTemplate,
  textResource,
} from '@sqior/plugins/language';
import { makeHTML, VisualEntities, VisualInterfaces } from '@sqior/plugins/visual';
import { NumberInput, NumericalResultEntity, SelectionEntities } from '@sqior/viewmodels/input';
import {
  convertToJSDate,
  DateEntity,
  DateEntityModel,
  DatesEntity,
  DatesModel,
  dateWeekday,
  GetWeekdayModel,
  ISODateModel,
  makeCurrentDate,
  makeDateFromJS,
  makeDateFromTimestamp,
  makeWeekday,
  PeriodicDateModel,
  RelativeDate,
  RelativeDateEntityModel,
  Weekday,
  WeekdayModel,
  WorkDayModel,
} from './date';
import { createWholeDayDateRange, DateRangeModel } from './date-range';
import {
  AddDurationEntity,
  AddDurationModel,
  DurationEntity,
  DurationEntityModel,
  DurationLessThan,
  DurationLessThanModel,
  DurationsModel,
  makeDuration,
  makeProbabilisticDuration,
  ProbabilisticDurationEntity,
  ProbabilisticDurationModel,
  TimerDurationEntityModel,
  TimestampDifferenceDurationModel,
  TimestampDifferenceEntity,
} from './duration';
import { DurationInputEntity, DurationInputModel } from './duration-interpreter';
import { SetTimerEntity, SetTimerEntityModel } from './set-timer';
import {
  HoursEntity,
  HoursModel,
  InTimeRangeModel,
  isTimeBefore,
  makeHours,
  makeTimeEntity,
  makeTimeFromJS,
  makeTimeFromTimestamp,
  TimeEntity,
  TimeEntityModel,
  TimeRangeEntity,
  TimeRangeEntityModel,
} from './time';
import {
  DailyTimeModel,
  DateOrTimestampModel,
  DurationOrTextModel,
  InPastModel,
  PeriodicTimestampModel,
  TextTimeOrDatetimeModel,
  TimeEntities,
  TimeInterfaces,
  TimestampOrEndOfDayModel,
  TimestampOrStartOfDayModel,
} from './time-definitions';
import {
  addToTimestampEntity,
  extractTimestamp,
  InSecondsEntity,
  InSecondsModel,
  makeProbabilisticTimestamp,
  makeTimestampFromDateTime,
  nextDate,
  ProbabilisticTimestampEntity,
  ProbabilisticTimestampModel,
  TimestampLessThan,
  TimestampLessThanModel,
  TimestampsModel,
} from './timestamp';
import {
  TimestampInputControlModel,
  TimestampInputEntity,
  TimestampInputModel,
} from './time-input';
import { TimestampInputControlType, TimestampInputVM } from '@sqior/viewmodels/time';

export const TimeDomainName = 'Time';
export type SetTimerMessage = { timeout: ClockTimestamp; entities: Entity[] };
export type StopTimerMessage = { invocation: string };
type TimerEntry = SequencedEvent & SetTimerMessage & { active: boolean };

/** Models */

const SelectedDateModel: Interface = {
  type: TimeInterfaces.SelectedDate,
  requires: TimeEntities.Date,
};
const DateSelectionModel: EntityModel = {
  type: TimeEntities.DateSelection,
  props: ['date', 'prevDate', 'nextDate'],
  unclassified: true,
};
const ShowDateSelectionModel: Interface = {
  type: TimeInterfaces.ShowDateSelection,
};

export const TimerDocumentModel: EntityModel = {
  type: 'TimerDocument',
  props: [SequenceNumberName, 'eventId', 'timeout', 'entities', 'active'],
  keys: [SequenceNumberName],
  unclassified: true,
};

/** Time domain */

export class TimeDomain extends Domain {
  constructor(demo?: boolean) {
    /* Entities */
    super(TimeDomainName, {
      entities: [
        DateEntityModel,
        RelativeDateEntityModel,
        TimeEntityModel,
        DurationEntityModel,
        SetTimerEntityModel,
        InSecondsModel,
        AddDurationModel,
        DurationInputModel,
        ProbabilisticTimestampModel,
        ProbabilisticDurationModel,
        DurationLessThanModel,
        TimestampLessThanModel,
        TimerDocumentModel,
        DatesModel,
        TimerDurationEntityModel,
        TimestampDifferenceDurationModel,
        TimeRangeEntityModel,
        TimestampsModel,
        DurationsModel,
        HoursModel,
        TimestampInputModel,
        TimestampInputControlModel,
        DateRangeModel,
        WeekdayModel,
        PeriodicDateModel,
        DateSelectionModel,
      ],
      interfaces: [
        DateOrTimestampModel,
        TimestampOrStartOfDayModel,
        TimestampOrEndOfDayModel,
        DurationOrTextModel,
        DailyTimeModel,
        ISODateModel,
        PeriodicTimestampModel,
        TextTimeOrDatetimeModel,
        InTimeRangeModel,
        WorkDayModel,
        InPastModel,
        GetWeekdayModel,
        SelectedDateModel,
        ShowDateSelectionModel,
      ],
      dbType: TimerDocumentModel.type,
      migrateUntyped: async (obj) => {
        return { entityType: TimerDocumentModel.type, ...obj };
      },
    });

    /* Mappings */
    this.addBasicMapping<DateEntity, TextEntity>(TimeEntities.Date, CoreEntities.Text, (entity) => {
      return makeTextEntity(entity.day + '.' + entity.month + '.' + entity.year);
    });
    this.addBasicMapping<DateEntity>(TimeEntities.Date, VisualEntities.HTML, (entity) => {
      return makeHTML('<time>' + entity.day + '.' + entity.month + '.' + entity.year + '</time>');
    });

    /* Returns the date in ISO format */
    this.addEntityMapping<DateEntity>(
      TimeEntities.Date,
      TimeInterfaces.ISODate,
      async (dateEnt) => {
        return makeTextEntity(
          String(dateEnt.year).padStart(4, '0') +
            '-' +
            String(dateEnt.month).padStart(2, '0') +
            '-' +
            String(dateEnt.day).padStart(2, '0')
        );
      }
    );

    this.addTrivialMapping(TimeEntities.Date, TimeInterfaces.DateOrTimestamp);
    this.addTrivialMapping(CoreEntities.Timestamp, TimeInterfaces.DateOrTimestamp);
    /* Returns a timestamp for the date */
    this.addEntityMapping<DateEntity | TimestampEntity>(
      TimeInterfaces.DateOrTimestamp,
      TimeInterfaces.TimestampOrStartOfDay,
      async (ent, mapper) => {
        return await convertToTimestamp(mapper, ent, ConvertToTimestampOption.DateToStartOfDay);
      }
    );
    this.addEntityMapping<DateEntity | TimestampEntity>(
      TimeInterfaces.DateOrTimestamp,
      TimeInterfaces.TimestampOrEndOfDay,
      async (ent, mapper) => {
        return await convertToTimestamp(mapper, ent, ConvertToTimestampOption.DateToEndOfDay);
      }
    );

    this.addEntityMapping<RelativeDate>(
      TimeEntities.RelativeDate,
      LanguageEntities.TextTemplate,
      async (relDate) => {
        if (relDate.dayOffset === 0) return resourceTextTemplate('date_today', {});
        if (relDate.dayOffset === 1) return resourceTextTemplate('date_tomorrow', {});
        if (relDate.dayOffset === 2) return resourceTextTemplate('date_after_tomorrow', {});
        if (relDate.dayOffset > 2)
          return resourceTextTemplate('date_in_days', {
            days: makeAnonymizedText(relDate.dayOffset.toString()),
          });
        if (relDate.dayOffset === -1) return resourceTextTemplate('date_tomorrow', {});
        if (relDate.dayOffset < -1)
          return resourceTextTemplate('date_ago_days', {
            days: makeAnonymizedText((-relDate.dayOffset).toString()),
          });
        return undefined;
      }
    );
    this.addEntityMapping<RelativeDate>(
      TimeEntities.RelativeDate,
      TimeEntities.Date,
      async (relDate) => {
        const day = demo !== undefined && demo ? new Date(2022, 3, 21) : new Date();
        day.setDate(day.getDate() + relDate.dayOffset);
        return makeDateFromJS(day);
      },
      { weight: 2.0 }
    );

    this.addBasicMapping<TimeEntity, TextEntity>(
      TimeEntities.Time,
      LanguageEntities.AnonymizedText,
      (entity) => {
        return makeAnonymizedText(entity.hours + ':' + String(entity.minutes).padStart(2, '0'));
      }
    );
    this.addBasicMapping<TimestampEntity, TextEntity>(
      CoreEntities.Timestamp,
      CoreEntities.Text,
      (entity) => {
        return makeTextEntity(new Date(entity.timestamp).toLocaleString('de'));
      }
    );
    this.addEntityMapping<TimestampEntity>(
      CoreEntities.Timestamp,
      TimeInterfaces.TextTimeOrDatetime,
      async (entity, mapper) => {
        // Trigger invalidation at midnight
        mapper.map(makeHours(0), TimeInterfaces.DailyTime);
        const dn = new Date(mapper.displayTimer.now);
        const dt = new Date(entity.timestamp);
        if (dn.toLocaleDateString('de') === dt.toLocaleDateString('de'))
          return mapper.map(makeTimeFromJS(dt), CoreEntities.Text);
        else
          return makeTextEntity(
            new Date(entity.timestamp).toLocaleString('de', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })
          );
      }
    );

    this.addEntityMapping(
      CoreEntities.Timestamp,
      LanguageInterfaces.Anonymized,
      async (entity, mapper) => {
        return await mapper.mapChain(entity, [TimeEntities.Time, LanguageInterfaces.Anonymized]);
      }
    );
    this.addBasicMapping<TimestampEntity, TimeEntity>(
      CoreEntities.Timestamp,
      TimeEntities.Time,
      (entity) => {
        return makeTimeFromTimestamp(extractTimestamp(entity));
      }
    );
    this.addEntityMapping<InSecondsEntity>(
      TimeEntities.InSeconds,
      CoreInterfaces.Result,
      async (entity, mapper) => {
        return makeTimestampEntity(addSeconds(entity.seconds, mapper.displayTimer.now));
      },
      { cache: false }
    );
    this.addBasicMapping<DurationEntity>(
      TimeEntities.Duration,
      LanguageEntities.TextTemplate,
      (entity) => {
        return resourceTextTemplate('duration_general', {
          dur: makeAnonymizedText(msToTimeString(entity.duration)),
        });
      }
    );

    /* Function determining the difference of two timestamps */
    this.addEntityMapping<TimestampDifferenceEntity>(
      TimeEntities.TimestampDifference,
      CoreInterfaces.Result,
      async (diff, mapper) => {
        /* Get the two timestamps */
        const earlierEnt = await mapper.tryMap<TimestampEntity>(
          diff.earlier,
          CoreEntities.Timestamp
        );
        const laterEnt = await mapper.tryMap<TimestampEntity>(diff.later, CoreEntities.Timestamp);
        return earlierEnt && laterEnt
          ? makeDuration(laterEnt.timestamp - earlierEnt.timestamp)
          : undefined;
      }
    );

    /* Function adding a duration to a timestamp */
    this.addEntityMapping<AddDurationEntity>(
      TimeEntities.AddDuration,
      CoreInterfaces.Result,
      async (entity, mapper) => {
        const addEnt = await mapper.tryMap<DurationEntity>(entity.duration, TimeEntities.Duration);
        if (!addEnt) return undefined;
        /* Check if this adds a duration to a timestamp */
        const tsEnt = await mapper.tryMap<TimestampEntity>(
          entity.timestamp,
          CoreEntities.Timestamp
        );
        if (tsEnt) return addToTimestampEntity(tsEnt, addEnt.duration);
        /* Check if this adds two durations */
        const durEnt = await mapper.tryMap<DurationEntity>(entity.timestamp, TimeEntities.Duration);
        return durEnt ? makeDuration(durEnt.duration + addEnt.duration) : undefined;
      }
    );

    this.addEntityMapping<DurationLessThan>(
      TimeEntities.DurationLessThan,
      CoreInterfaces.Result,
      async (entity, mapper) => {
        const valueEnt = await mapper.map<DurationEntity>(entity.value, TimeEntities.Duration);
        const refEnt = await mapper.map<DurationEntity>(entity.ref, TimeEntities.Duration);
        return valueEnt.duration < refEnt.duration ? valueEnt : undefined;
      }
    );
    this.addEntityMapping<TimestampLessThan>(
      TimeEntities.TimestampLessThan,
      CoreInterfaces.Result,
      async (entity, mapper) => {
        const valueEnt = await mapper.map<TimestampEntity>(entity.value, CoreEntities.Timestamp);
        const refEnt = await mapper.map<TimestampEntity>(entity.ref, CoreEntities.Timestamp);
        return valueEnt.timestamp < refEnt.timestamp ? valueEnt : undefined;
      }
    );
    this.addEntityMapping<TimestampEntity, ProbabilisticTimestampEntity>(
      CoreEntities.Timestamp,
      TimeEntities.ProbabilisticTimestamp,
      async (tsEnt) => {
        const timestamp = extractTimestamp(tsEnt);
        return makeProbabilisticTimestamp({
          timestamp: timestamp,
          low: timestamp,
          high: timestamp,
        });
      }
    );
    this.addEntityMapping<DurationEntity, ProbabilisticDurationEntity>(
      TimeEntities.Duration,
      TimeEntities.ProbabilisticDuration,
      async (durEnt) => {
        return makeProbabilisticDuration({
          duration: durEnt.duration,
          low: durEnt.duration,
          high: durEnt.duration,
        });
      }
    );
    this.addTrivialMapping(CoreEntities.Text, TimeInterfaces.DurationOrText);
    this.addTrivialMapping(TimeEntities.Duration, TimeInterfaces.DurationOrText);

    /* Mapping from duration input interpreter to input control */
    this.addEntityMapping<DurationInputEntity>(
      TimeEntities.DurationInput,
      InputInterfaces.SelectionControl,
      async (durInt, mapper) => {
        const dur = await mapper.tryMap<DurationEntity>(durInt.default, TimeEntities.Duration);
        const res: NumberInput = {
          entityType: SelectionEntities.Numerical,
          default: Math.round(
            (dur
              ? Math.min(Math.max(dur.duration, durInt.model.min), durInt.model.max)
              : durInt.model.default) / addMinutes(1)
          ),
          min: durInt.model.min / addMinutes(1),
          max: durInt.model.max / addMinutes(1),
          defaultTitle: await extractText(mapper, durInt.model.defaultTitle),
          unit: await extractText(mapper, textResource('minutes')),
        };
        if (durInt.model.numericalResult) res.numericalResult = durInt.model.numericalResult;
        return res;
      }
    );

    /* Mapping from numerical result to duration (to be generalized) */
    this.addEntityMapping<NumericalResultEntity>(
      SelectionEntities.NumericalResult,
      TimeEntities.Duration,
      async (numRes) => {
        return makeDuration(addMinutes(numRes.number));
      }
    );

    /* Returns the current date and switches to the next date at the specified hour (cachable) */
    this.addEntityMapping<HoursEntity>(
      TimeEntities.Hours,
      TimeInterfaces.DailyTime,
      (timeEnt, mapper) => {
        return mapper.tryMap(makeTimeEntity(timeEnt.hours, 0), TimeInterfaces.DailyTime);
      }
    );
    /* Returns the current date and switches to the next date at the specified day time */
    this.addSyncMapping<TimeEntity>(
      TimeEntities.Time,
      TimeInterfaces.DailyTime,
      (timeEnt, mapper) => {
        /* Get current date */
        const currDate = makeCurrentDate(this.displayTimer);
        /* Invalidate cache at the specified day time on the next day */
        mapper.cacheAccess(
          new TimedCacheState(
            makeTimestampFromDateTime(nextDate(currDate), timeEnt).timestamp,
            this.displayTimer
          )
        );
        return currDate;
      }
    );

    /* Returns a timestamp advancing periodically based on the specified duration */
    this.addEntityMapping<DurationEntity>(
      TimeEntities.TimerDuration,
      TimeInterfaces.PeriodicTimestamp,
      async (durEnt, mapper) => {
        /* Determine the remaininig duration until the next tick and invalidate the cache then */
        let timestamp = mapper.displayTimer.now;
        timestamp = timestamp - (timestamp % durEnt.duration);
        mapper.cacheAccess(new TimedCacheState(timestamp + durEnt.duration, mapper.displayTimer));
        if (this.displayTimerCacheHolder) this.displayTimerCacheHolder.access(mapper);
        return makeTimestampEntity(timestamp);
      },
      { valueComparison: true }
    );

    /* Returns a list of texts from a list of dates */
    this.addEntityMapping<DatesEntity>(
      TimeEntities.Dates,
      LanguageEntities.Texts,
      async (datesEnt, mapper) => {
        return makeTexts(
          await arrayTransformAsync(datesEnt.dates, (date) => {
            return mapper.tryMap(date, CoreEntities.Text);
          })
        );
      }
    );

    this.addEntityMapping<TimeRangeEntity>(
      TimeEntities.TimeRange,
      LanguageEntities.TextTemplate,
      async (timeRangeEnt, mapper) => {
        const start = await mapper.map(timeRangeEnt.start, LanguageEntities.AnonymizedText);
        const end = await mapper.map(timeRangeEnt.end, LanguageEntities.AnonymizedText);
        return resourceTextTemplate('time_range', {
          start: start,
          end: end,
        });
      }
    );

    /* Checks if the current time is within a specified time range */
    this.addEntityMapping<TimeRangeEntity>(
      TimeEntities.TimeRange,
      TimeInterfaces.InTimeRange,
      async (range, mapper) => {
        const now = mapper.displayTimer.now;
        const currTime = makeTimeFromTimestamp(now);
        let inTimeRange: boolean;
        if (isTimeBefore(range.start, range.end)) {
          inTimeRange = isTimeBefore(currTime, range.end) && !isTimeBefore(currTime, range.start);
          /* Invalidate result, cases:
             1) at start time, if current time is before
             2) at end time, if current time is in range
             3) at start time of tomorrow, if current time is beyond */
          mapper.cacheAccess(
            new TimedCacheState(
              makeTimestampFromDateTime(
                isTimeBefore(currTime, range.end)
                  ? makeDateFromTimestamp(now)
                  : nextDate(makeDateFromTimestamp(now)),
                inTimeRange ? range.end : range.start
              ).timestamp,
              mapper.displayTimer
            )
          );
        } else {
          inTimeRange = isTimeBefore(range.start, currTime) || isTimeBefore(currTime, range.end);
          /* Invalidate result, cases:
             1) at start time, if current time is before
             2) at end time of tomorrow, if current time is in range
             3) at start time of tomorrow, if current time is beyond */
          mapper.cacheAccess(
            new TimedCacheState(
              makeTimestampFromDateTime(
                isTimeBefore(currTime, range.start)
                  ? makeDateFromTimestamp(now)
                  : nextDate(makeDateFromTimestamp(now)),
                inTimeRange ? range.end : range.start
              ).timestamp,
              mapper.displayTimer
            )
          );
        }
        return inTimeRange ? range : undefined;
      }
    );

    /* Returns the week day for a date */
    this.addBasicMapping<DateEntity>(TimeEntities.Date, TimeInterfaces.Weekday, (dateEnt) => {
      return makeWeekday(dateWeekday(dateEnt));
    });

    /* Checks if a specified date represents a work day */
    this.addBasicMapping<DateEntity>(
      TimeEntities.Date,
      TimeInterfaces.WorkDay,
      (dateEnt) => {
        const weekDay = dateWeekday(dateEnt);
        return weekDay === Weekday.Saturday || weekDay === Weekday.Sunday ? undefined : dateEnt;
      },
      2.0
    );

    this.addEntityMapping<TimestampEntity>(
      CoreEntities.Timestamp,
      TimeInterfaces.InPast,
      async (tsEnt, mapper) => {
        if (tsEnt.timestamp < mapper.displayTimer.now) return tsEnt;
        mapper.cacheAccess(new TimedCacheState(tsEnt.timestamp, mapper.displayTimer));
        return undefined;
      }
    );

    /* Provides the selection control for a timestamp input */
    this.addTrivialMapping(TimestampInputControlType, VisualInterfaces.ViewModel);
    this.addTrivialMapping(TimestampInputControlType, InputInterfaces.SelectionControl);
    this.addEntityMapping<TimestampInputEntity, TimestampInputVM>(
      TimeEntities.TimestampInput,
      InputInterfaces.SelectionControl,
      async (entity, mapper) => {
        return {
          entityType: TimestampInputControlType,
          label: await extractText(mapper, entity.label),
          offset: (await mapper.map<DurationEntity>(entity.offset, TimeEntities.Duration)).duration,
          min: (await mapper.map<DurationEntity>(entity.min, TimeEntities.Duration)).duration,
          max: (await mapper.map<DurationEntity>(entity.max, TimeEntities.Duration)).duration,
          textResult: entity.textResult,
        };
      }
    );

    /* Invoke handler for time commands */
    this.addInvokeHandler<SetTimerEntity, SetTimerMessage & EventKey>(
      { entityType: TimeEntities.SetTimer },
      async (entity, mapper, seq, invocation) => {
        return {
          timeout: extractTimestamp(
            await mapper.map<TimestampEntity>(entity.timeout, CoreEntities.Timestamp)
          ),
          entities: entity.entities,
          eventKey: invocation,
        };
      }
    );
    this.addStopHandler<SetTimerEntity, StopTimerMessage & EventKey>(
      { entityType: TimeEntities.SetTimer },
      async (entity, invocation) => {
        return { invocation: invocation, eventKey: invocation };
      }
    );

    /* Projections for time commands */
    this.addEventProjector<SetTimerMessage | StopTimerMessage>(async (db, msg) => {
      /* Check if this is a set timer message */
      if ('timeout' in msg)
        await db.addUnique<TimerEntry>(
          { sequenceNumber: msg.sequenceNumber },
          {
            sequenceNumber: msg.sequenceNumber,
            eventId: msg.eventId,
            timeout: msg.timeout,
            entities: msg.entities,
            active: true,
          }
        );
      else await db.update({ eventId: msg.invocation }, { active: false });
    });

    this.timers = new Map<string, Entity[]>();
  }

  private invokeCommands(
    domain: Domain,
    db: DatabaseInterface,
    id: string,
    eventId: string,
    entities: Entity[]
  ) {
    this.queue.add(async () => {
      try {
        const pv = new ProjectionView(domain, db, this.name, eventId);
        /* Invoke all commands */
        await pv.invokeAll(
          entities.map((ent, index) => {
            return [ent, index.toString()];
          })
        );
        /* Deactivate the timer entry */
        await pv.update(id, { active: false });
      } catch (e) {
        Logger.error(['Exception in TimeDomain.invokeCommands: ', Logger.exception(e)]);
      }
    });
  }

  override activate(
    db: DatabaseInterface,
    mapper: RootDomainInterface,
    manageEvents: boolean
  ): void {
    /* In case that an adjustable timer is set for display, observe it and invalidate a cache state on change */
    if (mapper.displayTimer instanceof TestTimer) {
      const cacheHolder = new CacheHolder();
      this.displayTimerCacheHolder = cacheHolder;
      this.stopDisplayTimerListening = mapper.displayTimer.modified.on(() => {
        cacheHolder.touch();
      });
    }
    /* Watch database entries if event processing is activated */
    if (manageEvents) {
      Logger.debug('Starting processing of timer events');
      this.watch = db.findAndWatch<TimerEntry>(this.name, [{ active: true }, {}], (res) => {
        /* Check if this is the addition of a timer */
        if (res.obj && res.obj.active && res.op === WatchOperation.Add) {
          const current = this.displayTimer.now;
          if (res.obj.timeout <= current)
            this.invokeCommands(mapper, db, res.id, res.obj.eventId, res.obj.entities);
          else if (res.obj.timeout - current < Number.POSITIVE_INFINITY) {
            /* Register commands */
            this.timers.set(res.id, res.obj.entities);
            const eventId = res.obj.eventId;
            this.displayTimer.schedule(() => {
              /* Check if commands are still registered */
              const entities = this.timers.get(res.id);
              if (!entities) return;
              this.timers.delete(res.id);
              this.invokeCommands(mapper, db, res.id, eventId, entities);
            }, res.obj.timeout - current);
          }
        } else this.timers.delete(res.id);
      });
    }
    /* Call base class */
    super.activate(db, mapper);
  }

  /** Initializes the domain */
  override init(db: DatabaseInterface) {
    /* Register this domain collection for watches */
    db.prepareWatch(this.name);
  }

  /** Called to give the domain the opportunity to define the database indexes */
  override async ensureIndexes(db: DatabaseInterface) {
    await db.ensureIndexes(this.name, ['active', 'eventId', 'timeout', 'sequenceNumber']);
  }

  override async cleanUp(db: DatabaseInterface) {
    Logger.debug('Cleaning up expired timer events');
    await db.delete(this.name, {
      active: false,
      timeout: lessThan(addHours(-12, this.displayTimer.now)),
    });
  }

  override async close() {
    /* Stop the observation of the display timer and the display timer itself, if applicable */
    if (this.stopDisplayTimerListening) {
      this.stopDisplayTimerListening();
      this.stopDisplayTimerListening = undefined;
    }
    if (this.displayTimer instanceof TestTimer) this.displayTimer.close();
    /* Close watch for timer entries */
    if (this.watch) await this.watch.close();
    await this.queue.close();
    /* Call base class */
    await super.close();
  }

  private watch?: Closable;
  private timers: Map<string, Entity[]>;
  private queue = new FunctionQueue();
  /* Cache holder for adjustable display timers */
  private displayTimerCacheHolder?: CacheHolder;
  private stopDisplayTimerListening?: StopListening;
}

export enum ConvertToTimestampOption {
  DateToStartOfDay,
  DateToEndOfDay,
}
/** Helper function that converts an entity to a timestamp, optionally clipped tovthe start or end of day */
export async function convertToTimestamp(
  mapper: DomainInterface,
  ent: DateEntity | TimestampEntity,
  option: ConvertToTimestampOption
) {
  const timestamp = await mapper.tryMap<TimestampEntity>(ent, CoreEntities.Timestamp);
  if (timestamp) return timestamp;
  const date = await mapper.tryMap<DateEntity>(ent, TimeEntities.Date);
  const jsDate = date && convertToJSDate(date);
  if (jsDate) {
    const dayRange = createWholeDayDateRange([jsDate, jsDate]);
    return makeTimestampEntity(
      dayRange[option === ConvertToTimestampOption.DateToStartOfDay ? 0 : 1].getTime()
    );
  }
  return undefined;
}
