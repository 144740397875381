import { DarkTheme } from '@sqior/react/theme';

export const NODE_WIDTH = 160;
export const NODE_HEIGHT = 70;
export const NODE_VERTICAL_GAP = 100;
export const NODE_HORIZONTAL_GAP = 150;

export const CHART_RED = '#FC2E58';
export const CHART_ORANGE = '#FB9504';
export const CHART_YELLOW = '#FBCC00';
export const CHART_GREY = '#8F8E93';
export const CHART_BLUE = '#5C57D7';
export const CHART_DARK_BLUE = '#2F4B7C';
export const CHART_PURPLE = '#675192';
export const CHART_LIGHT_BLUE = '#36A2EB';
export const CHART_TEAL = '#4BC0C0';
export const CHART_SALMON = '#FF5A5E';
export const CHART_LIGHT_TEAL = '#5AD3D1';
export const CHART_GOLD = '#FFD700';
export const CHART_DARK_RED = '#8B0000';
export const CHART_DARK_TEAL = '#008080';
export const CHART_DARK_PURPLE = '#800080';
export const CHART_ORANGE_RED = '#FF4500';
export const CHART_YELLOW_GREEN = '#9ACD32';
export const CHART_SLATE_BLUE = '#6A5ACD';
export const CHART_LIGHT_SEA_GREEN = '#20B2AA';
export const CHART_HOT_PINK = '#FF69B4';
export const CHART_SEA_GREEN = '#2E8B57';
export const CHART_SOFT_RED = '#FF6384';
export const CHART_SOFT_ORANGE = '#FF9F40';
export const CHART_SOFT_YELLOW = '#FFCE56';
export const CHART_SOFT_GREY = '#C9CBCF';
export const CHART_VIOLET = '#8A2BE2';
export const CHART_CYAN = '#00FFFF';
export const CHART_WHITE = '#FFFFFF';
export const CHART_BROWN = '#A52A2A';
export const CHART_LIGHT_GREEN = '#90EE90';

export const CHART_COLORS: string[] = [
  DarkTheme.colors.content.sqiorBlue.fg.value,
  DarkTheme.colors.content.lightYellow.fg.value,
  DarkTheme.colors.content.peach.fg.value,
  DarkTheme.colors.content.green.fg.value,
  DarkTheme.colors.content.salmon.fg.value,
  DarkTheme.colors.content.pink.fg.value,
  DarkTheme.colors.content.steelBlue.fg.value,
  DarkTheme.colors.content.violet.fg.value,
];

export const applyOpacityToHexColor = (hexColor: string, opacity: number): string => {
  if (hexColor.charAt(0) !== '#') {
    hexColor = '#' + hexColor;
  }

  hexColor = hexColor.substring(1);

  if (hexColor.length === 3) {
    hexColor = hexColor
      .split('')
      .map(function (hex) {
        return hex + hex;
      })
      .join('');
  }

  // Parse the hex color into RGB components
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Clamp opacity between 0 and 1
  opacity = Math.min(Math.max(opacity, 0), 1);

  // Return the rgba color string
  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
};

// export const CHART_COLORS: string[] = [
//   '#FC2E58', // Red
//   '#FB9504', // Orange
//   '#FBCC00', // Yellow
//   '#8F8E93', // Grey
//   '#5C57D7', // Blue
//   '#2F4B7C', // Dark Blue
//   '#05405C', // Dark Blue
//   '#675192', // Purple
//   '#36A2EB', // Light Blue
//   '#4BC0C0', // Teal
//   '#9966FF', // Purple
//   '#FF5A5E', // Salmon
//   '#5AD3D1', // Light Teal
//   '#FFD700', // Gold
//   '#8B0000', // Dark Red
//   '#008080', // Dark Teal
//   '#00008B', // Dark Blue
//   '#800080', // Dark Purple
//   '#FF4500', // Orange-Red
//   '#9ACD32', // Yellow-Green
//   '#6A5ACD', // Slate Blue
//   '#20B2AA', // Light Sea Green
//   '#FF69B4', // Hot Pink
//   '#2E8B57', // Sea Green.
//   '#FF6384', // Soft Red
//   '#FF9F40', // Orange
//   '#FFCE56', // Yellow
//   '#C9CBCF', // Grey
// ];
