import { useContext, useEffect, useState } from 'react';
import { AuthConfig } from '@sqior/js/authbase';
import { ConfigContext } from '@sqior/react/utils';
import {
  KeycloakConfirmProvider,
  KeycloakProvider,
  OAuthProviderBase,
  OpenIdProvider,
} from '@sqior/react/uiauthkc';
import axios from 'axios';
import { joinUrlPath } from '@sqior/js/url';
import { AuthenticationSystem } from './app-frame/app-frame';

interface UseAuthProps {
  auth?: AuthenticationSystem;
}

const keycloakConfirmProvider = new KeycloakConfirmProvider();

export const useAuth = ({ auth }: UseAuthProps) => {
  const [error, setError] = useState('');
  const [authConfig, setAuthConfig] = useState<AuthConfig | undefined>(undefined);
  const [initialized, setInitialized] = useState(false);

  const configContext = useContext(ConfigContext);
  const configUrl = configContext.getEndpoint('config');

  const authSystemChoice =
    auth ??
    (authConfig?.sqiorPublicPairingMode ? AuthenticationSystem.QRCode : AuthenticationSystem.OAuth);

  const [authProvider, setAuthProvider] = useState<OAuthProviderBase | undefined>(
    authSystemChoice === AuthenticationSystem.KeycloakConfirm ? keycloakConfirmProvider : undefined
  );

  useEffect(() => {
    const getAuthConfig = async () => {
      try {
        const configUrl = configContext.getEndpoint('config');
        const res = await axios.get(configUrl.href);
        setAuthConfig(res.data as AuthConfig);
      } catch (err) {
        setError(`Error on loading config: ${err}`);
        console.error(err);
      }
    };

    getAuthConfig();
  }, [configContext]);

  useEffect(() => {
    if (!authConfig) return;
    if (authSystemChoice === AuthenticationSystem.None) return;
    const { sqiorAuthBaseUrl, sqiorAuthClientId, sqiorAuthUrl, baseUrlPrefix } = authConfig;
    if (!sqiorAuthBaseUrl || !sqiorAuthClientId || !sqiorAuthUrl || !baseUrlPrefix) {
      setError(`Missing mandatory parameters in: ${configUrl}`);
      return;
    }
    const appUrl = joinUrlPath(window.location.origin, authConfig.baseUrlPrefix);
    const client =
      authProvider ?? (authConfig.sqiorAuthRealm ? new KeycloakProvider() : new OpenIdProvider());
    client.init(authConfig, appUrl);
    setInitialized(true);
    setError('');
    setAuthProvider(client);
  }, [authConfig]);

  return { error, authProvider, initialized, authConfig, authSystemChoice };
};
