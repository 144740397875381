import { OperationSpec, OperationType } from '@sqior/js/operation';

export const SupportChatPath = 'support-chat';

/** Operation creating a support chat with the respective parameters */
export type SupportChatParams = {
  category: string;
  description?: string;
};
export function CreateSupportChat(data: SupportChatParams): OperationSpec<SupportChatParams> {
  return { type: OperationType.Add, path: SupportChatPath, data };
}

export type SupportChatState = {
  enabled: boolean;
};
