import { Value } from '@sqior/js/data';
import { Entity, EntityRecord, EntityType } from '@sqior/js/entity';
import { Logger } from '@sqior/js/log';

/** Interface for mapping entities to types */

export interface MappingInterface {
  /** Attempts to map the specified entity to the specified target type */
  tryMap<Type extends Entity>(
    entity: Entity | undefined,
    type: EntityType,
    context?: EntityRecord
  ): Promise<Type | undefined> | Type | undefined;

  /** Checks if an entity or type can represent the specified type */
  represents(entity: EntityType | Entity, type: EntityType): boolean;
}

/** Typed mapping helper */

export abstract class TypedMappingHelper<Type extends Entity> {
  /** Attempts to map the specified object to the desired type */
  get(
    mapper: MappingInterface,
    input?: Entity | Promise<Entity | undefined>,
    context?: EntityRecord
  ): Promise<Type | undefined> | Type | undefined {
    if (input instanceof Promise)
      return input
        .then((res) => {
          return mapper.tryMap<Type>(res, this.mappingTarget, context);
        })
        .catch((e) => {
          Logger.reportError([
            'Unexpected exception when mapping in TypedMappingHelper',
            Logger.exception(e),
          ]);
          return undefined;
        });
    return mapper.tryMap<Type>(input, this.mappingTarget, context);
  }

  /** Extracts a property from an entity */
  extractProperty<Type extends Value = Value>(
    input: Entity | Promise<Entity | undefined> | undefined,
    prop: string
  ): Promise<Type | undefined> | Type | undefined {
    if (input instanceof Promise)
      return input.then((r) => {
        return r?.[prop] as Type | undefined;
      });
    return input ? (input[prop] as Type | undefined) : undefined;
  }

  /** Type to map to */
  abstract get mappingTarget(): EntityType;
}

/** Typed mapping helper for entities with a single member */

export abstract class ValueMappingHelper<
  Type extends Entity,
  PropType extends Value
> extends TypedMappingHelper<Type> {
  /** Returns the value of the simple property */
  value(
    mapper: MappingInterface,
    input?: Entity | Promise<Entity | undefined>,
    context?: EntityRecord
  ): Promise<PropType | undefined> | PropType | undefined {
    return this.extractProperty<PropType>(this.get(mapper, input, context), this.propName);
  }

  /** Name of the single member */
  abstract get propName(): string;
}
