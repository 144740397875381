import styles from './blocker-list-view.module.css';
import { SIcon } from '@sqior/react/uibase';
import { Icons } from '@sqior/viewmodels/visual';
import Typography from '../typography/typography';
import { EntityHeader } from '@sqior/js/entity';
import { FactoryProps } from '@sqior/react/factory';
import { BlockerListViewProps } from './blocker-list-view';

export const BlockerListViewEntityType = 'BlockerListView';
export type BlockerListViewType = EntityHeader & BlockerListViewProps;
export type BlockerListViewFactoryProps = FactoryProps<BlockerListViewType>;

export function BlockerListViewFactory({ data: { text } }: BlockerListViewFactoryProps) {
  return (
    <div className={styles['container']}>
      <div className={styles['icon']}>
        <SIcon icon={Icons.Lock} size={28} />
      </div>
      <Typography type="p">{text}</Typography>
    </div>
  );
}

export default BlockerListViewFactory;
