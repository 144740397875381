import { compare as naturalCompare } from 'natural-orderby';
import { applyAfter, arrayTransformAsync } from '@sqior/js/async';
import { CacheState, CombinedCacheState, CurrentValue } from '@sqior/js/cache';
import { ensureArray, TimerInterface, ValueObject } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { ErrorReportingMode, Logger } from '@sqior/js/log';
import { UID } from '@sqior/js/uid';
import { CoreEntities, CoreInterfaces } from './core-definitions';
import { EntityRecord, transformEntitiesAsync } from './entity';
import { EntityMapping, EntityMappingTrace } from './entity-mapping';
import { Undefined } from './function';
import { TextEntity } from './text';
import { tupleName } from './tuple';
import { EntityMappingCacheResult } from './entity-mapping-cache';
import { Models } from './models';
import { ContextPropertyModel } from './context-property';
import { MappingInterface } from './mapping-interface';

export type MetaContext = {
  readonly models: Models;
  readonly entityMapping: EntityMapping;
  readonly contextProperties: Map<string, ContextPropertyModel>;
};

export type EntityMappingInput = Entity | undefined | Entity[];

export abstract class DomainInterface implements MappingInterface {
  constructor(meta: MetaContext) {
    this.meta = meta;
  }

  map<ToEntity extends Entity>(
    entity: EntityMappingInput,
    type: string,
    context?: EntityRecord
  ): ToEntity | Promise<ToEntity> {
    /* Perform mapping */
    const res = this.tryMapEntity<ToEntity>(entity, type, context);
    /* Checking for synchronous result */
    if (res instanceof Promise)
      return res.then((res) => {
        res[1]?.decRef();
        if (res[0]) return res[0];
        throw new Error(
          `Cannot map entity of type: ${this.logEntityType(
            entity
          )} to type: ${type} - mapping returned undefined (1)`
        );
      });
    /* Result is synchronous */
    res[1]?.decRef();
    if (res[0]) return res[0];
    throw new Error(
      'Cannot map entity of type: ' +
        this.logEntityType(entity) +
        ' to type: ' +
        type +
        ' - mapping returned undefined (2)'
    );
  }
  tryMap<ToEntity extends Entity>(
    entity: EntityMappingInput,
    type: string,
    context?: EntityRecord
  ): ToEntity | undefined | Promise<ToEntity | undefined> {
    /* Perform mapping */
    const res = this.tryMapEntity<ToEntity>(entity, type, context);
    /* Checking for synchronous result */
    if (res instanceof Promise)
      return res.then((res) => {
        res[1]?.decRef();
        return res[0];
      });
    /* Result is synchronous */
    res[1]?.decRef();
    return res[0];
  }

  tryMapChain<ToEntity extends Entity>(
    entity: EntityMappingInput,
    types: string[],
    context?: EntityRecord
  ): ToEntity | undefined | Promise<ToEntity | undefined> {
    /* Perform the first N-1 mappings as long as they asynchronous */
    for (let i = 0; i < types.length - 1; i++) {
      const res = this.tryMap(entity, types[i], context);
      if (!res) return undefined;
      if (res instanceof Promise)
        return res.then((value) => {
          /* Check if there is more than one type left */
          return i < types.length - 2
            ? this.tryMapChain<ToEntity>(value, types.slice(i + 1), context)
            : this.tryMap<ToEntity>(value, types[types.length - 1], context);
        });
      entity = res;
    }
    /* Map the last with cast to target type */
    return this.tryMap<ToEntity>(entity, types[types.length - 1], context);
  }
  mapChain<ToEntity extends Entity>(
    entity: EntityMappingInput,
    types: string[],
    context?: EntityRecord
  ): ToEntity | Promise<ToEntity> {
    /* Perform the first N-1 mappings as long as they asynchronous */
    for (let i = 0; i < types.length - 1; i++) {
      const res = this.map(entity, types[i], context);
      if (res instanceof Promise)
        return res.then((value) => {
          /* Check if there is more than one type left */
          return i < types.length - 2
            ? this.mapChain<ToEntity>(value, types.slice(i + 1), context)
            : this.map<ToEntity>(value, types[types.length - 1], context);
        });
      entity = res;
    }
    /* Map the last with cast to target type */
    return this.map<ToEntity>(entity, types[types.length - 1], context);
  }

  async evaluate<ToEntity extends Entity = Entity>(entity: Entity): Promise<ToEntity> {
    /* Map to result if the provided entity is a function */
    while (this.represents(entity, CoreInterfaces.Result))
      entity = await this.map(entity, CoreInterfaces.Result);
    return entity as ToEntity;
  }
  async tryEvaluate<ToEntity extends Entity = Entity>(
    entity?: Entity
  ): Promise<ToEntity | undefined> {
    /* Map to result if the provided entity is a function */
    let res: Entity | undefined = entity;
    while (res && this.represents(res, CoreInterfaces.Result))
      res = await this.tryMap(res, CoreInterfaces.Result);
    return res as ToEntity;
  }

  /** The method evaluates all function entities found in the object until a non-functional entity results or an exception is thrown */
  async evaluateAll(entity: Entity): Promise<Entity> {
    return transformEntitiesAsync<Entity, Entity>(entity, async (entity) => {
      /* Check if the entity is a function - try to evaluate it until a non-functional result is achieved or an exception is thrown */
      return [(await this.tryEvaluate(entity)) ?? Undefined, true]; // Also transformed contained entities
    });
  }

  mapEntity<ToEntity extends Entity>(
    entity: EntityMappingInput,
    type: string,
    context?: EntityRecord
  ): [ToEntity, CacheState | undefined] | Promise<[ToEntity, CacheState | undefined]> {
    return applyAfter(this.tryMapEntity<ToEntity>(entity, type, context), (res) => {
      if (res[0]) return res as [ToEntity, CacheState | undefined];
      /* Decrement use count */
      res[1]?.decRef();
      throw new Error(
        'Cannot map entity of type: ' +
          this.logEntityType(entity) +
          ' to type: ' +
          type +
          '- mapping returned undefined (3)'
      );
    });
  }

  tryMapEntity<ToEntity extends Entity>(
    input: EntityMappingInput,
    type: string,
    context?: EntityRecord
  ):
    | [ToEntity | undefined, CacheState | undefined]
    | Promise<[ToEntity | undefined, CacheState | undefined]> {
    /* Check if entity already has the expected type */
    if (!input || (!(input instanceof Array) && input.entityType === type))
      return [input as unknown as ToEntity, undefined];
    /* Validate the input and output type, if applicable */
    if (Logger.validate) {
      for (const entity of ensureArray(input))
        if (!this.validate(entity)) return [undefined, undefined];
      if (!this.meta.models.has(type)) {
        Logger.warn([
          'Target type for entity mapping needs to be known to domain - provided:',
          type,
        ]);
        return [undefined, undefined];
      }
    }
    /* Check if the input is a tuple */
    if (input instanceof Array) {
      if (
        input.find((entity) => {
          return this.needsPreparation(entity, type);
        })
      )
        return this.prepareAndMap<ToEntity>(input, type, context);
    } else if (this.needsPreparation(input, type))
      return this.prepareAndMap<ToEntity>(input, type, context);
    /* Perform the mapping and narrow the result, validate if desired */
    const res = this.mapInternal(input, type, context);
    /* Check if the result is synchronous */
    if (res instanceof Promise)
      return res.then((res) => {
        /* Validate result if applicable */
        if (Logger.validate && res.result) this.validateType(res.result, type);
        return [res.result as ToEntity | undefined, res.cache];
      });
    /* Validate result if applicable */
    if (Logger.validate && res.result) this.validateType(res.result, type);
    return [res.result as ToEntity | undefined, res.cache];
  }

  /* Returns a current value class for the mapped entity, this will be re-evaluating as soon as the cache state becomes invalid, so do not call for non-cacheable mappings */
  currentMappedEntity<ToEntity extends Entity = Entity>(
    entity: EntityMappingInput,
    types: string,
    use: (entity: ToEntity | undefined) => Promise<void>,
    options: { context?: EntityRecord; initialUsage?: boolean } = {}
  ) {
    return new CurrentValue<ToEntity>(
      () => {
        return this.tryMapEntity<ToEntity>(entity, types, options.context);
      },
      use,
      options
    );
  }

  makeKey(entity: Entity): UID {
    return this.meta.models.key(entity);
  }
  makeKeyArr(entities: Entity[]): UID[] {
    return entities.map((e) => this.makeKey(e));
  }
  makeKeySet(entities: Entity[]): Set<UID> {
    const keys = new Set<UID>();
    for (const entity of entities) keys.add(this.makeKey(entity));
    return keys;
  }
  makeKeyMap(entities: Entity[]): Map<UID, Entity> {
    const keys = new Map<UID, Entity>();
    for (const entity of entities) keys.set(this.makeKey(entity), entity);
    return keys;
  }

  contains(set: Entity[], el: Entity) {
    return this.indexOf(set, el) >= 0;
  }

  indexOf(set: Entity[], el: Entity) {
    const key = this.makeKey(el);
    return set.findIndex((item) => {
      return this.makeKey(item) === key;
    });
  }

  intersect(first: Entity[], second: Entity[]): Entity[] {
    const res: Entity[] = [];
    const keySet = this.makeKeySet(first);
    for (const ent of second) if (keySet.has(this.makeKey(ent))) res.push(ent);
    return res;
  }

  equalSet(first: Entity[], second: Entity[]): boolean {
    const keySetFirst = this.makeKeySet(first);
    const keySetSecond = this.makeKeySet(second);
    return (
      keySetFirst.size === keySetSecond.size && [...keySetFirst].every((x) => keySetSecond.has(x))
    );
  }

  /** Finds all elements in the second array that cannot be found in the first */
  not_contained(first: Entity[], second: Entity[]): Entity[] {
    const res: Entity[] = [];
    const keySet = this.makeKeySet(first);
    for (const ent of second) if (!keySet.has(this.makeKey(ent))) res.push(ent);
    return res;
  }

  isEqual(first: Entity | undefined, second: Entity | undefined): boolean {
    return (
      first !== undefined && second !== undefined && this.makeKey(first) === this.makeKey(second)
    );
  }
  async isEquivalent(first: Entity, second: Entity): Promise<boolean> {
    /* Try to map the first to the second */
    const firstToSecond = await this.tryMap(first, second.entityType);
    if (firstToSecond) return this.isEqual(firstToSecond, second);
    const secondToFirst = await this.tryMap(second, first.entityType);
    if (secondToFirst) return this.isEqual(first, secondToFirst);
    return false;
  }

  /* Returns the list of entities sorted acc. to their text representation */
  async sortAlphabetically(
    entities: Entity[],
    compareFN: (a: string, b: string) => number = naturalCompare()
  ) {
    const textPairs = await arrayTransformAsync(entities, async (ent) => {
      const textEnt = await this.tryMap<TextEntity>(ent, CoreEntities.Text);
      return textEnt ? { text: textEnt.text, entity: ent } : undefined;
    });
    textPairs.sort((a, b) => {
      return compareFN(a.text, b.text);
    });

    return textPairs.map((item) => {
      return item.entity;
    });
  }

  buildHistogram(entities: (Entity | [Entity, number])[]) {
    const histogram = new Map<string, [Entity, number]>();
    entities.forEach((item) => {
      const [ent, scale] = Array.isArray(item) ? item : [item, 1];
      const key = this.makeKey(ent);
      const entry = histogram.get(key);
      if (entry) entry[1] += scale;
      else histogram.set(key, [ent, scale]);
    });
    return Array.from(histogram.values()).sort((a, b) => b[1] - a[1]);
  }

  extends(derived: string, base: string) {
    return this.meta.models.extends(derived, base);
  }

  /** Checks if the source type directly represents the target type in the sense of that mapping it to it will not alter the source entity
   *  (= the source is either identical to the target type or there exists a trivial mapping to it)
   */
  directlyRepresents(source: string | Entity, type: string) {
    const sourceType = typeof source === 'string' ? source : source.entityType;
    /* Check if this directly represents the requested type */
    if (sourceType === type) return true;
    /* Check if the target type is known at all */
    if (Logger.validate && !this.meta.models.has(type))
      Logger.reportError(['Unknown target type provided to Domain.directlyRepresents:', type]);
    /* Check if the source can be trivially mapped to the target type */
    return this.meta.entityMapping.canBeMappedTrivially(sourceType, type);
  }

  represents(entity: string | Entity, type: string) {
    const sourceType = typeof entity === 'string' ? entity : entity.entityType;
    /* Check if this directly represents the requested type */
    if (sourceType === type) return true;
    /* Check if the target type is known at all */
    if (Logger.validate && !this.meta.models.has(type))
      Logger.reportError(['Unknown target type provided to Domain.represents:', type]);
    /* Check if the specified type can be mapped to the desired target type */
    return this.meta.entityMapping.canBeMapped(sourceType, type);
  }

  properties(type: string): string[] {
    const props: string[] = [];
    for (const key in this.meta.models.properties(type)) props.push(key);
    return props;
  }

  eliminateDuplicates<Type extends Entity>(input: Type[]): Type[] {
    const keys = new Set<string>();
    const out: Type[] = [];
    for (const ent of input) {
      const key = this.makeKey(ent);
      if (keys.has(key)) continue;
      out.push(ent);
      keys.add(key);
    }
    return out;
  }

  /** Strips off additional properties that do not belong in the entity itself */
  restrictProps<Type extends Entity>(entity: Type): Type {
    const props = this.meta.models.properties(entity.entityType);
    const res: Entity = { entityType: entity.entityType };
    for (const key in entity) if (props[key] !== undefined) res[key] = entity[key];
    return res as Type;
  }

  /** Validates that an entity conforms to the registered entity model */
  validate(entity: Entity, mode?: ErrorReportingMode): boolean {
    return this.meta.models.validate(entity, mode);
  }

  /** Validates whether an entity conforms to the specified type */
  validateType(entity: Entity, type: string, mode?: ErrorReportingMode): boolean {
    /* First check if the entity is valid at all */
    if (!this.validate(entity, mode)) return false;
    /* Check if the type co-incides */
    if (entity.entityType === type) return true;
    /* Check if the type corresponds to another entity */
    return this.meta.models.validateResult(entity, type, this, mode);
  }

  ensureEntity<Type extends Entity>(obj: ValueObject, options: { representType?: string } = {}) {
    const entity = obj as Entity;
    if (entity.entityType === undefined)
      throw new Error(`passed object is not an entity, entityType missing`);

    if (options.representType !== undefined && !this.represents(entity, options.representType))
      throw new Error(
        `passed object an entity, but does not represent the requested type '${options.representType}'`
      );

    // TODO: Add additional real checks on the obj, e.g. correct entity type and correct attributes ((a) existing and (b) type is correct)
    return obj as Type;
  }

  ensureEntityArr<Type extends Entity>(
    obj: ValueObject[],
    options: { representType?: string } = {}
  ) {
    const res: Type[] = [];
    for (const item of obj) res.push(this.ensureEntity<Type>(item, options));
    return res;
  }

  /** Checks if the entity needs to be prepared (= is a function that needs evaluation) */
  protected needsPreparation(entity: Entity, type: string) {
    return (
      this.meta.entityMapping.canBeMapped(entity.entityType, CoreInterfaces.Result) &&
      type != CoreInterfaces.Result &&
      !this.meta.entityMapping.canBeMapped(entity.entityType, type)
    );
  }

  /** Prepares an entity for mapping, if it represents a function, it is evaluated */
  protected async prepareForMapping(
    entity: Entity,
    type: string,
    cacheStates: CacheState[],
    context?: EntityRecord
  ): Promise<Entity | undefined> {
    /* Check if the provided entity is a function, if yes and if it cannot be mapped, try to evaluate it and map the result */
    while (this.needsPreparation(entity, type)) {
      const res = await this.mapInternal(entity, CoreInterfaces.Result, context || {});
      /* Combine cache states context key sets */
      if (res.cache) cacheStates.push(res.cache);
      if (!res.result) return undefined;
      entity = res.result;
    }
    return entity;
  }

  protected logEntityType(entity: EntityMappingInput) {
    return entity instanceof Array
      ? tupleName(
          entity.map((ent) => {
            return ent.entityType;
          })
        )
      : entity
      ? entity.entityType
      : 'undefined';
  }

  /** Prepares the input and maps the result */
  protected async prepareAndMap<TargetType extends Entity = Entity>(
    input: Entity | Entity[],
    type: string,
    context?: EntityRecord
  ): Promise<[TargetType | undefined, CacheState | undefined]> {
    const cacheStates: CacheState[] = [];
    /* Prepare the data for mapping */
    let entity: Entity | Entity[];
    if (input instanceof Array) {
      /* Prepare all components independently */
      entity = [];
      for (const ent of input) {
        const res = await this.prepareForMapping(ent, type, cacheStates, context);
        if (!res) return [undefined, CombinedCacheState.combine(...cacheStates)];
        entity.push(res);
      }
    } else if (this.needsPreparation(input, type)) {
      const res = await this.prepareForMapping(input, type, cacheStates, context);
      if (!res) return [undefined, CombinedCacheState.combine(...cacheStates)];
      entity = res;
    } else entity = input;
    /* Perform a normal mapping */
    const res = await this.mapInternal(entity, type, context);
    /* Validate result if applicable */
    if (Logger.validate && res.result) this.validateType(res.result, type);
    return [
      res.result as TargetType | undefined,
      CombinedCacheState.combine(res.cache, ...cacheStates),
    ];
  }

  /** Internal mapping function which is overridden to track intermediate results */
  protected mapInternal(
    input: Entity | Entity[],
    type: string,
    context?: EntityRecord
  ): EntityMappingCacheResult | Promise<EntityMappingCacheResult> {
    if ('entityType' in input)
      return this.meta.entityMapping.mapEntity(input, type, context || {}, this.mappingTrace || {});
    return this.meta.entityMapping.mapTuple(input, type, context || {}, this.mappingTrace || {});
  }

  abstract get steadyTimer(): TimerInterface;
  abstract get displayTimer(): TimerInterface;

  readonly meta: MetaContext;
  protected mappingTrace?: EntityMappingTrace;
}
