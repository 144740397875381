import { EntityHeader } from '@sqior/js/entity';
import { CoreEntities } from './core-definitions';
import { EntityModel } from './entity';

/** Model describing a context property */

export type ContextPropertyModel = {
  name: string;
  autoForward: boolean;
  mandatory?: boolean;
  requires?: string;
};

/** Context property for controlling logging of exceptions during mapping */

export enum MappingReportingMode {
  Normal = 'normal',
  Ignore = 'ignore',
}
export type MappingReportingModeEntity = EntityHeader & { mode: MappingReportingMode };
export const MappingReportingModeModel: EntityModel = {
  type: CoreEntities.MappingReportingMode,
  props: ['mode'],
  keys: ['mode'],
};
export function makeMappingReportingMode(mode: MappingReportingMode): MappingReportingModeEntity {
  return { entityType: MappingReportingModeModel.type, mode };
}

export const MappingReportingModeContextProperty: ContextPropertyModel = {
  name: 'reportingMode',
  autoForward: true,
  requires: MappingReportingModeModel.type,
};
