import { ClockTimestamp, isEqual } from '@sqior/js/data';
import { Entity, EntityHeader } from '@sqior/js/entity';
import { DateEntity, isDateBefore, makeDateFromTimestamp } from './date';
import { TimeEntities } from './time-definitions';
import { relativeDate } from './timestamp';

/** Representing a date range */
export type DateRange = [Date, Date];

/** Check whether a  date is in a date range
 */
export function isDateInRange(dateRange: DateRange, date: Date) {
  return dateRange[0] <= date && date <= dateRange[1];
}

/** Checks whether a date is in the range */
export function isDateEntInRange(range: DateRangeEntity<DateEntity>, date: DateEntity) {
  return (
    isEqual(range.start, date) ||
    isEqual(date, range.end) ||
    (isDateBefore(range.start, date) && isDateBefore(date, range.end))
  );
}

/** Splits a date range at a given date */
export function splitDateRange(range: DateRangeEntity<DateEntity>, date: DateEntity) {
  if (!isDateEntInRange(range, date)) return undefined;

  const range1 = makeDateRange(range.start, relativeDate(date, -1));
  const range2 = makeDateRange(relativeDate(date, 1), range.end);

  const res: {
    before?: DateRangeEntity<DateEntity>;
    middle: DateEntity;
    after?: DateRangeEntity<DateEntity>;
  } = {
    middle: date,
  };

  if (isDateBefore(range1.start, range1.end)) res.before = range1;
  if (isDateBefore(range2.start, range2.end)) res.after = range2;
  return res;
}

/** Creates a date range which is bound to the days bounaries (00:00 and 23:59)
 */
export function createWholeDayDateRange(dateTimeRange: DateRange | Date): DateRange {
  if (Array.isArray(dateTimeRange)) {
    const startDay = startOfDay(dateTimeRange[0]);
    const endDay = endOfDay(dateTimeRange[1]);
    return [startDay, endDay];
  }
  return [startOfDay(dateTimeRange), endOfDay(dateTimeRange)];
}

export function startOfDay(date: Date | ClockTimestamp) {
  const startDay = new Date(date);
  startDay.setHours(0, 0, 0, 0);
  return startDay;
}

export function endOfDay(date: Date | ClockTimestamp) {
  const endDay = new Date(date);
  endDay.setHours(23, 59, 59, 999); // Minus 1s to be safely in the preceeding day
  return endDay;
}

/** Date range entity */

export type DateRangeEntity<Type extends Entity = Entity> = EntityHeader & {
  start: Type;
  end: Type;
};
export const DateRangeModel = {
  type: TimeEntities.DateRange,
  props: ['start', 'end'],
  keys: ['start', 'end'],
};
export function makeDateRange<Type extends Entity = Entity>(
  start: Type,
  end: Type
): DateRangeEntity<Type> {
  return { entityType: TimeEntities.DateRange, start, end };
}

export function makeDateRangeFromTS(
  range: [ClockTimestamp, ClockTimestamp]
): DateRangeEntity<DateEntity> {
  return makeDateRange(makeDateFromTimestamp(range[0]), makeDateFromTimestamp(range[1]));
}
