import DashboardList from '../dashboard-list/dashboard-list';
import styles from './dashboard-stage.module.css';

/* eslint-disable-next-line */
export interface DashboardStageProps {
  title: string;
  path: string;
  className: string;
  separator: boolean;
  containerStyle?: React.CSSProperties;
  style?: React.CSSProperties;
}

export function DashboardStage(props: DashboardStageProps) {
  return (
    <div
      className={props.className}
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        ...props.style,
      }}
    >
      <div className={styles['title']}>{props.title}</div>

      <DashboardList
        location="old-dashboard"
        path={props.path}
        className={styles[props.separator ? 'entries' : 'no-entries']}
      />
    </div>
  );
}

export default DashboardStage;
