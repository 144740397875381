/** Path containing all analytics dashboard to offer */
export const AnalyticsDashboardSelectionPath = 'analytics-dashboard-selection';

/** Analytics dashboards */
export enum AnalyticsDashboards {
  Generic = 'generic',
  // Following enums are not used anymore
  // Occupancy = 'occupancy',
  // ORManagement = 'ormanagement',
}
