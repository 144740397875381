import { Entity } from '@sqior/js/entity';
import { CoreEntities, EntityModel, Interface } from '@sqior/js/meta';
import { InputEntities, InputInterfaces } from './input-definitions';
import {
  EscalationReason as EscalationReasonVM,
  EscalationReasonEntity as EscalationReasonEntityVM,
} from '@sqior/viewmodels/input';

/** Escalation reason entity */

export type EscalationReasonEntity = EscalationReasonEntityVM;
export const EscalationReason = EscalationReasonVM;

export const EscalationReasonModel: EntityModel = {
  type: InputEntities.EscalationReason,
  props: ['reason', 'delay', 'denyReason'],
  unclassified: true,
};
export function makeEscalationReason(reason: string): EscalationReasonEntity {
  return { entityType: EscalationReasonModel.type, reason: reason };
}
export function makeEscalationReasonDeferred(delay: Entity): EscalationReasonEntity {
  return {
    entityType: EscalationReasonModel.type,
    reason: EscalationReason.Deferred,
    delay: delay,
  };
}
export function makeEscalationReasonDenied(text?: string): EscalationReasonEntity {
  const ret: EscalationReasonEntity = {
    entityType: EscalationReasonModel.type,
    reason: EscalationReason.Denied,
  };
  if (text !== undefined) ret.denyReason = text;
  return ret;
}

export const EscalationReasonVerbModel: Interface = {
  type: InputInterfaces.EscalationReasonVerb,
  represents: CoreEntities.Text,
};
