import {
  ORWorkflowOverviewDashboardORItemData,
  ORWorkflowOverviewDashboardProcedureData,
  ORWorkflowOverviewDashboardState,
} from '@sqior/viewmodels/orworkflow';

type Border =
  | {
      color: string | undefined;
      opacity: number | undefined;
    }
  | undefined;

export const colors = {
  CARD_DEFAULT: '#303859',

  CARD_ACTIVE: '#1cade4',
  CARD_ACTIVE_BACKGROUND: 'rgb(7,81,117)',

  CARD_INACTIVE: 'rgba(48,56,89,0.5)',

  EXTENSION_DARK: '#5e315d',
  EXTENSION_ACTIVE: '#ED7BE8',
};

export enum BorderType {
  Card = 'Card',
  Card_Deactivated = 'Card_Deactivated',
  Default = 'Default',
  Extension = 'Extension',
}

const EMERGENCY_BASE_COLOR = '245, 93, 93';
export const LINE_WIDTH = 4;

export const getEmergencyColorWithOpacity = (opacity: undefined | number = 1): string => {
  return `rgba(${EMERGENCY_BASE_COLOR}, ${opacity})`;
};

export const getDefaultBorderColor = (state: ORWorkflowOverviewDashboardState, border: Border) => {
  if (state === ORWorkflowOverviewDashboardState.Completed) {
    if (!border || !border.opacity) return colors.CARD_INACTIVE;
    return `${getEmergencyColorWithOpacity(border.opacity - 0.5)}`;
  }

  if (!border || !border.opacity) return colors.CARD_DEFAULT;
  return `${getEmergencyColorWithOpacity(border.opacity)}`;
};

export const getInactiveColor = (border: BorderType) => {
  switch (border) {
    case BorderType.Default:
      return colors.CARD_ACTIVE_BACKGROUND;
    case BorderType.Extension:
      return colors.EXTENSION_DARK;
    case BorderType.Card:
      return colors.CARD_DEFAULT;
    case BorderType.Card_Deactivated:
      return colors.CARD_INACTIVE;
    default:
      return 'green';
  }
};

export const getActiveColor = (border: BorderType) => {
  switch (border) {
    case BorderType.Default:
      return colors.CARD_ACTIVE;
    case BorderType.Extension:
      return colors.EXTENSION_ACTIVE;
    case BorderType.Card:
      return colors.CARD_DEFAULT;
    default:
      return 'green';
  }
};

export interface GetBorderBottomColorProps {
  selected: boolean;
  splitHeightTop: number;
  currentTime: number;
  item: ORWorkflowOverviewDashboardORItemData;
  onePixelInTime: number;
  procedureState: ORWorkflowOverviewDashboardState;
  procedureData: ORWorkflowOverviewDashboardProcedureData;
  extensionWithTolerance: number;
}

export const getBorderBottomColor = ({
  selected,
  splitHeightTop,
  currentTime,
  item,
  procedureState,
  procedureData,
  extensionWithTolerance,
  onePixelInTime,
}: GetBorderBottomColorProps) => {
  if (selected && splitHeightTop === 100) return getActiveColor(BorderType.Default);

  if (currentTime > item.end && splitHeightTop !== 100) {
    return getInactiveColor(BorderType.Default);
  }

  if (currentTime > item.end && splitHeightTop === 100) {
    return getDefaultBorderColor(procedureState, procedureData.border);
  }

  if (currentTime < item.start && splitHeightTop === 100)
    return getDefaultBorderColor(procedureState, procedureData.border);

  if (currentTime < item.start && splitHeightTop !== 100)
    return getInactiveColor(BorderType.Default);

  if (
    currentTime >= extensionWithTolerance - (LINE_WIDTH - 2) * onePixelInTime &&
    currentTime <= item.end &&
    splitHeightTop !== 100
  ) {
    return getActiveColor(BorderType.Default);
  }

  if (currentTime >= item.start && currentTime <= item.end && splitHeightTop === 100) {
    return getDefaultBorderColor(procedureState, procedureData.border);
  }

  if (currentTime >= item.start && currentTime < extensionWithTolerance && splitHeightTop !== 100) {
    return getInactiveColor(BorderType.Default);
  }

  return getActiveColor(BorderType.Default);
};
