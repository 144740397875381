import { OperationSpec, OperationType } from '@sqior/js/operation';
import { joinPath } from '@sqior/js/url';
import { DateData } from './date-vm';
import { EntityHeader } from '@sqior/js/entity';

export const SelectedDatePath = 'selected-date';
export const SelectedDateNextDaySubPath = 'next';
export const SelectedDatePrevDaySubPath = 'prev';

export type DateSelectionVM = {
  date: EntityHeader & DateData;
  prevDate: boolean;
  nextDate: boolean;
};

export function NextDay(): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(SelectedDatePath, SelectedDateNextDaySubPath),
    data: '',
  };
}
export function PrevDay(): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(SelectedDatePath, SelectedDatePrevDaySubPath),
    data: '',
  };
}
