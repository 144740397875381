import styles from './dashboard-renderer.module.css';
import {
  AnalyticDashboardItem,
  AnalyticDashboardItems,
} from '@sqior/viewmodels/analytics-dashboard';
import { ReactNode, useCallback, useMemo } from 'react';
import { useFactoryComponent } from '@sqior/react/factory';

export interface DashboardRendererProps {
  items: AnalyticDashboardItems[] | undefined;
  layout?: 'grid' | 'flexbox';
}

export function DashboardRenderer({ items, layout = 'grid' }: DashboardRendererProps) {
  const FactoryComponent = useFactoryComponent();

  const renderItemRecursively = useCallback(
    (item: AnalyticDashboardItem, keyPrefix = ''): ReactNode => {
      if (Array.isArray(item)) {
        return (
          <div key={keyPrefix} className={styles['yellow-background-container']}>
            {item.map((nestedItem, nestedIndex) =>
              renderItemRecursively(nestedItem, `${keyPrefix}-${nestedIndex}`)
            )}
          </div>
        );
      } else {
        return (
          <div
            className={styles['item-container']}
            key={keyPrefix}
            style={{
              flexGrow: item.hGrow || 0,
            }}
          >
            <FactoryComponent data={item} />
          </div>
        );
      }
    },
    [FactoryComponent]
  );

  const calculatedLayout = useMemo(() => {
    // check every item in the items array to see if any of them has hGrow !== 1
    // if so, we need to use flexbox layout
    if (!items) return layout;
    return hasHgrowNotOne(items) ? 'flexbox' : 'grid';
  }, [layout, items]);

  return (
    <div
      className={
        calculatedLayout === 'grid' ? styles['container-grid'] : styles['container-flexbox']
      }
    >
      {items?.map((row, rowIndex) => (
        <div
          key={`row-${rowIndex}`}
          className={
            calculatedLayout === 'grid'
              ? styles['row-container-grid']
              : styles['row-container-flexbox']
          }
        >
          {row.map((item, itemIndex) => renderItemRecursively(item, `${rowIndex}-${itemIndex}`))}
        </div>
      ))}
    </div>
  );
}

export default DashboardRenderer;

export function hasHgrowNotOne(items?: AnalyticDashboardItems): boolean {
  if (!items) {
    return false;
  }

  for (const item of items) {
    if (Array.isArray(item)) {
      // Item is a nested AnalyticDashboardItems array
      if (hasHgrowNotOne(item)) {
        return true;
      }
    } else if ('hGrow' in item && item.hGrow !== 1) {
      // Item is a Card with hGrow not equal to 1
      return true;
    }
  }

  return false;
}
