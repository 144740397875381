import { Entity, EntityHeader } from '@sqior/js/entity';
import { OperationSpec, OperationType } from '@sqior/js/operation';

/** Type for phone number view models */

export type PhoneNumberPair = {
  phoneNumber: string;
  phoneNumberVis: string; // can be HTML
};
export type PhoneNumbersVM = EntityHeader & { numbers: PhoneNumberPair[] };

/** Operation for calling a phone number (executed locally on the phone) */

export const PhoneCallPath = 'phone-call';
export function CallPhoneNumber(numbers: Entity): OperationSpec<Entity> {
  return { type: OperationType.Add, path: PhoneCallPath, data: numbers };
}
