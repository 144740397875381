import { useLayoutEffect, RefObject } from 'react';
import { TimePositionCalculator } from '../orworkflow-overview-dashboard/TimePositionCalculator';
import { ORWorkflowOverviewProcedureType } from '@sqior/viewmodels/orworkflow';

const SPACE_ON_OVERFLOW = 10; // in px

export const useUpdatePaddingTop = ({
  wrapperRef,
  entityType,
  itemStart,
  timeCalc,
  contentHeight,
}: {
  wrapperRef: RefObject<HTMLDivElement>;
  entityType: string;
  itemStart: number;
  timeCalc: TimePositionCalculator;
  contentHeight: number;
}) => {
  useLayoutEffect(() => {
    if (entityType !== ORWorkflowOverviewProcedureType) return;
    if (!wrapperRef.current) return;
    const fatherElement = wrapperRef.current.parentElement;
    if (!fatherElement) return;
    const parentHeight = contentHeight || fatherElement.clientHeight;
    if (!parentHeight) return;
    const top = timeCalc.convertTimestampNumber(itemStart);
    const missingPixel = top * parentHeight * -1 - SPACE_ON_OVERFLOW;
    if (missingPixel > 0) {
      wrapperRef.current.style.paddingTop = `${missingPixel}px`;
    } else {
      wrapperRef.current.style.removeProperty('padding-top');
    }
  }, [entityType, itemStart, timeCalc, contentHeight, wrapperRef]);
};

export default useUpdatePaddingTop;

// const updatePaddingTop = () => {
//   if (procedureData.entityType !== ORWorkflowOverviewProcedureType) return;
//   const top = timeCalc.convertTimestampNumber(item.start); // in % of the total height of the father
//   const fatherElement = wrapperRef.current?.parentElement;
//   if (!wrapperRef.current || !fatherElement) return;
//   const parentHeight = contentHeight || fatherElement.clientHeight;
//   if (!parentHeight) return;
//   const missingPixel = top * parentHeight * -1 - SPACE_ON_OVERFLOW;
//   if (missingPixel > 0) wrapperRef.current.style.paddingTop = `${missingPixel}px`;
//   else wrapperRef.current.style.removeProperty('padding-top');
// };
//
// updatePaddingTop();
