import { ClockTimestamp } from '@sqior/js/data';
import { PatientTransportIndicatorState } from '@sqior/viewmodels/patient';

export enum ORWorkflowCountdownType {
  Emergency = 'emergency',
  Transport = 'transport',
  Anesthesia = 'anesthesia',
  Surgery = 'surgery',
}

/** View model describing a common indicator for OR workflows */

export type ORWorkflowIndicatorVM =
  | {
      countdownType: ORWorkflowCountdownType;
      countdown?: ClockTimestamp;
      countdownStart?: ClockTimestamp;
      countdownDuration?: ClockTimestamp;
    }
  | PatientTransportIndicatorState;
