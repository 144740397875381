import { ClockTimestamp } from '@sqior/js/data';
import { Entity, IdEntity } from '@sqior/js/entity';
import { createUID } from '@sqior/js/uid';
import { CoreEntities } from './core-definitions';
import { EntityModel } from './entity';
import { SQIORAssistant } from './sqior-assistant';
import { SequencedEvent, UndefinedSequenceNumber } from '@sqior/js/event-stream';

/* Definition of the basic Event entity */

export type EventEntity = Entity &
  SequencedEvent & { creationTimestamp: number /* ms since epoch */ };
export const EventEntityModel: EntityModel = {
  type: CoreEntities.Event,
  props: ['sequenceNumber', 'eventId', 'creationTimestamp'],
  unclassified: true,
};
export function createEventEntity(
  type: string,
  timestamp: ClockTimestamp,
  eventId = createUID()
): EventEntity {
  return {
    entityType: type,
    sequenceNumber: UndefinedSequenceNumber,
    eventId: eventId,
    creationTimestamp: timestamp,
  };
}

/* Definition of entities that invoke other actions */

export type InvokingEntity<Type extends Entity = Entity> = EventEntity & {
  entity: Type;
  user: Entity;
};

/* Definition of the events invoking, undoing or stopping a command  */

export type InvokeEntity<Type extends Entity = Entity> = InvokingEntity<Type>;
export const InvokeEntityModel: EntityModel = {
  type: CoreEntities.Invoke,
  props: ['entity', 'user'],
  extends: CoreEntities.Event,
  unclassified: true,
};
export function createInvokeEntity(
  entity: Entity,
  timestamp: ClockTimestamp,
  user: Entity = SQIORAssistant,
  eventId = createUID()
): InvokeEntity {
  return {
    entity: entity,
    user: user,
    ...createEventEntity(CoreEntities.Invoke, timestamp, eventId),
  };
}

export type InvokedEntity<Type extends Entity = Entity> = InvokingEntity<Type> & {
  invocation: string;
};

export type UndoEntity = InvokedEntity;
export const UndoEntityModel: EntityModel = {
  type: CoreEntities.Undo,
  props: ['entity', 'invocation', 'user'],
  extends: CoreEntities.Event,
  unclassified: true,
};
export function createUndoEntity(
  entity: Entity,
  invocation: string,
  timestamp: ClockTimestamp,
  user: Entity = SQIORAssistant
): UndoEntity {
  return {
    entity: entity,
    invocation: invocation,
    user: user,
    ...createEventEntity(CoreEntities.Undo, timestamp),
  };
}

export type RedoEntity = InvokedEntity;
export const RedoEntityModel: EntityModel = {
  type: CoreEntities.Redo,
  props: ['entity', 'invocation', 'user'],
  extends: CoreEntities.Event,
  unclassified: true,
};
export function createRedoEntity(
  entity: Entity,
  invocation: string,
  timestamp: ClockTimestamp,
  user: Entity = SQIORAssistant
): RedoEntity {
  return {
    entity: entity,
    invocation: invocation,
    user: user,
    ...createEventEntity(CoreEntities.Redo, timestamp),
  };
}

export type StopEntity = InvokedEntity;
export const StopEntityModel: EntityModel = {
  type: CoreEntities.Stop,
  props: ['entity', 'invocation', 'user'],
  extends: CoreEntities.Event,
  unclassified: true,
};
export function createStopEntity(
  entity: Entity,
  invocation: string,
  timestamp: ClockTimestamp,
  user: Entity = SQIORAssistant
): StopEntity {
  return {
    entity: entity,
    invocation: invocation,
    user: user,
    ...createEventEntity(CoreEntities.Stop, timestamp),
  };
}

export type UpdateEntity = InvokedEntity;
export const UpdateEntityModel: EntityModel = {
  type: CoreEntities.Update,
  props: ['entity', 'invocation', 'user'],
  extends: CoreEntities.Event,
  unclassified: true,
};
export function createUpdateEntity(
  entity: Entity,
  invocation: string,
  timestamp: ClockTimestamp,
  user: Entity = SQIORAssistant
): StopEntity {
  return {
    entity: entity,
    invocation: invocation,
    user: user,
    ...createEventEntity(CoreEntities.Update, timestamp),
  };
}

/* Definition of the event ID entity */

export type EventIdEntity = IdEntity;
export const EventIdModel: EntityModel = {
  type: CoreEntities.EventId,
  props: ['id'],
  keys: ['id'],
  unclassified: true,
};
export function makeEventId(id: string): EventIdEntity {
  return { entityType: CoreEntities.EventId, id: id };
}

/* Name of the root event sequence */

export const RootEventStreamName = 'root';
