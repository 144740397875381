import { isEmpty } from '@sqior/js/data';
import { Entity, EntityHeader, EntityRecord, IdEntity } from '@sqior/js/entity';
import { AnchorModel, makeId, RelatedDataModel } from '@sqior/js/meta';
import { LanguageEntities } from '@sqior/plugins/language';
import { VisualEntities } from './visual-definitions';

/** Domain */

export const DynamicMessageDomainName = 'DynamicMessage';

/** Entities and interfaces */

export enum DynamicMessageEntities {
  Id = 'DynamicMessageId',
  Message = 'DynamicMessage', // Instance of a dynamic message
  Parameters = 'MessageParameters', // Parameter specifications for parameters found in message
  ParameterSpec = 'DynamicMessageParameterSpec', // Parameter specification
  LibraryItem = 'DynamicMessageLibraryItem', // Dynamic message in package library
  ConfigItem = 'DynamicMessageConfigItem', // Dynamic message processed for configurator
}

export enum DynamicMessageInterfaces {
  Key = 'DynamicMessageKey',
  ViewSpec = 'DynamicMessageViewSpec',
}

/** Related data */

export const DynamicMessageAnchor = new AnchorModel(
  DynamicMessageDomainName,
  DynamicMessageInterfaces.Key
);

export const DynamicMessageTitle = new RelatedDataModel(
  DynamicMessageAnchor,
  'Title',
  LanguageEntities.AnonymizedText
);
export const DynamicMessageText = new RelatedDataModel(
  DynamicMessageAnchor,
  'Text',
  LanguageEntities.AnonymizedText
);
export const DynamicMessageParameters = new RelatedDataModel(
  DynamicMessageAnchor,
  'Parameters',
  DynamicMessageEntities.Parameters
);
export const DynamicMessageIcon = new RelatedDataModel(
  DynamicMessageAnchor,
  'Icon',
  VisualEntities.Icon
);

export const DynamicMessagePackageModels = [
  DynamicMessageTitle,
  DynamicMessageParameters,
  DynamicMessageText,
];

/** ID */

export function makeDynamicMessageId(id: string): IdEntity {
  return makeId(id, DynamicMessageEntities.Id);
}

/** Message instance */

export type DynamicMessageEntity = EntityHeader & { key: Entity; params?: EntityRecord };
export function DynamicMessage(key: Entity, params?: EntityRecord): DynamicMessageEntity {
  const res: DynamicMessageEntity = { entityType: DynamicMessageEntities.Message, key };
  if (!isEmpty(params)) res.params = params;
  return res;
}

/** Message parameters */

export type DynamicMessageParametersEntity = EntityHeader & { params: EntityRecord };
export function makeDynamicMessageParameters(params: EntityRecord): DynamicMessageParametersEntity {
  return { entityType: DynamicMessageEntities.Parameters, params };
}

export type DynamicMessageParameterSpecEntity = EntityHeader & { key: string; template: Entity };
export function makeDynamicMessageParameterSpec(
  key: string,
  template: Entity
): DynamicMessageParameterSpecEntity {
  return { entityType: DynamicMessageEntities.ParameterSpec, key, template };
}
