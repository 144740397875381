import { FactoryProps } from '@sqior/react/factory';
import styles from './orworkflow-location-separator.module.css';
import { ORWorkflowLocationSeparatorData } from '@sqior/viewmodels/orworkflow';

type ORWorkflowLocationSeparatorProps = FactoryProps<ORWorkflowLocationSeparatorData>;

export function ORWorkflowLocationSeparator(props: ORWorkflowLocationSeparatorProps) {
  return <div className={styles['container']}>{props.data.location}</div>;
}

export default ORWorkflowLocationSeparator;
