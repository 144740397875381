import { Entity } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { LanguageEntities } from './language-definitions';

/* Language Gender */

export enum Gender {
  Male = 'male',
  Female = 'female',
  Neuter = 'neuter',
}
export type GenderEntity = Entity & { gender: Gender };
export const GenderModel: EntityModel = {
  type: LanguageEntities.Gender,
  props: ['gender'],
  keys: ['gender'],
};
export function makeGender(gender: Gender): GenderEntity {
  return { entityType: LanguageEntities.Gender, gender: gender };
}

/* Grammatical Case */

export enum GrammaticalCase {
  Nominative = 'nominative',
  DativeSoft = 'dative-soft',
}
export type GrammaticalCaseEntity = Entity & { grammaticalCase: string };
export const GrammaticalCaseModel: EntityModel = {
  type: LanguageEntities.Case,
  props: ['grammaticalCase'],
};
export function makeGrammaticalCase(grammaticalCase: string): GrammaticalCaseEntity {
  return { entityType: LanguageEntities.Case, grammaticalCase: grammaticalCase };
}
