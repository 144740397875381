import styles from './sqior-dropbox.module.css';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React, { cloneElement, MouseEvent, ReactNode, useState } from 'react';
import { SvgCheckIcon } from '../svg-icon/svg-icon';
import {
  DROPBOX_getClassWithDivider,
  DROPBOX_ICON_COLOR,
  DROPBOX_ICON_DROPDOWN_COLOR,
} from './sqior-dropbox-utils';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/material';

export type SqiorDropboxItem = {
  label: string;
  id: string;
};

interface BaseSqiorDropboxProps {
  options: SqiorDropboxItem[];
  label: string;
  icon?: ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
}

interface SingleSqiorDropboxProps extends BaseSqiorDropboxProps {
  value?: string;
  onChange?: (id: string) => void;
  multiple?: false;
}

interface MultipleSqiorDropboxProps extends BaseSqiorDropboxProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  multiple: true;
}

export type SqiorDropboxProps = SingleSqiorDropboxProps | MultipleSqiorDropboxProps;

export function SqiorDropbox({
  options,
  icon,
  label,
  fullWidth,
  disabled,
  value,
  onChange,
  multiple,
}: SqiorDropboxProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const toggleDropBox = (event: MouseEvent<HTMLDivElement>) => {
    if (disabled) return;
    if (anchorEl) return setAnchorEl(null);
    return setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLocalChange = (_event: MouseEvent<HTMLDivElement>, newValue: string) => {
    if (multiple) {
      const newValueArray = Array.isArray(value) ? [...value] : [];
      const valueIndex = newValueArray.indexOf(newValue);

      if (valueIndex === -1) {
        newValueArray.push(newValue);
      } else {
        newValueArray.splice(valueIndex, 1);
      }

      onChange?.(newValueArray as string[]);
    } else {
      onChange?.(newValue as string);
    }
    handleClose();
  };

  const isSelected = (id: string): boolean => {
    if (multiple && Array.isArray(value)) {
      return value.includes(id);
    }
    return id === value;
  };

  const getSelectedLabel = () => {
    if (multiple && Array.isArray(value)) {
      if (value.length === 0 || !value) return label;
      return value.map((id) => options.find((option) => option.id === id)?.label).join(', ');
    }
    if (value === '' || !value) return label;
    return options.find((option) => option.id === value)?.label;
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <div
          className={styles['container']}
          onClick={toggleDropBox}
          style={{
            width: fullWidth ? '100%' : 'auto',
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? 'default' : 'pointer',
            position: 'relative',
          }}
        >
          <div className={styles['center']}>
            {icon &&
              cloneElement(icon as React.ReactElement, {
                style: { fill: DROPBOX_ICON_COLOR },
              })}
          </div>
          <div className={styles['center']}>{getSelectedLabel()}</div>
          <div className={styles['center']}>
            {anchorEl ? (
              <ArrowDropUpIcon style={{ color: DROPBOX_ICON_DROPDOWN_COLOR }} />
            ) : (
              <ArrowDropDownIcon style={{ color: DROPBOX_ICON_DROPDOWN_COLOR }} />
            )}
          </div>
          {Boolean(anchorEl) && (
            <Popper
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              sx={{
                '& .MuiPaper-root': {
                  backgroundImage: 'none',
                  backgroundColor: 'transparent',
                },
                zIndex: 1000,
              }}
              modifiers={[
                {
                  name: 'flip',
                  enabled: true,
                  options: {
                    altBoundary: true,
                    rootBoundary: 'document',
                    padding: 8,
                  },
                },
                {
                  name: 'preventOverflow',
                  enabled: true,
                  options: {
                    altAxis: true,
                    altBoundary: true,
                    tether: true,
                    rootBoundary: 'document',
                  },
                },
              ]}
            >
              <div className={styles['dropbox-wrapper']}>
                {options.map((item, index, arr) => (
                  <div
                    className={DROPBOX_getClassWithDivider(index, arr)}
                    key={item.id}
                    onClick={(e) => onLocalChange(e, item.id)}
                  >
                    <div className={styles['icon-container']}>
                      {isSelected(item.id) && <SvgCheckIcon />}
                    </div>
                    <div className={styles['center']}>{item.label}</div>
                  </div>
                ))}
              </div>
            </Popper>
          )}
        </div>
      </ClickAwayListener>
    </>
  );
}

export default SqiorDropbox;
