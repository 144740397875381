import { Node } from 'reactflow';
import { Edge } from '@reactflow/core/dist/esm/types/edges';
import {
  CHART_COLORS,
  NODE_HEIGHT,
  NODE_HORIZONTAL_GAP,
  NODE_VERTICAL_GAP,
  NODE_WIDTH,
} from './constants';
import {
  ChartValueLabel,
  HelpLine,
  StackedTimeSeriesChartVM,
} from '@sqior/viewmodels/analytics-dashboard';
import { getChartColor } from './colors';
import {
  addDays,
  addWeeks,
  differenceInDays,
  format,
  isFirstDayOfMonth,
  isMonday,
  lastDayOfMonth,
} from 'date-fns';

export type WithSelection<T> = {
  value: T;
  selected: number;
};

export const withSelection = <T>(value: T, selected: number): WithSelection<T> => {
  return { value, selected };
};

interface MakeFlowChartProps {
  edges: Edge[];
  nodes: Node[];
  areas?: Node[];
}

interface MakeFlowChartResult {
  mEdges: Edge[];
  mNodes: Node[];
  mAreas?: Node[];
}

export const makeManualPosition = ({
  edges,
  nodes,
  areas,
}: MakeFlowChartProps): MakeFlowChartResult => {
  const rootX = 0;
  const allNodes: Node[] = [];

  const allYs = nodes.map((node) => node.position?.y ?? 0);
  const uniqueYs = Array.from(new Set(allYs));

  for (let j = 0; j <= uniqueYs.length; j++) {
    const value = uniqueYs[j];
    const allNodesInRow = nodes.filter((node) => node.position?.y === value);
    // set the x position of the nodes that the root node will be the center of the row
    const rowWidth = allNodesInRow.length * (NODE_WIDTH + NODE_HORIZONTAL_GAP);
    const startX = rootX - rowWidth / 2;
    for (let i = 0; i < allNodesInRow.length; i++) {
      const nodeX = startX + i * (NODE_WIDTH + NODE_HORIZONTAL_GAP);
      const nodeY = j * (NODE_HEIGHT + NODE_VERTICAL_GAP);
      const node = allNodesInRow[i];
      if (node) {
        allNodes.push({
          ...node,
          position: {
            x: nodeX,
            y: nodeY,
          },
        });
      }
    }
  }

  return { mNodes: allNodes, mEdges: edges, mAreas: areas };
};

export const getValueFromLocalStorage = (key: string): object | null => {
  try {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  } catch (e) {
    return null;
  }
};

export const setValueToLocalStorage = (key: string, value: object): boolean => {
  try {
    const stringifyValue = JSON.stringify(value);
    localStorage.setItem(key, stringifyValue);
    return true;
  } catch (e) {
    return false;
  }
};

export const makeGridPosition = ({
  edges,
  nodes,
  areas,
}: MakeFlowChartProps): MakeFlowChartResult => {
  const SPACE_BETWEEN_NODES = 20;
  const allNodes = nodes.map((node) => {
    const secureX = node.position?.x ?? 0;
    const secureY = node.position?.y ?? 0;

    const x = secureX * NODE_WIDTH + NODE_HORIZONTAL_GAP + secureX * SPACE_BETWEEN_NODES;
    const y = secureY * (NODE_HEIGHT + NODE_VERTICAL_GAP);
    return {
      ...node,
      position: {
        x,
        y,
      },
    };
  });

  return { mNodes: allNodes, mEdges: edges, mAreas: areas };
};

export const getChartColors = (receivedColors?: string[], itemsLength?: number) => {
  if (CHART_COLORS) {
    return CHART_COLORS;
  }
  if (!receivedColors) {
    return CHART_COLORS;
  }

  if (!itemsLength) {
    return CHART_COLORS;
  }

  const receivedColorsLength = receivedColors.length;
  if (receivedColorsLength === 0) {
    return CHART_COLORS;
  }
  const valuesLength = itemsLength;
  if (receivedColorsLength !== valuesLength) {
    // fill the rest of the colors with default colors
    const defaultColorsLength = [...CHART_COLORS].length;
    const diff = valuesLength - receivedColorsLength;
    const defaultColorsIndex = receivedColorsLength % defaultColorsLength;
    const defaultColorsSlice = [...CHART_COLORS].slice(
      defaultColorsIndex,
      defaultColorsIndex + diff
    );
    return [...receivedColors, ...defaultColorsSlice];
  }

  return receivedColors;
};

export const extractChartColors = (graph: StackedTimeSeriesChartVM['chart']['graph']) => {
  let pickedIndex = 0;

  const colors: string[] = [];

  graph.forEach((_) => {
    if (_.color) {
      colors.push(getChartColor(_.color));
    } else {
      colors.push(CHART_COLORS[pickedIndex]);
      pickedIndex++;
    }
  });

  return colors;
};

export type ChartXAxisType = 'datetime' | 'numeric';

export interface TimeXAxisProps {
  items: number[];
  type: ChartXAxisType;
  showAxis?: boolean;
}

export const xaxisOptions = ({ items, type, showAxis = true }: TimeXAxisProps): ApexXAxis => {
  if (type !== 'datetime') {
    // For non-datetime types, return the existing logic
    return {
      tickAmount: Math.floor(items.length / 8),
      categories: items,
      labels: {
        show: !!showAxis,
        hideOverlappingLabels: true,
      },

      axisTicks: {
        show: !!showAxis,
      },
      axisBorder: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    };
  }

  // For datetime type, apply the new logic
  const dates = items.map((item) => new Date(item));
  const startDate = dates[0];
  const endDate = dates[dates.length - 1];

  const daysDiff = differenceInDays(endDate, startDate);

  let categories: string[] = [];

  // Helper function to format dates
  const formatDateLabel = (date: Date) => format(date, 'dd MMM');

  // Helper function to check if data covers full months
  const isFullMonths = (): boolean => {
    const startIsFirstDay = isFirstDayOfMonth(startDate);
    const endIsLastDay = endDate.getTime() === lastDayOfMonth(endDate).getTime();
    return startIsFirstDay && endIsLastDay;
  };

  if (isFullMonths()) {
    // Data covers full months
    // Show labels on the first day of each month, always include start and end labels
    categories = dates.map((date, index) => {
      if (isFirstDayOfMonth(date) || index === 0 || index === dates.length - 1) {
        return formatDateLabel(date);
      } else {
        return '';
      }
    });

    if (daysDiff <= 180) {
      categories = dates.map((date, index) => {
        if (isMonday(date) || index === 0 || index === dates.length - 1) {
          return formatDateLabel(date);
        } else {
          return '';
        }
      });
    }
  } else if (daysDiff <= 7) {
    // Show all ticks
    categories = dates.map((date) => formatDateLabel(date));
  } else if (daysDiff > 7 && daysDiff <= 180) {
    // Show labels on Mondays, always include start and end labels
    categories = dates.map((date, index) => {
      if (isMonday(date) || index === 0 || index === dates.length - 1) {
        return formatDateLabel(date);
      } else {
        return '';
      }
    });
  } else if (daysDiff > 180) {
    // Show labels every 4 weeks starting from the first Monday after startDate
    const labelDates: number[] = [];

    // Find the first Monday on or after startDate
    let currentDate = new Date(startDate);
    while (!isMonday(currentDate)) {
      currentDate = addDays(currentDate, 1);
    }

    // Collect dates every 4 weeks (28 days)
    while (currentDate <= endDate) {
      labelDates.push(currentDate.getTime()); // Using timestamp for comparison
      currentDate = addWeeks(currentDate, 4);
    }

    categories = dates.map((date, index) => {
      if (labelDates.includes(date.getTime()) || index === 0 || index === dates.length - 1) {
        return formatDateLabel(date);
      } else {
        return '';
      }
    });
  }

  return {
    categories,
    labels: {
      show: !!showAxis,
      formatter: (value) => value || '',
    },
    axisTicks: {
      show: !!showAxis,
    },
    axisBorder: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };
};

export interface TooltipOptionsProps extends ApexTooltip {
  type: ChartXAxisType;
  extraLabels?: (ChartValueLabel[] | undefined)[];
  categories: number[];
}

export const tooltipOptions = ({
  type,
  extraLabels,
  categories,
  ...rest
}: TooltipOptionsProps): ApexTooltip => {
  return {
    enabled: true, // Enable tooltips
    y: {
      formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        const extraLabelString = extraLabels?.[seriesIndex]?.[0].data[dataPointIndex];
        if (value === undefined) return '';
        if (value === null) return '';
        if (!extraLabelString) return `${value}`; // Return the value if there is no extra label text
        return `${value} (${extraLabelString})`; // Append the extra label text if available
      },
    },
    x: {
      formatter: (val, { seriesIndex, dataPointIndex, w }): string => {
        if (type === 'datetime') {
          return `${format(new Date(categories[dataPointIndex]), 'EEEE, dd MMMM yyyy')}`;
        }
        return categories[dataPointIndex].toString();
      },
    },
    ...rest,
  };
};

export interface AnnotationsOptionsProps extends ApexAnnotations {
  helpLines?: HelpLine[];
}

export const annotationsOptions = ({
  helpLines,
  ...rest
}: AnnotationsOptionsProps): ApexAnnotations => {
  return {
    yaxis:
      helpLines && helpLines.length > 0
        ? helpLines.map((line) => {
            return {
              y: line.value,
              borderColor: line.color || 'rgba(28,173,228,0.8)',
              label: {
                borderColor: line.color || '#1cade4',
                style: {
                  color: 'white',
                  background: line.color || 'rgba(28,173,228,0.8)',
                },
                text: line.name,
                offsetX: -10,
              },
            };
          })
        : [],
    ...rest,
  };
};
