import { useMemo } from 'react';
import Fuse from 'fuse.js';

interface UseSearchMatchProps {
  searchKeys?: string[];
  searchQuery: string;
}

export const useSearchMatch = ({ searchKeys, searchQuery }: UseSearchMatchProps) => {
  const fuse = useMemo(
    () =>
      new Fuse(searchKeys || [], {
        includeMatches: true,
        includeScore: true,
        shouldSort: true,
        threshold: 0.3,
      }),
    [searchKeys]
  );

  const results = useMemo(() => {
    if (!searchQuery) return [];
    return fuse.search(searchQuery).map((r) => r.item);
  }, [searchQuery, fuse]);

  return results.length > 0;
};

export default useSearchMatch;
