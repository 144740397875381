import { Entity } from '@sqior/js/entity';
import { CoreEntities, EntityModel, Interface } from '@sqior/js/meta';
import { VisualEntities, VisualInterfaces } from './visual-definitions';

/* Overview entity listing entities with an optional title */

export type OverviewElement = { entity: Entity; title?: Entity };
export type OverviewEntity = Entity & { entity: Entity; elements: OverviewElement[] };
export const OverviewModel: EntityModel = {
  type: VisualEntities.Overview,
  props: ['entity', 'elements'],
};
export function makeOverview(entity: Entity, elements: OverviewElement[] = []): OverviewEntity {
  return { entityType: VisualEntities.Overview, entity: entity, elements: elements };
}
export function addToOverview(overview: OverviewEntity, entity?: Entity, title?: Entity) {
  if (entity)
    if (title) overview.elements.push({ entity: entity, title: title });
    else overview.elements.push({ entity: entity });
}

/* Title interface used to derive a title from an entity if not explicitly provided */

export const TitleModel: Interface = {
  type: VisualInterfaces.Title,
  represents: CoreEntities.Text,
};
