import { Entity } from '@sqior/js/entity';
import { AnchorModel, RelatedDataModel, RelatedDataSet } from './related-data-model';
import { Domain } from './domain';
import { isEqual } from '@sqior/js/data';
import { CoreEntities } from './core-definitions';

export type RelatedDataImportSpec = [AnchorModel, Entity, RelatedDataSet];

/** Compares the specified data with the data already existing (in domain), returns only those related datasets which need to be set */
export async function diffAgainstDomainData(importData: RelatedDataImportSpec[], domain: Domain) {
  const neededChanges: RelatedDataImportSpec[] = [];
  for (const importLine of importData) {
    const neededLineChanges: RelatedDataSet = [];
    for (const rd of importLine[2]) {
      const curValue = await domain.tryMap(importLine[1], rd[0].interface.type);
      if (rd[1]?.entityType === CoreEntities.Insert) {
        if (!curValue) neededLineChanges.push(rd);
      } else if (!isEqual(rd[1], curValue)) neededLineChanges.push(rd);
    }
    if (neededLineChanges.length > 0) {
      neededChanges.push([importLine[0], importLine[1], neededLineChanges]);
    }
  }
  return neededChanges;
}

/** Returns change commands based on neededChanges calculated with diffAgainstDomainData() */
export function getChangeCommands(importData: RelatedDataImportSpec[]) {
  const changeCommands: Entity[] = [];
  for (const importLine of importData) {
    if (importLine[2].length > 0) {
      changeCommands.push(importLine[0].set(importLine[1], importLine[2]));
    }
  }
  return changeCommands;
}

/** Compares the specified data with the data already existing (in domain), creates commands to update the data */
export async function getRelatedDataImportSpecCommands(
  importData: RelatedDataImportSpec[],
  domain: Domain
) {
  const neededChanges = await diffAgainstDomainData(importData, domain);
  return getChangeCommands(neededChanges);
}

export function getChangedValue<Type extends Entity>(
  importData: RelatedDataImportSpec[],
  model: RelatedDataModel
) {
  for (const importLine of importData) {
    const cv = getChangedValueRDS<Type>(importLine[2], model);
    if (cv) return cv;
  }
  return undefined;
}

export function getChangedValueRDS<Type extends Entity>(
  rds: RelatedDataSet,
  model: RelatedDataModel
) {
  const val = rds.find((rd) => rd[0] === model)?.[1];
  if (val) return val as Type;
  return undefined;
}

export async function importData(
  domain: Domain,
  importData: RelatedDataImportSpec[],
  sender?: Entity
) {
  const neededChanges = await diffAgainstDomainData(importData, domain);
  const changeCommands = getChangeCommands(neededChanges);

  if (changeCommands.length > 0) {
    await domain.invokeEvents(changeCommands, sender);
  }
}
