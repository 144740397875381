import { useMediaQuery } from '@mui/material';
import useIsMobile from './use-is-mobile/use-is-mobile';

export const useIsIpadMini = () => {
  const isMini = useMediaQuery(`(max-width: ${1200}px)`);
  const isMobile = useIsMobile();

  return isMini && !isMobile;
};

export default useIsIpadMini;
