import { LanguageTextResourceManager } from '@sqior/js/language';
import {
  CoreEntities,
  createConsoleTextEntity,
  DebugDomain,
  Domain,
  DomainInterface,
  EntityModel,
  EntityRecord,
  extractText,
  makeTextEntity,
  TextEntity,
  TrackingDomainInterface,
} from '@sqior/js/meta';
import {
  AnonymizationLevelModel,
  AnonymizedModel,
  AnonymizedTextModel,
  makeAnonymizedText,
  makeAnonymousTextTemplate,
} from './anonymized-text';
import { BasicTextResources } from './basic-text-resources';
import {
  Gender,
  GenderEntity,
  GenderModel,
  GrammaticalCaseEntity,
  GrammaticalCaseModel,
  makeGender,
  makeGrammaticalCase,
} from './gender';
import {
  AlternativeTextsModel,
  AnonymizationContextProperty,
  ConcatenationTextsEntity,
  ConcatenationTextsModel,
  GenderContextProperty,
  GrammaticalCaseContextProperty,
  LanguageEntities,
  LanguageInterfaces,
  PlainIdTextModel,
  TextLikeModel,
  TextsEntity,
  TextsModel,
  VerbosityContextProperty,
} from './language-definitions';
import { textResource, TextResource, TextResourceModel } from './text-resource';
import { Verbosity, VerbosityEntity, VerbosityModel } from './verbosity';
import { Entity } from '@sqior/js/entity';
import {
  makeTextTemplate,
  substituteTextParams,
  TextTemplate,
  TextTemplateModel,
} from './text-template';
import {
  makePreposition,
  PrepositionEntity,
  PrepositionOption,
  PrepositionTexts,
} from './preposition';
import { Logger } from '@sqior/js/log';
import { capitalizeFirst } from '@sqior/js/data';

export const LanguageDomainName = 'Language';

/** Models */

const PrepositionModel: EntityModel = {
  type: LanguageEntities.Preposition,
  props: ['prepo'],
  keys: ['prepo'],
  unclassified: true,
};

/** Domain for language concepts */
export class LanguageDomain extends Domain {
  constructor(debugDomain?: DebugDomain) {
    /* Definitions */
    super(LanguageDomainName, {
      entities: [
        TextTemplateModel,
        TextResourceModel,
        GenderModel,
        AnonymizedTextModel,
        VerbosityModel,
        GrammaticalCaseModel,
        TextsModel,
        AlternativeTextsModel,
        ConcatenationTextsModel,
        AnonymizationLevelModel,
        PrepositionModel,
      ],
      interfaces: [AnonymizedModel, PlainIdTextModel, TextLikeModel],
      contextProperties: [
        { name: AnonymizationContextProperty, autoForward: false },
        { name: GenderContextProperty, autoForward: true },
        { name: GrammaticalCaseContextProperty, autoForward: true },
        { name: VerbosityContextProperty, autoForward: true },
      ],
    });

    /* Text template */
    this.addBasicMapping<TextEntity, TextTemplate>(
      CoreEntities.Text,
      LanguageEntities.TextTemplate,
      (entity) => {
        return makeTextTemplate(entity.text, {});
      }
    );
    this.addEntityMapping<TextTemplate, TextEntity>(
      LanguageEntities.TextTemplate,
      CoreEntities.Text,
      async (entity, mapper) => {
        const text = await LanguageDomain.fillTextTemplate(
          mapper,
          entity,
          CoreEntities.Text,
          CoreEntities.Text
        );
        return text !== undefined ? makeTextEntity(text) : undefined;
      },
      { weight: 10 }
    );
    if (debugDomain)
      this.addEntityMapping<TextTemplate, TextEntity>(
        LanguageEntities.TextTemplate,
        CoreEntities.Console,
        async (entity, mapper) => {
          const templ = await mapper.tryMap<TextEntity>(entity.text, debugDomain.targetType);
          if (!templ) return undefined;
          return createConsoleTextEntity(
            await substituteTextParams(templ.text, (param: string, options) => {
              try {
                return LanguageDomain.constructTextTemplateParam(
                  mapper,
                  entity.params[param],
                  debugDomain.targetType,
                  options
                );
              } catch (e) {
                return debugDomain.log(entity.params[param], mapper, debugDomain.checkClassified());
              }
            })
          );
        },
        { weight: 0.5 }
      );

    /* Mappings */
    this.addBasicMapping<TextEntity, TextEntity>(
      LanguageEntities.AnonymizedText,
      CoreEntities.Text,
      (entity) => {
        return makeTextEntity(entity.text);
      }
    );
    this.addBasicMapping(
      LanguageEntities.AnonymizedText,
      LanguageEntities.TextTemplate,
      (entity) => {
        return makeTextTemplate(entity, {});
      },
      1.1
    );
    this.addTrivialMapping(LanguageEntities.AnonymizedText, LanguageInterfaces.Anonymized);
    this.addEntityMapping<TextTemplate, TextEntity>(
      LanguageEntities.TextTemplate,
      LanguageInterfaces.Anonymized,
      async (entity, mapper) => {
        const text = await LanguageDomain.fillTextTemplate(
          mapper,
          entity,
          LanguageEntities.AnonymizedText,
          LanguageInterfaces.Anonymized
        );
        return text !== undefined ? makeAnonymizedText(text) : undefined;
      },
      { weight: 2 }
    );
    this.addSyncMapping<TextResource, TextEntity>(
      LanguageEntities.TextResource,
      LanguageEntities.AnonymizedText,
      (entity, mapper) => {
        const value = this.textResources.get('de', entity.id);
        if (typeof value === 'string') return makeAnonymizedText(value);
        let mod = '';
        const grammCaseEnt = mapper.tryContext<GrammaticalCaseEntity>(
          GrammaticalCaseContextProperty
        );
        if (grammCaseEnt && value[grammCaseEnt.grammaticalCase]) mod = grammCaseEnt.grammaticalCase;
        const genderEnt = mapper.tryContext<GenderEntity>(GenderContextProperty);
        if (genderEnt && value[genderEnt.gender])
          mod = mod.length ? mod + '-' + genderEnt.gender : genderEnt.gender;
        return makeAnonymizedText(value[mod]);
      },
      { context: [GrammaticalCaseContextProperty, GenderContextProperty] }
    );

    this.addTupleMapping<[PrepositionEntity, GenderEntity]>(
      [LanguageEntities.Preposition, LanguageEntities.Gender],
      LanguageEntities.AnonymizedText,
      ([prepoEnt, genderEnt]) => {
        const text = PrepositionTexts.get(prepoEnt.prepo)?.[genderEnt.gender];
        if (!text)
          Logger.warn(['Undefined preposition:', prepoEnt.prepo, 'for gender:', genderEnt.gender]);
        return makeAnonymizedText(text ?? '...');
      }
    );

    /* Joins several texts into one */
    this.addEntityMapping<TextsEntity>(
      LanguageEntities.Texts,
      LanguageEntities.TextTemplate,
      async (textsEnt, mapper) => {
        return this.combineTexts(textsEnt.texts, await extractText(mapper, textResource('and')));
      }
    );
    this.addEntityMapping<TextsEntity>(
      LanguageEntities.AlternativeTexts,
      LanguageEntities.TextTemplate,
      async (textsEnt, mapper) => {
        return this.combineTexts(textsEnt.texts, await extractText(mapper, textResource('or')));
      }
    );
    this.addBasicMapping<ConcatenationTextsEntity>(
      LanguageEntities.ConcatenationTexts,
      LanguageEntities.TextTemplate,
      (textsEnt) => {
        if (textsEnt.texts.length === 0) return makeAnonymousTextTemplate('', {});
        if (textsEnt.texts.length === 1)
          return makeAnonymousTextTemplate('<e>text1</e>', {
            text1: textsEnt.texts[0],
          });
        else if (textsEnt.texts.length > 1) {
          let ret: TextTemplate | Entity = textsEnt.texts[0];
          ret = textsEnt.texts[0];
          for (let i = 0; i < textsEnt.texts.length - 1; i++)
            if (textsEnt.separator === undefined)
              ret = makeAnonymousTextTemplate('<e>text1</e>\n<e>text2</e>', {
                text1: ret,
                text2: textsEnt.texts[i + 1],
              });
            else if (typeof textsEnt.separator === 'string')
              ret = makeAnonymousTextTemplate(`<e>text1</e>${textsEnt.separator}<e>text2</e>`, {
                text1: ret,
                text2: textsEnt.texts[i + 1],
              });
            else
              ret = makeAnonymousTextTemplate(`<e>text1</e><e>separator</e><e>text2</e>`, {
                text1: ret,
                text2: textsEnt.texts[i + 1],
                separator: textsEnt.separator,
              });

          return ret;
        }
        return undefined;
      }
    );

    /* Gender of sqior assistant */
    this.addConstMapping(
      CoreEntities.SQIORAssistant,
      LanguageEntities.Gender,
      makeGender(Gender.Male)
    );

    /** Define which entities are text like */
    this.addTrivialMapping(CoreEntities.Text, LanguageInterfaces.TextLike);
    this.addTrivialMapping(LanguageEntities.AnonymizedText, LanguageInterfaces.TextLike);

    /* Init params */
    this.textResources.addLanguageResources(BasicTextResources);
    this.clientTextResources.addLanguageResources(BasicTextResources);
  }

  /** Combines texts to a string with a custom combination word like 'and' */
  private combineTexts(texts: Entity[], combiner: string) {
    let templ = '';
    const entities: EntityRecord = {};
    for (let i = 0; i < texts.length; i++) {
      if (i)
        if (i + 1 === texts.length) templ += ' ' + combiner + ' ';
        else templ += ', ';
      templ += '<e>' + i + '</e>';
      entities[i.toString()] = texts[i];
    }
    return makeAnonymousTextTemplate(templ, entities);
  }

  /** Maps a text template parameter */
  static async constructTextTemplateParam(
    mapper: DomainInterface,
    param: Entity | undefined,
    paramType: string,
    options?: Record<string, string>
  ): Promise<string> {
    let prepo = '';
    if (options?.[PrepositionOption]) {
      const genderEnt = await mapper.tryMap(param, LanguageEntities.Gender);
      prepo =
        (genderEnt &&
          (
            await mapper.tryMap<TextEntity>(
              [makePreposition(options?.[PrepositionOption]), genderEnt],
              CoreEntities.Text
            )
          )?.text) ??
        '...';
      prepo += ' ';
    }
    /* Construct text */
    const text =
      prepo +
      (
        await mapper.map<TextEntity>(
          param,
          paramType,
          options && options['case']
            ? { grammaticalCase: makeGrammaticalCase(options['case']) }
            : undefined
        )
      )?.text;
    /* Check whether to capitalize */
    if (options?.['caps'] === 'start') return capitalizeFirst(text);
    return text;
  }
  /** Fills a text template with parameters */
  static async fillTextTemplate(
    mapper: DomainInterface,
    templ: TextTemplate,
    templType: string,
    paramType: string
  ): Promise<string | undefined> {
    return substituteTextParams(
      (await mapper.map<TextEntity>(templ.text, templType)).text,
      (param: string, options) =>
        LanguageDomain.constructTextTemplateParam(mapper, templ.params[param], paramType, options)
    );
  }

  readonly textResources = new LanguageTextResourceManager();
  readonly clientTextResources = new LanguageTextResourceManager();
}

/** Return the correct value depending on the verbosity */
export function verbositySwitch<Type = string>(
  mapper: TrackingDomainInterface,
  short: Type,
  normal: Type,
  long?: Type
): Type {
  const verbosity = mapper.tryContext<VerbosityEntity>(VerbosityContextProperty);
  if (verbosity?.verbosity === Verbosity.Short) return short;
  if (verbosity?.verbosity === Verbosity.Normal) return normal;
  if (verbosity?.verbosity === Verbosity.Verbose) return long || normal;
  return normal;
}
