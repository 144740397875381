import {
  ArraySource,
  ClockTimestamp,
  ensureArray,
  makeImmutable,
  now,
  TimerInterface,
} from '@sqior/js/data';
import { Entity, EntityHeader } from '@sqior/js/entity';
import { CoreEntities, EntityModel, Interface } from '@sqior/js/meta';
import { TimeEntities, TimeInterfaces } from './time-definitions';
import { DateData } from '@sqior/viewmodels/time';

export enum Month {
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12,
}
export type DateEntity = EntityHeader & DateData;
export const DateEntityModel: EntityModel = {
  type: TimeEntities.Date,
  props: ['day', 'month', 'year'],
  keys: ['day', 'month', 'year'],
  unclassified: true,
};
export function makeDate(
  day: number,
  month: number,
  year: number,
  type: string = TimeEntities.Date
): DateEntity {
  return { entityType: type, day: day, month: month, year: year };
}
export function makeDateFromJS(date: Date, type: string = TimeEntities.Date): DateEntity {
  return makeDate(date.getDate(), date.getMonth() + 1, date.getFullYear(), type);
}
export function makeDateFromTimestamp(
  timestamp: ClockTimestamp,
  type: string = TimeEntities.Date
): DateEntity {
  return makeDateFromJS(new Date(timestamp), type);
}
export function makeCurrentDate(timer?: TimerInterface): DateEntity {
  return makeDateFromTimestamp(timer ? timer.now : now(), TimeEntities.Date);
}
export function convertToJSDate(date: DateEntity) {
  return new Date(date.year, date.month - 1, date.day);
}

/** Interface to return date in ISO format */

export const ISODateModel: Interface = {
  type: TimeInterfaces.ISODate,
  requires: CoreEntities.Text,
};

/** sqior medical date of birth */

export const SQIORDate = makeImmutable(makeDate(21, 4, 2022)); // was a Thursday

/** Checks if first date entity is before second date (i.e. first date is older than second date)*/
export function isDateBefore(first: DateEntity, second: DateEntity) {
  if (first.year !== second.year) return first.year < second.year;
  if (first.month !== second.month) return first.month < second.month;
  return first.day < second.day;
}

export type RelativeDate = EntityHeader & { dayOffset: number };
export const RelativeDateEntityModel: EntityModel = {
  type: TimeEntities.RelativeDate,
  props: ['dayOffset'],
};
export function makeRelativeDate(dayOffset: number): RelativeDate {
  return { entityType: TimeEntities.RelativeDate, dayOffset: dayOffset };
}

/** List of dates */
export type DatesEntity = EntityHeader & { dates: Entity[] };
export const DatesModel: EntityModel = { type: TimeEntities.Dates, props: ['dates'] };
export function makeDates(dates: ArraySource<Entity>): DatesEntity {
  return { entityType: DatesModel.type, dates: ensureArray(dates) };
}

export enum Weekday {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

/** Yes, JS Week starts with Sunday */
export const Weekdays: Weekday[] = [
  Weekday.Sunday,
  Weekday.Monday,
  Weekday.Tuesday,
  Weekday.Wednesday,
  Weekday.Thursday,
  Weekday.Friday,
  Weekday.Saturday,
];

export function dateWeekday(date: DateEntity): Weekday {
  return Weekdays[convertToJSDate(date).getDay()];
}

/** Week day entity */

export type WeekdayEntity = EntityHeader & { weekday: Weekday };
export const WeekdayModel: EntityModel = {
  type: TimeEntities.Weekday,
  props: ['weekday'],
  keys: ['weekday'],
  unclassified: true,
};
export function makeWeekday(weekday: Weekday): WeekdayEntity {
  return { entityType: TimeEntities.Weekday, weekday };
}

/** Interface returning the weekday (e.g. of a date) */

export const GetWeekdayModel: Interface = {
  type: TimeInterfaces.Weekday,
  requires: TimeEntities.Weekday,
};

/** Interface checking if the specified date represents a work day */

export const WorkDayModel: Interface = { type: TimeInterfaces.WorkDay };

/** Periodic date entity */

export enum PeriodicDate {
  Weekly = 7,
  BiWeekly = 14,
}
export type PeriodicDateEntity = EntityHeader & { period: number };
export const PeriodicDateModel: EntityModel = {
  type: TimeEntities.PeriodicDate,
  props: ['period'],
  unclassified: true,
};
export function makePeriodicDate(period: number): PeriodicDateEntity {
  return { entityType: TimeEntities.PeriodicDate, period };
}
