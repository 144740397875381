import styles from './date-navigator.module.css';
import { format, isSameDay } from 'date-fns';
import { IconButton } from '@mui/material';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import React, { memo } from 'react';
import { de } from 'date-fns/locale';
import Typography, { TypographyColor } from '../typography/typography';
import { useTextResources } from '@sqior/react/uibase';
import { useDynamicState } from '@sqior/react/state';
import { ClockTimestamp } from '@sqior/js/data';
import { DisplayTimePath } from '@sqior/viewmodels/time';

declare module '@mui/material/IconButton' {
  export interface IconButtonPropsColorOverrides {
    grayed: true;
  }
}

export type DateNavigatorBaseProps = {
  day?: number;
  month?: number;
  year?: number;
};

export type DateNavigatorProps = DateNavigatorBaseProps & {
  onNext?: () => void;
  onPrev?: () => void;
  nextDisabled?: boolean;
  prevDisabled?: boolean;
};

export function DateNavigator({
  day,
  month,
  year,
  onNext,
  onPrev,
  nextDisabled,
  prevDisabled,
}: DateNavigatorProps) {
  const serverTime = useDynamicState<ClockTimestamp>(DisplayTimePath, Date.now());
  const textDic = useTextResources();

  if (!day || !month || !year) return null;
  return (
    <div className={styles['container']}>
      <div className={styles['font-bold-space']}>{getFormattedDate(day, month, year)}</div>
      <div className={styles['row']}>
        <div className={styles['date-container']}>
          <div className={styles['box-number-2']}>{day.toString().padStart(2, '0')}</div>
          <div>.</div>
          <div className={styles['box-number-2']}>{month.toString().padStart(2, '0')}</div>
          <div>.</div>
          <div className={styles['box-number-4']}>{year}</div>
        </div>
        <div>
          <IconButton disabled={prevDisabled} onClick={onPrev} color={'grayed'}>
            <NavigateBeforeRoundedIcon />
          </IconButton>
          <IconButton disabled={nextDisabled} onClick={onNext} color="grayed">
            <NavigateNextRoundedIcon />
          </IconButton>
        </div>
      </div>

      {!isDateToday({ date: { day, month, year }, serverTime }) && (
        <div className={styles['absolute']}>
          <Typography color={TypographyColor.WARNING}>
            {textDic.get('not_today_description')}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default memo(DateNavigator);

const getFormattedDate = (day?: number, month?: number, year?: number): string => {
  try {
    if (!day || !month || !year) return '';
    const newDate = new Date(year, month - 1, day);
    return format(newDate, 'EEEE', {
      locale: de,
    });
  } catch (e) {
    return '';
  }
};

interface IsDateTodayParams {
  date: {
    day: number;
    month: number;
    year: number;
  };
  serverTime: number;
}

function isDateToday({ date: { day, month, year }, serverTime }: IsDateTodayParams): boolean {
  // Create a Date object from the provided day, month, and year
  const dateToCheck = new Date(year, month - 1, day); // Month is 0-indexed in JavaScript Date

  return isSameDay(dateToCheck, serverTime);
}
