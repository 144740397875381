import { FC } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import InfoNodeCard from '../InfoNodeCard/InfoNodeCard';
import { Icon } from '@iconify/react';
import { useTheme } from '@sqior/react/theme';
import { edgeStyle } from '../config';
import { NodeInfoType } from '../types';

const separationIconName = 'ph:caret-right-thin';

export const InfoNode: FC<NodeProps<NodeInfoType>> = ({ data, id }) => {
  const { other, separationIcon, displayPosition, border, nodeIdHovered, ...rest } = data;
  const {
    theme: {
      colors: {
        primary: {
          sqiorBlue: { value: sqiorBlue },
        },
        background: {
          darkBlue: { value: darkBlue },
        },
      },
    },
  } = useTheme();

  const handle = () => {
    switch (displayPosition) {
      case 'left':
        return (
          <>
            <Handle type="source" position={Position.Right} style={edgeStyle} />
            <Handle type="target" position={Position.Right} style={edgeStyle} />
          </>
        );
      case 'right':
        return (
          <>
            <Handle type="source" position={Position.Left} style={edgeStyle} />
            <Handle type="target" position={Position.Left} style={edgeStyle} />
          </>
        );
      default:
        return (
          <>
            <Handle type="source" position={Position.Right} style={edgeStyle} />
            <Handle type="source" position={Position.Left} style={edgeStyle} />
            <Handle type="target" position={Position.Left} style={edgeStyle} />
            <Handle type="target" position={Position.Right} style={edgeStyle} />
          </>
        );
    }
  };

  const isHovered = nodeIdHovered === id;

  return (
    <div
      style={{
        border: border ? `1px solid ${sqiorBlue}` : 'none',
        padding: border ? 10 : 0,
        borderRadius: 8,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'start',
          gap: separationIcon ? 0 : 30,
          backgroundColor: darkBlue,
        }}
      >
        <InfoNodeCard {...rest} />
        {separationIcon && (
          <div style={{ position: 'relative', width: 40, height: '100%' }}>
            <Icon
              icon={separationIconName}
              fontSize={30}
              color={sqiorBlue}
              style={{ position: 'absolute', left: 0, top: '50%', transform: 'translate(0, 50%)' }}
            />
            <Icon
              icon={separationIconName}
              fontSize={30}
              color={'rgba(28,173,228,0.3)'}
              style={{ position: 'absolute', left: 5, top: '50%', transform: 'translate(0, 50%)' }}
            />
          </div>
        )}
        {other && <InfoNodeCard {...other} />}
      </div>

      {handle()}
    </div>
  );
};

export default InfoNode;
