import { Entity } from '@sqior/js/entity';
import { CoreEntities, Domain, DomainInterface, EntityRecord, Interface } from '@sqior/js/meta';
import { VisualEntities, VisualInterfaces } from './visual-definitions';
import { resourceTextTemplate, textResource } from '@sqior/plugins/language';
import { makeHTMLTemplate } from './html-template';
import { makeIcon } from './icon-entity';
import { Icons } from '@sqior/viewmodels/visual';

export const EmojiIconModel: Interface = {
  type: VisualInterfaces.EmojiIcon,
  represents: [CoreEntities.Text],
};
export const MessageIconModel: Interface = { type: VisualInterfaces.MessageIcon };
export const MessageTitleModel: Interface = {
  type: VisualInterfaces.MessageTitle,
  represents: CoreEntities.Text,
};
export const MessageBodyModel: Interface = {
  type: VisualInterfaces.MessageBody,
  represents: CoreEntities.Text,
};
export const MessageBodyModelHTML: Interface = {
  type: VisualInterfaces.MessageBodyHTML,
  represents: VisualEntities.HTML,
};
export const RichMessageModel: Interface = { type: VisualInterfaces.RichMessage };

export function addRichMessageMappings<EntityType extends Entity = Entity>(
  domain: Domain,
  from: string,
  templateParamMapper: (
    entity: EntityType,
    mapper: DomainInterface
  ) => Promise<
    | EntityRecord
    | {
        templates: {
          textResourceTemplate?: string;
          htmlTextResourceTemplate?: string;
        };
        params: EntityRecord;
      }
  >,
  titleResourceTemplateOrMapping:
    | string
    | ((entity: EntityType, mapper: DomainInterface) => Promise<Entity>),
  textResourceTemplate: string,
  htmlTextResourceTemplate?: string,
  icon?: Icons
) {
  domain.addTrivialMapping(from, VisualInterfaces.RichMessage);
  if (titleResourceTemplateOrMapping instanceof Function) {
    domain.addEntityMapping<EntityType>(
      from,
      VisualInterfaces.MessageTitle,
      titleResourceTemplateOrMapping
    );
  } else
    domain.addBasicMapping<EntityType>(from, VisualInterfaces.MessageTitle, () =>
      textResource(titleResourceTemplateOrMapping)
    );
  if (icon !== undefined)
    domain.addBasicMapping<EntityType>(from, VisualInterfaces.MessageIcon, () => makeIcon(icon));
  domain.addEntityMapping<EntityType>(
    from,
    VisualInterfaces.MessageBody,
    async (entity, mapper) => {
      const params = await templateParamMapper(entity, mapper);
      if ('templates' in params && 'params' in params) {
        const template = (params.templates.textResourceTemplate as string) ?? textResourceTemplate;
        const p = params.params as EntityRecord;
        return resourceTextTemplate(template, p);
      }
      return resourceTextTemplate(textResourceTemplate, params);
    }
  );
  if (htmlTextResourceTemplate)
    domain.addEntityMapping<EntityType>(
      from,
      VisualInterfaces.MessageBodyHTML,
      async (entity, mapper) => {
        const params = await templateParamMapper(entity, mapper);
        if ('templates' in params && 'params' in params) {
          const template =
            (params.templates.htmlTextResourceTemplate as string) ?? htmlTextResourceTemplate;
          const p = params.params as EntityRecord;
          return makeHTMLTemplate(textResource(template), p);
        }
        return makeHTMLTemplate(textResource(htmlTextResourceTemplate), params);
      }
    );
}
