import { ArraySource, ensureArray } from '@sqior/js/data';
import { Entity, EntityHeader, SubjectsEntity } from '@sqior/js/entity';
import { CoreEntities, EntityModel, Interface, PlaybackEvent } from '@sqior/js/meta';

export enum InputEntities {
  Interpreters = 'Interpreters',
  SimpleInterpreter = 'SimpleInterpreter',
  CommandInterpreter = 'CommandInterpreter',
  PersonInterpreter = 'PersonInterpreter',
  SwitchInterpreter = 'SwitchInterpreter',
  FreeTextInterpreter = 'FreeTextInterpreter',
  ConfidenceFallbackInterpreter = 'ConfidenceFallbackInterpreter',
  SelectionMatch = 'SelectionMatch',
  SelectionDisplay = 'SelectionDisplay',
  CommandFeedback = 'CommandFeedback',
  Subjects = 'InputSubjects',
  Roots = 'InputRoots',
  SelectionControlBase = 'SelectionControlBase',
  EscalationReason = 'EscalationReason', // Escalation reason code
  PlaybackEvents = 'PlaybackEvents', // Playback events entity (used for simulating user events in demo mode)
  EmitEvents = 'EmitEvents', // Events emitted from an input
}
export enum InputInterfaces {
  SelectionFeedback = 'SelectionFeedback',
  InstanceInterpreter = 'InstanceInterpreter',
  Subject = 'InputSubject',
  CommandSubject = 'CommandSubject',
  SelectionControl = 'SelectionControl',
  PlugInParameter = 'PlugInInterpreterParameter',
  SelectionHeader = 'SelectionHeader', // Optional header element to present for the selection
  TaskType = 'GetTaskType', // Determines the abstract type of a task from a concrete task instance
  EscalationReasonVerb = 'EscalationReasonVerb',
}

export const PlugInParamContextProperty = 'plugInParam';

/* Types of input sources */

export type InputRoots = Entity & { types: Entity[] };
export const InputRootsModel: EntityModel = { type: InputEntities.Roots, props: ['types'] };

/* Interface representing entities that can be mapped to a set of related interpreters */

export const InputSubjectModel: Interface = { type: InputInterfaces.Subject };

/* Entity representing a list of input subjects into a command template */

export type InputSubjects = SubjectsEntity & { check?: string };
export const InputSubjectsModel: EntityModel = {
  type: InputEntities.Subjects,
  props: ['check'],
  extends: CoreEntities.Subjects,
};
export function makeInputSubjects(subjects: Entity[], check?: string): InputSubjects {
  const res: InputSubjects = { entityType: InputEntities.Subjects, subjects: subjects };
  if (check) res.check = check;
  return res;
}

/* Instace interpreter interface */

export const InstanceInterpreterModel: Interface = {
  type: InputInterfaces.InstanceInterpreter,
  requires: [InputEntities.CommandInterpreter, InputEntities.SimpleInterpreter],
};

/* Interface to map subject provided to the subject provided to the command template */

export const CommandSubjectModel: Interface = { type: InputInterfaces.CommandSubject };

/* Interface returning an intepreter with a specified parameter plugged in */

export const PlugInParameterModel: Interface = { type: InputInterfaces.PlugInParameter };

/** Playback events entity */

export type PlaybackEventsEntity = EntityHeader & { events: PlaybackEvent[] };
export const PlaybackEventsModel: EntityModel = {
  type: InputEntities.PlaybackEvents,
  props: ['events'],
};
export function makePlaybackEvents(events: ArraySource<PlaybackEvent>): PlaybackEventsEntity {
  return { entityType: PlaybackEventsModel.type, events: ensureArray(events) };
}

/** Task type interface to determine the abstract task concept from a concrete instance */
export const GetTaskTypeModel: Interface = { type: InputInterfaces.TaskType };
export type TaskTypeEntity = EntityHeader & { type: string };

/** Events emitted from an input */

export type EmitEvents = EntityHeader & { events: Entity[] };
export const EmitEventsModel: EntityModel = {
  type: InputEntities.EmitEvents,
  props: ['events'],
};
export function makeEmitEvents(events: ArraySource<Entity>): EmitEvents {
  return { entityType: EmitEventsModel.type, events: ensureArray(events) };
}
