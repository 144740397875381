import { createLicenseMap, fetchLicenses, LicenseMap, Package } from '@sqior/js/licenses';
import { ClosablePage } from '@sqior/react/uibase';
import { useEffect, useState } from 'react';
import styles from './license-info.module.css';
import { extractDomainWithPort } from '@sqior/js/url';

export interface LicenseInfoProps {
  onClose: () => void;
}

export function LicenseInfo(props: LicenseInfoProps) {
  const [licenseMap, setLicenseMap] = useState<LicenseMap>({});
  useEffect(() => {
    async function fetchInfos() {
      const licenses = await fetchLicenses(extractDomainWithPort(document.URL));
      setLicenseMap(createLicenseMap(licenses));
    }

    fetchInfos();
  }, []);

  function getLicenseProlog(license: string) {
    return (
      <>
        The following component(s) are licensed under the <em>{license}</em> reproduced below:
      </>
    );
  }

  function LicenseDetails(licenseName: string, licenseText: string, applications: Package[]) {
    return (
      <div className={styles['license-details']}>
        <div className={styles['license-name']}>{licenseName}</div>
        <div className={styles['license-prolog']}>{getLicenseProlog(licenseName)}</div>
        <div className={styles['license-applications']}>
          <ul>
            {applications.map((v) => {
              if (v.publisher !== undefined)
                return <li>{`${v.name} (${v.version} by ${v.publisher})`}</li>;
              else if (v.email !== undefined)
                return <li>{`${v.name} (${v.version} by ${v.email})`}</li>;
              else return <li>{`${v.name} (${v.version})`}</li>;
            })}
          </ul>
        </div>
        <div className={styles['license-text']}>{licenseText}</div>
      </div>
    );
  }

  return (
    <ClosablePage onClose={props.onClose}>
      <div className={styles['license-container']}>
        <div className={styles['license-heading']}>License Attribution Notices</div>
        <div className={styles['license-intro']}>
          The following sets forth attribution notices for third party software that may be
          contained in portion of the <em>sqior medical</em> product.
        </div>
        {Object.entries(licenseMap)
          .sort((a, b) => {
            return a[0].localeCompare(b[0]);
          })
          .map((item) => {
            return LicenseDetails(item[0], item[1].licenseText, item[1].packages);
          })}
      </div>
    </ClosablePage>
  );
}

export default LicenseInfo;
