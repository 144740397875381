import styles from './procedure-wrapper.module.css';
import { motion } from 'framer-motion';
import React, { memo, useContext, useMemo, useRef, useState } from 'react';
import {
  ORWorkflowOverviewDashboardMarkerGroup,
  ORWorkflowOverviewDashboardMarkerType,
  ORWorkflowOverviewDashboardORItemData,
  ORWorkflowOverviewDashboardProcedureData,
  ORWorkflowOverviewProcedureType,
  ORWorkflowOverviewQuotaType,
} from '@sqior/viewmodels/orworkflow';
import { TimePositionCalculator } from '../orworkflow-overview-dashboard/TimePositionCalculator';
import { ComponentFactory } from '@sqior/react/factory';
import { MotionLayout } from '../orworkflow-overview-dashboard/orworkflow-overview-dashboard';
import { ZIndex } from '@sqior/react/utils';
import { addSeconds, ClockTimestamp } from '@sqior/js/data';
import { useCustomTimer, useUIGlobalState } from '@sqior/react/state';
import {
  ActionsContextMenu,
  ContextOption,
  handleOnContextMenu,
  SContextAnchorEl,
  SqiorMarker,
} from '@sqior/react/uibase';
import { BlockedWrapper } from '../blocked-wrapper/blocked-wrapper';
import BlockFormDialog from '../block-form-dialog/block-form-dialog';
import { Icons } from '@sqior/viewmodels/visual';
import CardOverlayState from '../card-overlay-state/card-overlay-state';
import { useSearchMatch } from '@sqior/react/hooks';
import { SEARCH_OPACITY } from '@sqior/react/constants';
import useUpdatePaddingTop from './useUpdatePaddingTop';
import useSplitTopHeight from './useSplitTopHeight';
import {
  BorderType,
  getActiveColor,
  getBorderBottomColor,
  getDefaultBorderColor,
  getInactiveColor,
  LINE_WIDTH,
} from './procedure-wrapper.utils';
import { OperationSpec } from '@sqior/js/operation';
import { OperationContext } from '@sqior/react/operation';

export interface ProcedureWrapperProps {
  item: ORWorkflowOverviewDashboardORItemData;
  timeCalcStart: ClockTimestamp;
  timeCalcEnd: ClockTimestamp;
  cardMinWidth: number;
  contentHeight: number;

  autoScroll?: boolean;
  showChallenges?: boolean;
  motionLayout?: MotionLayout;
  zoomLevel?: number;
  nextExtension?: number;
  stateOverlay?: boolean;
  noTime?: boolean;
}

export function ProcedureWrapper({
  item,
  timeCalcStart,
  timeCalcEnd,
  autoScroll,
  showChallenges,
  motionLayout,
  contentHeight,
  zoomLevel,
  nextExtension,
  cardMinWidth,
  stateOverlay,
  noTime = false,
}: ProcedureWrapperProps) {
  const {
    UIGlobalState: { searchQuery },
  } = useUIGlobalState();

  const dispatcher = useContext(OperationContext);
  const customTimer = useCustomTimer(addSeconds(10));

  const currentTime = noTime ? 0 : customTimer;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const FactoryComponent = useContext(ComponentFactory);
  const timeCalc = new TimePositionCalculator(timeCalcStart, timeCalcEnd);
  const [tabAnimation, setTabAnimation] = useState<boolean>(true);

  const [anchorElContextMenu, setAnchorElContextMenu] = useState<SContextAnchorEl | null>(null);
  const [openBlockDialog, setOpenBlockDialog] = useState(false);

  const procedureData = item.component as ORWorkflowOverviewDashboardProcedureData;
  const procedureState = procedureData.state;

  const onePixelInTime = (timeCalcEnd - timeCalcStart) / contentHeight;
  const extension = item.extension;
  const endExtension = item.endExtension;

  const selected = useSearchMatch({ searchKeys: procedureData.searchKeys, searchQuery });

  useUpdatePaddingTop({
    wrapperRef,
    entityType: procedureData.entityType,
    itemStart: item.start,
    timeCalc,
    contentHeight,
  });

  const { splitHeightTop, extensionWithTolerance } = useSplitTopHeight({
    item,
    onePixelInTime,
    timeCalc,
    nextExtension,
    endExtension,
  });

  const getMotionPosition = () => ({
    top: timeCalc.convertTimestamp(item.start),
    bottom: timeCalc.convertTimestampBottom(item.end),
    // zIndex: procedureData.entityType === ORWorkflowOverviewQuotaType ? -1 : 15,
    right: procedureData.entityType !== ORWorkflowOverviewProcedureType ? 0 : undefined,
  });

  const splitHeightBottom = 100 - splitHeightTop;

  const adaptTimeBorder = (time: number) => {
    if (splitHeightTop === 100) return time + onePixelInTime;
    return time - onePixelInTime * 2;
  };

  const endAnesthesia = item.markers.find((marker) => {
    return (
      marker.group === ORWorkflowOverviewDashboardMarkerGroup.Anesthesia &&
      marker.type === ORWorkflowOverviewDashboardMarkerType.End
    );
  });

  const getBottomTimeAnesthesia = () => {
    if (endAnesthesia) {
      if (endAnesthesia.timestamp < item.start) return item.start + 2 * onePixelInTime;

      return endAnesthesia.timestamp + 8 * onePixelInTime;
    }
    return item.start + 2 * onePixelInTime;
  };

  const getAnestesiaStartEndMarkers = () => {
    return item.markers
      .filter((marker) => {
        return (
          marker.group === ORWorkflowOverviewDashboardMarkerGroup.Anesthesia &&
          (marker.type === ORWorkflowOverviewDashboardMarkerType.Start ||
            marker.type === ORWorkflowOverviewDashboardMarkerType.End)
        );
      })
      .filter((marker) => {
        if (!extensionWithTolerance) return true;
        return marker.timestamp <= extensionWithTolerance;
      });
  };

  const onContextMenu = (event: React.MouseEvent) => {
    handleOnContextMenu(event, setAnchorElContextMenu);
  };

  const getWhileTab = () => {
    if (ORWorkflowOverviewProcedureType !== procedureData.entityType) return undefined;
    if (tabAnimation) return { scale: 0.9 };
    return { scale: 1 };
  };

  const blockSaalAction: ContextOption = {
    id: 'blockSaalAction',
    icon: Icons.Lock,
    label: `Patient sperren`,
    action: {
      type: 'operation',
      path: 'open',
      data: {
        entity: 'or',
      },
    },
    callback: () => {
      setOpenBlockDialog((prev) => !prev);
    },
  };

  const onBlock = (reason: string) => {
    // TODO call the block operation for Patient
    console.log('Block reason:', reason);
  };

  const getOpacity = () => {
    if (selected) return 1;
    if (searchQuery?.length > 0) return SEARCH_OPACITY;
    return 1;
  };

  const opacity = getOpacity();

  const hideBorderBottomColor = useMemo(() => {
    return getBorderBottomColor({
      selected,
      splitHeightTop,
      currentTime,
      item,
      onePixelInTime,
      procedureState,
      procedureData,
      extensionWithTolerance,
    });
  }, [
    selected,
    splitHeightTop,
    currentTime,
    item,
    onePixelInTime,
    procedureState,
    procedureData,
    extensionWithTolerance,
  ]);

  const handleNavigate = () => {
    const select = procedureData?.select;
    if (!select) return;
    dispatcher.start(select);
  };

  return (
    <>
      <BlockFormDialog open={openBlockDialog} setOpen={setOpenBlockDialog} onSubmit={onBlock} />
      <ActionsContextMenu
        anchorEl={anchorElContextMenu}
        setAnchorEl={setAnchorElContextMenu}
        options={item.actions}
      />

      {extension && (
        <div
          className={styles['extension-border']}
          style={{
            top: timeCalc.convertTimestamp(extension),
            bottom: timeCalc.convertTimestampBottom(getBottomTimeAnesthesia()),
          }}
        >
          {getAnestesiaStartEndMarkers().map((marker) => (
            <SqiorMarker
              key={marker.label}
              currentTime={currentTime}
              data={marker}
              start={extension}
              end={getBottomTimeAnesthesia()}
              startMarginRight={-2}
              endMarginRight={2}
              stopActiveColor={getBottomTimeAnesthesia()}
              opacity={opacity}
            />
          ))}
          <SplitBorder
            extension="start"
            noBorder={item.start === extension}
            body
            activeUtilTime={item.start}
            start={extension}
            end={getBottomTimeAnesthesia()}
            currentTime={currentTime}
            defaultColor={getInactiveColor(BorderType.Extension)}
            activeColor={getActiveColor(BorderType.Extension)}
            inactiveColor={getInactiveColor(BorderType.Extension)}
            width={LINE_WIDTH}
            opacity={opacity}
          />
        </div>
      )}

      {endExtension && (
        <div
          className={styles['extension-border']}
          style={{
            top: timeCalc.convertTimestamp(item.end),
            bottom: timeCalc.convertTimestampBottom(endExtension),
            right: splitHeightTop !== 100 ? 6 : undefined,
          }}
        >
          <SplitBorder
            extension="end"
            noBorder={item.start === extension}
            body
            start={item.end}
            end={endExtension}
            currentTime={currentTime}
            defaultColor={getInactiveColor(BorderType.Extension)}
            activeColor={getActiveColor(BorderType.Extension)}
            inactiveColor={getInactiveColor(BorderType.Extension)}
            width={LINE_WIDTH}
            activeUtilTime={endExtension}
            opacity={opacity}
          />
        </div>
      )}

      <motion.div
        ref={wrapperRef}
        whileTap={getWhileTab()}
        className={styles['or-item']}
        style={getMotionPosition()}
        onContextMenu={onContextMenu}
        onClick={handleNavigate}
      >
        {item.blocked && (
          <BlockedWrapper
            style={{
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              gap: 5,
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 14,
              right: -6,
              background:
                'repeating-linear-gradient(-45deg,rgba(18, 23, 46, 0.6),rgba(18, 23, 46, 0.6) 10px,rgba(48, 56, 89, 0.6) 10px,rgba(48, 56, 89, 0.6) 20px)',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
                backdropFilter: 'blur(10px)',
                width: '300%',
                textAlign: 'center',
                padding: 5,
              }}
            >
              <div>Sperrgrund:</div>
              <div>{item.blockingReason}</div>
            </div>
          </BlockedWrapper>
        )}
        <FactoryComponent
          data={item.component}
          autoScroll={autoScroll}
          showChallenges={showChallenges}
          motionLayout={motionLayout}
          setTabAnimation={setTabAnimation}
          cardMinWidth={cardMinWidth}
          doppleBorder={splitHeightTop !== 100}
          stateOverlay={stateOverlay}
          selected={selected}
        />

        {procedureData.entityType === ORWorkflowOverviewProcedureType && (
          <div className={styles['cut-container']}>
            <div className={styles['n-container']}>
              {item.markers
                .filter((marker) => {
                  return (
                    marker.timestamp >= item.start &&
                    splitHeightTop <
                      timeCalc.convertMarkerTimestampToNumber({
                        markerTimeStamp: marker.timestamp,
                        itemStart: item.start,
                        itemEnd: item.end,
                      })
                  );
                })
                .map((marker) => (
                  <SqiorMarker
                    key={marker.label}
                    currentTime={currentTime}
                    data={marker}
                    start={item.start}
                    end={item.end}
                    endMarginRight={4}
                    stopActiveColor={item.end}
                    opacity={opacity}
                  />
                ))}

              <div
                className={styles['fillerNTop']}
                style={{
                  height: `${splitHeightTop}%`,
                }}
              />
              <div
                className={styles['fillerNBottom']}
                style={{
                  height: `${splitHeightBottom}%`,
                }}
              >
                <SplitBorder
                  start={extensionWithTolerance}
                  end={item.end}
                  currentTime={currentTime}
                  activeUtilTime={item.end}
                  defaultColor={getInactiveColor(BorderType.Card)}
                  activeColor={getActiveColor(BorderType.Default)}
                  inactiveColor={getInactiveColor(BorderType.Default)}
                  width={LINE_WIDTH}
                  opacity={opacity}
                />
              </div>
            </div>
            <div className={styles['s-container']}>
              {item.markers
                .filter((marker) => {
                  return (
                    marker.timestamp >= item.start &&
                    marker.group !== ORWorkflowOverviewDashboardMarkerGroup.Anesthesia &&
                    splitHeightTop >=
                      timeCalc.convertMarkerTimestampToNumber({
                        markerTimeStamp: marker.timestamp,
                        itemStart: item.start,
                        itemEnd: item.end,
                      })
                  );
                })
                .map((marker) => (
                  <SqiorMarker
                    currentTime={currentTime}
                    key={marker.label}
                    data={marker}
                    start={item.start}
                    end={item.end}
                    stopActiveColor={item.end + onePixelInTime}
                    startMarginRight={-4}
                    opacity={opacity}
                  />
                ))}

              <div
                className={styles['fillerSTop']}
                style={{
                  position: 'relative',
                  borderTopRightRadius: 0,
                  height: `${splitHeightTop}%`,
                }}
              >
                <CardOverlayState
                  visibility={stateOverlay}
                  noBorderRadius
                  timeCalc={timeCalc}
                  currentTime={currentTime}
                  itemEnd={extensionWithTolerance}
                  itemStart={item.start}
                  stage={(item.component as ORWorkflowOverviewDashboardProcedureData).stage}
                  stayActiveUtil={item.end}
                />
                <BackgroundLayer zIndex={-1} />
                <SplitBorder
                  start={item.start}
                  end={adaptTimeBorder(extensionWithTolerance)}
                  currentTime={currentTime}
                  activeUtilTime={item.end}
                  defaultColor={getInactiveColor(BorderType.Card)}
                  activeColor={getActiveColor(BorderType.Default)}
                  inactiveColor={getInactiveColor(BorderType.Default)}
                  width={LINE_WIDTH}
                  opacity={opacity}
                />

                <div
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    height: splitHeightTop === 100 ? 2 : 4,
                    backgroundColor: hideBorderBottomColor,
                    opacity,
                  }}
                />
                <motion.div
                  initial={{
                    opacity,
                    backgroundColor: selected
                      ? '#1CADE4'
                      : getDefaultBorderColor(procedureState, procedureData.border),
                  }}
                  animate={{
                    opacity,
                    backgroundColor: selected
                      ? '#1CADE4'
                      : getDefaultBorderColor(procedureState, procedureData.border),
                  }}
                  style={{
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    height: 2,
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {/*{procedureData.entityType === ORWorkflowOverviewProcedureType && <BackgroundLayer />}*/}

        {procedureData.entityType === ORWorkflowOverviewProcedureType && (
          <CardOverlayState
            visibility={stateOverlay}
            timeCalc={timeCalc}
            currentTime={currentTime}
            itemEnd={item.end}
            itemStart={item.start}
            stage={(item.component as ORWorkflowOverviewDashboardProcedureData).stage}
          />
        )}
      </motion.div>
    </>
  );
}

export default memo(ProcedureWrapper);

// Background Layer
const BackgroundLayer: React.FC<{ zIndex: number }> = ({ zIndex }) => (
  <div
    className={styles['or-item-background']}
    style={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
      // zIndex,
    }}
  />
);

const SplitBorder: React.FC<{
  start: number;
  end: number;
  currentTime: number; // current time
  defaultColor: string; // color that would be used in normal dashboard
  activeColor: string; // color that would be used if the card is active
  inactiveColor: string; // color that would be used if the card is inactive
  activeUtilTime: number; // until witch time has the line to be active
  width: number;
  body?: boolean;
  noBorder?: boolean;
  extension?: 'end' | 'start';
  opacity?: number;
}> = ({
  start,
  end,
  currentTime,
  defaultColor,
  activeColor,
  inactiveColor,
  activeUtilTime,
  width,
  body,
  noBorder,
  extension,
  opacity,
}) => {
  const getTopBorderRadius = () => {
    if (extension === 'end') return 0;
    if (extension === 'start') return width;
    if (noBorder) return 0;
    if (body) return width;
    return undefined;
  };

  const getBottomBorderRadius = () => {
    if (extension === 'start') return 0;
    if (extension === 'end') return width;
    if (noBorder) return 0;
    if (body) return width;
    return undefined;
  };

  if (currentTime < start || currentTime > end) {
    const divColor =
      currentTime <= activeUtilTime && currentTime >= start ? activeColor : inactiveColor;

    return (
      <div
        style={{
          height: '100%',
          borderRight: body ? 'none' : `${width}px solid ${divColor}`,
          zIndex: 21,
          backgroundColor: body ? divColor : undefined,
          width: body ? width : undefined,

          borderTopRightRadius: getTopBorderRadius(),
          borderTopLeftRadius: getTopBorderRadius(),

          borderBottomRightRadius: getBottomBorderRadius(),
          borderBottomLeftRadius: getBottomBorderRadius(),
          opacity,
        }}
      />
    );
  }

  const topPercentage = ((currentTime - start) / (end - start)) * 100;
  const bottomPercentage = 100 - topPercentage;

  return (
    <>
      <div
        style={{
          height: `${topPercentage}%`,
          borderRight: body ? 'none' : `${width}px solid ${activeColor}`,
          zIndex: 21,
          // width: body ? width : undefined,
          backgroundColor: body ? activeColor : undefined,
          // backgroundColor: activeColor,
          width: body ? width : undefined,
          borderTopRightRadius: getTopBorderRadius(),
          borderTopLeftRadius: getTopBorderRadius(),
          borderBottomRightRadius: getBottomBorderRadius(),
          borderBottomLeftRadius: getBottomBorderRadius(),
          opacity,
        }}
      />
      <div
        style={{
          height: `${bottomPercentage}%`,
          borderRight: body ? 'none' : `${width}px solid ${inactiveColor}`,
          zIndex: 21,
          width: body ? width : undefined,
          backgroundColor: body ? inactiveColor : undefined,
          opacity,
        }}
      />
    </>
  );
};
