import { FC } from 'react';
import { PlanStabilityVM } from '@sqior/viewmodels/orworkflow-analytics-dashboard';
import styles from './plan-stability-page.module.css';
import MixBarChart from '../mix-bar-chart/mix-bar-chart';
import { SIcon } from '@sqior/react/uibase';
import { Chip, NumberFormatter, Typography } from '@sqior/react/sqior-ui';
import PlanStabilityFlowChart from '../plan-stability-flow-chart/plan-stability-flow-chart';
import { ReactFlowProvider } from 'reactflow';

type PlanStabilityPageProps = PlanStabilityVM;

export const PlanStabilityPage: FC<PlanStabilityPageProps> = ({ mainGraph, summary, details }) => {
  return (
    <div className={styles['container']}>
      {mainGraph && (
        <div className={styles['row']}>
          <div className={styles['main-card']}>
            <NumberFormatter {...mainGraph.value} fontSize={50} />
            <Typography htmlChildren={mainGraph.description} />
          </div>
          <MixBarChart {...mainGraph.chart} />
        </div>
      )}
      {summary && (
        <div className={styles['summary-container']}>
          <SIcon color="secondary" icon={summary.icon} size={40} />
          <div className={styles['summary-value-chip']}>
            <NumberFormatter {...summary.procedures} fontSize={30} />
            {summary.trend && (
              <Chip valueWithUnit={summary.trend.value} tooltip={summary.trend.tooltip} />
            )}
          </div>
          <div className={styles['summary-description']}>
            <Typography htmlChildren={summary.description} />
          </div>
        </div>
      )}

      <div className={styles['explain']}>
        <ReactFlowProvider>
          <PlanStabilityFlowChart data={details} />
        </ReactFlowProvider>
      </div>
    </div>
  );
};

export default PlanStabilityPage;
