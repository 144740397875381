import { EntityModel, Interface, TextEntity } from '@sqior/js/meta';
import { VisualEntities, VisualInterfaces } from './visual-definitions';
import { Entity, EntityHeader } from '@sqior/js/entity';

/** Entity containing HTML text */
export const HTMLEntityModel: EntityModel = { type: VisualEntities.HTML, props: ['text'] };
export function makeHTML(html: string): TextEntity {
  return { entityType: VisualEntities.HTML, text: html };
}

/** Entity containing HTML text without classified data */
export const AnonymousHTMLModel: EntityModel = {
  type: VisualEntities.AnonymousHTML,
  props: ['text'],
  unclassified: true,
};
export function makeAnonymousHTML(html: string): TextEntity {
  return { entityType: AnonymousHTMLModel.type, text: html };
}

/** Interface anonymizing HTML */
export const AnonymizedHTMLModel: Interface = {
  type: VisualInterfaces.AnonymizedHTML,
  requires: VisualEntities.AnonymousHTML,
};

/** Wrapper removing the boldness of the start of the HTML */
export type RemoveBoldStartEntity = EntityHeader & { html: Entity };
export const RemoveBoldStartModel: EntityModel = {
  type: VisualEntities.RemoveBoldStart,
  props: ['html'],
};
export function RemoveBoldStart(html: Entity): RemoveBoldStartEntity {
  return { entityType: RemoveBoldStartModel.type, html };
}
