import { AuthConfig } from '@sqior/js/authbase';
import { Emitter } from '@sqior/js/event';
import { AuthConfirmTriggerType, AuthContext } from '@sqior/react/uiauth';
import { ReactNode } from 'react';
import { OAuthProviderBase } from '../oauth-provider-base';

/* eslint-disable-next-line */
export interface OAuthWrapperProps {
  authProvider?: OAuthProviderBase;
  scopes?: string[];
  children: ReactNode;
  authConfig?: AuthConfig;
  initialized?: boolean;
  error?: string;
}

export function OAuthWrapper({ authProvider, initialized, error, children }: OAuthWrapperProps) {
  if (authProvider) {
    return (
      <AuthContext.Provider
        value={{
          provider: authProvider,
          confirmIdentity: new Emitter<AuthConfirmTriggerType>(),
        }}
      >
        {initialized && children}
        {error && error}
      </AuthContext.Provider>
    );
  }
  return <div></div>;
}

export default OAuthWrapper;
