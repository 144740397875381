import styles from './card-layout.module.css';
import { useTheme } from '@sqior/react/theme';

export interface CardLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  noPadding?: boolean;
  noStyle?: boolean;
  noGap?: boolean;
  onClick?: () => void;
  gradient?: boolean;
  noBorder?: boolean;
  withPadding?: boolean;
}

export function CardLayout({
  children,
  noStyle,
  noPadding,
  noGap,
  onClick,
  gradient,
  style,
  noBorder,
  withPadding = true,
  ...rest
}: CardLayoutProps) {
  const {
    theme: {
      colors: {
        background: {
          borderGrey: { value: borderColor },
        },
      },
      shape: {
        borderRadius: { medium },
      },
    },
  } = useTheme();

  const getBackground = () => {
    if (noStyle) return 'transparent';
    if (gradient)
      return 'radial-gradient(148.63% 150.87% at 82.18% 187.45%, rgba(28, 173, 228, 0.30) 0%, rgba(28, 173, 228, 0.00) 100%), #090F23';
    return undefined;
  };

  const getBorder = () => {
    if (noBorder) return 'none';
    if (noStyle) return 'none';
    return `1px solid ${borderColor}`;
  };

  return (
    <div
      onClick={onClick}
      className={styles['container']}
      style={{
        border: getBorder(),
        background: getBackground(),
        gap: noGap ? '0' : undefined,
        cursor: onClick ? 'pointer' : undefined,
        borderRadius: medium,
        padding: withPadding ? 16 : 0,
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
}

export default CardLayout;
