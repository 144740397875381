import { Entity, EntityHeader } from '@sqior/js/entity';
import { ListData, ListSelectionData } from '@sqior/viewmodels/input';
import { MetricCardStats, PieCardData } from './types';

export const OccupancyAnalyticsDashboard = 'OccupancyAnalyticsDashboard';

export type OccupancyAnalyticsDashboardVM = EntityHeader & OccupancyAnalyticsData;

export type OccupancyAnalyticsPerformanceVM = {
  [K in OccupancyMetricCardTypes]: MetricCardStats;
} & {
  [K in OccupancyPieCardTypes]: PieCardData;
};

export type OccupancyAnalyticsData = {
  wards?: ListSelectionData<ListData[]>;
  stats?: OccupancyAnalyticsPerformanceVM;
  cards: Entity[];
};

export type WardStatDataBase = Record<
  OccupancyMetricCardTypes | OccupancyPieCardTypes,
  MetricCardStats | PieCardData
>;

export enum OccupancyMetricCardTypes {
  Spi = 'spi',
  OccupancyRate = 'occupancyRate',
  StayDuration = 'stayDuration',
  SPIBelowTwentyFive = 'spiBelowTwentyFive',
}

export enum OccupancyPieCardTypes {
  PatientsRequestSource = 'patientsRequestSource',
}

////////////////////////////////////////
// NEW

export type OccupancyAnalyticsOverview = {
  cards: Entity[];
};
