import { NodeId, NodeInfoType, NodeTypes, ReturnNodeEdges } from './types';
import { Edge, Node } from 'reactflow';
import { DetailedInfo } from '@sqior/viewmodels/analytics-dashboard';
import { isEmpty } from 'lodash';
import { WITH_BORDER } from './config';

const style: Edge['style'] = {
  stroke: '#1cade4',
  strokeWidth: 3,
  strokeDasharray: '0,8',
  strokeLinecap: 'round',
};

const getStyle = (animated?: boolean): Edge['style'] => {
  const baseStyle: Edge['style'] = {
    stroke: '#1cade4',
    strokeLinecap: 'round',
  };

  if (!animated) {
    return { ...baseStyle, strokeDasharray: '0,8', strokeWidth: 3 };
  }

  return { ...baseStyle, strokeWidth: 1, strokeDasharray: undefined };
};

export const makePointerId = (target: NodeId) => {
  return `pointerId:${target}`;
};

export const makeEdgeId = (target: NodeId) => {
  return `${makePointerId(target)}-${target}`;
};

export const extractNodeId = (id: string): NodeId | null => {
  if (id.startsWith('pointerId:')) {
    if (id.includes('-')) {
      const parts = id.split('-');
      if (parts.length > 1) {
        return parts[1] as NodeId;
      }
    }
    return id.substring('pointerId:'.length) as NodeId;
  }

  return id as NodeId;
};

export const yWithBorder = (value: number, border?: boolean) => (border ? value - 11 : value);

export const createPointerNode = (
  nodeId: NodeId,
  position: { x: number; y: number },
  displayPosition?: 'left' | 'right',
  nodeIdHovered?: NodeId | null
): Node<NodeInfoType, NodeTypes> => {
  return {
    id: makePointerId(nodeId),
    position,
    data: { displayPosition, nodeIdHovered },
    type: NodeTypes.Pointer,
  };
};

export const createInfoNode = (
  nodeId: NodeId,
  position: { x: number; y: number },
  data: DetailedInfo
): Node<DetailedInfo, NodeTypes> => {
  return {
    id: nodeId,
    position,
    data,
    type: NodeTypes.Info,
  };
};

interface CreateInfoNodeWithPointerProps {
  nodeId: NodeId;
  pointerCoordinates: { x: number; y: number };
  nodeCoordinates: { x: number; y: number };
  data: NodeInfoType;
}

export const createInfoNodeWithPointer = ({
  nodeId,
  pointerCoordinates,
  nodeCoordinates,
  data,
}: CreateInfoNodeWithPointerProps): Node<NodeInfoType, NodeTypes>[] => {
  return [
    createPointerNode(
      nodeId,
      pointerCoordinates,
      data.displayPosition === 'right' ? 'left' : 'right',
      data.nodeIdHovered
    ),
    createInfoNode(nodeId, nodeCoordinates, data),
  ];
};

export const createConnectionEdge = (nodeId: NodeId, animated = false): Edge => {
  const nodePosition = 'right';
  const target = !nodePosition || nodePosition === 'right' ? nodeId : makePointerId(nodeId);
  const source = !nodePosition || nodePosition === 'right' ? makePointerId(nodeId) : nodeId;
  return {
    id: makeEdgeId(nodeId),
    target,
    source,
    // type: 'smoothstep',
    animated,
    zIndex: 9999,
    style: getStyle(animated),
  };
};

export const addBackgroundNode = () => {
  return {
    id: NodeId.Background,
    position: { x: -117.5, y: 250 },
    data: {},
    type: NodeTypes.Background,
  };
};

export const addProcedureNode = () => {
  return {
    id: NodeId.Procedure,
    position: { x: -105, y: 250 },
    data: {},
    type: NodeTypes.Procedure,
  };
};

export const createInitialNodesAndEdges = (): ReturnNodeEdges => {
  const nodes: Node<DetailedInfo, NodeTypes>[] = [addBackgroundNode(), addProcedureNode()];
  const edges: Edge[] = [];
  return { nodes, edges };
};

export const createPositionChanges = (
  data?: DetailedInfo,
  nodeIdHovered?: NodeId | null
): ReturnNodeEdges => {
  if (!data || isEmpty(data)) return { nodes: [], edges: [] };
  const border = data.border ?? WITH_BORDER;
  const nodeId = NodeId.ChangeFirstPosition;
  const isHovered = nodeIdHovered === nodeId;
  const nodes = createInfoNodeWithPointer({
    nodeId,
    pointerCoordinates: { x: -140, y: 274.5 },
    nodeCoordinates: { x: 150, y: yWithBorder(245, border) },
    data: {
      nodeIdHovered,
      border,
      displayPosition: 'right',
      separationIcon: true,
      ...data,
    },
  });

  const edges = [createConnectionEdge(nodeId, isHovered)];
  return { nodes, edges };
};

export const createPatientStability = (
  data?: DetailedInfo,
  nodeIdHovered?: NodeId | null
): ReturnNodeEdges => {
  if (!data || isEmpty(data)) return { nodes: [], edges: [] };
  const border = data.border ?? WITH_BORDER;
  const nodeId = NodeId.PatientStability;
  const isHovered = nodeIdHovered === nodeId;
  const nodes = createInfoNodeWithPointer({
    nodeId,
    pointerCoordinates: { x: -109, y: 375 },
    nodeCoordinates: { x: -400, y: yWithBorder(335, border) },
    data: {
      nodeIdHovered,
      border,
      displayPosition: 'left',
      ...data,
    },
  });

  const edges = [createConnectionEdge(nodeId, isHovered)];
  return { nodes, edges };
};

export const createInduction = (
  data?: DetailedInfo,
  nodeIdHovered?: NodeId | null
): ReturnNodeEdges => {
  if (!data || isEmpty(data)) return { nodes: [], edges: [] };
  const border = data.border ?? WITH_BORDER;
  const nodeId = NodeId.EinleitungLagerung;
  const isHovered = nodeIdHovered === nodeId;
  const nodes = createInfoNodeWithPointer({
    nodeId,
    pointerCoordinates: { x: -109, y: yWithBorder(415, border) },
    nodeCoordinates: { x: data.other ? -570 : -425, y: 480 },
    data: {
      nodeIdHovered,
      border,
      titleColor: 'induction',
      displayPosition: 'left',
      ...data,
    },
  });

  const edges = [createConnectionEdge(nodeId, isHovered)];
  return { nodes, edges };
};

export const createTeam = (data?: DetailedInfo, nodeIdHovered?: NodeId | null): ReturnNodeEdges => {
  if (!data || isEmpty(data)) return { nodes: [], edges: [] };
  const border = data.border ?? WITH_BORDER;
  const nodeId = NodeId.Team;
  const isHovered = nodeIdHovered === nodeId;
  const nodes = createInfoNodeWithPointer({
    nodeId,
    pointerCoordinates: { x: 90, y: yWithBorder(475, border) },
    nodeCoordinates: { x: 175, y: 445.5 },
    data: {
      nodeIdHovered,
      border,
      displayPosition: 'right',
      ...data,
    },
  });

  const edges = [createConnectionEdge(nodeId, isHovered)];
  return { nodes, edges };
};

export const createClosingSuture = (
  data?: DetailedInfo,
  nodeIdHovered?: NodeId | null
): ReturnNodeEdges => {
  if (!data || isEmpty(data)) return { nodes: [], edges: [] };
  const border = data.border ?? WITH_BORDER;
  const nodeId = NodeId.OnTimeFinish;
  const isHovered = nodeIdHovered === nodeId;
  const nodes = createInfoNodeWithPointer({
    nodeId,
    pointerCoordinates: { x: -115, y: yWithBorder(712, border) },
    nodeCoordinates: { x: 50, y: 688 },
    data: {
      nodeIdHovered,
      border,
      displayPosition: 'right',
      ...data,
    },
  });

  const edges = [createConnectionEdge(nodeId, isHovered)];
  return { nodes, edges };
};

export const createClosingPotential = (
  data?: DetailedInfo,
  nodeIdHovered?: NodeId | null
): ReturnNodeEdges => {
  if (!data || isEmpty(data)) return { nodes: [], edges: [] };
  const border = data.border ?? WITH_BORDER;
  const nodeId = NodeId.OnTimeFinishPotential;
  const isHovered = nodeIdHovered === nodeId;
  const nodes = createInfoNodeWithPointer({
    nodeId,
    pointerCoordinates: { x: 55, y: yWithBorder(737, border) },
    nodeCoordinates: { x: -250, y: 795 },
    data: {
      nodeIdHovered,
      border,
      displayPosition: 'left',
      ...data,
    },
  });

  const edges = [createConnectionEdge(nodeId, isHovered)];
  return { nodes, edges };
};

export const createClosingOvertime = (
  data?: DetailedInfo,
  nodeIdHovered?: NodeId | null
): ReturnNodeEdges => {
  if (!data || isEmpty(data)) return { nodes: [], edges: [] };
  const border = data.border ?? WITH_BORDER;
  const nodeId = NodeId.OnTimeFinishDayEnd;
  const isHovered = nodeIdHovered === nodeId;
  const nodes = createInfoNodeWithPointer({
    nodeId,
    pointerCoordinates: { x: 280, y: yWithBorder(737, border) },
    nodeCoordinates: { x: 350, y: 800 },
    data: {
      nodeIdHovered,
      border,
      displayPosition: 'right',
      ...data,
    },
  });

  const edges = [createConnectionEdge(nodeId, isHovered)];
  return { nodes, edges };
};
