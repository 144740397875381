import { useMemo } from 'react';
import {
  ORWorkflowOverviewDashboardMarkerType,
  ORWorkflowOverviewDashboardORItemData,
} from '@sqior/viewmodels/orworkflow';
import { TimePositionCalculator } from '../orworkflow-overview-dashboard/TimePositionCalculator';

interface UseSplitTopHeightProps {
  item: ORWorkflowOverviewDashboardORItemData;
  onePixelInTime: number;
  timeCalc: TimePositionCalculator;
  nextExtension?: number;
  endExtension?: number;
}

interface SplitTopHeightResult {
  splitHeightTop: number;
  extensionWithTolerance: number;
}

export function useSplitTopHeight({
  item,
  onePixelInTime,
  timeCalc,
  nextExtension,
  endExtension,
}: UseSplitTopHeightProps): SplitTopHeightResult {
  return useMemo(() => {
    const getAdaptedNextExtension = ({
      start,
      end,
      nextExtension,
      onePixelInTime,
      endExtension,
    }: {
      start: number;
      end: number;
      nextExtension?: number;
      onePixelInTime: number;
      endExtension?: number;
    }) => {
      const tolerance = 12; // in px

      if (!nextExtension) return undefined;

      if (nextExtension >= start && nextExtension <= end) return nextExtension;

      if (endExtension && nextExtension >= start && nextExtension <= endExtension)
        return nextExtension - (endExtension - end);

      if (nextExtension > end && nextExtension < end + tolerance * onePixelInTime) return end;

      return undefined;
    };

    const adaptedNextExtension = getAdaptedNextExtension({
      start: item.start,
      end: item.end,
      nextExtension,
      onePixelInTime,
      endExtension,
    });

    const END_TOLERANCE = 14 * onePixelInTime;
    const START_TOLERANCE = 8 * onePixelInTime;
    const SPACE = 2 * onePixelInTime;

    if (!adaptedNextExtension) return { splitHeightTop: 100, extensionWithTolerance: item.end };

    let endMarker;
    let startMarker;

    for (const marker of item.markers) {
      if (
        marker.type === ORWorkflowOverviewDashboardMarkerType.End &&
        adaptedNextExtension <= marker.timestamp + END_TOLERANCE &&
        adaptedNextExtension > marker.timestamp
      ) {
        endMarker = marker.timestamp - SPACE;
      }

      if (
        marker.type === ORWorkflowOverviewDashboardMarkerType.Start &&
        adaptedNextExtension <= marker.timestamp + START_TOLERANCE &&
        adaptedNextExtension > marker.timestamp - START_TOLERANCE
      ) {
        startMarker = marker.timestamp - START_TOLERANCE - SPACE;
      }
    }

    let extensionWithTolerance = adaptedNextExtension - SPACE;

    if (startMarker) extensionWithTolerance = startMarker;
    if (endMarker) extensionWithTolerance = endMarker;

    const splitHeightTop = timeCalc.convertMarkerTimestampToNumber({
      markerTimeStamp: extensionWithTolerance,
      itemStart: item.start,
      itemEnd: item.end,
    });

    return { splitHeightTop, extensionWithTolerance };
  }, [item, onePixelInTime, timeCalc, nextExtension, endExtension]);
}

export default useSplitTopHeight;
