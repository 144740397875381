// eslint-disable-next-line @typescript-eslint/no-empty-interface
import { TimeSeriesChart } from '@sqior/viewmodels/analytics-dashboard';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { annotationsOptions, extractChartColors, tooltipOptions, xaxisOptions } from '../utils';
import { SeriesNoValue } from '@sqior/js/statistics';
import { getWeek, isWeekend } from 'date-fns';

interface Series {
  name: string;
  data: (number | null)[];
  primary: boolean;
}

export function MixBarChart({ graph, helpLines, timeSeries }: TimeSeriesChart) {
  const seriesTrans: Series[] = graph.map((_) => ({
    name: _.name,
    data: _.values.map((item) => (item === SeriesNoValue ? null : Math.round(item * 100) / 100)),
    primary: _.axis === 'primary',
  }));

  const firstSerie = seriesTrans[0];
  if (seriesTrans.length > 1) console.warn('MixBarChart only supports one serie');

  const extraLabels = graph.map((_) => _.valueLabels);

  const options: ApexOptions = {
    theme: {
      mode: 'dark',
    },
    chart: {
      height: 350,
      type: 'line',
      background: 'transparent', // Set the chart background to transparent
      toolbar: {
        show: false, // Hide the chart toolbar
      },
      zoom: {
        enabled: false, // Disable zooming
      },
    },

    colors: [...extractChartColors(graph), '#00bbff'],
    xaxis: xaxisOptions({
      items: timeSeries,
      type: 'datetime',
      showAxis: true,
    }),
    series: withWeekLine(firstSerie, timeSeries),
    grid: {
      show: false, // Remove grid lines
    },
    legend: {
      show: false, // Hide legend
    },
    tooltip: tooltipOptions({
      type: 'datetime',
      extraLabels,
      categories: timeSeries,
      shared: false,
      intersect: true,
    }),
    stroke: {
      lineCap: 'round',
      width: [0, 2],
      dashArray: [0, 5],
      curve: 'smooth',
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    annotations: annotationsOptions({ helpLines }),
    yaxis: [
      { show: false },
      {
        show: false,
      },
    ],
  };
  return (
    <Chart
      options={options}
      series={options.series}
      type="line"
      height={250}
      width="100%"
      style={{ width: '100%' }}
    />
  );
}

export default MixBarChart;

interface DataPoint {
  date: Date;
  value: number | null;
  weekNumber: number;
  isWeekend: boolean;
}

const withWeekLine = (series: Series, timeSeries: number[]): ApexAxisChartSeries => {
  const dataPoints: DataPoint[] = timeSeries.map((timestamp, index) => {
    const date = new Date(timestamp);
    const value = series.data[index];
    return {
      date,
      value,
      weekNumber: getWeek(date, { weekStartsOn: 1 }),
      isWeekend: isWeekend(date),
    };
  });

  // Group data points by weekNumber
  const weekData: { [weekNumber: number]: DataPoint[] } = {};

  dataPoints.forEach((dp) => {
    const weekNumber = dp.weekNumber;
    if (!weekData[weekNumber]) {
      weekData[weekNumber] = [];
    }
    weekData[weekNumber].push(dp);
  });

  // Compute average value for each week (Monday to Friday)
  const weekAverages: { [weekNumber: number]: number | null } = {};

  for (const weekNumber in weekData) {
    const dataPoints = weekData[weekNumber];
    const weekdayValues = dataPoints
      .filter((dp) => !dp.isWeekend && dp.value !== null)
      .map((dp) => dp.value as number);

    if (weekdayValues.length > 0) {
      const sum = weekdayValues.reduce((acc, val) => acc + val, 0);
      const avg = sum / weekdayValues.length;
      weekAverages[weekNumber] = avg;
    } else {
      weekAverages[weekNumber] = null;
    }
  }

  // Build new data series
  const newSeriesData = dataPoints.map((dp) => {
    if (!dp.isWeekend) {
      // Weekday
      return weekAverages[dp.weekNumber];
    } else {
      // Weekend
      return dp.value;
    }
  });

  // Build ApexAxisChartSeries
  const apexSeries: ApexAxisChartSeries = [
    {
      name: series.name,
      data: series.data,
      type: 'column',
    },
    {
      name: 'Week Average',
      data: newSeriesData,
      type: 'line',
    },
  ];

  return apexSeries;
};
