import { Bytes, ensureArray, Value, ValueObject } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { CoreEntities } from './core-definitions';
import { EntityModel, substituteEntities } from './entity';

/* Function deducing the target type from a set of references */

function deduceMatchTargetType(refs: Entity[]) {
  if (refs.length === 0)
    throw new Error('Empty array of reference entities is not allowed in match');
  const type = refs[0].entityType;
  if (!type)
    throw new Error(
      'First subset provided in match must carry an entity type in case that no target type is provided'
    );
  /* Make sure all other entities have the same type */
  for (let i = 1; i < refs.length; i++)
    if (refs[i].entityType !== type)
      throw new Error(
        'All reference entities need to share a common type in case that no target type is specified in makeMatch()'
      );
  return type;
}

/* Definition of a place-holder that is supposed to be matched to a reference value */

export type MatchEntity = Entity & {
  reference: Entity[];
  targetType: string;
};
export type MatchSubsetEntity = Entity & {
  reference: ValueObject[];
  targetType: string;
};

/** Matches an entity to a set of reference entities and returns a positive result if one of the entities is found */

export const MatchEqualModel: EntityModel = {
  type: CoreEntities.MatchEqual,
  props: ['reference', 'targetType'],
  unclassified: true,
};
export function MatchEqual(ref: Entity | Entity[], targetType?: string): MatchEntity {
  const refs = ensureArray(ref);
  if (refs.length === 0)
    throw new Error('Empty array of reference entities is not allowed in makeMatch()');
  return {
    entityType: CoreEntities.MatchEqual,
    targetType: targetType ?? deduceMatchTargetType(refs),
    reference: refs,
  };
}

/** Matches an entity to a set of reference entities and returns a positive result if none of the entities are found */

export const MatchNotEqualModel: EntityModel = {
  type: CoreEntities.MatchNotEqual,
  props: ['reference', 'targetType'],
  unclassified: true,
};
export function MatchNotEqual(ref: Entity | Entity[], targetType?: string): MatchEntity {
  const refs = ensureArray(ref);
  if (refs.length === 0)
    throw new Error('Empty array of reference entities is not allowed in makeMatch()');
  return {
    entityType: CoreEntities.MatchNotEqual,
    targetType: targetType ?? deduceMatchTargetType(refs),
    reference: refs,
  };
}

/** Matches an entity to a set of subset entity objects and returns a positive result if one of the subsets matches */

export const MatchSubsetModel: EntityModel = {
  type: CoreEntities.MatchSubset,
  props: ['reference', 'targetType'],
  unclassified: true,
};
export function MatchEntitySubset(ref: Entity | Entity[], targetType?: string): MatchSubsetEntity {
  const refs = ensureArray(ref);
  if (refs.length === 0)
    throw new Error('Empty array of reference entities is not allowed in makeMatch()');
  return {
    entityType: CoreEntities.MatchSubset,
    targetType: targetType ?? deduceMatchTargetType(refs),
    reference: refs,
  };
}

/** Matches an entity to a set of subset reference objects and returns a positive result if one of the subsets matches */

export function MatchSubset(
  ref: ValueObject | ValueObject[],
  targetType: string
): MatchSubsetEntity {
  const refs = ensureArray(ref);
  if (refs.length === 0)
    throw new Error('Empty array of reference subsets is not allowed in makeMatch()');
  return {
    entityType: CoreEntities.MatchSubset,
    targetType: targetType,
    reference: refs,
  };
}

/** Matches an entity to a set of subset entity objects and returns a positive result if one of the subsets matches */

export const MatchSubsetNotEqualModel: EntityModel = {
  type: CoreEntities.MatchSubsetNotEqual,
  props: ['reference', 'targetType'],
  unclassified: true,
};
export function MatchEntitySubsetNotEqual(
  ref: Entity | Entity[],
  targetType?: string
): MatchSubsetEntity {
  const refs = ensureArray(ref);
  if (refs.length === 0)
    throw new Error('Empty array of reference entities is not allowed in makeMatch()');
  return {
    entityType: CoreEntities.MatchSubsetNotEqual,
    targetType: targetType ?? deduceMatchTargetType(refs),
    reference: refs,
  };
}

/** Matches an entity to a set of subset reference objects and returns a positive result if one of the subsets matches */

export function MatchSubsetNotEqual(
  ref: ValueObject | ValueObject[],
  targetType: string
): MatchSubsetEntity {
  const refs = ensureArray(ref);
  if (refs.length === 0)
    throw new Error('Empty array of reference subsets is not allowed in makeMatch()');
  return {
    entityType: CoreEntities.MatchSubsetNotEqual,
    targetType: targetType,
    reference: refs,
  };
}

/** Matches an entity to a target type (can be entity or interface) */

export function MatchType(type: string): MatchSubsetEntity {
  return MatchSubset({}, type);
}

/* Definition of a condition entity that compares a subject to a match entity */

export const MatchEntities = new Set<string>([
  CoreEntities.MatchEqual,
  CoreEntities.MatchNotEqual,
  CoreEntities.MatchSubset,
  CoreEntities.MatchSubsetNotEqual,
]);

export type ConditionEntity = Entity & { subject: Entity; match: Entity };
export const ConditionEntityModel: EntityModel = {
  type: CoreEntities.Condition,
  props: ['subject', 'match'],
};
export function makeCondition(subject: Entity, match: Entity): ConditionEntity {
  return { entityType: CoreEntities.Condition, subject: subject, match: match };
}

/* Extracts the matching partners of an entity instance based on a filter object */

export function extractMatches(filter: Value, entity: Value): [Entity, Entity][] {
  let res: [Entity, Entity][] = [];

  /* Check if these are primitive types */
  if (typeof filter !== typeof entity)
    throw new Error(
      'Types of property of the filter and the entity in extractParameters() do not match'
    );
  if (
    typeof filter !== 'object' ||
    typeof entity !== 'object' ||
    filter instanceof Bytes ||
    entity instanceof Bytes
  )
    return res;

  /* Check if this is an array and recurse */
  if (filter instanceof Array || entity instanceof Array) {
    if (!(filter instanceof Array) || !(entity instanceof Array))
      throw new Error(
        'Types of property of the filter and the entity in extractParameters() do not match'
      );
    if (filter.length > entity.length)
      throw new Error(
        'Length of array property of the filter exceeds the length of the entity in extractParameters()'
      );
    for (let i = 0; i < filter.length; i++) res = res.concat(extractMatches(filter[i], entity[i]));
    return res;
  }

  /* Check if this a filter for a match */
  if ('entityType' in filter)
    if (
      typeof filter['entityType'] === 'string' &&
      MatchEntities.has(filter['entityType']) &&
      'reference' in filter &&
      'targetType' in filter
    ) {
      if (!('entityType' in entity) || typeof entity['entityType'] !== 'string')
        throw new Error(
          'Filter expects matching entity in extractMatchers() but entity is not matched'
        );
      return [[filter as Entity, entity as Entity]];
    } else if (filter['entityType'] === CoreEntities.Parameter) return res; // Check if there is a parameter in the filter, ignore this as this could otherwise prevent this from succeeding

  /* Loop all keys of the filer and recurse */
  for (const key in filter) {
    const fValue = filter[key];
    const eValue = entity[key];
    if (eValue === undefined)
      throw new Error(
        'Provided entity lacks a property of the filter in extractParameters() - key is: ' + key
      );
    res = res.concat(extractMatches(fValue, eValue));
  }

  return res;
}

/** Elminates matches from an entity */

const MatchEliminator: Record<string, () => undefined> = {};
for (const type of MatchEntities)
  MatchEliminator[type] = () => {
    return undefined;
  };

export function eliminateMatches<Type extends ValueObject = Entity>(obj: Type) {
  return substituteEntities(obj, MatchEliminator);
}
