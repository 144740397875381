import { ClockTimestamp } from '@sqior/js/data';
import { Entity, EntityHeader } from '@sqior/js/entity';
import { EntityModel } from './entity';
import { CoreEntities } from './core-definitions';

export type EntityModificationHistoryItem = {
  data: Entity;
  timestamp: ClockTimestamp;
  setter?: Entity;
};
export type EntityModificationHistory = EntityHeader & {
  history: EntityModificationHistoryItem[];
};
export const EntityModificationHistoryModel: EntityModel = {
  type: CoreEntities.EntityModificationHistory,
  props: ['history'],
};
export function makeEntityModificationHistory(
  history: EntityModificationHistoryItem[]
): EntityModificationHistory {
  return { entityType: CoreEntities.EntityModificationHistory, history };
}

export function lastModifiedTimestamp(
  history: EntityModificationHistory | undefined
): ClockTimestamp | undefined {
  return history && history.history.length > 0 ? history.history[0].timestamp : undefined;
}
