import { EntityHeader } from '@sqior/js/entity';
import { LanguageEntities } from './language-definitions';
import { arrayConcat } from '@sqior/js/data';
import { Gender } from './gender';

/** Name of the preposition option */

export const PrepositionOption = 'prepo';

/** Prepositions (includes the plain article) */

export enum Prepositions {
  from = 'from',
  inside = 'inside',
  into = 'into',
  the = 'the',
  to = 'to',
}

/** Preposition entity */

export type PrepositionEntity = EntityHeader & { prepo: string };
export function makePreposition(prepo: string): PrepositionEntity {
  return { entityType: LanguageEntities.Preposition, prepo };
}

/** Prepositions per gender */

type GenderPrepositions = { male: string; female: string; neuter: string };
export const PrepositionTexts = new Map<string, GenderPrepositions>([
  [Prepositions.from, { male: 'vom', female: 'von der', neuter: 'vom' }],
  [Prepositions.inside, { male: 'im', female: 'in der', neuter: 'im' }],
  [Prepositions.into, { male: 'in den', female: 'in die', neuter: 'in das' }],
  [Prepositions.the, { male: 'der', female: 'die', neuter: 'das' }],
  [Prepositions.to, { male: 'zum', female: 'zur', neuter: 'zum' }],
]);
export const PrepositionBackMap: [string, [string, Gender]][] = arrayConcat(
  [...PrepositionTexts],
  (entry) => {
    return [
      [entry[1].female, [entry[0], Gender.Female]],
      [entry[1].male, [entry[0], Gender.Male]],
      [entry[1].neuter, [entry[0], Gender.Neuter]],
    ];
  }
);
