import {
  BedAllocationWardListItemBed,
  BedAllocationWardListItemBedState,
  DischargeInfo,
} from '@sqior/viewmodels/occupancy';
import styles from '../pill-view.module.css';
import { FC } from 'react';
import { PatientInfo } from '@sqior/viewmodels/patient';
import { InterweaveExt, SvgIconPatient } from '@sqior/react/uibase';
import { PatientSex } from '@sqior/react/uipatient';
import { ColorCodes } from '@sqior/viewmodels/visual';
import { format, isSameDay } from 'date-fns';

interface Props {
  bed: BedAllocationWardListItemBed;
}

const getBedBlockedTime = (timestamp?: number) => {
  if (!timestamp) return null;
  const date = new Date(timestamp);
  // check if the time is 23:99:99 or 00:00:00
  if (
    (date.getHours() === 23 && date.getMinutes() === 59 && date.getSeconds() === 59) ||
    (date.getHours() === 0 && date.getMinutes() === 0)
  ) {
    // return only the date without time
    return date.toLocaleDateString();
  }
  if (isSameDay(date, new Date())) return format(date, "'heute um' HH:mm 'Uhr'");
  return format(date, "dd.MM.yyyy 'um' HH:mm 'Uhr'");
};

const PillTooltipTitle: FC<Props> = ({ bed }) => {
  const getFreeBedsDescription = () => {
    if (bed.current.state === BedAllocationWardListItemBedState.Free) {
      const sex = bed.current?.sex;
      switch (sex) {
        case 'male':
          return 'Freies Bett (Mann)';
        case 'female':
          return 'Freies Bett (Frau)';
        default:
          return 'Freies Bett';
      }
    }
    return null;
  };

  if (bed.current.blockInfo) {
    return (
      <div>
        {bed.current.blockInfo.reason ? (
          <div className={styles['blocked-container']}>
            <div className={styles['blocked-reason-title']}>Gesperrtes Bett</div>
            <div className={styles['blocked-reason']}>{bed.current.blockInfo.reason}</div>
            {bed.current.blockInfo.start && (
              <div className={styles['blocked-reason']}>
                Seit: {getBedBlockedTime(bed.current.blockInfo.start)}
              </div>
            )}
            {bed.current.blockInfo.end && (
              <div className={styles['blocked-reason']}>
                Bis: {getBedBlockedTime(bed.current.blockInfo.end)}
              </div>
            )}
          </div>
        ) : (
          <div className={styles['no-data']}>Keine Daten vorhanden</div>
        )}
      </div>
    );
  }
  if (bed?.current?.patient) {
    return (
      <BedAllocationDetailInfo
        patient={bed.current.patient}
        other={bed.other.map((b) => b.patient) as PatientInfo[]}
        dischargeInfo={bed.current.dischargeInfo}
      />
    );
  }
  return (
    <div className={styles['no-data']}>
      <div className={styles['blocked-reason-title']}>{getFreeBedsDescription()}</div>
    </div>
  );
};

export default PillTooltipTitle;

export const getColorCodeSPI = (colorCode?: ColorCodes) => {
  switch (colorCode) {
    case ColorCodes.ScaleRG3Level1:
      return '#3CD04E';
    case ColorCodes.ScaleRG3Level2:
      return '#FFBB5C';
    case ColorCodes.ScaleRG3Level3:
      return '#E14747';
    default:
      return 'white';
  }
};

type BedAllocationDetailInfoProps = {
  patient?: PatientInfo;
  other: PatientInfo[];
  dischargeInfo?: DischargeInfo;
};

function BedAllocationDetailInfo(props: BedAllocationDetailInfoProps) {
  const { patient, dischargeInfo } = props;
  return (
    <div>
      <BedAllocationPatientDetailInfo patient={patient} main={true}>
        {patient?.spi && (
          <div
            style={{ color: getColorCodeSPI(patient?.spiColorCode), fontSize: 16 }}
          >{`SPI: ${Math.round(patient?.spi)}`}</div>
        )}
        {dischargeInfo && (
          <>
            {(dischargeInfo.plannedDate || dischargeInfo.annotatedDate) && (
              <div style={{ fontSize: 16 }}>
                Entlassung:{' '}
                <span style={dischargeInfo.annotated ? { textDecoration: 'line-through' } : {}}>
                  {dischargeInfo.plannedDate}
                </span>
                <span> {dischargeInfo.annotatedDate}</span>
              </div>
            )}
            {dischargeInfo.annotation && (
              <div style={{ fontSize: 16 }}>{dischargeInfo.annotation}</div>
            )}
          </>
        )}
      </BedAllocationPatientDetailInfo>
      {props.other.length > 0 && <br />}
      {props.other.map((patient) => (
        <BedAllocationPatientDetailInfo key={patient.id} patient={patient} main={false}>
          {patient.spi && (
            <div
              style={{ color: getColorCodeSPI(patient?.spiColorCode), fontSize: 16 }}
            >{`SPI: ${Math.round(patient.spi)}`}</div>
          )}
        </BedAllocationPatientDetailInfo>
      ))}
    </div>
  );
}

type BedAllocationPatientDetailInfoProps = {
  patient?: PatientInfo;
  children: React.ReactNode;
  main: boolean;
};
function BedAllocationPatientDetailInfo(props: BedAllocationPatientDetailInfoProps) {
  return (
    <div className={styles['detail-info-container']}>
      <SvgIconPatient className={styles[props.main ? 'icon' : 'other-icon']} />
      <div className={styles['detail-info-meta']}>
        <InterweaveExt content={props.patient?.name} className={styles['name']} />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {props.patient?.sex && <PatientSex sex={props.patient?.sex} />}
          {props.patient?.dob && <div className={styles['dob-info']}>*{props.patient?.dob}</div>}
        </div>
        <div className={styles['detail-info-additional']}>{props.children}</div>
      </div>
    </div>
  );
}
