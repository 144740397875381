import { EntityHeader } from '@sqior/js/entity';
import { EntityModel, Interface } from '@sqior/js/meta';
import { TimeEntities, TimeInterfaces } from './time-definitions';
import { minToHoursAndMin } from '@sqior/js/data';

/** Hours (0-23) time component */

export type HoursEntity = EntityHeader & { hours: number };
export const HoursModel: EntityModel = {
  type: TimeEntities.Hours,
  props: ['hours'],
  keys: ['hours'],
  unclassified: true,
};
export function makeHours(hours: number): HoursEntity {
  return { entityType: TimeEntities.Hours, hours };
}

/* Time in a day */

export type TimeEntity = HoursEntity & { minutes: number };
export const TimeEntityModel: EntityModel = {
  type: TimeEntities.Time,
  props: ['hours', 'minutes'],
  unclassified: true,
};
export function makeTimeEntity(hours: number, minutes: number): TimeEntity {
  return { entityType: TimeEntities.Time, hours, minutes };
}
export function makeTimeFromTimestamp(timestamp: number): TimeEntity {
  const d = new Date(timestamp);
  return makeTimeEntity(d.getHours(), d.getMinutes());
}
export function makeTimeFromJS(date: Date): TimeEntity {
  return makeTimeEntity(date.getHours(), date.getMinutes());
}

export function addMinutesToTimeEntity(time: TimeEntity, minutes: number): TimeEntity {
  const timeInMin = time.hours * 60 + time.minutes;
  const newTime = minToHoursAndMin(timeInMin + minutes);
  return makeTimeEntity(newTime.hours, newTime.minutes);
}

export type TimeRangeEntity = EntityHeader & { start: TimeEntity; end: TimeEntity };
export const TimeRangeEntityModel: EntityModel = {
  type: TimeEntities.TimeRange,
  props: ['start', 'end'],
};
export function makeTimeRangeEntity(start: TimeEntity, end: TimeEntity): TimeRangeEntity {
  return { entityType: TimeEntities.TimeRange, start, end };
}

/** Interface for checking if the current time is within a time range */

export const InTimeRangeModel: Interface = { type: TimeInterfaces.InTimeRange };

/** Function comparing two times */
export function isTimeBefore(a: TimeEntity, b: TimeEntity) {
  return a.hours < b.hours || (a.hours === b.hours && a.minutes < b.minutes);
}

/** Check whether a time is within a specified time range */
export function inTimeRange(range: TimeRangeEntity, time: TimeEntity) {
  if (isTimeBefore(range.start, range.end)) {
    return isTimeBefore(time, range.end) && !isTimeBefore(time, range.start);
  } else {
    return isTimeBefore(range.start, time) || isTimeBefore(time, range.end);
  }
}

/** Function subtract a time range from another */

export function timeRangeSubtract(range: TimeRangeEntity, sub: TimeRangeEntity): TimeRangeEntity[] {
  /* Check if start of subtracted range is after the start of the original */
  const res: TimeRangeEntity[] = [];
  if (isTimeBefore(range.start, sub.start)) {
    /* Check if complete range is preserved */
    if (!isTimeBefore(sub.start, range.end)) return [range];
    res.push(makeTimeRangeEntity(range.start, sub.start));
  } else if (!isTimeBefore(range.start, sub.end)) return [range];
  /* CHeck if a section at the end remains */
  if (isTimeBefore(sub.end, range.end)) res.push(makeTimeRangeEntity(sub.end, range.end));
  return res;
}
