import { Entity, EntityHeader } from '@sqior/js/entity';
import { EntityModel } from '@sqior/js/meta';
import { TimeEntities } from './time-definitions';
import { TimestampInputControlType } from '@sqior/viewmodels/time';

/** Entity representing the input of a timestamp */

export type TimestampInputEntity = EntityHeader & {
  label: Entity;
  offset: Entity;
  min: Entity;
  max: Entity;
  textResult: boolean;
};
export const TimestampInputModel: EntityModel = {
  type: TimeEntities.TimestampInput,
  props: ['label', 'offset', 'min', 'max', 'textResult'],
};
export function TimestampInput(
  label: Entity,
  offset: Entity,
  min: Entity,
  max: Entity,
  textResult = true
): TimestampInputEntity {
  return { entityType: TimestampInputModel.type, label, offset, min, max, textResult };
}

/** Entity representing the input control for a timestamp */

export const TimestampInputControlModel: EntityModel = {
  type: TimestampInputControlType,
  props: ['label', 'offset', 'min', 'max', 'textResult'],
};
