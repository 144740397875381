import { ClockTimestamp, ValueObject } from '@sqior/js/data';
import { Entity, EntityHeader } from '@sqior/js/entity';
import { OperationSpec, OperationType } from '@sqior/js/operation';
import { joinPath } from '@sqior/js/url';
import { PatientOverview, PatientOverviewPath, WorkflowInfoVM } from '@sqior/viewmodels/patient';

export const WorkflowCommandSubPath = 'workflow-command';
export const WorkflowCommandPath = joinPath(PatientOverviewPath, WorkflowCommandSubPath);
export const PatientAndWorkflowCommandSubPath = 'patient-workflow-command';
export const PatientAndWorkflowCommandPath = joinPath(
  PatientOverviewPath,
  PatientAndWorkflowCommandSubPath
);

export enum WorkflowPathStepState {
  Done = 'done',
  Problem = 'challenge',
  Todo = 'todo',
  Comment = 'comment',
  AffirmativeComment = 'affirmative-comment',
  ChallengedComment = 'challenged-comment',
  PresenceComment = 'presence-comment',
}
export enum WorkflowPathStepLabelType {
  Text = 'text',
  Button = 'button',
  Custom = 'custom',
}
export type WorkflowPathStepLabel = {
  type: WorkflowPathStepLabelType;
  text: string;
  operation?: OperationSpec | Entity;
  addText?: string;
  pending?: boolean;
  custom?: Entity;
  timestamp?: ClockTimestamp;
};
export type WorkflowPathStepModel = {
  id: string;
  group: string;
  state: WorkflowPathStepState;
  label: WorkflowPathStepLabel;
  foldable?: boolean;
};
export type WorkflowPathModel = {
  current: string;
  steps: WorkflowPathStepModel[];
  timestamp: ClockTimestamp;
};
export type WorkflowOverview = Entity & { workflow?: WorkflowPathModel };

export type WorkflowPathResponseData = {
  text?: string;
  entity?: Entity;
};
export type WorkflowActionButtonVM = EntityHeader & {
  iconType: string;
  nameLong: string;
  nameShort: string;
  select: OperationSpec;
};

export type MainWorkflowOverview = WorkflowOverview & {
  relatedId?: ValueObject;
  actions: WorkflowActionButtonVM[]; // First 2 will be display, all other under more
  infos: WorkflowInfoVM[]; // First two or three short entries or one long entry will be displayed primarily, all other under more
};

export type WorkflowPathResponse = {
  id: string;
  timestamp?: ClockTimestamp;
} & WorkflowPathResponseData;
export type ComplexWorkflowPathResponse = {
  id: string;
  timestamp?: ClockTimestamp;
} & (WorkflowPathResponseData | { selectedData: WorkflowPathResponseData[] });
export function SelectWorkflowCommand(
  data: WorkflowPathResponse
): OperationSpec<WorkflowPathResponse> {
  return { type: OperationType.Add, path: WorkflowCommandPath, data: data };
}

/* Combined selection and command execution */
export type PatientWorkflowPathResponse = {
  select: Entity;
  command: WorkflowPathResponse;
};
export function PatientAndWorkflowCommand(
  select: Entity,
  command: WorkflowPathResponse
): OperationSpec<PatientWorkflowPathResponse> {
  return {
    type: OperationType.Add,
    path: PatientAndWorkflowCommandPath,
    data: { select: select, command: command },
  };
}

/* Method returning the workflow path from a patient overview, if applicable */

export function getWorkflowPathFromOverview(overview?: PatientOverview) {
  return overview && overview.content ? (overview.content as WorkflowOverview).workflow : undefined;
}
