import styles from './block-form-dialog.module.css';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { HighlightButton } from '@sqior/react/uibase';

export interface BlockSaalDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: (reason: string) => void;
}

export function BlockFormDialog({ open, setOpen, onSubmit }: BlockSaalDialogProps) {
  const [grund, setGrund] = useState('');

  const [text, setText] = useState('');

  const onClose = () => {
    setOpen(false);
  };

  const onBlock = () => {
    onSubmit(grund === 'sonstiges' ? text : grund);
    setOpen(false);
  };

  const isSubmitDisabled = () => {
    if (grund === '') return true;
    if (grund === 'sonstiges' && text === '') return true;
    return false;
  };

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length > 160) return;
    setText(e.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Saal-Sperre'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Bitte geben Sie den Grund für die Sperrung des OP-Saals an:
        </DialogContentText>
        <div className={styles['body']}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              value={grund}
              onChange={(e) => setGrund(e.target.value)}
            >
              <FormControlLabel value="befunde" control={<Radio />} label="Befunde" />
              <FormControlLabel
                value="patientenzustand"
                control={<Radio />}
                label="Patientenzustand"
              />
              <FormControlLabel value="bett" control={<Radio />} label="Bett" />
              <FormControlLabel value="personal" control={<Radio />} label="Personal" />
              <FormControlLabel value="material" control={<Radio />} label="Material" />
              <FormControlLabel value="sonstiges" control={<Radio />} label="Sonstiges" />
            </RadioGroup>
          </FormControl>

          <TextField
            disabled={grund !== 'sonstiges'}
            multiline
            rows={3}
            value={text}
            onChange={onTextChange}
            label="Grund"
            helperText={`${text.length}/160`}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '14px',
            gap: 8,
          }}
        >
          <Button onClick={onClose}>Abbrechen</Button>

          <div>
            <HighlightButton
              secondary
              style={{ minWidth: 120 }}
              onClick={onBlock}
              disabled={isSubmitDisabled()}
            >
              Sperren
            </HighlightButton>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default BlockFormDialog;
