import styles from './new-support-chat.module.css';
import {
  ClosablePage,
  HighlightButton,
  MessengerHeaderActionButtons,
  SqiorListItem,
  useTextResources,
} from '@sqior/react/uibase';
import { FactoryProps } from '@sqior/react/factory';
import { useContext, useState } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { TextField } from '@mui/material';
import { CreateSupportChat } from '@sqior/viewmodels/support';

export type SupportCategory = 'question' | 'problem' | 'change';

const MAX_DESC_LENGTH = 100;

/* eslint-disable-next-line */
export interface NewSupportChatProps {}

export function NewSupportChat(props: FactoryProps) {
  const dispatch = useContext(OperationContext);
  const textDict = useTextResources();

  const [category, setCategory] = useState<SupportCategory | null>(null);
  const [description, setDescription] = useState<string>('');

  const onNewSupportChat = () => {
    dispatch.start(CreateSupportChat({ category: category ?? '', description }));
  };

  const onCategory = (newCategory: SupportCategory) => () => {
    setCategory(newCategory);
  };

  const onDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (value.length > 100) return;
    setDescription(e.target.value);
  };

  return (
    <ClosablePage
      onClose={() => props.onClose?.(true, undefined)}
      header={
        <MessengerHeaderActionButtons
          onClose={() => props.onClose?.()}
          closeLabel={textDict.get('cancel')}
          onConfirm={onNewSupportChat}
          confirmLabel={textDict.get('next')}
          confirmDisabled={!description || !category}
        />
      }
    >
      <div className={styles['container']}>
        <div className={styles['text-field-container']}>
          <TextField
            required
            multiline
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '16px',
              },
            }}
            minRows={3}
            placeholder={textDict.get('problem_description')}
            value={description}
            onChange={onDescriptionChange}
          />
          <div className={styles['counter']}>
            <div>{`${description.length}/${MAX_DESC_LENGTH}`}</div>
          </div>
        </div>

        <div className={styles['radio-cont']}>
          <SqiorListItem title={textDict.get('choose_category')} />
          <div className={styles['radio-buttons']}>
            <HighlightButton secondary={category !== 'question'} onClick={onCategory('question')}>
              Frage
            </HighlightButton>
            <HighlightButton secondary={category !== 'problem'} onClick={onCategory('problem')}>
              Problem melden
            </HighlightButton>
            <HighlightButton secondary={category !== 'change'} onClick={onCategory('change')}>
              Änderungsvorschläge
            </HighlightButton>
          </div>
          <SqiorListItem description={textDict.get('new_support_chat_privacy')} />
        </div>
      </div>
    </ClosablePage>
  );
}

export default NewSupportChat;
