import styles from './support-inbox.module.css';
import { ClosablePage, HighlightButton, useTextResources } from '@sqior/react/uibase';
import { FactoryProps } from '@sqior/react/factory';
import { ChatPage } from '@sqior/react/uichat';
import { useContext } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { MessengerPages, OpenPage, OpenPageReplaceStrategy } from '@sqior/viewmodels/app';

/* eslint-disable-next-line */
export interface SupportInboxProps {}

export function SupportInbox(props: FactoryProps) {
  const dispatch = useContext(OperationContext);
  const textDict = useTextResources();

  const onNewChat = () => {
    dispatch.start(
      OpenPage({ entityType: MessengerPages.NewSupportChat }, OpenPageReplaceStrategy.SamePageType)
    );
  };

  return (
    <ClosablePage
      onClose={() => props.onClose?.(true, undefined)}
      title={<div className={styles['title']}>{textDict.get('support_chats')}</div>}
    >
      <div className={styles['chat-list-container']}>
        <div className={styles['chat-list']}>
          <ChatPage type="support" />
        </div>
        <div className={styles['button-container']}>
          <HighlightButton onClick={onNewChat}>{textDict.get('new_request')}</HighlightButton>
        </div>
      </div>
    </ClosablePage>
  );
}

export default SupportInbox;
