import { ConversationFrame } from '@sqior/react/uiconversation';
import { ClosablePage, SvgIconHistory, useTextResources } from '@sqior/react/uibase';
import { PageHeader } from '@sqior/react/uiadvanced';
import { FactoryProps, factoryGetProp } from '@sqior/react/factory';

export function CommandHistoryPage(props: FactoryProps) {
  const textRes = useTextResources();

  return (
    <ClosablePage
      className={factoryGetProp('className', props)}
      onClose={() => props.onClose?.(true, undefined)}
    >
      <PageHeader title={textRes.get('command_history')} icon={<SvgIconHistory />} />
      <ConversationFrame
        onPhoto={() => {
          /* ihnore */
        }}
      />
    </ClosablePage>
  );
}

export default CommandHistoryPage;
