import {
  FC,
  type MouseEvent as ReactMouseEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactFlow, { Node, useReactFlow } from 'reactflow';
import styles from './plan-stability-flow-chart.module.css';
import 'reactflow/dist/style.css';
import useResizeObserver from 'use-resize-observer/polyfilled';
import BackgroundNode from './BackgroundNode/BackgroundNode';
import ProcedureNode from './ProcedureNode/ProcedureNode';
import InfoNode from './InfoNode/InfoNode';
import PointerNode from './PointerNode/PointerNode';
import { PlanStabilityVM } from '@sqior/viewmodels/orworkflow-analytics-dashboard';
import { useNodesAndEdges } from './useNodesAndEdges';
import { Edge } from '@reactflow/core/dist/esm/types/edges';
import { NodeId } from './types';
import { extractNodeId } from './utils';
import { debounce } from 'lodash';

interface Props {
  data?: PlanStabilityVM['details'];
}

const nodeTypes = {
  background: BackgroundNode,
  procedure: ProcedureNode,
  info: InfoNode,
  pointer: PointerNode,
};

export const PlanStabilityFlowChart: FC<Props> = ({ data }) => {
  const reactFlow = useReactFlow();
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const chartRef = useRef<HTMLDivElement>(null);

  const [nodeIdHovered, setNodeIdHovered] = useState<NodeId | null>(null);
  const { nodes, edges } = useNodesAndEdges({ data, nodeIdHovered });

  const debouncedSetNodeIdHovered = useMemo(
    () => debounce((nodeId: NodeId | null) => setNodeIdHovered(nodeId), 50),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSetNodeIdHovered.cancel();
    };
  }, [debouncedSetNodeIdHovered]);

  useResizeObserver<HTMLDivElement>({
    ref: reactFlowWrapper,
    onResize: ({ width }) => {
      reactFlow.fitView();
    },
  });

  const onEdgeMouseEnter = (event: ReactMouseEvent, edge: Edge) => {
    debouncedSetNodeIdHovered(extractNodeId(edge.id));
  };

  const onEdgeMouseLeave = (event: ReactMouseEvent, edge: Edge) => {
    debouncedSetNodeIdHovered(null);
  };

  const onNodeMouseEnter = (event: ReactMouseEvent, node: Node) => {
    if (node.id === NodeId.Background || node.id === NodeId.Procedure) return;
    debouncedSetNodeIdHovered(extractNodeId(node.id));
  };

  const onNodeMouseLeave = (event: ReactMouseEvent, node: Node) => {
    debouncedSetNodeIdHovered(null);
  };

  if (!data) return null;
  return (
    <div ref={reactFlowWrapper} className={styles['container']}>
      <ReactFlow
        onNodeMouseLeave={onNodeMouseLeave}
        onNodeMouseEnter={onNodeMouseEnter}
        onEdgeMouseLeave={onEdgeMouseLeave}
        onEdgeMouseEnter={onEdgeMouseEnter}
        ref={chartRef}
        panOnScroll={false}
        zoomOnScroll={false}
        fitView
        nodeTypes={nodeTypes}
        edges={edges}
        nodes={nodes}
        panOnDrag={false} // Disable panning when dragging
        zoomOnPinch={false} // Disable zooming with pinch gestures
        nodesDraggable={false} // Disable node dragging
        nodesConnectable={false} // Disable connecting nodes
        elementsSelectable={true} // Disable selection of nodes and edges
        preventScrolling={false}
        // onEdgeClick={onEdgeClick}
        // nodeTypes={nodeTypes}
        // edgeTypes={edgeTypes}
        // onLoad={onLoad}
        // onNodesChange={onNodesChange}
        // onNodeClick={onNodeClick}
        // onNodeMouseEnter={onNodeMouseEnter}
        // onNodeMouseLeave={onNodeMouseLeave}
        proOptions={{ hideAttribution: true }}
        defaultViewport={{ zoom: 1, x: 0, y: 0 }}
      />
    </div>
  );
};

export default PlanStabilityFlowChart;
