import { FactoryProps } from '@sqior/react/factory';
import { PlanStabilityVM } from '@sqior/viewmodels/orworkflow-analytics-dashboard';
import PlanStabilityPage from '../plan-stability-page/plan-stability-page';

type PlanStabilityFactoryProps = FactoryProps<PlanStabilityVM>;

export function PlanStabilityFactory({ data }: PlanStabilityFactoryProps) {
  return <PlanStabilityPage {...data} />;
}

export default PlanStabilityFactory;
