import { AdvancedPages, UserInfoServer } from '@sqior/react/uiadvanced';
import { AuthContext } from '@sqior/react/uiauth';
import {
  ContainerWithShadow,
  HighlightButton,
  SIcon,
  SqiorPage,
  SvgAccountBalance,
  SvgContactSupport,
  SvgDevices,
  SvgExitToApp,
  SvgIconHistory,
  SvgLock,
  useTextResources,
} from '@sqior/react/uibase';
import { useContext, useEffect, useState } from 'react';
import { ReactComponent as BadgeIcon } from './badge.svg';
import { ReactComponent as LocationIcon } from './location.svg';
import { ReactComponent as RolesIcon } from './roles.svg';
import { ReactComponent as RestartIcon } from './restart.svg';
import { ReactComponent as DemoLayoutIcon } from './demo-layout.svg';
import { ReactComponent as SettingsIcon } from './settings.svg';
import styles from './menu-page.module.css';
import { AppStyle, useAppStyle, useDynamicState } from '@sqior/react/state';
import {
  AvailableLocationsPath,
  RoleSelectionStatePath,
  UserChangePath,
  UserPages,
} from '@sqior/viewmodels/user';
import { sqiorOpenExternal } from '@sqior/js/url';
import {
  DemoModePath,
  MessengerPages,
  OpenPage,
  PairingFeaturePublicServerUrlPath,
  RestartServer,
} from '@sqior/viewmodels/app';
import { OperationContext } from '@sqior/react/operation';
import { addSeconds } from '@sqior/js/data';
import { Icons } from '@sqior/viewmodels/visual';
import { ExperienceUrlPath } from '@sqior/viewmodels/experience';

export interface MenuPageProps {
  onClose: () => void;
}

export function MenuPage(props: MenuPageProps) {
  const { appStyle, setAppStyle } = useAppStyle();

  const dispatcher = useContext(OperationContext);
  const textDict = useTextResources();
  const authContext = useContext(AuthContext);
  const demoMode = useDynamicState<boolean>(DemoModePath, false);
  const roleSelection = useDynamicState<[]>(RoleSelectionStatePath, []);
  const availableLocations = useDynamicState<[]>(AvailableLocationsPath, []);
  const availableSpecialties = useDynamicState<[]>('available-specialties', []);
  const userChange = useDynamicState<boolean>(UserChangePath, false);
  const isUserAdmin = useDynamicState<boolean>('user-info/admin', false);
  const experienceURL = useDynamicState<string>(ExperienceUrlPath, '');
  const [restartHot, setRestartHot] = useState(false);
  const pairingFeatureAvailable =
    useDynamicState<string>(PairingFeaturePublicServerUrlPath, '') !== '';

  /* Reset restart if it was not confirmed within 10 seconds */
  useEffect(() => {
    if (restartHot) {
      const timeout = setTimeout(() => {
        setRestartHot(false);
      }, addSeconds(5));
      return () => {
        clearTimeout(timeout);
      };
    }
    return;
  }, [restartHot, setRestartHot]);

  function openSimplePage(entityTpe: string) {
    dispatcher.start(OpenPage({ entityType: entityTpe, data: {} }));
  }

  // const onOpenSupportChat = () => {
  //   dispatcher.start(CreateSupportChat({ category: 'Test' }));
  // };

  const SQIOR_EXPERIENCE_URL = process.env['REACT_APP_SQIOR_EXPERIENCE_URL'];

  return (
    <SqiorPage onClose={props.onClose}>
      <ContainerWithShadow>
        <div className={styles['container']}>
          <div className={styles['content-container']}>
            <div className={styles['top-spacer']} />
            <UserInfoServer showUserIcon={false} className={styles['user']} />
            <div className={styles['bottom-spacer']} />
            <div
              className={styles['central-container']}
              onClick={() => {
                openSimplePage(MessengerPages.CommandHistory);
              }}
            >
              <SvgIconHistory className={styles['central-icon']} />
              <div className={styles['central-text']}>{textDict.get('command_history')}</div>
            </div>
            {userChange && (
              <div
                className={styles['central-container']}
                onClick={() => {
                  openSimplePage(MessengerPages.QRScan);
                }}
              >
                <BadgeIcon className={styles['central-icon']} />
                <div className={styles['central-text']}>{textDict.get('change_user')}</div>
              </div>
            )}
            {availableLocations.length > 0 && (
              <div
                className={styles['central-container']}
                onClick={() => {
                  openSimplePage(MessengerPages.ChoseRoom);
                }}
              >
                <LocationIcon className={styles['central-icon']} />
                <div className={styles['central-text']}>{textDict.get('change_room')}</div>
              </div>
            )}
            {availableSpecialties.length > 0 && (
              <div
                className={styles['central-container']}
                onClick={() => {
                  openSimplePage(MessengerPages.ChoseSpecialty);
                }}
              >
                <SvgAccountBalance className={styles['central-icon']} />
                <div className={styles['central-text']}>{textDict.get('change_specialty')}</div>
              </div>
            )}
            {roleSelection.length > 0 && (
              <div
                className={styles['central-container']}
                onClick={() => {
                  openSimplePage(UserPages.PageRoleSelection);
                }}
              >
                <RolesIcon className={styles['central-icon']} />
                <div className={styles['central-text']}>{textDict.get('change_roles')}</div>
              </div>
            )}
            {pairingFeatureAvailable && (
              <div
                className={styles['central-container']}
                onClick={() => {
                  openSimplePage(UserPages.PagePairedDevices);
                }}
              >
                <SvgDevices className={styles['central-icon']} />
                <div className={styles['central-text']}>{textDict.get('paired_devices')}</div>
              </div>
            )}

            {isUserAdmin && (
              <div
                className={styles['central-container']}
                onClick={() => {
                  setRestartHot(!restartHot);
                }}
              >
                <RestartIcon className={styles['central-icon']} />
                {!restartHot && (
                  <div className={styles['central-text']}>{textDict.get('restart_server')}</div>
                )}
                {restartHot && (
                  <HighlightButton
                    onClick={() => {
                      dispatcher.start(RestartServer());
                    }}
                  >
                    {textDict.get('restart_server_confirm')}
                  </HighlightButton>
                )}
              </div>
            )}
            {demoMode && appStyle !== AppStyle.Messenger && (
              <div
                className={styles['central-container']}
                onClick={() => {
                  setAppStyle(
                    appStyle === AppStyle.Combined ? AppStyle.ParallelPhoneDemo : AppStyle.Combined
                  );
                }}
              >
                <DemoLayoutIcon className={styles['central-icon']} />
                <div className={styles['central-text']}>{textDict.get('demo_layout')}</div>
              </div>
            )}

            {experienceURL && (
              <a className={styles['hidden-link']} href={experienceURL}>
                <div className={styles['central-container']}>
                  <SIcon icon={Icons.Tv} className={styles['central-icon']} />
                  <div className={styles['central-text']}>{textDict.get('experience')}</div>
                </div>
              </a>
            )}

            <div
              className={styles['central-container']}
              onClick={() => {
                openSimplePage(MessengerPages.Settings);
              }}
            >
              <SettingsIcon className={styles['central-icon']} />
              <div className={styles['central-text']}>{textDict.get('settings')}</div>
            </div>

            <div
              className={styles['central-container-no-border']}
              onClick={() => {
                authContext.provider.logOut();
              }}
            >
              <SvgExitToApp className={styles['central-icon']} />
              <div className={styles['central-text']}> {textDict.get('log_out')}</div>
            </div>

            <div className={styles['middle-spacer']} />

            {/*{supportChat.enabled && (*/}
            {/*  <div className={styles['central-container']} onClick={onOpenSupportChat}>*/}
            {/*    <SvgInfo className={styles['central-icon']} />*/}
            {/*    <div className={styles['central-white-text']}>*/}
            {/*      {textDict.get('support_contact')}*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*)}*/}
            <div
              className={styles['central-container']}
              onClick={() => {
                openSimplePage(AdvancedPages.AboutInfo);
              }}
            >
              <SvgContactSupport className={styles['central-icon']} />
              <div className={styles['central-white-text']}>{textDict.get('help_and_support')}</div>
            </div>
            <div className={styles['central-container']} style={{ borderBottom: 'none' }}>
              <SvgLock className={styles['central-icon']} />
              <a
                href={sqiorOpenExternal('http://sqior.com/datenschutzerklarung-sqior-app-pdf')}
                target={'_blank'}
                rel="noopener noreferrer"
                className={styles['data-protection']}
              >
                <div className={styles['central-white-text']}>
                  {textDict.get('data_protection')}
                </div>
              </a>
            </div>
          </div>
        </div>
      </ContainerWithShadow>
    </SqiorPage>
  );
}

export default MenuPage;
