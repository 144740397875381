export interface FormatNumber {
  value: number;
  decimalPlaces?: number;
  locale?: string;
  showSign?: boolean;
}

export const formatNumber = ({
  value,
  decimalPlaces,
  locale = 'de-DE',
  showSign,
}: FormatNumber): string => {
  const sign = showSign ? (value > 0 ? '+' : '') : '';
  return (
    sign +
    new Intl.NumberFormat(locale, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    }).format(value)
  );
};
