import { DragEvent, useContext, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import {
  SvgIconChat,
  SvgQRCode,
  SvgUploadFile,
  useTextResources,
  SvgIconGroup,
  useOnCancel,
} from '@sqior/react/uibase';
import { OperationContext } from '@sqior/react/operation';
import { SelectionPageContext } from '@sqior/react/uiselection';
import { SelectionMenuType } from '@sqior/viewmodels/input';
import { AddressSelectionType } from '@sqior/viewmodels/user';
import {
  ChatListPath,
  CreateAndDisplayChat,
  EnrichedChatVM,
  PersonalChatType,
} from '@sqior/viewmodels/communication';
import { MessengerPages, OpenPage } from '@sqior/viewmodels/app';
import { AnimatePresence, motion } from 'framer-motion';
import { UploadFile } from '@sqior/js/media';
import { Bytes, ValueObject } from '@sqior/js/data';
import { SxProps } from '@mui/material';

import styles from './speed-dial-floating-button.module.css';
import { useDynamicStateRaw } from '@sqior/react/state';

const PRIMARY_COLOR = '#1cade4';
const BACKDROP_BACKGROUND_COLOR = 'rgba(9,15,35,0.8)';

const SPEED_ACTION_SX: SxProps = {
  '& .MuiSpeedDialAction-staticTooltipLabel': {
    boxShadow: 'none',
    width: 230,
    color: PRIMARY_COLOR,
    fontSize: '1.2rem',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: 0,
    zIndex: 150,
  },
};

/* eslint-disable-next-line */
export interface SpeedDialFloatingButtonProps {}

export function SpeedDialFloatingButton(props: SpeedDialFloatingButtonProps) {
  const dispatcher = useContext(OperationContext);
  const textDict = useTextResources();
  const selection = useContext(SelectionPageContext);

  const [open, setOpen] = useState(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'copy';
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    if (files.length === 0) return;
    const file = files.item(0);
    if (file)
      dispatcher.start(UploadFile(file.name, file.lastModified, file.type, new Bytes(file)));
  };

  const handleOpenNewChat = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    selection(
      {
        entityType: SelectionMenuType,
        title: textDict.get('select_chat_partner'),
        selection: { entityType: AddressSelectionType },
      },
      (success, user) => {
        if (user)
          dispatcher.start(CreateAndDisplayChat({ entityType: PersonalChatType }, [user], true));
      }
    );
    handleClose();
  };

  const handleOpenNewGroupChat = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    selection(
      {
        entityType: SelectionMenuType,
        title: textDict.get('select_chat_partner'),
        selection: { entityType: AddressSelectionType },
        multiSelect: true,
      },
      (success, user) => {
        if (!user) return;
        const arrayUsers = user['addresses'] as ValueObject[];
        const title = user['title'] as string;
        if (Array.isArray(arrayUsers) && arrayUsers.length > 0)
          dispatcher.start(
            CreateAndDisplayChat({ entityType: PersonalChatType }, arrayUsers, true, title)
          );
      }
    );
    handleClose();
  };

  const handleOpenQRCode = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    dispatcher.start(OpenPage({ entityType: MessengerPages.QRScan, data: {} }));
    handleClose();
  };

  const toggleOpen = () => setOpen((prev) => !prev);
  const handleClose = () => setOpen(false);
  useOnCancel(() => {
    if (open) setOpen(false);
  });

  const chatList = useDynamicStateRaw<EnrichedChatVM[]>(ChatListPath);
  const actions = (
    chatList
      ? [
          { icon: <SvgIconChat />, name: 'Neuer Chat', onClick: handleOpenNewChat },
          {
            icon: <SvgIconGroup />,
            name: 'Neuer Gruppenchat',
            onClick: handleOpenNewGroupChat,
          },
        ]
      : []
  ).concat({ icon: <SvgQRCode />, name: 'QR-Code Scannen', onClick: handleOpenQRCode });

  const animateProps = isDragging
    ? {
        left: 0,
        height: 300,
        opacity: 1,
        border: `4px dashed ${PRIMARY_COLOR}`,
      }
    : {
        height: 90,
        opacity: 0,
        border: `4px dashed ${PRIMARY_COLOR}`,
        right: 0,
        left: 'auto',
      };

  return (
    <>
      <Backdrop
        open={open}
        style={{ position: 'absolute', backgroundColor: BACKDROP_BACKGROUND_COLOR, zIndex: 150 }}
      />
      <motion.div
        className={styles['drag-area']}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        initial={{ opacity: 0 }}
        animate={animateProps}
        style={{
          width: isDragging ? 'auto' : 110,
        }}
      >
        <AnimatePresence>
          {isDragging && (
            <motion.div
              className={styles['input-editor']}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <SvgUploadFile style={{ height: 100, width: 50 }} />
              <p>{textDict.get('upload_file_label')}</p>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>

      <SpeedDial
        ariaLabel="SpeedDial tooltip"
        icon={<SpeedDialIcon />}
        onClick={toggleOpen}
        sx={{
          position: 'absolute',
          bottom: 16,
          right: 16,
          display: isDragging ? 'none' : 'flex',
        }}
        style={{
          zIndex: 999,
        }}
        open={open}
        FabProps={{
          style: open
            ? {
                backgroundColor: 'transparent',
                border: `2px solid ${PRIMARY_COLOR}`,
                color: PRIMARY_COLOR,
              }
            : {
                color: 'white',
                boxShadow: 'rgb(9,15,35) 0px 22px 60px 30px',
              },
        }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            sx={SPEED_ACTION_SX}
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={action.onClick as never}
            FabProps={{
              size: 'large',
              style: {
                boxShadow: 'none',
              },
            }}
          />
        ))}
      </SpeedDial>
    </>
  );
}

export default SpeedDialFloatingButton;
