import { useDynamicState, useDynamicStateRaw } from '@sqior/react/state';
import {
  ClosablePage,
  SqiorListItem,
  SvgIconCall2,
  SvgIconEmail,
  SvgSupportChat,
  useTextResources,
} from '@sqior/react/uibase';
import { SupportInfo } from '@sqior/viewmodels/user';
import { useContext, useState } from 'react';
import LicenseInfo from '../license-info/license-info';
import styles from './about-info.module.css';
import { FactoryProps } from '@sqior/react/factory';
import LogView from '../log-view/log-view';
import { MessengerPages, OpenPage, OpenPageReplaceStrategy } from '@sqior/viewmodels/app';
import { Divider } from '@mui/material';
import { OperationContext } from '@sqior/react/operation';
import { SupportChatPath, SupportChatState } from '@sqior/viewmodels/support';
import useChats from '../useChats';

export function AboutInfo(props: FactoryProps) {
  const dispatch = useContext(OperationContext);
  const textDict = useTextResources();

  const supportInfo = useDynamicStateRaw<SupportInfo>('system/support/info');

  const supportChat = useDynamicState<SupportChatState>(SupportChatPath, { enabled: false });

  const [showLicense, setShowLicense] = useState(false);
  const [showLogs, setShowLogs] = useState(false);

  const { chats } = useChats({ type: 'support' });

  function onCall(phone: string | undefined) {
    if (phone) document.location.href = `tel:${phone}`;
  }
  function onEmail(email: string | undefined) {
    if (email) document.location.href = `mailto:${email}`;
  }

  const onOpenSupportChat = () => {
    if (chats.length > 0) {
      return dispatch.start(
        OpenPage({ entityType: MessengerPages.SupportInbox }, OpenPageReplaceStrategy.SamePageType)
      );
    }
    return dispatch.start(
      OpenPage({ entityType: MessengerPages.NewSupportChat }, OpenPageReplaceStrategy.SamePageType)
    );
  };

  const onOpenServerInfoPage = () => {
    dispatch.start(
      OpenPage({ entityType: MessengerPages.ServerInfo }, OpenPageReplaceStrategy.SamePageType)
    );
  };

  return (
    <div className={styles['container']}>
      {!showLicense && !showLogs && (
        <ClosablePage
          onClose={() => props.onClose?.(true, undefined)}
          title={<div className={styles['title']}>{textDict.get('help_and_support')}</div>}
        >
          <div className={styles['info-container']}>
            <div className={styles['info-section']}>
              <SqiorListItem
                title={textDict.get('hospital_contact')}
                description={textDict.get('hospital_contact_description')}
              />
              {supportInfo?.clinicEmail && (
                <SqiorListItem
                  oneLiner
                  title={supportInfo.clinicEmail}
                  icon={<SvgIconEmail />}
                  onClick={() => onEmail(supportInfo.clinicEmail)}
                />
              )}

              {supportInfo?.clinicPhone && (
                <SqiorListItem
                  oneLiner
                  title={supportInfo.clinicPhone}
                  icon={<SvgIconCall2 />}
                  onClick={() => onCall(supportInfo.clinicPhone)}
                />
              )}

              <Divider />

              <SqiorListItem
                title={textDict.get('sqior_contact')}
                description={textDict.get('sqior_contact_description')}
              />

              <div>
                {/*<SqiorListItem*/}
                {/*  oneLiner*/}
                {/*  title={textDict.get('questions_faq')}*/}
                {/*  icon={<SvgIconQuiz />}*/}
                {/*/>*/}
                {supportChat.enabled && (
                  <SqiorListItem
                    oneLiner
                    title={textDict.get('support_chat_open')}
                    icon={<SvgSupportChat />}
                    onClick={onOpenSupportChat}
                  />
                )}

                {supportInfo?.email && (
                  <SqiorListItem
                    oneLiner
                    title={supportInfo.email}
                    icon={<SvgIconEmail />}
                    onClick={() => onEmail(supportInfo.email)}
                  />
                )}
                {supportInfo?.phone && (
                  <SqiorListItem
                    oneLiner
                    title={supportInfo.phone}
                    icon={<SvgIconCall2 />}
                    onClick={() => onCall(supportInfo.phone)}
                  />
                )}
              </div>

              <Divider />

              <div className={styles['info-section-text']}>
                <div className={styles['license']}>
                  <div className={styles['license-info-heading']} onClick={onOpenServerInfoPage}>
                    {textDict.get('server_information')}
                  </div>
                  <div
                    className={styles['license-info-heading']}
                    onClick={() => setShowLicense(true)}
                  >
                    {textDict.get('support_infos_licenses')}
                  </div>
                  <div className={styles['license-info-heading']} onClick={() => setShowLogs(true)}>
                    {textDict.get('support_infos_logs')}
                  </div>
                </div>
                <div className={styles['copyrightcontainer']}>
                  <div className={styles['copyright']}>Copyright &copy; 2022-2024</div>
                  <div className={styles['company']}>sqior medical GmbH</div>
                </div>
              </div>
            </div>

            <div className={styles['info-section-footer']} />
          </div>
        </ClosablePage>
      )}
      {showLicense && <LicenseInfo onClose={() => setShowLicense(false)} />}
      {showLogs && <LogView onClose={() => setShowLogs(false)} />}
    </div>
  );
}

export default AboutInfo;
