import styles from './error-component.module.css';
import Typography from '../typography/typography';

interface ErrorComponentProps {
  title: string;
  description?: string;
  data?: object | any[];
  expand?: boolean;
}

export function ErrorComponent({ title, description, data, expand }: ErrorComponentProps) {
  return (
    <div className={styles['container']}>
      <div>
        <Typography error fontSize={24}>
          {title}
        </Typography>
        {description && <Typography secondary>{description}</Typography>}
      </div>

      {data && <div>{JSON.stringify(data, null, 2)}</div>}
    </div>
  );
}

export default ErrorComponent;
