import { CHART_COLORS } from './constants';
import { ColorCodes } from '@sqior/viewmodels/visual';
import { DarkTheme } from '@sqior/react/theme';

// export const CHART_COLORS_MAP = new Map<ColorCodes, string>([
//   [ColorCodes.Green, CHART_SEA_GREEN],
//   [ColorCodes.Yellow, CHART_YELLOW],
//   [ColorCodes.Pink, CHART_HOT_PINK],
//   [ColorCodes.Red, CHART_SOFT_RED],
//   [ColorCodes.Orange, CHART_ORANGE],
//   [ColorCodes.Purple, CHART_PURPLE],
//   [ColorCodes.Violet, CHART_VIOLET],
//   [ColorCodes.Blue, CHART_BLUE],
//   [ColorCodes.Cyan, CHART_CYAN],
//   [ColorCodes.White, CHART_WHITE],
//   [ColorCodes.Gray, CHART_SOFT_GREY],
//   [ColorCodes.Salmon, CHART_SALMON],
//   [ColorCodes.Brown, CHART_BROWN],
//   [ColorCodes.LightGreen, CHART_LIGHT_GREEN],
//   [ColorCodes.Teal, CHART_SEA_GREEN],
// ]);

export const CHART_COLORS_MAP = new Map<ColorCodes, string>([
  [ColorCodes.SqiorBlue, DarkTheme.colors.content.sqiorBlue.fg.value],
  [ColorCodes.GreenOlive, DarkTheme.colors.content.greenOlive.fg.value],
  [ColorCodes.Purple, DarkTheme.colors.content.purple.fg.value],
  [ColorCodes.Red, DarkTheme.colors.content.red.fg.value],
  [ColorCodes.Blue, DarkTheme.colors.content.blue.fg.value],
  [ColorCodes.Peach, DarkTheme.colors.content.peach.fg.value],
  [ColorCodes.SteelBlue, DarkTheme.colors.content.steelBlue.fg.value],
  [ColorCodes.Cream, DarkTheme.colors.content.cream.fg.value],
  [ColorCodes.Cyan, DarkTheme.colors.content.cyan.fg.value],
  [ColorCodes.RiverBlue, DarkTheme.colors.content.riverBlue.fg.value],
  [ColorCodes.Green, DarkTheme.colors.content.green.fg.value],
  [ColorCodes.LightGreen, DarkTheme.colors.content.lightGreen.fg.value],
  [ColorCodes.Yellow, DarkTheme.colors.content.yellow.fg.value],
  [ColorCodes.Sand, DarkTheme.colors.content.sand.fg.value],
  [ColorCodes.Orange, DarkTheme.colors.content.orange.fg.value],
  [ColorCodes.Salmon, DarkTheme.colors.content.salmon.fg.value],
  [ColorCodes.Violet, DarkTheme.colors.content.violet.fg.value],
  [ColorCodes.Pink, DarkTheme.colors.content.pink.fg.value],
  [ColorCodes.LightPink, DarkTheme.colors.content.lightPink.fg.value],
  [ColorCodes.LightBrown, DarkTheme.colors.content.lightBrown.fg.value],
  [ColorCodes.EggShell, DarkTheme.colors.content.eggshell.fg.value],
  [ColorCodes.Brown, DarkTheme.colors.content.brown.fg.value],
  [ColorCodes.LightYellow, DarkTheme.colors.content.lightYellow.fg.value],
  [ColorCodes.DarkBlue, DarkTheme.colors.content.darkBlue.fg.value],
  [ColorCodes.LightOrange, DarkTheme.colors.content.lightOrange.fg.value],
  [ColorCodes.DarkGray, DarkTheme.colors.content.darkGray.fg.value],
  [ColorCodes.Gray, DarkTheme.colors.content.gray.fg.value],
]);

export const getChartColor = (code: ColorCodes): string => {
  return CHART_COLORS_MAP.get(code) || CHART_COLORS[0];
};
