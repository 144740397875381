/* Core entities */

import { EntityHeader, IdEntity } from '@sqior/js/entity';
import { EntityModel } from './entity';
import { Interface } from './interface';
import { ClockTimestamp } from '@sqior/js/data';

export enum CoreEntities {
  SQIORAssistant = 'SQIORAssistant',
  Event = 'Event',
  Invoke = 'Invoke',
  Undo = 'Undo',
  Redo = 'Redo',
  Stop = 'Stop',
  Update = 'Update',
  EventId = 'EventId',
  Command = 'Command',
  Notification = 'Notification',
  Text = 'Text',
  Console = 'Console',
  Key = 'Key',
  Map = 'Map',
  Evaluate = 'Evaluate',
  Undefined = 'Undefined',
  Lookup = 'Lookup',
  Backup = 'Backup',
  If = 'If',
  Condition = 'Condition',
  Parameter = 'Parameter',
  MatchEqual = 'MatchEqual',
  MatchNotEqual = 'MatchNotEqual',
  MatchSubset = 'MatchSubset',
  MatchSubsetNotEqual = 'MatchSubsetNotEqual',
  Tuple = 'Tuple', // Aggregate of multiple entity components
  Subjects = 'Subjects', // Multiple entities
  Id = 'Id', // Generic ID
  MappingReportingMode = 'MappingReportingMode', // Error reporting mode during mapping
  Insert = 'Insert', // Insert mode for related data updates
  EntityModificationHistory = 'EntityModificationHistory',
  Timestamp = 'Timestamp',
}

/* Core interfaces - 'Entity' represents all entities */

export enum CoreInterfaces {
  Entity = 'Entity',
  Result = 'Result',
  Success = 'Success', // Defined mapping result to be interpreted as success in conditions etc.
  Anonymized = 'AnonymizedEntity', // Anonymized version of the entity
  UpdateNotification = 'UpdateNotification', // Event emitted to inform about a change of data
}

/** Generic ID */

export const IdModel: EntityModel = { type: CoreEntities.Id, props: ['id'], keys: ['id'] };
export function makeId(id: string, type = IdModel.type): IdEntity {
  return { entityType: type, id };
}

/** Anonymized version of an entity */

export const AnonymizedEntityModel: Interface = { type: CoreInterfaces.Anonymized };

/** Core timestamp model */
export type TimestampEntity = EntityHeader & { timestamp: ClockTimestamp };
export const TimestampEntityModel: EntityModel = {
  type: CoreEntities.Timestamp,
  props: ['timestamp'],
  unclassified: true,
};
export function makeTimestampEntity(timestamp: Date | ClockTimestamp): TimestampEntity {
  return {
    entityType: CoreEntities.Timestamp,
    timestamp: timestamp instanceof Date ? timestamp.getTime() : timestamp,
  };
}
