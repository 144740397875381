import styles from './table-card.module.css';
import CardLayout from '../card-layout/card-layout';
import MetricCardHeader from '../metric-card-header/metric-card-header';
import {
  SortableColumnsCell,
  SortableTable,
  SqiorDropboxItem,
  SvgCheckIcon,
} from '@sqior/react/uibase';
import { TableColumnStyleType, TableData } from '@sqior/viewmodels/analytics-dashboard';
import React, { useMemo, useState } from 'react';
import { Popover } from '@mui/material';

export interface TableCardProps {
  title?: string;
  subTitle?: string;
  hideSettings?: boolean;
  data: TableData;
}

export function TableCard({ title, subTitle, hideSettings, data }: TableCardProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    data.columns.filter((_) => !_.hidden).map((col) => col.id)
  );

  const onClose = () => {
    setAnchorEl(null);
  };

  const onSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const isSelected = (id: string): boolean => {
    return selectedColumns.includes(id);
  };

  const onChange = (newValue: string) => {
    const newValueArray = [...selectedColumns];
    const valueIndex = newValueArray.indexOf(newValue);

    if (valueIndex === -1) {
      newValueArray.push(newValue);
    } else {
      newValueArray.splice(valueIndex, 1);
    }

    setSelectedColumns?.(newValueArray);
  };

  const filteredColumns = useMemo(() => {
    const cols = data.columns.filter((col) => selectedColumns.includes(col.id));

    return cols;
    //
    // const priority = {
    //   [TableColumnStyleType.Header]: 0,
    //   [TableColumnStyleType.Primary]: 1,
    //   [TableColumnStyleType.Secondary]: 2,
    //   undefined: 3,
    // };
    //
    // return cols.sort((a, b) => {
    //   const aPriority = priority[a.styleType ?? 'undefined'];
    //   const bPriority = priority[b.styleType ?? 'undefined'];
    //   return aPriority - bPriority;
    // });
  }, [selectedColumns, data.columns]);

  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            backgroundImage: 'none',
            backgroundColor: 'transparent',
          },
        }}
      >
        <div className={styles['dropbox-wrapper']}>
          {data.columns.map((item, index, arr) => (
            <div
              className={DROPBOX_getClassWithDivider(index, arr)}
              key={item.id}
              onClick={() => onChange(item.id)}
            >
              <div className={styles['icon-container']}>
                {isSelected(item.id) && <SvgCheckIcon />}
              </div>
              <div className={styles['center']}>{item.label}</div>
            </div>
          ))}
        </div>
      </Popover>
      <CardLayout gradient style={{ padding: 16, gap: 16 }}>
        <MetricCardHeader
          title={title ? title : ''}
          subTitle={subTitle}
          onSettingsClick={onSettingsClick}
        />

        <SortableTable
          noStyle
          data={data.rows}
          columns={filteredColumns.map((col, index) => {
            const newCol: SortableColumnsCell = { ...col, numeric: index > 0 };

            if (col.styleType === TableColumnStyleType.Primary) {
              newCol.cellStyle = { fontWeight: 'bold', fontSize: 18 };
            }

            if (col.styleType === TableColumnStyleType.Secondary) {
              newCol.cellStyle = { maxWidth: '60px' };
            }

            return newCol;
          })}
        />
      </CardLayout>
    </>
  );
}

export default TableCard;

export const DROPBOX_getClassWithDivider = (index: number, array: SqiorDropboxItem[]) => {
  if (index === array.length - 1) return styles['item-no-divider'];
  return styles['item'];
};
