import { Entity } from '@sqior/js/entity';
import { CoreEntities } from './core-definitions';
import { DomainInterface } from './domain-interface';
import { EntityModel, EntityRecord } from './entity';

/* Definition of the basic text entity */

export type TextEntity = Entity & { text: string };
export const TextEntityModel: EntityModel = { type: CoreEntities.Text, props: ['text'] };
export function makeTextEntity(text: string): TextEntity {
  return { entityType: CoreEntities.Text, text: text };
}
export async function extractText(
  domain: DomainInterface,
  entity: Entity,
  context?: EntityRecord
): Promise<string> {
  return (await domain.map<TextEntity>(entity, CoreEntities.Text, context)).text;
}
export async function extractTextOrDefault(
  domain: DomainInterface,
  entity: Entity | undefined,
  def: string,
  context?: EntityRecord
): Promise<string> {
  const textEnt = await domain.tryMap<TextEntity>(entity, CoreEntities.Text, context);
  return textEnt ? textEnt.text : def;
}

/* Definition of the console entity model */

export type ConsoleTextEntity = Entity & { text: string };
export const ConsoleEntityModel: EntityModel = { type: CoreEntities.Console, props: ['text'] };
export function createConsoleTextEntity(text: string): ConsoleTextEntity {
  return { entityType: CoreEntities.Console, text: text };
}
