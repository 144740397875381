import {
  InformProjectionVM,
  isChatMessage,
  MarkReadData,
  ReadConfirmationTypes,
} from '@sqior/viewmodels/communication';
import { ensureAndCombineArray, now } from '@sqior/js/data';
import { AddOperation } from '@sqior/js/operation';
import { OperationContext } from '@sqior/react/operation';
import {
  CountdownTimer,
  HighlightButton,
  ImageInfo,
  SIcon,
  StringToJSXMapKeys,
  SvgIconChat,
  useTextResources,
} from '@sqior/react/uibase';
import { useContext } from 'react';
import EntityVisual from '../entity-visual/entity-visual';
import { getResponseBlock, ThreadItemProps } from '../thread-item/thread-item';

import styles from './main-info-item.module.css';
import '../uiconversation.css';
import EscalationIcon from '../escalation-icon/escalation-icon';
import { CreateAndDisplayInquiry } from '@sqior/viewmodels/communication';
import { SelectPatient } from '@sqior/viewmodels/patient';
import { FactoryProps } from '@sqior/react/factory';

export type MainInfoItemProps = FactoryProps<InformProjectionVM>;

export function MainInfoItemVF(props: MainInfoItemProps) {
  return MainInfoItem({
    content: props.data,
    displayMarkRead: true,
  });
}

function MainInfoItem(props: ThreadItemProps) {
  const debugMode = false;
  const [responseBlock, responseTimeout, responseDuration] = getResponseBlock(
    props.content,
    undefined,
    false,
    false
  );

  const textDict = useTextResources();

  const dispatcher = useContext(OperationContext);

  function markRead(item: InformProjectionVM) {
    const data: MarkReadData = {
      id: ensureAndCombineArray(item.id, item.additionalIds),
      confirmationType: ReadConfirmationTypes.Explicit,
      timestamp: now(),
    };
    dispatcher.handle(new AddOperation(data), 'communication-read-confirmation');
  }

  function handleClick(e: React.MouseEvent) {
    if (props.content.related) dispatcher.start(SelectPatient(props.content.related));
  }

  function startInquiry() {
    markRead(props.content);
    dispatcher.start(CreateAndDisplayInquiry(props.content.reference));
  }

  function icon(item: InformProjectionVM) {
    if (item.icon) {
      if (item.icon === 'escalation')
        return <EscalationIcon className={styles['symbol-escalation']} />;
      else if (item.icon === 'chat') return <SvgIconChat />;
      else
        return (
          <SIcon
            icon={item.icon as StringToJSXMapKeys}
            size={45}
            alternative={<ImageInfo className={styles['symbol-info']} />}
          />
        );
    }
    // Otherwise assume info
    return <ImageInfo className={styles['symbol-info']} />;
  }

  return (
    <div className={styles['main-info-item']}>
      <div className={styles['symbol']}>
        {responseTimeout && responseDuration ? (
          <div className={styles['countdown']}>
            <CountdownTimer timeout={responseTimeout} duration={responseDuration} />
          </div>
        ) : (
          icon(props.content)
        )}
      </div>

      <div className={styles['content']} onClick={handleClick}>
        {debugMode && (
          <div>
            <EntityVisual content={props.content.sender} />
            &rarr;
            <EntityVisual content={props.content.recipient} />
          </div>
        )}

        {isChatMessage(props.content) && (
          <div className={styles['sender']}>
            <EntityVisual content={props.content.sender} />
          </div>
        )}
        {Array.isArray(props.content.message) ? (
          props.content.message.map((i, idx) => {
            return <EntityVisual key={idx} content={i} />;
          })
        ) : (
          <EntityVisual content={props.content.message} />
        )}
      </div>
      <div className={styles['response']}>
        {responseBlock}
        {props.displayMarkRead &&
          !props.content.responseOptions?.length &&
          !isChatMessage(props.content) && (
            <HighlightButton onClick={() => markRead(props.content)}>OK</HighlightButton>
          )}
        {props.content.checkBack && (
          <HighlightButton
            additionalClassName={styles['inquiry']}
            onClick={startInquiry}
            secondary={true}
          >
            {textDict.get('check_back')}
          </HighlightButton>
        )}
        {isChatMessage(props.content) && (
          <>
            <HighlightButton
              additionalClassName={styles['inquiry']}
              secondary={true}
              onClick={startInquiry}
            >
              {textDict.get('reply_to')}
            </HighlightButton>
            <HighlightButton
              additionalClassName={styles['inquiry']}
              secondary={true}
              onClick={() => markRead(props.content)}
            >
              {textDict.get('close')}
            </HighlightButton>
          </>
        )}
      </div>
    </div>
  );
}

export default MainInfoItemVF;
