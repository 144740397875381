import styles from './card-overlay-state.module.css';
import { ORWorkflowOverviewDashboardStage } from '@sqior/viewmodels/orworkflow';
import React from 'react';
import { TimePositionCalculator } from '../orworkflow-overview-dashboard/TimePositionCalculator';
import { useUIGlobalState } from '@sqior/react/state';

export interface CardOverlayStateProps {
  currentTime: number;
  itemStart: number;
  itemEnd: number;
  timeCalc: TimePositionCalculator;
  noBorderRadius?: boolean;
  zIndex?: number;
  stayActiveUtil?: number;
  visibility?: boolean;
  stage: ORWorkflowOverviewDashboardStage;
}

const Z_INDEX = 10;

export function CardOverlayState({
  itemStart,
  itemEnd,
  currentTime,
  timeCalc,
  noBorderRadius,
  zIndex,
  visibility,
  stage,
}: CardOverlayStateProps) {
  const {
    UIGlobalState: { showBackgroundMarker },
  } = useUIGlobalState();

  if (!visibility || !showBackgroundMarker) {
    return null;
  }

  if (stage === ORWorkflowOverviewDashboardStage.Normal) {
    return null;
  }

  const heightCardState = timeCalc.convertMarkerTimestampToNumber({
    markerTimeStamp: currentTime,
    itemStart,
    itemEnd,
  });

  const activeColor =
    stage === ORWorkflowOverviewDashboardStage.Surgery
      ? 'rgba(28,173,228,0.1)'
      : 'rgba(237,123,232,0.1)';
  const inactiveColor =
    stage === ORWorkflowOverviewDashboardStage.Surgery
      ? 'rgba(28,173,228,0.1)'
      : 'rgba(237,123,232,0.1)';

  const getHeight = (height: number): string => {
    if (height > 100) return '100%';
    if (height < 0) return '0%';
    return `${height}%`;
  };

  return (
    <>
      <div
        className={styles['all']}
        style={{
          borderRadius: noBorderRadius ? 0 : undefined,
          zIndex: Z_INDEX,
          backgroundColor: inactiveColor,
        }}
      />
      <div
        className={styles['container']}
        style={{
          height: getHeight(heightCardState),
          backgroundColor: activeColor,
          borderRadius: noBorderRadius ? 0 : undefined,
          zIndex: Z_INDEX,
        }}
      />
    </>
  );
}

export default CardOverlayState;
