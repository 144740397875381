import { addHours, ClockTimestamp } from '@sqior/js/data';
import { DateEntity, isDateBefore, makeDate, makeDateFromTimestamp } from './date';
import { makeTimeEntity } from './time';
import { makeTimestampFromDateTime, nextDate, relativeDate } from './timestamp';
import { DateRangeEntity, makeDateRange } from './date-range';

const DefaultDatePyramidScheme = [7, 4, 6, 4]; // 7 days, 4 weeks, approx. 6 months, 2 years

/** Class supporting disecting a date range into period of equal size on multiple levels */

export class DatePyramid {
  constructor(
    scheme: number[] = DefaultDatePyramidScheme,
    startDate: DateEntity = makeDate(31, 12, 1899) // Sunday so that week is from Sun- to Saturdays
  ) {
    this.scheme = scheme;
    this.refTimestamp = makeTimestampFromDateTime(startDate, makeTimeEntity(8, 0)).timestamp;
  }

  /** Creates an array with the specified number of zeroes */
  private static ZeroArray(length: number): number[] {
    return Array(length).fill(0);
  }

  /** Returns a date for a full period at a specified pyramid level */
  private fullPeriodAtLevel(
    input: number[],
    level: number,
    value: number
  ): DateEntity | DateRangeEntity<DateEntity> {
    if (level) {
      const pyramid = DatePyramid.ZeroArray(level)
        .concat(value)
        .concat(input.slice(level + 1));
      const start = this.fromPyramid(pyramid);
      pyramid[level] += 1;
      return makeDateRange(start, relativeDate(this.fromPyramid(pyramid), -1));
    }
    return this.fromPyramid([value].concat(input.slice(1)));
  }

  /** Checks if all values of an array are zero */
  private static hasZeroes(arr: number[], level: number): boolean {
    for (let i = 0; i < level; i++) if (arr[i]) return false;
    return true;
  }

  /** Splits a date range into full periods acc. to the pyramid */
  public splitDateRange(
    startEnt: DateEntity,
    endEnt: DateEntity
  ): (DateEntity | DateRangeEntity<DateEntity>)[] {
    if (isDateBefore(endEnt, startEnt))
      throw new Error('Date range end needs to be at or after start');
    const start = this.toPyramid(startEnt);
    const end = this.toPyramid(nextDate(endEnt));
    const res: (DateEntity | DateRangeEntity<DateEntity>)[] = [];
    let inSamePeriod = true;
    for (let i = start.length - 1; i >= 0; i--)
      if (inSamePeriod) {
        /* Add full periods */
        for (let j = start[i] + (DatePyramid.hasZeroes(start, i) ? 0 : 1); j < end[i]; j++)
          res.push(this.fullPeriodAtLevel(start, i, j));
        inSamePeriod = start[i] === end[i];
      } else {
        /* Add the components till the next full period */
        const zeroesBelow = DatePyramid.hasZeroes(start, i);
        if (start[i] || !zeroesBelow)
          for (let j = start[i] + (zeroesBelow ? 0 : 1); j < this.scheme[i]; j++)
            res.push(this.fullPeriodAtLevel(start, i, j));
        /* Add the components at the end from the full period */
        for (let j = 0; j < end[i]; j++) res.push(this.fullPeriodAtLevel(end, i, j));
      }
    res.sort((a, b) =>
      isDateBefore(
        'year' in a ? a : (a.start as DateEntity),
        'year' in b ? b : (b.start as DateEntity)
      )
        ? -1
        : 1
    );
    return res;
  }

  /** Sub-divides a date range into the components of the next hierarchy level */
  public subDivideDateRange(
    startEnt: DateEntity,
    endEnt: DateEntity
  ): (DateEntity | DateRangeEntity<DateEntity>)[] {
    const start = this.toPyramid(startEnt);
    const end = this.toPyramid(endEnt);
    /* The input needs to be a complete period acc. to the pyramid hierarchy */
    let level = 0;
    for (; level < start.length; level++)
      if (start[level] || end[level] !== this.scheme[level] - 1) break;
    /* The components from this level on need to be identical */
    for (let i = level; i < start.length; i++)
      if (start[i] !== end[i])
        throw new Error('The input to subDivideDateRange() needs to be a complete period');
    if (!level) throw new Error('The input to subDivideDateRange() must not be a single day');
    level--;
    const res: (DateEntity | DateRangeEntity<DateEntity>)[] = [];
    for (let j = 0; j < this.scheme[level]; j++) res.push(this.fullPeriodAtLevel(start, level, j));
    return res;
  }

  /** Conversion of date to pyramid */
  private toPyramid(date: DateEntity): number[] {
    /* Calculate the days since the start */
    let value = Math.floor(
      (makeTimestampFromDateTime(date, makeTimeEntity(12, 0)).timestamp - this.refTimestamp) /
        DatePyramid.DayOffset
    );
    /* Transform to pyramidal model */
    const res: number[] = [];
    for (let i = 0; i < this.scheme.length; i++) {
      res.push(value % this.scheme[i]);
      value = Math.floor(value / this.scheme[i]);
    }
    res.push(value);
    return res;
  }

  /** Conversion of pyramid to date */
  private fromPyramid(pyramid: number[]): DateEntity {
    /* Calculate the days since the start */
    let value = 0;
    for (let i = pyramid.length - 1; i >= 0; i--)
      value = (i < this.scheme.length ? value * this.scheme[i] : 0) + pyramid[i];
    /* Transform to date */
    return makeDateFromTimestamp(this.refTimestamp + value * DatePyramid.DayOffset + addHours(12));
  }

  private scheme: number[];
  private refTimestamp: ClockTimestamp;
  private static DayOffset = addHours(24);
}
