import { OperationSpec, OperationType } from '@sqior/js/operation';
import { Entity, EntityHeader } from '@sqior/js/entity';
import { ItemState } from '@sqior/viewmodels/visual';

export const PageSelectLocationType = 'page.select-location';

export const UpdateLocationPath = 'update-location';

export type LocationData = { text: string; id?: string }[];

export function hasMissingLocation(locations: LocationData) {
  return locations.length > 0 && locations[0].id === undefined;
}
export function hasLocationRole(locations: LocationData) {
  return locations.length > 0;
}
export function hasValidLocation(locations: LocationData) {
  return locations.length > 0 && locations[0].id !== undefined;
}

/* Transfer Priority Group */
export type TransferPriorityGroup = EntityHeader & {
  name: string;
};

export enum DailyStartConfigItemTypes {
  TransferPriorityGroup = 'TransferPriorityGroup',
  IndividualTime = 'IndividualTime',
  TransferLocation = 'TransferLocation',
  InductionLocation = 'InductionLocation',
}

export type DailyStartConfigItem = {
  configType: DailyStartConfigItemTypes;
  options: {
    entity: Entity;
    text: string;
    description?: string;
  }[];
  selected?: number; // undefined if not selected

  // In case of configType === DailyStartConfigItemTypes.IndividualTime
  indiviualTime?: { hours: number; minutes: number };
};

export type DailyStartConfig = {
  configs: DailyStartConfigItem[];
  enabled: boolean;
  day: number; // Day as timestamp in milliseconds representing the day to adjust
};

export type TransferPriorityGroupVM = EntityHeader & {
  label: string;
  state: ItemState;
};

export type UpdateLocationData = EntityHeader & {
  location: string;
  day: number; // Day as timestamp in milliseconds representing the day to adjust
  configType: DailyStartConfigItemTypes;
  index?: number;
  time?: { hours: number; minutes: number };
};
export function makeUpdateLocationData(
  location: string,
  day: number, // Day as timestamp in milliseconds representing the day to adjust
  configType: DailyStartConfigItemTypes,
  index: number
): UpdateLocationData {
  return {
    entityType: UpdateLocationPath,
    location: location,
    day: day,
    configType: configType,
    index: index,
  };
}

export function makeUpdateLocationIndividualTime(
  location: string,
  day: number, // Day as timestamp in milliseconds representing the day to adjust
  time: { hours: number; minutes: number }
): UpdateLocationData {
  return {
    entityType: UpdateLocationPath,
    location: location,
    day: day,
    configType: DailyStartConfigItemTypes.IndividualTime,
    time: time,
  };
}

export function UpdateLocation(data: UpdateLocationData): OperationSpec<UpdateLocationData> {
  return { type: OperationType.Add, path: UpdateLocationPath, data: data };
}

export type EnableLocationData = {
  location: string;
  enabled: boolean;
};
export function EnableDailyStart(
  location: string,
  enabled: boolean
): OperationSpec<EnableLocationData> {
  return {
    type: OperationType.Add,
    path: UpdateLocationPath,
    data: { location: location, enabled: enabled },
  };
}
