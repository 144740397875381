import { ComponentFactory } from '@sqior/react/factory';
import { useDynamicState } from '@sqior/react/state';
import { useConnectionError } from '@sqior/react/uiapp';
import { ListViewInfos } from '@sqior/viewmodels/visual';
import { useContext } from 'react';
import styles from './dashboard.module.css';
import { ZIndex } from '@sqior/react/utils';

/* eslint-disable-next-line */
export interface DashboardProps {
  className: string;
  path: string;
}

export function Dashboard(props: DashboardProps) {
  const dashboard = useDynamicState<ListViewInfos>(props.path, {
    entityType: '',
    lists: [],
  });
  const connError = useConnectionError();
  const FactoryComponent = useContext(ComponentFactory);

  return (
    <div className={`${styles['outer-container']} ${props.className}`}>
      {dashboard.entityType.length > 0 && (
        <FactoryComponent data={dashboard} statePath={props.path} />
      )}
      {connError && (
        <div className={styles['shade']} style={{ zIndex: ZIndex.DashboardErrorConnectionShade }} />
      )}
    </div>
  );
}

export default Dashboard;
