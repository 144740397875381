import styles from './sqior-list-item.module.css';
import { cloneElement } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

const ICON_SIZE = 24;

export interface SqiorListItemProps {
  title?: string;
  description?: string;
  icon?: ReactJSXElement;
  oneLiner?: boolean;
  onClick?: () => void;
}

export function SqiorListItem({ title, description, icon, oneLiner, onClick }: SqiorListItemProps) {
  return (
    <div
      className={styles['container']}
      onClick={onClick}
      style={{
        cursor: onClick ? 'pointer' : 'default',
      }}
    >
      {icon && (
        <div className={styles['icon']}>
          {cloneElement(icon, { style: { width: ICON_SIZE, height: ICON_SIZE } })}
        </div>
      )}
      <div
        className={styles['infos']}
        style={{
          color: onClick ? 'var(--fg-color-frame)' : undefined,
        }}
      >
        <div className={oneLiner ? undefined : styles['title']}>{title}</div>
        <div className={styles['description']}>{description}</div>
      </div>
    </div>
  );
}

export default SqiorListItem;
