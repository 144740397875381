import { FC } from 'react';
import Typography, { TypographyColor } from '../typography/typography';
import styles from './name.module.css';
import { ItemState } from '@sqior/viewmodels/visual';
import { StateTypographyMap } from '../ui-maps';

interface Props {
  firstName?: string;
  lastName?: string;
  state?: ItemState;
  fontSize?: number;
}

export const Name: FC<Props> = ({ firstName, lastName, state, fontSize }) => {
  if (!firstName || !lastName) {
    return <Typography color={TypographyColor.WARNING}>No name</Typography>;
  }

  const color = state ? StateTypographyMap.get(state) : undefined;

  return (
    <div className={styles['row']}>
      <Typography color={color} fontSize={fontSize} fontWeight={600}>
        {lastName}
      </Typography>
      <Typography color={color} fontSize={fontSize} fontWeight={600}>
        {','}
      </Typography>
      <Typography color={color} fontSize={fontSize} fontWeight={600}>
        &nbsp;
      </Typography>
      <Typography color={color} fontSize={fontSize}>
        {firstName}
      </Typography>
    </div>
  );
};

export default Name;
