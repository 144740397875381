export class TimedPromise {
  constructor(delayMS: number) {
    this.promise = new Promise<void>((res) => {
      setTimeout(() => {
        res();
      }, delayMS);
    });
  }

  readonly promise: Promise<void>;
}
