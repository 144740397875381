import { Icons } from '@sqior/viewmodels/visual';
import { TrendWithValue, ValueWithUnit } from './card-types';

export type TimeRange = {
  id: string;
  label: string;
  start: number;
  end: number;
  index: number;
};

export type MetricChartEntry = {
  timestamp: number;
  value?: number;
};

export type MetricChartTrend = {
  percentage: number;
  hasIncreased: boolean;
  text: string;
};

export type ChartMeta = {
  /** If not undefined, show an average line */
  avg?: number;
  /** If not undefined, use the specified value as minimum scale instead of automatic scale */
  minY?: number;
  /** If not undefined, use the specified value as maximum scale instead of automatic scale */
  maxY?: number;
};

/** Describes time series data
 */
export type MetricCardStats = {
  /** Unit to display; all value are expected to be in that unit */
  unit: string;
  chart: MetricChartEntry[];
  chartMeta?: ChartMeta;
  avg: number;
  current?: number;
  trend?: MetricChartTrend;
};

export type ScalarStats = {
  mean: number;
  stddev: number;
  count: number;
};

/** Describes scalar statistics (avg, stddev, ...) per group
 */
export type ScalarStatsPerGroupChart = {
  /** Unit to display; all value are expected to be in that unit */
  unit: string;
  data: {
    key: string;
    name: string;
    statistics?: ScalarStats;
  }[];
  totals: ScalarStats;
};

/** Describes histogram data
 */
export type IntervalHistogramData = {
  count: number;
  interval: [number, number];
  label?: string;
};
export type IntervalHistogramChart = {
  data: IntervalHistogramData[];
};

export type SliderVM = {
  min: number;
  max: number;
  value: number;
};

/** Describes a table */
export enum TableColumnStyleType {
  Primary = 'primary',
  Secondary = 'secondary',
  Header = 'header',
}
export type TableColumnData = {
  id: string;
  label: string;
  description?: string;
  hidden?: boolean; // if true, this columns shall be hidden per default, but the user can enable its visibility

  styleType?: TableColumnStyleType;
};
export type TableData = {
  columns: TableColumnData[];
  rows: Record<string, string | number>[];
};

/** Pie chart card */

export type PieCardPieData = { value: number; label: string };
export type PieCardData = { total: number; pies: PieCardPieData[] };

/** Bar chart card */

export type BarCardBarData = { value: number; label: string };
export type BarCardData = { total: number; bars: BarCardBarData[] };
