import { AsyncIterator } from '@sqior/js/async';
import { LogicalTimestamp, PureObject, ValueObject } from '@sqior/js/data';
import { UID } from '@sqior/js/uid';
import { FindResult, SearchOptions, SearchParam } from './crud-interface';

export class UniqueIndexDatabaseError extends Error {
  constructor(msg: string) {
    super(msg);
  }
}

export interface CollectionInterface {
  /* Adds a new object */
  add<Type extends ValueObject>(obj: Type): Promise<[UID, LogicalTimestamp]>;
  /* Adds a set of new objects preserving the insertion order */
  addMany<Type extends ValueObject>(objs: Type[]): Promise<[UID[], LogicalTimestamp]>;
  /* Adds a new sequenced object if it does not exist already */
  addUnique<Type extends ValueObject>(
    param: SearchParam,
    obj: Type
  ): Promise<[UID, LogicalTimestamp] | undefined>;

  /* Replaces an existing object identified by ID or filter, if the object is not found, a new one is added */
  set<Type extends ValueObject>(param: SearchParam, obj: Type): Promise<[UID, LogicalTimestamp]>;
  /* Updates properties of an existing object identified by ID for filter, if the object is not found, an exception is thrown */
  update(
    param: SearchParam,
    props: ValueObject,
    partial?: PureObject
  ): Promise<LogicalTimestamp | undefined>;

  /* Deletes an existing object identified by ID or filter */
  deleteOne(param: SearchParam): Promise<LogicalTimestamp | undefined>;
  /* Deletes all objects matching the filter */
  delete(param: ValueObject): Promise<LogicalTimestamp>;

  /* Finds one object identified by ID or filter */
  findOne<Type extends ValueObject>(param: SearchParam): Promise<FindResult<Type> | undefined>;
  /* Finds all objects matching the filter with an optional ordering */
  find<Type extends ValueObject>(
    param: ValueObject,
    options?: SearchOptions
  ): Promise<AsyncIterator<FindResult<Type>>>;
}
