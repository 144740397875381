import { PatientInfo } from '@sqior/viewmodels/patient';
import styles from './patient-header.module.css';
import { PageHeader } from '@sqior/react/uiadvanced';
import { Cave, InterweaveExt, SIcon, SpecialtyView } from '@sqior/react/uibase';
import { PersonSex } from '@sqior/viewmodels/person';
import { Icons } from '@sqior/viewmodels/visual';

export function PatientHeader(props: PatientInfo) {
  const { sex, name, dob, age, id, caseId, caveSeverity, specialty } = props;

  return (
    <PageHeader
      metaStyle={{ gap: '0.5rem' }}
      title={
        <div className={styles['row']}>
          <InterweaveExt content={name} className={styles['name']} />{' '}
          {sex && <PatientSex white sex={sex} />}
          {age && <div className={styles['age']}>{age}</div>}
          <div className={styles['cave']}>
            {caveSeverity ? <Cave severity={caveSeverity}></Cave> : ''}
          </div>
        </div>
      }
      subTitle={
        <div className={styles['sub-meta-container']}>
          {dob && (
            <div className={styles['dob-info']}>
              <div>*{dob}</div>
            </div>
          )}
          {id && (
            <div className={styles['icon-info']}>
              <div className={styles['icon-id']}>
                <SIcon icon={Icons.Work} />
              </div>

              <div>{id}</div>
            </div>
          )}
          {caseId && (
            <div className={styles['icon-info']}>
              <div className={styles['icon-id']}>
                <SIcon icon={Icons.Case} />
              </div>
              <div>{caseId}</div>
            </div>
          )}
          <SpecialtyView specialty={specialty} />
        </div>
      }
    />
  );
}

export type PatientSexProps = {
  sex: PersonSex;
  white?: boolean;
};

export function PatientSex(props: PatientSexProps) {
  return (
    <div className={styles['sex-info']}>
      {props.sex === PersonSex.Male && <SIcon icon={Icons.Male} />}
      {props.sex === PersonSex.Female && <SIcon icon={Icons.Female} />}
      {props.sex === PersonSex.Diverse && <SIcon icon={Icons.Unisex} />}
    </div>
  );
}

export default PatientHeader;
