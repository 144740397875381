import { Entity } from '@sqior/js/entity';
import { CoreEntities, EntityModel, Interface } from '@sqior/js/meta';

/* Entities */

export enum LanguageEntities {
  TextTemplate = 'TextTemplate',
  TextResource = 'TextResource',
  Gender = 'Gender',
  AnonymizedText = 'AnonymizedText',
  Verbosity = 'Verbosity',
  Case = 'GrammaticalCase',
  Texts = 'Texts',
  AlternativeTexts = 'AlternativeTexts',
  ConcatenationTexts = 'ConcatenationTexts',
  AnonymizationLevel = 'AnonymizationLevel', // Level of anonymization to apply
  Preposition = 'Preposition', // Preposition type
}

/* Interfaces */

export enum LanguageInterfaces {
  Anonymized = 'AnonymizeText', // Anonymized text version of an entity
  PlainIdText = 'PlainIdText', // Returns the plain text of an identifier and not a nicely human readable text
  TextLike = 'TextLike', // Returns a text like entity (one that conforms to the TextEntity type) and be converted to text
}

/* Context properties */

export const AnonymizationContextProperty = 'anonymize';
export const GenderContextProperty = 'gender';
export const GrammaticalCaseContextProperty = 'grammaticalCase';
export const VerbosityContextProperty = 'verbosity';

/* Entity representing a list of texts */

export type TextsEntity = Entity & { texts: Entity[] };
export const TextsModel: EntityModel = { type: LanguageEntities.Texts, props: ['texts'] };
export function makeTexts(texts: Entity[], type: string = LanguageEntities.Texts): TextsEntity {
  return { entityType: type, texts: texts };
}

/** Entity representing a list of texts that are catenated using 'or' instead of 'and' */

export const AlternativeTextsModel: EntityModel = {
  type: LanguageEntities.AlternativeTexts,
  props: ['texts'],
};

export type ConcatenationTextsEntity = TextsEntity & { separator?: string | Entity };
export function makeConcatenationTexts(texts: Entity[], separator?: string | Entity) {
  const ret: ConcatenationTextsEntity = {
    entityType: LanguageEntities.ConcatenationTexts,
    texts: texts,
  };
  if (separator !== undefined) ret.separator = separator;
  return ret;
}
export const ConcatenationTextsModel: EntityModel = {
  type: LanguageEntities.ConcatenationTexts,
  props: ['texts', 'separator'],
};

/* Interface to get access to the plain patient id text */

export const PlainIdTextModel: Interface = {
  type: LanguageInterfaces.PlainIdText,
  requires: CoreEntities.Text,
};

/** Interface returning a text like entity (one that conforms to the TextEntity type) and be converted to text */

export const TextLikeModel: Interface = {
  type: LanguageInterfaces.TextLike,
  represents: CoreEntities.Text,
};
