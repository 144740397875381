import { Statistics } from './statistics';
import { SumCount, SumCountValue } from './sum-count';
import { combineSeries } from './timeseries-utils';

export const SeriesNoValue = 'noValue';
export type SeriesType = number | 'noValue';

export class SumCountSeriesHelper {
  static addValue(sc: SumCountValue, series: SeriesType[], value: number | undefined) {
    if (value !== undefined) {
      SumCount.add(sc, value);
      series.push(value);
    } else {
      series.push(SeriesNoValue);
    }
  }

  static precisionFromSeries(
    delta: SumCountValue[] | { deltaPos: SumCountValue[]; deltaNeg: SumCountValue[] },
    reference: SumCountValue[],
    decimals?: number
  ) {
    let deltaSum: number;
    if ('deltaPos' in delta) {
      const absDelta = combineSeries<SumCountValue, number>(
        [delta.deltaPos, delta.deltaNeg],
        ([p, n]) => p.sum + n.sum
      );
      deltaSum = Statistics.Sum(absDelta);
    } else {
      deltaSum = Statistics.Sum(delta.map((s) => s.sum));
    }
    const targetSum = Statistics.Sum(reference.map((s) => s.sum));
    const precision = (100 * deltaSum) / targetSum;
    return decimals === undefined ? precision : Statistics.Round(precision, decimals);
  }
}

export function sumSeries(series: SeriesType[]): SeriesType {
  return series.reduce((acc: number, curr: SeriesType) => {
    if (typeof curr === 'number') {
      return acc + curr;
    }
    return acc;
  }, 0);
}

export function meanSeries(series: SeriesType[]): SeriesType {
  const { sum, count } = series.reduce(
    (acc, curr: SeriesType) => {
      if (typeof curr === 'number') {
        acc.sum += curr;
        acc.count++;
      }
      return acc;
    },
    { sum: 0, count: 0 }
  );
  return count > 0 ? sum / count : 0;
}
