import styles from './sqior-dropbox.module.css';
import { SqiorDropboxItem } from './sqior-dropbox';

export const DROPBOX_ICON_COLOR = 'rgb(174, 176, 189)';
export const DROPBOX_ICON_DROPDOWN_COLOR = '#51535E';
export const DROPBOX_ANIMATION = {
  initial: {
    opacity: 0,
    scale: 0.95,
    y: -10,
  },
  animate: {
    opacity: 1,
    scale: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    y: -10,
  },
};

export const DROPBOX_getClassWithDivider = (index: number, array: SqiorDropboxItem[]) => {
  if (index === array.length - 1) return styles['item-no-divider'];
  return styles['item'];
};
