import { Entity } from '@sqior/js/entity';
import { DomainInterface } from '@sqior/js/meta';
import { StateHandlerFactory, SwitchStateHandler } from '@sqior/js/state-operation';
import { VisualEntities } from './visual-definitions';
import { Closable } from '@sqior/js/async';
import { StateHandlerType } from './state-handler-type';

/** Class mapping a source entity to a state handler type and automatically switching to this type */
export class MappedSwitchStateHandler extends SwitchStateHandler {
  constructor(
    factory: StateHandlerFactory,
    domain: DomainInterface,
    source: Entity,
    target: string = VisualEntities.StateHandlerType
  ) {
    super(factory);
    /* Observe the mapping and switch to the type, if applicable */
    this.typeObs = domain.currentMappedEntity<StateHandlerType>(source, target, async (sht) => {
      if (sht) this.set(sht.type);
      else this.reset();
    });
  }
  override async close() {
    /* Stop type observer */
    await this.typeObs.close();
    /* Call base class */
    await super.close();
  }

  private typeObs: Closable;
}
