import { EntityHeader } from '@sqior/js/entity';
import { OperationSpec, OperationType } from '@sqior/js/operation';
import { joinPath } from '@sqior/js/url';
import { SelectTimeRange } from '@sqior/viewmodels/input';
import { AnalyticsDashboardVM } from './dashboard-vm';
import { AnalyticsDashboardStatePath } from './paths';
import {
  WorkflowAnalyticsDashboardOpSubPaths,
  WorkflowAnalyticsDashboardStateOperationData,
} from './workflow-analytics-dashboard';

export const ORWorkflowAnalyticsDashboard = 'ORWorkflowAnalyticsDashboard';
export const ORWorkflowAnalyticsDashboardOpPath = 'ORWorkflowAnalyticsDashboard';

export type ORWorkflowAnalyticsDashboardVM = EntityHeader & {
  // new stuff to add
};

export function ORWorkflowAnalyticsDashboardOperation(
  data: WorkflowAnalyticsDashboardStateOperationData
): OperationSpec<WorkflowAnalyticsDashboardStateOperationData> {
  return { type: OperationType.Add, path: AnalyticsDashboardStatePath, data };
}

export function ORWorkflowAnalyticsDashboardSelectEdge(id: string[]): OperationSpec<string[]> {
  return {
    type: OperationType.Add,
    path: joinPath(AnalyticsDashboardStatePath, WorkflowAnalyticsDashboardOpSubPaths.SelectEdge),
    data: id,
  };
}

export function ORWorkflowAnalyticsDashboardSelectNode(id: string[]): OperationSpec<string[]> {
  return {
    type: OperationType.Add,
    path: joinPath(AnalyticsDashboardStatePath, WorkflowAnalyticsDashboardOpSubPaths.SelectNode),
    data: id,
  };
}

export function ORWorkflowAnalyticsDashboardTimeRange(
  select: string | { start?: number; end?: number }
) {
  return SelectTimeRange(
    joinPath(AnalyticsDashboardStatePath, WorkflowAnalyticsDashboardOpSubPaths.SelectTimeRange),
    select
  );
}

export function ORWorkflowAnalyticsDashboardSelectSpecialty(id: string): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(
      AnalyticsDashboardStatePath,
      WorkflowAnalyticsDashboardOpSubPaths.SelectSpecialty
    ),
    data: id,
  };
}

export function ORWorkflowAnalyticsDashboardSelectFilter(id: string): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(AnalyticsDashboardStatePath, WorkflowAnalyticsDashboardOpSubPaths.SelectFilter),
    data: id,
  };
}
export function ORWorkflowAnalyticsDashboardSelectRoom(id: string): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(AnalyticsDashboardStatePath, WorkflowAnalyticsDashboardOpSubPaths.SelectRoom),
    data: id,
  };
}
export function ORWorkflowAnalyticsDashboardSelectIncident(id: string): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(
      AnalyticsDashboardStatePath,
      WorkflowAnalyticsDashboardOpSubPaths.SelectIncident
    ),
    data: id,
  };
}
export function ORWorkflowAnalyticsDashboardSelectDashboard(id: string): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(
      AnalyticsDashboardStatePath,
      WorkflowAnalyticsDashboardOpSubPaths.SelectDashboard
    ),
    data: id,
  };
}

export const ORWorkflowAnalyticsPerformanceSubPath = 'perf';

export type Breadcrumb = {
  label: string;
  select?: OperationSpec;
};
export type ORWorkflowAnalyticsPerformanceVM = {
  headerNavigation: Breadcrumb[];
  dashboard?: AnalyticsDashboardVM;
};
