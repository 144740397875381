import { Closable } from '@sqior/js/async';
import { Domain } from '@sqior/js/meta';
import { DataResult, StdOperationHandler } from '@sqior/js/operation';
import { State } from '@sqior/js/state';
import { makeTimeEntity } from './time';
import { TimeEntities, TimeInterfaces } from './time-definitions';
import { DateEntity } from './date';
import { daysSince, relativeDate } from './timestamp';
import { NoTimestamp } from '@sqior/js/data';
import { SelectedDateNextDaySubPath, SelectedDatePrevDaySubPath } from '@sqior/viewmodels/time';

/** State and handler allow to select date */

export class SelectedDateStateHandler extends StdOperationHandler implements Closable {
  constructor(domain: Domain, fromOffset: number, toOffset: number) {
    super();
    this.fromOffset = fromOffset;
    this.toOffset = toOffset;

    this.dateObs = domain.currentMappedEntity<DateEntity>(
      makeTimeEntity(0, 0),
      TimeInterfaces.DailyTime,
      async (dateEnt) => {
        if (!dateEnt) return;
        /* Check if the offset is 0, in this case follow the change, otherwise keep the date by adjusting the offset */
        if (this.date && this.offset) {
          this.offset -= daysSince(dateEnt, this.date);
          if (this.offset < this.fromOffset) this.offset = this.fromOffset;
          else if (this.offset > this.toOffset) this.offset = this.toOffset;
        }
        this.date = dateEnt;
        this.setState();
      }
    );
  }

  /** Handles the operations */
  override async add(data: string, path: string): Promise<DataResult> {
    if (path == SelectedDateNextDaySubPath) {
      if (this.offset < this.toOffset && this.date) {
        this.offset++;
        this.setState();
      }
      return ['', NoTimestamp];
    }
    if (path == SelectedDatePrevDaySubPath) {
      if (this.offset > this.fromOffset && this.date) {
        this.offset--;
        this.setState();
      }
      return ['', NoTimestamp];
    }
    throw new Error('Unknown sub-path provided to selected date handler: ' + path);
  }

  /** Sets the state */
  private setState() {
    if (!this.date) return;
    this.state.set({
      entityType: TimeEntities.DateSelection,
      date: relativeDate(this.date, this.offset),
      prevDate: this.offset > this.fromOffset,
      nextDate: this.offset < this.toOffset,
    });
  }

  /** Shuts down the services */
  close() {
    return this.dateObs.close();
  }

  readonly state = new State();
  private date?: DateEntity;
  private offset = 0;
  private fromOffset: number;
  private toOffset: number;

  private dateObs: Closable;
}
