import styles from './actions-context-menu.module.css';
import { List, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import { SIcon, StringToJSXMapKeys } from '../sicon/sicon';
import React, { SetStateAction, useContext } from 'react';
import SContextMenu, { SContextAnchorEl } from '../scontext-menu/scontext-menu';
import { Icons } from '@sqior/viewmodels/visual';
import { OperationSpec } from '@sqior/js/operation';
import { OperationContext } from '@sqior/react/operation';

export type ContextOption = {
  id: string;
  icon: Icons;
  label: string;
  action: OperationSpec;
  callback?: () => void;
  disabled?: boolean;
};

export interface ActionsContextMenuProps {
  anchorEl: SContextAnchorEl | null;
  setAnchorEl: React.Dispatch<SetStateAction<SContextAnchorEl | null>>;
  options: ContextOption[];
  onActionClick?: (action: OperationSpec) => void;
}

export function ActionsContextMenu({
  anchorEl,
  setAnchorEl,
  options,
  onActionClick,
}: ActionsContextMenuProps) {
  const dispatcher = useContext(OperationContext);

  const onLocaleActionClick = (item: ContextOption) => {
    if (item.callback) {
      item.callback();
      onClose();
      return;
    }

    if (onActionClick) {
      onActionClick(item.action);
      onClose();
      return;
    }
    dispatcher.start(item.action);
    onClose();
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const getFilteredOptions = () => {
    if (!options) {
      return [];
    }
    if (options.length === 1 && options[0].disabled) {
      return [];
    }

    return options;
  };

  return (
    <SContextMenu anchorEl={anchorEl} onClose={onClose}>
      <List>
        {getFilteredOptions().map((item) => (
          <ListItem disablePadding key={item.id}>
            <ListItemButton disabled={item.disabled} onClick={() => onLocaleActionClick(item)}>
              <ListItemIcon>
                <SIcon icon={item.icon as StringToJSXMapKeys} size={30} />
              </ListItemIcon>
              <div className={styles['text']}>{item.label}</div>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </SContextMenu>
  );
}

export default ActionsContextMenu;

export const handleOnContextMenu = (
  event: React.MouseEvent,
  setAnchorEl: React.Dispatch<SetStateAction<SContextAnchorEl | null>>
) => {
  event.preventDefault();
  event.stopPropagation();

  const position = {
    getBoundingClientRect: () => new DOMRect(event.clientX, event.clientY, 0, 0),
  };
  setAnchorEl({
    position,
    value: event.currentTarget,
  });
};
