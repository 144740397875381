import { FC } from 'react';
import styles from './ProcedureNode.module.css';
import { NodeProps } from 'reactflow';
import { ProcedureWrapper } from '@sqior/react/uiorworkflow';
import fakeProcedure from './fakeProcedure';
import { BACKGROUND_HEIGHT } from '../BackgroundNode/BackgroundNode';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const ProcedureNode: FC<NodeProps<Props>> = ({}) => {
  return (
    <div
      style={{
        height: BACKGROUND_HEIGHT,
        width: 230,
      }}
    >
      <ProcedureWrapper {...fakeProcedure} noTime />
    </div>
  );
};

export default ProcedureNode;
