/* Entities */

import { EntityHeader, IdEntity } from '@sqior/js/entity';
import { EntityModel, Interface } from '@sqior/js/meta';

export enum VisualEntities {
  EntityHTML = 'EntityHTML',
  HTML = 'HTML',
  HTMLTemplate = 'HTMLTemplate',
  Overview = 'Overview',
  List = 'ListView',
  ListRoots = 'ListViewRoots',
  ListInfo = 'ListViewInfo',
  ListInfos = 'ListViewInfos',
  ItemState = 'ItemState',
  URL = 'URL', // URL for accessing an object
  ColorCode = 'ColorCode', // String representing an abstract color
  StateHandlerType = 'StateHandlerType', // State handler type name found in factory
  Attachments = 'AttachmentsContainer', // Container for attachments
  TrafficLight = 'TrafficLight', // States of a traffic light
  AnonymousHTML = 'AnonymousHTML', // HTML text not containing classified data
  SessionId = 'SessionId', // Generic ID which is session specific
  URLTemplate = 'URLTemplate', // Template URL with separate ID parameters
  Icon = 'VisualIcon', // And abstraction of an icon
  VisibilityHint = 'VisibilityHint', // Hint for visibility of an header item
  RemoveBoldStart = 'RemoveBoldStart', // Wrapper removing the boldness of the start of the HTML text
  SearchKeyGenerationMode = 'SearchKeyGenerationMode',
}

/* Interfaces */

export enum VisualInterfaces {
  Title = 'Title',
  Dashboard = 'Dashboard',
  IsChallenged = 'IsChallenged', // Checks whether there are challenges associated with the source entity, so that e.g. a warning indiciator shall be displayed
  Attachments = 'Attachments', // Interface to get attachments
  AnonymizedHTML = 'AnonymizedHTML', // Interface returning anonymous HTML for an entity
  AnonymizedEntityHTML = 'AnonymizedEntityHTML', // Interface returning anonymous entity HTML
  ViewModel = 'ViewModel', // Interface returning a view model representation of an entity
  ViewResult = 'ViewResult', // Interface returning whether an entity qualifies as a result from a view
  ListRoot = 'ListViewRoot', // Interface demarking entities that can serve as a list view root

  // Message Visuals
  MessageIcon = 'MessageIcon',
  MessageTitle = 'MessageTitle',
  MessageBody = 'MessageBody',
  MessageBodyHTML = 'MessageBodyHTML',
  RichMessage = 'RhichMessage',
  EmojiIcon = 'EmojiIcon',
}

export const AttachmentModel: Interface = {
  type: VisualInterfaces.Attachments,
  represents: [VisualEntities.Attachments],
};

/** Interface returning a view model representation */

export const ViewModelInterface: Interface = { type: VisualInterfaces.ViewModel };

/** Session specific ID */

export const SessionIdModel: EntityModel = {
  type: VisualEntities.SessionId,
  props: ['id'],
  keys: ['id'],
  unclassified: true,
};
export function makeSessionId(id: string): IdEntity {
  return { entityType: SessionIdModel.type, id };
}

/** Interface returning whether an entity is a valid view result */

export const ViewResultInterface: Interface = { type: VisualInterfaces.ViewResult };

export enum VisibilityHints {
  AlwaysVisible = 'AlwaysVisible',
  OptionalVisible = 'OptionalVisible',
  NotVisible = 'NotVisible',
}
export type VisibilityHintEntity = EntityHeader & { visibility: VisibilityHints };
export function makeVisibilityHint(visibility: VisibilityHints): VisibilityHintEntity {
  return { entityType: VisualEntities.VisibilityHint, visibility };
}
export const VisibilityHintModel: EntityModel = {
  type: VisualEntities.VisibilityHint,
  props: ['visibility'],
};
export function isVisible(visibilyEntity: VisibilityHintEntity | undefined): boolean {
  return visibilyEntity !== undefined && visibilyEntity.visibility !== VisibilityHints.NotVisible;
}
