import {
  DomainInterface,
  EntityModel,
  Interface,
  makeId,
  RelatedDataDomain,
  RelatedDataLibraryItem,
  substituteParameters,
  SubstituteParametersUnknownHandling,
  TextEntity,
} from '@sqior/js/meta';
import {
  DynamicMessageAnchor,
  DynamicMessageEntities,
  DynamicMessageEntity,
  DynamicMessageIcon,
  DynamicMessageInterfaces,
  DynamicMessageParameters,
  DynamicMessageParametersEntity,
  DynamicMessageText,
  DynamicMessageTitle,
  makeDynamicMessageId,
  makeDynamicMessageParameters,
} from './dynamic-message-definitions';
import { VisualInterfaces } from './visual-definitions';
import { EntityHeader, EntityRecord, IdEntity } from '@sqior/js/entity';
import { Logger } from '@sqior/js/log';
import { DynamicMessageConfigVM } from '@sqior/viewmodels/visual';
import { makeAnonymizedText, makeTextTemplate } from '@sqior/plugins/language';
import { IconEntity, makeIcon } from './icon-entity';

/** Models */

const IdModel: EntityModel = {
  type: DynamicMessageEntities.Id,
  props: ['id'],
  keys: ['id'],
  unclassified: true,
};

const MessageModel: EntityModel = {
  type: DynamicMessageEntities.Message,
  props: ['key', 'params'],
  unclassified: true,
};

const MessageParametersModel: EntityModel = {
  type: DynamicMessageEntities.Parameters,
  props: ['params'],
  unclassified: true,
};

const MessageParameterSpecModel: EntityModel = {
  type: DynamicMessageEntities.ParameterSpec,
  props: ['key', 'template'],
  unclassified: true,
};

const ParameterViewSpec: Interface = {
  type: DynamicMessageInterfaces.ViewSpec,
};

/** Configuration */

type LibraryItem = EntityHeader & RelatedDataLibraryItem;
const LibraryItemModel: EntityModel = {
  type: DynamicMessageEntities.LibraryItem,
  props: ['anchor', 'data', 'icon'],
};

type ConfigItem = EntityHeader & DynamicMessageConfigVM;
const ConfigItemModel: EntityModel = {
  type: DynamicMessageEntities.ConfigItem,
  props: ['id', 'title', 'text', 'params', 'icon'],
};

/** Related data domain for dynamic message information */

export class DynamicMessageDomain extends RelatedDataDomain {
  constructor() {
    super(DynamicMessageAnchor, {
      entities: [
        IdModel,
        MessageModel,
        MessageParametersModel,
        MessageParameterSpecModel,
        LibraryItemModel,
        ConfigItemModel,
      ],
      interfaces: [ParameterViewSpec],
      relatedData: [
        DynamicMessageTitle,
        DynamicMessageText,
        DynamicMessageParameters,
        DynamicMessageIcon,
      ],
    });

    /* ID */
    this.addTrivialMapping(DynamicMessageEntities.Id, DynamicMessageInterfaces.Key);

    /* Message */
    this.addTrivialMapping(DynamicMessageEntities.Message, VisualInterfaces.RichMessage);

    this.addEntityMapping<DynamicMessageEntity>(
      DynamicMessageEntities.Message,
      VisualInterfaces.MessageTitle,
      (msg, mapper) => {
        return mapper.tryMap(msg.key, DynamicMessageTitle.valueType);
      }
    );

    this.addEntityMapping<DynamicMessageEntity>(
      DynamicMessageEntities.Message,
      VisualInterfaces.MessageBody,
      async (msg, mapper) => {
        const body = await mapper.tryMap(msg.key, DynamicMessageText.valueType);
        return body
          ? msg.params
            ? makeTextTemplate(body, await DynamicMessageDomain.prepareParams(mapper, msg))
            : body
          : undefined;
      }
    );

    this.addTrivialMapping(DynamicMessageIcon.valueType, VisualInterfaces.MessageIcon);

    /* Configuration */
    this.addEntityMapping<LibraryItem>(
      DynamicMessageEntities.LibraryItem,
      DynamicMessageEntities.ConfigItem,
      async (libItem, mapper) => {
        const params: Record<string, string> = {};
        const res: ConfigItem = {
          entityType: DynamicMessageEntities.ConfigItem,
          id: (await mapper.map<IdEntity>(libItem.anchor, DynamicMessageEntities.Id)).id,
          params,
        };
        const title = libItem.data[DynamicMessageTitle.prop] as TextEntity;
        if (title) res.title = title.text;
        const textEnt = libItem.data[DynamicMessageText.prop] as TextEntity;
        if (textEnt) res.text = textEnt.text;
        const paramsEnt = libItem.data[
          DynamicMessageParameters.prop
        ] as DynamicMessageParametersEntity;
        for (const key in paramsEnt.params) params[key] = mapper.makeKey(paramsEnt.params[key]);
        /* Icon */
        const iconEnt = libItem.data[DynamicMessageIcon.prop] as IconEntity;
        if (iconEnt) res.icon = iconEnt.icon;
        return res;
      }
    );
    this.addBasicMapping<ConfigItem>(
      DynamicMessageEntities.ConfigItem,
      DynamicMessageEntities.LibraryItem,
      (libItem) => {
        const res: LibraryItem = {
          entityType: DynamicMessageEntities.LibraryItem,
          anchor: makeDynamicMessageId(libItem.id),
          data: {},
        };
        if (libItem.title) res.data[DynamicMessageTitle.prop] = makeAnonymizedText(libItem.title);
        if (libItem.text) res.data[DynamicMessageText.prop] = makeAnonymizedText(libItem.text);
        const params: EntityRecord = {};
        if (libItem.params)
          for (const key in libItem.params) {
            const parts = libItem.params[key].split('|');
            if (parts.length === 2) params[key] = makeId(parts[1], parts[0]);
          }
        res.data[DynamicMessageParameters.prop] = makeDynamicMessageParameters(params);
        if (libItem.icon) res.data[DynamicMessageIcon.prop] = makeIcon(libItem.icon);
        return res;
      },
      2
    );
  }

  /** Prepares the message parameters for use */
  private static async prepareParams(
    mapper: DomainInterface,
    msg: DynamicMessageEntity
  ): Promise<EntityRecord> {
    const paramsEnt = await mapper.tryMap<DynamicMessageParametersEntity>(
      msg.key,
      DynamicMessageParameters.valueType
    );
    if (!paramsEnt) {
      Logger.warn(['Dynamic message found with parameters but no specification:', msg.key]);
      return {};
    }
    /* Loop for all specified parameters */
    const res: EntityRecord = {};
    for (const key in paramsEnt.params) {
      const param = paramsEnt.params[key];
      /* Get view parameter specification */
      const viewSpec = await mapper.tryMap(param, DynamicMessageInterfaces.ViewSpec);
      if (!viewSpec) {
        Logger.warn([
          'Dynamic message parameter found but no view specification:',
          msg.key,
          key,
          param,
        ]);
        continue;
      }
      /* Map parameter if found */
      const subst = substituteParameters(
        viewSpec,
        msg.params ?? {},
        SubstituteParametersUnknownHandling.Substitute
      );
      if (!subst) continue;
      const mapped = await mapper.tryEvaluate(subst);
      if (mapped) res[key] = mapped;
      else
        Logger.warn([
          'Dynamic message parameter could not be mapped to view specification:',
          msg.key,
          key,
          param,
          viewSpec,
        ]);
    }
    return res;
  }
}
