import { Icon } from '@iconify/react';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import HailIcon from '@mui/icons-material/EmojiPeople';
import { PatientTransportIndicatorState } from '@sqior/viewmodels/patient';
import { FC } from 'react';
import styles from './transport-state.module.css';
import SIcon from '../sicon/sicon';
import { Icons } from '@sqior/viewmodels/visual';

export interface TransportStateProps {
  state?: PatientTransportIndicatorState;
}

const LIGHT_GRAY = '#d7d8d8';
const WARNING = '#f9c80e';

const StartIcon = () => (
  <div className={styles['stopped-container']}>
    <div className={styles['circle-start']}>
      <HailIcon style={{ color: LIGHT_GRAY, fontSize: 24 }} />
    </div>
  </div>
);
interface RunningIconProps {
  iconColor?: string;
}

const RunningIcon: FC<RunningIconProps> = ({ iconColor = LIGHT_GRAY }) => (
  <div className={styles['running-container']}>
    <div className={styles['shiny']}>
      <div className={styles['circle']} style={{ width: 40, height: 40 }}>
        <div className={styles['circle-content']}>
          <div className={styles['rect']}>
            <DirectionsWalkIcon style={{ color: iconColor, fontSize: 24 }} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

const StoppedIcon = () => (
  <div className={styles['stopped-container']}>
    <div
      className={styles['circle-stopped']}
      style={{
        position: 'relative',
        right: 2,
        top: -3,
      }}
    >
      <div className={styles['rect']}>
        <DirectionsWalkIcon style={{ color: WARNING, fontSize: 24 }} />
      </div>
    </div>
    <div className={styles['close']}>
      <Icon icon="carbon:stop-sign" style={{ width: '100%', height: '100%' }} color={WARNING} />
    </div>
  </div>
);

const DoneIcon = () => (
  <div className={styles['stopped-container']}>
    <div className={styles['circle-start']}>
      <div className={styles['rect']}>
        <SIcon icon={Icons.TransportDone} />
      </div>
    </div>
  </div>
);
export const TransportState: FC<TransportStateProps> = ({ state }) => {
  const renderState = () => {
    switch (state) {
      case PatientTransportIndicatorState.Ordered:
        return <StartIcon />;
      case PatientTransportIndicatorState.Running:
        return <RunningIcon />;
      case PatientTransportIndicatorState.Cancelled:
        return <StoppedIcon />;
      case PatientTransportIndicatorState.Done:
        return <DoneIcon />;
      default:
        return null;
    }
  };

  return <div className={styles['container']}>{renderState()}</div>;
};

export default TransportState;
