import { EntityHeader } from '@sqior/js/entity';
import { VisualEntities } from './visual-definitions';
import { EntityModel } from '@sqior/js/meta';

export type IconEntity = EntityHeader & { icon: string };
export function makeIcon(icon: string): IconEntity {
  return { entityType: VisualEntities.Icon, icon };
}
export const IconModel: EntityModel = {
  type: VisualEntities.Icon,
  props: ['icon'],
};
