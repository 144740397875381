import { FactoryProps } from '@sqior/react/factory';
import styles from './qrscanner-input.module.css';
import { QRScannerInput as QRScannerInputType, SelectionEntities } from '@sqior/viewmodels/input';
import { SelectionAreaProps } from '../selection-area';
import { FacingMode, QRScannerControl } from '@sqior/react/qrcode';

export type QRScannerInputProps = FactoryProps<QRScannerInputType> & SelectionAreaProps;

export function QRScannerInput(props: QRScannerInputProps) {
  function onQRCode(code: string) {
    props.onSelection?.({
      entity: { entityType: SelectionEntities.QRScannerResult, qrcode: code },
    });
  }

  return (
    <div className={styles['container']}>
      <QRScannerControl
        facing={FacingMode.Environment}
        onDetected={async (code) => {
          /* Check prefixes, if applicable */
          if (
            props.data.prefixes &&
            !props.data.prefixes.find((prefix) => {
              return code.startsWith(prefix);
            })
          )
            return undefined;
          return code;
        }}
        onClose={(data) => {
          onQRCode(data as string);
        }}
      />
    </div>
  );
}

export default QRScannerInput;
