import { FC } from 'react';
import { Chip, NumberFormatter, Typography, TypographyColor } from '@sqior/react/sqior-ui';
import styles from './InfoNodeCard.module.css';
import { SIcon } from '@sqior/react/uibase';
import { DetailedInfo } from '@sqior/viewmodels/analytics-dashboard';

export const InfoNodeCard: FC<DetailedInfo> = ({
  title,
  titleColor = 'primary',
  icon,
  trend,
  descriptionHTML,
  description,
  value,
  timeValue,
}) => {
  return (
    <div className={styles['container']}>
      {icon && (
        <div
          style={{
            transform: 'translate(0, -15%)',
          }}
        >
          <SIcon icon={icon} size={20} />
        </div>
      )}
      <div className={styles['card']}>
        {title && (
          <Typography color={titleColor as TypographyColor} fontSize={12}>
            {title}
          </Typography>
        )}
        <div className={styles['value-trend']}>
          {value && <NumberFormatter {...value} fontSize={20} />}
          {timeValue && (
            <Typography fontSize={20}>{`${timeValue.hours}h ${timeValue.minutes}m`}</Typography>
          )}
          {trend && <Chip valueWithUnit={trend.value} tooltip={trend.tooltip} />}
        </div>

        <div className={styles['description']}>
          {descriptionHTML && <Typography fontSize={9} htmlChildren={descriptionHTML} />}
          {description && (
            <Typography fontSize={9} htmlChildren={description}>
              {description}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoNodeCard;
