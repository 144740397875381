import { ClockTimestamp, TimerInterface } from '@sqior/js/data';
import { CacheState, CacheStateType } from './cache-state';

export class TimedCacheState extends CacheState {
  constructor(invalidationTime: ClockTimestamp, timer: TimerInterface) {
    super(CacheStateType.Closable);
    const current = timer.now;
    if (invalidationTime <= current) this.invalidate();
    else {
      const stopTimer = timer.schedule(() => {
        this.invalidate();
      }, invalidationTime - current);
      this.closed?.on(() => {
        stopTimer();
      });
    }
  }
}
