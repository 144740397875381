import { Emitter, StopListening } from '@sqior/js/event';
import { CacheAccess } from './cache-access';
import { CacheCleanup } from './cache-cleanup';
import { CacheState, CacheStateType } from './cache-state';
import { CacheTracer } from './cache-tracer';

export class CacheHolder {
  constructor(cleanUp?: CacheCleanup) {
    this.cleanUp = cleanUp;
    if (cleanUp) this.closed = new Emitter();
  }

  private clean() {
    if (!this.state) return;
    if (this.closeList) this.closeList();
    this.state = undefined;
  }

  touch() {
    if (!this.state) return;
    const oldState = this.state;
    this.clean();
    /* Start clean-up interval, if applicable because it is not certain that this will be accessed again */
    if (this.closed && this.cleanUp)
      this.cacheAccess = new CacheAccess(this.cleanUp, () => {
        this.closed?.emit();
      });
    oldState.invalidate();
  }

  access(tracer?: CacheTracer) {
    /* Stop a potential clean-up process */
    if (this.cacheAccess) {
      this.cacheAccess.reset();
      this.cacheAccess = undefined;
    }
    /* Check if a tracer is provided */
    if (tracer) {
      /* Ensure cache state object */
      if (!this.state) {
        this.state = new CacheState(CacheStateType.Closable);
        this.closeList = this.state.closed?.on(() => {
          this.clean();
          if (this.closed && this.cleanUp)
            this.cacheAccess = new CacheAccess(this.cleanUp, () => {
              this.closed?.emit();
            });
        });
      } else this.state.incRef();
      /* Register cache access */
      tracer.cacheAccess(this.state);
    } else if (!this.state && this.cleanUp && this.closed) {
      /* If no tracer is specified and no other tracer keeps a reference to this, start the clean-up interval */
      this.cacheAccess = new CacheAccess(this.cleanUp, () => {
        this.closed?.emit();
      });
    }
  }

  private cleanUp?: CacheCleanup;
  readonly closed?: Emitter;
  private state?: CacheState;
  private closeList?: StopListening;
  private cacheAccess?: CacheAccess;
}
