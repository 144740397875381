import styles from './simple-static-card.module.css';
import { SimpleStaticCardVM } from '@sqior/viewmodels/analytics-dashboard';
import { FactoryProps } from '@sqior/react/factory';
import { Typography } from '@sqior/react/sqior-ui';

export type SimpleStaticCardProps = FactoryProps<SimpleStaticCardVM>;

export function SimpleStaticCard({ data }: SimpleStaticCardProps) {
  const { title, description } = data;

  return (
    <div className={styles['container']}>
      {title && <Typography fontSize={20}>{title}</Typography>}
      {description && (
        <Typography secondary fontSize={20}>
          {description}
        </Typography>
      )}
    </div>
  );
}

export default SimpleStaticCard;
