import { Theme } from './theme.types';

export const DarkTheme: Theme = {
  colors: {
    primary: {
      sqiorBlue: { value: '#1cade4', type: 'color' },
      white: { value: '#ffffff', type: 'color' },
      pinkEinleitung: { value: '#eb7de3', type: 'color' },
      warning: { value: '#f9c80e', type: 'color' },
      emergency: { value: '#f26160', type: 'color' },
      femalePink: { value: '#ed4ca7', type: 'color' },
      maleBlue: { value: '#2b76e5', type: 'color' },
      trafficGreen: { value: '#3cd04e', type: 'color' },
      trafficYellow: { value: '#ffbb5c', type: 'color' },
      trafficRed: { value: '#e14747', type: 'color' },
    },
    background: {
      darkBlue: { value: '#090f22', type: 'color' },
      darkGrey: { value: '#293042', type: 'color' },
      dropdownBg: { value: '#0e1632', type: 'color' },
      borderGrey: { value: '#303859', type: 'color' },
      card: { value: '#121930', type: 'color' },
      dark3: { value: '#3a506b', type: 'color' },
    },
    text: {
      primary: { value: '#ffffff', type: 'color' },
      secondary: { value: '#aeb0bd', type: 'color' },
      disabled: { value: '#2f3245', type: 'color' },
      link: { value: '#51abdf', type: 'color' },
      gray: { value: '#ffffff7f', type: 'color' },
    },
    content: {
      sqiorBlue: {
        border: { value: '#1cade4', type: 'color' },
        bg: { value: '#154A6C', type: 'color' },
        fg: { value: '#1cade4', type: 'color' },
      },

      gray: {
        border: { value: '#808080', type: 'color' },
        bg: { value: '#303240', type: 'color' },
        fg: { value: '#9aadbd', type: 'color' },
      },

      greenOlive: {
        fg: { value: '#929c23', type: 'color' },
        border: { value: '#363b22', type: 'color' },
        bg: { value: '#1a1f22', type: 'color' },
      },

      purple: {
        fg: { value: '#9e00ff', type: 'color' },
        border: { value: '#360a64', type: 'color' },
        bg: { value: '#1c0c3d', type: 'color' },
      },

      red: {
        fg: { value: '#e2445c', type: 'color' },
        bg: { value: '#241429', type: 'color' },
        border: { value: '#3e1b30', type: 'color' },
      },

      blue: {
        fg: { value: '#2b76e5', type: 'color' },
        bg: { value: '#0e1a39', type: 'color' },
        border: { value: '#122751', type: 'color' },
      },

      peach: {
        fg: { value: '#fbb8ad', type: 'color' },
        bg: { value: '#272233', type: 'color' },
        border: { value: '#443743', type: 'color' },
      },

      steelBlue: {
        fg: { value: '#a9bee8', type: 'color' },
        bg: { value: '#1d233a', type: 'color' },
        border: { value: '#3d4661', type: 'color' },
      },

      cream: {
        fg: { value: '#bd816e', type: 'color' },
        bg: { value: '#1f1c2b', type: 'color' },
        border: { value: '#43333a', type: 'color' },
      },

      cyan: {
        fg: { value: '#00E0E0', type: 'color' },
        bg: { value: '#092739', type: 'color' },
        border: { value: '#084050', type: 'color' },
      },

      riverBlue: {
        fg: { value: '#68a1bd', type: 'color' },
        bg: { value: '#152035', type: 'color' },
        border: { value: '#283d54', type: 'color' },
      },

      green: {
        fg: { value: '#037f4c', type: 'color' },
        bg: { value: '#091c27', type: 'color' },
        border: { value: '#08322f', type: 'color' },
      },

      lightGreen: {
        fg: { value: '#00c875', type: 'color' },
        bg: { value: '#0b2621', type: 'color' },
        border: { value: '#074a3d', type: 'color' },
      },

      yellow: {
        fg: { value: '#ffcb00', type: 'color' },
        bg: { value: '#27251e', type: 'color' },
        border: { value: '#453b1a', type: 'color' },
      },

      sand: {
        fg: { value: '#ffd08b', type: 'color' },
        bg: { value: '#27252f', type: 'color' },
        border: { value: '#453d3b', type: 'color' },
      },

      orange: {
        fg: { value: '#ff642e', type: 'color' },
        bg: { value: '#271823', type: 'color' },
        border: { value: '#452325', type: 'color' },
      },

      salmon: {
        fg: { value: '#ff7575', type: 'color' },
        bg: { value: '#271a2c', type: 'color' },
        border: { value: '#452736', type: 'color' },
      },

      violet: {
        fg: { value: '#783bff', type: 'color' },
        bg: { value: '#1a0c41', type: 'color' },
        border: { value: '#2f0a69', type: 'color' },
      },

      pink: {
        fg: { value: '#f8acff', type: 'color' },
        bg: { value: '#27213d', type: 'color' },
        border: { value: '#433457', type: 'color' },
      },

      lightPink: {
        fg: { value: '#f8acff', type: 'color' },
        bg: { value: '#27213d', type: 'color' },
        border: { value: '#433457', type: 'color' },
      },

      lightBrown: {
        fg: { value: '#BD816E', type: 'color' },
        bg: { value: '#1F1C2B', type: 'color' },
        border: { value: '#43333A', type: 'color' },
      },

      purpleLipstick: {
        fg: { value: '#9c4ea3', type: 'color' },
        bg: { value: '#1c1631', type: 'color' },
        border: { value: '#2d1d41', type: 'color' },
      },

      eggshell: {
        fg: { value: '#f8efcd', type: 'color' },
        bg: { value: '#565659', type: 'color' },
        border: { value: '#ad967a', type: 'color' },
      },

      brown: {
        fg: { value: '#b8523a', type: 'color' },
        bg: { value: '#1f1625', type: 'color' },
        border: { value: '#341e28', type: 'color' },
      },

      lightYellow: {
        fg: { value: '#fec557', type: 'color' },
        bg: { value: '#272428', type: 'color' },
        border: { value: '#453a2f', type: 'color' },
      },

      darkBlue: {
        fg: { value: '#053883', type: 'color' },
        bg: { value: '#0c152c', type: 'color' },
        border: { value: '#121f38', type: 'color' },
      },

      lightOrange: {
        fg: { value: '#fa8d29', type: 'color' },
        bg: { value: '#2c2016', type: 'color' },
        border: { value: '#634326', type: 'color' },
      },

      darkGray: {
        fg: { value: '#676767', type: 'color' },
        bg: { value: '#141825', type: 'color' },
        border: { value: '#2c2c30', type: 'color' },
      },

      teal: {
        fg: { value: '#1DA098', type: 'color' },
        bg: { value: '#0D2737', type: 'color' },
        border: { value: '#103A48', type: 'color' },
      },
    },
  },
  shape: {
    borderRadius: {
      small: 4,
      medium: 8,
      large: 16,
    },
  },
};

export default DarkTheme;
