import { FC } from 'react';
import { NodeProps } from 'reactflow';
import styles from './BackgroundNode.module.css';
import { format, set } from 'date-fns';
import { useTheme } from '@sqior/react/theme';
import { HourPosition } from '../types';
import { Icon } from '@iconify/react';
import { DetailedInfo } from '@sqior/viewmodels/analytics-dashboard';

export const BACKGROUND_HEIGHT = 500;

const startHour = 8;
const endHour = 16;

const calculatePosition = (hour: number) => {
  return (hour - startHour) * (BACKGROUND_HEIGHT / (endHour - startHour));
};

const calculateProzent = (position: number): string => {
  return (position / BACKGROUND_HEIGHT) * 100 + '%';
};

const hours: HourPosition[] = Array.from(
  { length: endHour - startHour + 1 },
  (_, i) => i + startHour
).map((hour) => {
  return {
    hour,
    position: {
      x: 0,
      y: calculatePosition(hour),
    },
  };
});

export const BackgroundNode: FC<NodeProps<DetailedInfo>> = (props) => {
  const {
    theme: {
      colors: {
        text: {
          secondary: { value: textColor },
        },
      },
    },
  } = useTheme();
  return (
    <div
      className={styles['container']}
      style={{
        width: 250,
        height: BACKGROUND_HEIGHT,
      }}
    >
      {hours.map((hour) => {
        return (
          <div
            key={hour.hour}
            className={styles['hour']}
            style={{
              top: calculateProzent(hour.position.y),
              transform: 'translate(-130%, -50%)',
              color: textColor,
            }}
          >
            {format(set(new Date(), { hours: hour.hour, minutes: 0 }), 'HH:mm')}
          </div>
        );
      })}
      {hours.map((hour) => {
        return (
          <div
            key={hour.hour}
            className={styles['line']}
            style={{
              top: calculateProzent(hour.position.y),
            }}
          />
        );
      })}

      <div className={styles['shadow']} />

      <div
        style={{
          position: 'absolute',
          top: calculateProzent(calculatePosition(15.5)),
          color: textColor,
          transform: 'translate(-130%, -50%)',
        }}
      >
        <Icon icon="circum:face-smile" />
      </div>
    </div>
  );
};

export default BackgroundNode;
