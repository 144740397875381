import { PlanStabilityVM } from '@sqior/viewmodels/orworkflow-analytics-dashboard';
import { Edge, Node } from 'reactflow';
import {
  createPositionChanges,
  createInduction,
  createInitialNodesAndEdges,
  createClosingSuture,
  createClosingOvertime,
  createClosingPotential,
  createPatientStability,
  createTeam,
} from './utils';
import { DetailedInfo } from '@sqior/viewmodels/analytics-dashboard';
import { NodeId, NodeTypes } from './types';

interface UseNodesAndEdgesProps {
  data: PlanStabilityVM['details'];
  nodeIdHovered: NodeId | null;
}

export const useNodesAndEdges = ({ data, nodeIdHovered }: UseNodesAndEdgesProps) => {
  const { nodes: initialNodes, edges: initialEdges } = createInitialNodesAndEdges();

  const { nodes: positionChangesNodes, edges: positionChangesEdges } = createPositionChanges(
    data?.positionChanges,
    nodeIdHovered
  );

  const { nodes: patientStabilityNodes, edges: patientStabilityEdges } = createPatientStability(
    data?.patientStability,
    nodeIdHovered
  );

  const { nodes: inductionNodes, edges: inductionEdges } = createInduction(
    data?.induction,
    nodeIdHovered
  );

  const { nodes: teamNodes, edges: teamEdges } = createTeam(data?.team, nodeIdHovered);

  const { nodes: closingSutureNodes, edges: closingSutureEdges } = createClosingSuture(
    data?.closingSuture,
    nodeIdHovered
  );

  const { nodes: closingPotentialNodes, edges: closingPotentialEdges } = createClosingPotential(
    data?.closingPotential,
    nodeIdHovered
  );

  const { nodes: closingOverTimeNodes, edges: closingOverTimeEdges } = createClosingOvertime(
    data?.closingOvertime,
    nodeIdHovered
  );

  const nodes: Node<DetailedInfo, NodeTypes>[] = [
    ...initialNodes,
    ...positionChangesNodes,
    ...patientStabilityNodes,
    ...inductionNodes,
    ...teamNodes,
    ...closingSutureNodes,
    ...closingPotentialNodes,
    ...closingOverTimeNodes,
  ];

  const edges: Edge[] = [
    ...initialEdges,
    ...positionChangesEdges,
    ...patientStabilityEdges,
    ...inductionEdges,
    ...teamEdges,
    ...closingSutureEdges,
    ...closingPotentialEdges,
    ...closingOverTimeEdges,
  ];

  return { nodes, edges };
};
