import {
  BedAllocationWardListItemBed,
  BedAllocationWardListItemBedState,
} from '@sqior/viewmodels/occupancy';
import React, { FC } from 'react';
import styles from './current-bed-view.module.css';
import { Interweave } from 'interweave';
import { useUIGlobalState } from '@sqior/react/state';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { SpecialtyView, SqiorChip } from '@sqior/react/uibase';

interface Props {
  bed: BedAllocationWardListItemBed;
  expanded: boolean;
  other?: number;
}

const CurrentBedView: FC<Props> = ({ bed, expanded, other }) => {
  const { UIGlobalState } = useUIGlobalState();

  if (!bed?.current) {
    return <div className={styles['center-row']}>Keine Daten</div>;
  }

  if (bed.current.state === BedAllocationWardListItemBedState.Free) {
    return <div className={styles['center']}>Frei</div>;
  }

  if (bed.current.state === BedAllocationWardListItemBedState.Blocked) {
    return (
      <div className={styles['blocked-bed']}>
        <CloseRoundedIcon style={{ fontSize: 16 }} />
        {bed.current?.blockInfo?.reason && <div>{bed.current.blockInfo.reason}</div>}
      </div>
    );
  }

  const getPatientBirthday = () => {
    if (other !== undefined && bed.other[other]?.patient?.dob) {
      return bed.other[other].patient.dob;
    }
    return bed.current.patient?.dob;
  };

  const getPatientNamePlaceholder = () => {
    if (other !== undefined && bed.other[other]?.patient?.name) {
      return bed.other[other].patient.name;
    }

    if (!bed.current.patient) return '';
    return bed.current.patient.name;
  };

  const getNameClassName = () => {
    if (!expanded && hasNextBed(bed) && bed.other.length > 0) return styles['name-small'];
    if (!expanded && bed.other.length > 0) return styles['name-medium'];
    return styles['name'];
  };

  const getInsurance = () => {
    if (other !== undefined) {
      return bed.other[other].patient?.insurance;
    }
    return bed.current.patient?.insurance;
  };

  const getSpi = () => {
    if (other !== undefined) {
      return bed.other[other].patient?.spi;
    }
    return bed.current.patient?.spi;
  };

  const insurance = getInsurance();
  const spi = getSpi();

  return (
    <div className={styles['info-container']} style={{ paddingBottom: hasNextBed(bed) ? 5 : 0 }}>
      <Interweave content={getPatientNamePlaceholder()} className={getNameClassName()} />
      <div className={styles['birthday-container']}>
        {bed?.current.patient?.dob && (
          <div
            className={styles['birthday']}
            style={{ bottom: hasNextBed(bed) && !expanded ? -2 : 0 }}
          >
            {getPatientBirthday()}
          </div>
        )}

        {bed.current.patient?.specialty &&
          UIGlobalState.bmShowSpeciality &&
          !expanded &&
          other === undefined && (
            <SpecialtyView uppercase specialty={bed.current.patient?.specialty} />
          )}
      </div>

      {expanded && (
        <div className={styles['chips-container']}>
          {bed.current.patient?.specialty &&
            UIGlobalState.bmShowSpeciality &&
            expanded &&
            other === undefined && (
              <SpecialtyView uppercase specialty={{ ...bed.current.patient?.specialty }} />
            )}
          {insurance && UIGlobalState.bmShowInsurance && expanded && (
            <SqiorChip label={insurance} />
          )}
          {spi && UIGlobalState.bmShowSpi && expanded && (
            <SqiorChip label={`SPI: ${Math.round(spi)}`} />
          )}
        </div>
      )}
    </div>
  );
};

export default CurrentBedView;

export const hasNextBed = (bed: BedAllocationWardListItemBed): boolean => {
  const currentBed = bed.current;
  const nextBed = bed.later;
  if (
    currentBed.state === BedAllocationWardListItemBedState.Occupied &&
    nextBed.state === BedAllocationWardListItemBedState.Free
  ) {
    return true;
  }

  if (
    currentBed.state !== BedAllocationWardListItemBedState.Blocked &&
    nextBed.state === BedAllocationWardListItemBedState.Blocked
  ) {
    return true;
  }

  if (
    currentBed.state !== BedAllocationWardListItemBedState.Free &&
    nextBed.state === BedAllocationWardListItemBedState.Free
  ) {
    return true;
  }

  return false;
};
