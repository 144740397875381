import { FC } from 'react';
import { Interweave } from 'interweave';
import styles from './html.module.css';

interface HTMLProps {
  children: string;
}

export const HTML: FC<HTMLProps> = ({ children }) => {
  return <Interweave content={children} className={styles['html']} />;
};

export default HTML;
