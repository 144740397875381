import { TestAuthProvider } from '@sqior/js/authbase';
import { Emitter } from '@sqior/js/event';
import { AuthConfirmTriggerType, AuthContext, AuthFrame } from '@sqior/react/uiauth';
import { KeycloakConfirmProvider, OAuthWrapper, PublicAuthProvider } from '@sqior/react/uiauthkc';
import { MainFrame } from '@sqior/react/uibase';
import { VersionInfo } from '@sqior/react/utils';
import CoreServices, { CoreServicesConfig } from '../core-services/core-services';
import { QrPairingPage } from '@sqior/react/uiqrscanner';
import { PublicCoreServices } from '../core-services/public-core-services';
import { AnonymousToken, UserDevice } from '@sqior/viewmodels/app';
import React, { useState } from 'react';
import { PublicAuthWrapper } from '../core-services/public-auth-wrapper';
import { SqiorNativeAppVersionType } from '../core-services/ios-android-interop-types';
import { SqiorNativeAppVersion } from '../core-services/ios-android-interop';
import { useAuth } from '../useAuth';

export enum AuthenticationSystem {
  None,
  Auth0,
  OAuth,
  KeycloakConfirm,
  QRCode,
}

export type AppConfig = {
  core: CoreServicesConfig;
  auth?: AuthenticationSystem;
};

export interface AppFrameProps {
  children: React.ReactNode;
  config: AppConfig;
  version: VersionInfo;
}

const keycloakConfirmProvider = new KeycloakConfirmProvider();

function createUserDevice(info: SqiorNativeAppVersionType): UserDevice {
  const userDevice: UserDevice = {};
  if (info?.model) userDevice.model = info.model;
  if (info?.manufacturer) userDevice.manufacturer = info.manufacturer;
  if (info?.deviceName) userDevice.deviceName = info.deviceName;
  return userDevice;
}

export function AppFrame(props: AppFrameProps) {
  /* Check if a test user is specified in the URL */
  const params = new URLSearchParams(window.location.search);
  const testUser = params.get('testUser');
  const kiosk = params.get('kiosk');
  const kioskToken = params.get('token');

  const noAuthSystem = testUser || (kiosk && kioskToken);
  const { error, authProvider, initialized, authConfig, authSystemChoice } = useAuth({
    auth: noAuthSystem ? AuthenticationSystem.None : props.config.auth,
  });

  const storage = window.localStorage ?? window.sessionStorage;
  const [token, setToken] = useState(storage.getItem('pairingToken') ?? '');

  const handleTokenChange = (token: string) => {
    storage.setItem('pairingToken', token);
    setToken(token);
  };

  /* Do not instantiate auth0 if window.crypto is not available which is the case with jsdom */
  /* if (window.crypto && authSystemChoice === AuthenticationSystem.Auth0)
    return <h1>Auth0 is disabled!</h1>;
    return (
      <AuthWrapper>
        <MainFrame>
          <AuthFrame>
            <CoreServices config={props.config.core}>
              {props.children}
            </CoreServices>
          </AuthFrame>
        </MainFrame>
      </AuthWrapper>
    );
 else */
  if (error) return <div>{error}</div>;
  if (noAuthSystem)
    return (
      <AuthContext.Provider
        value={{
          provider: new TestAuthProvider(
            testUser ? 'test-' + testUser : 'kiosk-' + kiosk + '-' + kioskToken
          ),
          confirmIdentity: new Emitter<AuthConfirmTriggerType>(),
        }}
      >
        <MainFrame>
          <CoreServices config={props.config.core} version={props.version}>
            {props.children}
          </CoreServices>
        </MainFrame>
      </AuthContext.Provider>
    );
  if (authSystemChoice === AuthenticationSystem.OAuth)
    return (
      <OAuthWrapper
        authConfig={authConfig}
        initialized={initialized}
        error={error}
        authProvider={authProvider}
      >
        <MainFrame>
          <AuthFrame>
            <CoreServices config={props.config.core} version={props.version}>
              {props.children}
            </CoreServices>
          </AuthFrame>
        </MainFrame>
      </OAuthWrapper>
    );
  if (authSystemChoice === AuthenticationSystem.KeycloakConfirm)
    return (
      <OAuthWrapper authProvider={keycloakConfirmProvider} authConfig={authConfig}>
        <div />
      </OAuthWrapper>
    );
  if (authSystemChoice === AuthenticationSystem.QRCode) {
    if (token)
      return (
        <AuthContext.Provider
          value={{
            provider: new PublicAuthProvider(token),
            confirmIdentity: new Emitter<AuthConfirmTriggerType>(),
          }}
        >
          <MainFrame>
            <AuthFrame>
              <CoreServices config={props.config.core} version={props.version}>
                <PublicAuthWrapper token={token}> {props.children}</PublicAuthWrapper>
              </CoreServices>
            </AuthFrame>
          </MainFrame>
        </AuthContext.Provider>
      );
    else {
      return (
        <AuthContext.Provider
          value={{
            provider: new PublicAuthProvider(AnonymousToken),
            confirmIdentity: new Emitter<AuthConfirmTriggerType>(),
          }}
        >
          <MainFrame>
            {
              <PublicCoreServices config={props.config.core} version={props.version}>
                <QrPairingPage
                  tokenCallback={handleTokenChange}
                  device={createUserDevice(SqiorNativeAppVersion)}
                />
              </PublicCoreServices>
            }
          </MainFrame>
        </AuthContext.Provider>
      );
    }
  } else
    return (
      <MainFrame>
        <CoreServices config={props.config.core} version={props.version}>
          {props.children}
        </CoreServices>
      </MainFrame>
    );
}

export default AppFrame;
