import { SqiorTooltip } from '@sqior/react/uibase';
import { BedAllocationWardListItemBed } from '@sqior/viewmodels/occupancy';
import { useIsTablet } from '@sqior/react/hooks';
import { FC, useContext, useState } from 'react';
import PillTooltipTitle from './components/pill-tooltip-title';
import { OperationContext } from '@sqior/react/operation';
import PillIcon from './components/pill-icon';
import MultiPillIcon from './components/multi-pill-icon';
import { Badge, BadgeProps, styled } from '@mui/material';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 3,
    top: 3,
    width: 10,
    height: 10,
    borderRadius: 100,
    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
  },
}));

const hasDoubleAllocation = (bed: BedAllocationWardListItemBed) => bed.other.length > 0;

export interface Props {
  bed: BedAllocationWardListItemBed;
  disableTooltip?: boolean;
  backgroundColor?: string;
}

export const PillView: FC<Props> = ({ bed, disableTooltip, backgroundColor }) => {
  const dispatcher = useContext(OperationContext);
  const isTablet = useIsTablet();
  const [selectedId, setSelectedId] = useState<string>('');

  const onBedClick = () => {
    const patients = [bed.current, ...bed.other]; // All patients in a list to find next patient to select
    const selectedIndex = patients.findIndex((p) => p.patient?.id === selectedId);
    const nextIndex = (selectedIndex + 1) % patients.length;
    const select = patients[nextIndex].select;
    setSelectedId(patients[nextIndex].patient?.id || '');
    if (select) dispatcher.start(select);
  };

  // Use this flag to make a annotated bullet...
  const annotated = bed.current.dischargeInfo?.annotated === true;

  return (
    <SqiorTooltip
      disableHoverListener={disableTooltip}
      disableTouchListener={isTablet}
      enterTouchDelay={0}
      placement="bottom"
      title={<PillTooltipTitle bed={bed} />}
      PopperProps={{
        style: {
          pointerEvents: 'none',
        },
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, isTablet ? -18 : -8],
            },
          },
        ],
      }}
    >
      <StyledBadge color="secondary" variant="dot" invisible={!annotated}>
        <div
          onClick={onBedClick}
          style={{ cursor: 'pointer', backgroundColor, width: 64, height: 20, borderRadius: 10 }}
        >
          {hasDoubleAllocation(bed) ? <MultiPillIcon bed={bed} /> : <PillIcon bed={bed} />}
        </div>
      </StyledBadge>
    </SqiorTooltip>
  );
};

export default PillView;
