import { Closable, ExternalPromise } from '@sqior/js/async';
import { CacheAccess, CacheCleanup, CacheState, CacheStateType } from '@sqior/js/cache';
import { addHours, KeyPairMap, makeImmutable, memoryConsumption } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { StopListening } from '@sqior/js/event';
import { Logger, PerformanceMetric, PerformanceUnit } from '@sqior/js/log';
import {
  MappingContextKeys,
  mappingContextKeysClone,
  mappingContextKeysFromArray,
  mappingContextKeysRemove,
  mappingContextKeysToArray,
} from './entity-mapping-context-keys';

export type EntityMappingCacheResult = {
  result?: Entity;
  cache?: CacheState;
  contextKeys?: MappingContextKeys;
};
type PreliminaryCacheValue = Promise<EntityMappingCacheResult>;
type CacheValue = EntityMappingCacheResult;
type CacheEntry = { res: CacheValue; access?: CacheAccess };
type CacheMap = { key: string; values: Map<string, CachePosition> };
type CachePosition = CacheEntry | CacheMap;
type CacheContext = Record<string, string>;

export class EntityMappingCache implements Closable {
  constructor(cleanup: CacheCleanup = new CacheCleanup(addHours(1))) {
    this.cleanup = cleanup;
  }

  /** Deactivates the cache */
  async close() {
    await this.cleanup.close();
  }

  private getPreliminary(
    key: string,
    context: CacheContext,
    type: string
  ): [Promise<EntityMappingCacheResult>, string] | string {
    /* Combine keys */
    let combinedKey = key;
    for (const contextKey in context) combinedKey += '|' + contextKey + '|' + context[contextKey];
    /* Check if there is an entry for this */
    const res = this.prelimEntries.get(combinedKey, type);
    if (res) return [res, combinedKey];
    return combinedKey;
  }

  get(
    key: string,
    context: CacheContext,
    type: string
  ): [Promise<EntityMappingCacheResult>, string] | EntityMappingCacheResult | string {
    /* Check if there is a cache entry */
    let entry = this.entries.get(key, type);
    if (!entry) return this.getPreliminary(key, context, type);
    /* Check if context parameters need to be checked */
    const cacheFilter: [string, string][] = [];
    while ('key' in entry) {
      const entryKey = entry.key;
      const contextValue = context[entryKey];
      const contextKey = contextValue ? contextValue : '';
      entry = entry.values.get(contextKey);
      if (!entry) return this.getPreliminary(key, context, type);
      cacheFilter.push([entryKey, contextKey]);
    }
    /* Signal that the cache was accessed */
    entry.access?.notify();
    return entry.res;
  }

  private create(
    contextKeys: MappingContextKeys,
    context: Record<string, string>,
    entry: CacheEntry,
    path: [string, string][]
  ) {
    let pos: CachePosition = entry;
    const pathAdded: [string, string][] = [];
    if (contextKeys !== undefined)
      for (const key of mappingContextKeysToArray(contextKeys)) {
        const values = new Map<string, CachePosition>();
        const value = context[key] ? context[key] : '';
        values.set(value, pos);
        pathAdded.push([key, value]);
        pos = { key: key, values: values };
      }
    for (let i = pathAdded.length - 1; i >= 0; i--) path.push(pathAdded[i]);
    return pos;
  }

  private enter(
    key: string,
    type: string,
    context: Record<string, string>,
    contextKeys: MappingContextKeys,
    entry: CacheEntry
  ): [string, string][] | { entity?: Entity; cache?: CacheState; context: MappingContextKeys } {
    /* Find or create the entry */
    const exPos = this.entries.get(key, type);
    /* If the entry already exists, advance it for the known separating parameters */
    const path: [string, string][] = [];
    if (exPos) {
      let pos = exPos;
      for (;;) {
        /* If this is a final entry, then keep it */
        if (!('key' in pos))
          return {
            entity: pos.res.result,
            cache: pos.res.cache,
            context: mappingContextKeysFromArray(
              path.map((item) => {
                return item[0];
              })
            ),
          };
        /* Mark that this key was already checked */
        contextKeys = mappingContextKeysRemove(contextKeys, pos.key);
        /* Determine the value */
        const value = context[pos.key] ? context[pos.key] : '';
        path.push([pos.key, value]);
        /* Check if this is already contained */
        const subPos = pos.values.get(value);
        if (subPos) pos = subPos;
        else {
          pos.values.set(value, this.create(contextKeys, context, entry, path));
          break;
        }
      }
    } else this.entries.set(key, type, this.create(contextKeys, context, entry, path));
    return path;
  }

  /** Sets the final cache result */
  setFinal(
    key: string,
    context: CacheContext,
    type: string,
    value: EntityMappingCacheResult
  ): EntityMappingCacheResult | undefined {
    /* Cache the calculated value if applicable */
    const cache = value.cache;
    if (!cache || cache.valid) {
      /* Make the cached value immutable if validation is desired */
      if (Logger.validate) makeImmutable(value.result);
      /* Check if exactly context parameters were used */
      const entry: CacheEntry = { res: value };
      const path = this.enter(
        key,
        type,
        context,
        mappingContextKeysClone(value.contextKeys),
        entry
      );
      /* Check if this has identified an existing cache entry */
      if (!(path instanceof Array)) {
        cache?.decRef();
        return {
          result: path.entity,
          cache: path.cache,
          contextKeys: path.context,
        };
      }
      /* Create the access object for cleanup */
      let stopListening: StopListening | undefined;
      const cacheAccess = (entry.access = new CacheAccess(this.cleanup, () => {
        if (stopListening) stopListening();
        this.resetCache(key, type, path);
        /* Decrement the usage count of the cache value */
        cache?.decRef();
      }));
      /* Reset cache on invalidation */
      if (value.cache?.invalidated)
        stopListening = value.cache.invalidated.on(() => {
          cacheAccess.reset();
          this.resetCache(key, type, path);
          /* Decrement the usage count of the cache value */
          cache?.decRef();
        });
    } else cache?.decRef(); // Decrement the usage count of the cache value
    return undefined;
  }

  /** Increases the usage count of a cache entry */
  static incCacheUse(res: EntityMappingCacheResult) {
    if (res.cache)
      if (res.cache.refCount) res.cache.incRef();
      else res.cache = new CacheState(CacheStateType.Invalid);
    return res;
  }

  /** Sets a cache entry for a key */
  set(
    key: string,
    prelimKey: string,
    context: CacheContext,
    type: string,
    entity: Promise<EntityMappingCacheResult> | EntityMappingCacheResult,
    finalizer: (exc?: unknown) => void
  ) {
    /* Check if the result is synchronously available */
    if (!(entity instanceof Promise)) {
      /* Call finalizer first */
      finalizer();
      /* Increase the use count of the result */
      EntityMappingCache.incCacheUse(entity);
      this.setFinal(key, context, type, entity);
      return entity;
    }
    /* Enter a decoupled proxy in the preliminary cache, this is done to ensure that the cache is reset prior to fulfilling the promise
       as otherwise unexpected effects can occur because the caller receives a value with invalid cache flag but the next call might still return it */
    const outsidePromise = new ExternalPromise<EntityMappingCacheResult>();
    this.prelimEntries.set(prelimKey, type, outsidePromise.promise);
    /* On success of the promise, check validity */
    entity
      .then((value) => {
        /* Call finalizer first */
        finalizer();
        /* Remove the preliminary cache entry */
        this.prelimEntries.delete(prelimKey, type);
        /* Set final cache entry */
        const exValue = this.setFinal(key, context, type, value);
        /* Increase the use count of the result */
        const res = exValue ?? value;
        EntityMappingCache.incCacheUse(res);
        /* Communicate to outside */
        outsidePromise.resolve(res);
      })
      .catch((e) => {
        /* Call finalizer first */
        finalizer(e);
        /* Remove the preliminary cache entry */
        this.prelimEntries.delete(prelimKey, type);
        /* Communicate to outside */
        outsidePromise.reject(e);
      });
    return outsidePromise.promise;
  }

  private resetCachePos(
    cache: CachePosition,
    path: [string, string][],
    pos: number
  ): CachePosition | undefined {
    /* If we are at the end of the path, the current element needs to be deleted unless the structure changed */
    if (pos >= path.length) return 'key' in cache ? cache : undefined;
    /* If the structure changed nothing is done */
    if (!('key' in cache) || cache.key !== path[pos][0]) return cache;
    /* Check if there is an entry for the key expected */
    const value = cache.values.get(path[pos][1]);
    if (!value) return cache;
    /* Recurse one lever deeper */
    const updatedValue = this.resetCachePos(value, path, pos + 1);
    /* Check if the value stays */
    if (updatedValue) {
      /* Update the value if applicable */
      if (updatedValue !== value) cache.values.set(path[pos][1], updatedValue);
      return cache;
    }
    /* Check if the whole map can be erased or stays */
    if (cache.values.size > 1) {
      cache.values.delete(path[pos][1]);
      return cache;
    }
    return undefined;
  }

  private resetCache(key: string, type: string, path: [string, string][]) {
    const entry = this.entries.get(key, type);
    if (entry) {
      const updatedEntry = this.resetCachePos(entry, path, 0);
      if (!updatedEntry) this.entries.delete(key, type);
      else if (entry !== updatedEntry) this.entries.set(key, type, updatedEntry);
    }
  }

  /** Counts the bytes in a cache position */
  private cacheBytes(entry: CachePosition) {
    /* Check if this is a leaf */
    if ('res' in entry) return [1, memoryConsumption(entry.res.result)];
    /* Recurse */
    let entries = 0;
    let bytes = 0;
    for (const subEntry of entry.values.values()) {
      const [e, b] = this.cacheBytes(subEntry);
      entries += e;
      bytes += b;
    }
    return [entries, bytes];
  }

  /** Collects performance metrics */
  collectMetrics(): PerformanceMetric[] {
    /* Count entries and memory of the long-term cache */
    let entries = 0,
      bytes = 0;
    for (const topEntry of this.entries.map.values())
      for (const entry of topEntry.values()) {
        const [e, b] = this.cacheBytes(entry);
        entries += e;
        bytes += b;
      }
    return [
      { id: 'Entries', value: entries, unit: PerformanceUnit.Count },
      { id: 'Memory', value: bytes, unit: PerformanceUnit.Memory },
      { id: 'PreliminaryEntries', value: this.prelimEntries.size, unit: PerformanceUnit.Count },
      { id: 'CleanupEntries', value: this.cleanup.size, unit: PerformanceUnit.Count },
    ];
  }

  private prelimEntries = new KeyPairMap<string, string, PreliminaryCacheValue>();
  private entries = new KeyPairMap<string, string, CachePosition>();
  private cleanup: CacheCleanup;
}
