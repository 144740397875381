import { Bytes, Value, ValueObject, ValueOrNothing, isEqual } from '@sqior/js/data';
import { Entity } from '@sqior/js/entity';
import { ErrorReportingMode, Logger } from '@sqior/js/log';
import { EventHistoryLevel, ModelInterface, Models } from './models';
import { MappingInterface, TypedMappingHelper, ValueMappingHelper } from './mapping-interface';

/* Definition of an object of entities */

export type EntityRecord = Record<string, Entity>;

/* Definition of the entity model meta data */

export type EntityModel = {
  type: string; // Type ID of the entity
  props: string[]; // Properties of the entity excluding properties of a base entity
  extends?: string; // Base entity type ID
  keys?: string[]; // Properties of the entity to represent in the key including key properties of the base entity
  keysCaseInsensitive?: boolean; // Flag specifying that the strings contained in the key properties shall be matched case-insensitively
  unclassified?: boolean; // Flag specifying that all non-entity parameters of the entity do not contain sensitive information
};

/* Typed entity model implementing the model interface */

export class TypedEntityModel<Type extends Entity = Entity>
  extends TypedMappingHelper<Type>
  implements ModelInterface
{
  constructor(model: EntityModel) {
    super();
    this.model = model;
  }

  /** Provides the type */
  get type() {
    return this.model.type;
  }
  /** Provides a possible base type */
  get base() {
    return this.model.extends;
  }
  /** Checks if the type is keyable */
  get keyable() {
    return !!this.model.keys;
  }
  /** Returns the anonymization level for event history */
  get eventHistoryLevel() {
    if (this.model.unclassified) return EventHistoryLevel.Retain;
    return this.model.unclassified === false || !this.model.keys
      ? EventHistoryLevel.Expurgate
      : EventHistoryLevel.Pseudonymize;
  }

  /** Provides a key */
  key(models: Models, entity: Entity): string {
    /* Check if there is a definition of a sub-set of key properties */
    let res: string;
    if (this.model.keys) {
      res = entity.entityType;
      /* Create a sub-set of the object */
      for (const key of this.model.keys) {
        res += '|';
        if (entity[key])
          res += TypedEntityModel.transformEntityKey(
            models,
            entity[key],
            this.model.keysCaseInsensitive || false
          );
      }
    } else res = JSON.stringify(entity); // Use complete object as key
    return res;
  }

  /** Properties expected to be found in this object */
  properties(models: Models): Record<string, boolean> {
    const res = this.model.extends ? models.properties(this.model.extends) : {};
    for (const prop of this.model.props) res[prop] = this.model.unclassified ?? false;
    return res;
  }

  /** Validates an entity to be conformant as a mapping result of this type */
  validateResult(
    entity: Entity,
    models: Models,
    mapper: MappingInterface,
    mode?: ErrorReportingMode
  ): boolean {
    /* Check if the types match */
    if (entity.entityType !== this.type) {
      Logger.reportError(
        ['Entity of type:', entity.entityType, 'does not correspond to expected type:', this.type],
        mode
      );
      return false;
    }
    return true;
  }

  /** Validates the model */
  validateModel(models: Models) {
    /* Checks that the base type if known, if applicable */
    if (this.model.extends && !models.has(this.model.extends))
      throw new Error('Type: ' + this.type + ' extends unknown base type: ' + this.model.extends);
    /* Check that no duplicate properties are specified */
    const props = models.propertyList(this.type);
    const propSet = new Set<string>(props);
    if (props.length !== propSet.size)
      throw new Error('Entity model declares duplicate properties - type: ' + this.type);
    if (this.model.keys) {
      /* Check that no key is referenced which is not specified as property */
      for (const keyProp of this.model.keys)
        if (!propSet.has(keyProp))
          throw new Error(
            'Entity model for type: ' +
              this.type +
              ' references undeclared key property: ' +
              keyProp
          );
      /* Check that no duplicate keys are specified */
      if (this.model.keys.length !== new Set<string>(this.model.keys).size)
        throw new Error('Entity model declares duplicate key properties - type: ' + this.type);
    }
  }

  /** Checks if the provided model is equal to this */
  isEqual(that: ModelInterface): boolean {
    return that instanceof TypedEntityModel && isEqual(this.model, that.model);
  }

  /** Helper function for transforming the entity key parameters */
  private static transformEntityKey(
    models: Models,
    value: Value,
    caseInsensitive: boolean
  ): string {
    /* If this is not an object, convert strings to lower case if case insensitive matching is requested */
    if (typeof value === 'string') return caseInsensitive ? value.toLowerCase() : value;
    else if (typeof value !== 'object' || value instanceof Bytes) return JSON.stringify(value);
    /* Check if this an array, if yes transform all values */
    if (value instanceof Array) {
      const arr: string[] = [];
      for (const a of value) arr.push(this.transformEntityKey(models, a, caseInsensitive));
      return JSON.stringify(arr);
    }
    /* Check if this is an entity */
    if (value['entityType']) return '{' + models.key(value as Entity) + '}';
    /* This is a normal object, transform all of its values */
    const obj: ValueObject = {};
    for (const key in value)
      obj[key] = this.transformEntityKey(models, value[key], caseInsensitive);
    return JSON.stringify(obj);
  }

  /** Returns the mapping target type */
  get mappingTarget() {
    return this.model.type;
  }

  readonly model: EntityModel;
}

/* Typed entity model with a single property */

export class ValueEntityModel<Type extends Entity, PropType extends Value = Value>
  extends TypedEntityModel<Type>
  implements ValueMappingHelper<Type, PropType>
{
  constructor(model: EntityModel) {
    super(model);
    if (this.model.props.length !== 1)
      throw new Error('SimpleEntityModel must have exactly one property - type: ' + model.type);
  }

  /** Returns the value of the simple property */
  value(
    mapper: MappingInterface,
    input?: Entity | Promise<Entity | undefined>,
    context?: EntityRecord
  ): Promise<PropType | undefined> | PropType | undefined {
    return this.extractProperty<PropType>(this.get(mapper, input, context), this.propName);
  }

  /** Creates an entity of this type */
  create(value: PropType) {
    return { entityType: this.type, [this.propName]: value } as Type;
  }

  /** Returns the property name */
  get propName() {
    return this.model.props[0];
  }
}

/* Creates a map of entity models */

export function makeEntityModelMap(...models: EntityModel[]) {
  const map = new Map<string, EntityModel>();
  for (const model of models) map.set(model.type, model);
  return map;
}

/* Narrows an entity to a base entity type */

export function narrowEntity(models: Models, entity: Entity, type: string) {
  /* Make sure that the provided entity actually extends the desired target type */
  if (!models.extends(entity.entityType, type))
    throw new Error(
      'Entity of type: ' +
        entity.entityType +
        ' cannot be narrowed as it does not extend entity: ' +
        type
    );
  /* Create a new instance of the desired type and copy all registered properties */
  const res: Entity = { entityType: type };
  for (const key in models.properties(type)) if (entity[key]) res[key] = entity[key];
  return res;
}

/* Visits all entities inside the object */

export enum VisitEntitiesResult {
  Continue,
  Recurse,
  Exit,
}
export function visitEntities(
  obj: Value,
  callback: (entity: Entity) => VisitEntitiesResult,
  applyToRoot = true
) {
  /* Check if this is a native type */
  if (typeof obj !== 'object' || obj instanceof Bytes) return true;
  /* Check if this is an array */
  if (obj instanceof Array) {
    for (let i = 0; i < obj.length; i++) if (!visitEntities(obj[i], callback)) return false;
  } else if (obj === null) {
    Logger.reportError('Detected null value in visitEntities()');
  } else {
    /* Check if this is an entity by itself */
    if (typeof obj['entityType'] === 'string' && applyToRoot) {
      const res = callback(obj as Entity);
      if (res !== VisitEntitiesResult.Recurse) return res === VisitEntitiesResult.Continue;
    }
    /* Loop all keys */
    for (const key in obj)
      if (obj[key] === null)
        Logger.reportError(['Detected null value in visitEntities with key:', key]);
      else if (!visitEntities(obj[key], callback)) return false;
  }

  return true;
}

/* Transforms entities in an object by another entity or eliminates them */

export function transformEntitiesBase<
  Type extends Value = Value,
  MapType extends ValueOrNothing = Entity | undefined
>(obj: Type, transform: (entity: Entity) => MapType, applyToRoot = true): Type | MapType {
  /* Check if this is a native type */
  if (typeof obj !== 'object' || obj instanceof Bytes) return obj;
  /* Check if this is an array */
  if (obj instanceof Array) {
    let arr: Value[] | undefined;
    for (let i = 0; i < obj.length; i++) {
      const subst = transformEntitiesBase(obj[i], transform);
      if (arr !== undefined || subst !== obj[i]) {
        /* Initialize array with unchanged items */
        if (arr === undefined) arr = obj.slice(0, i);
        if (subst !== undefined) arr.push(subst);
      }
    }
    return arr !== undefined ? (arr as Type) : obj;
  } else if (obj === null) {
    Logger.reportError('Detected null value in visitEntities()');
    return obj;
  }

  /* Check if this is an entity */
  const type = obj['entityType'];
  if (type && typeof type === 'string' && applyToRoot) return transform(obj as Entity);

  /* Recurse */
  let res: Record<string, Value> | undefined;
  for (const key in obj) {
    const subst = transformEntitiesBase(obj[key] as unknown as Value, transform);
    if (res !== undefined || subst !== obj[key]) {
      /* Check if a result object needs to be created */
      if (res === undefined) {
        res = {};
        for (const prevKey in obj)
          if (key === prevKey) break;
          else res[prevKey] = obj[prevKey] as Value;
      }
      if (subst !== undefined) res[key] = subst;
    }
  }
  return res !== undefined ? (res as Type) : obj;
}

/** Transformation function with additional recursion option */

export function transformEntities<
  Type extends Value = Value,
  MapType extends ValueOrNothing = Entity | undefined
>(
  obj: Type,
  transform: (entity: Entity) => [MapType, boolean],
  applyToRoot = true
): Type | MapType {
  return transformEntitiesBase(
    obj,
    (ent) => {
      const res = transform(ent);
      return res[0] !== undefined && res[1] ? transformEntities(res[0], transform, false) : res[0];
    },
    applyToRoot
  );
}

export async function transformEntity<MapType extends ValueOrNothing = Entity | undefined>(
  obj: Entity,
  transform: (entity: Entity) => Promise<[MapType, boolean]>
): Promise<MapType> {
  const res = await transform(obj);
  /* Check if the internals shall also be transformed */
  return res[0] !== undefined && res[1]
    ? await transformEntitiesAsync(res[0], transform, false)
    : res[0];
}

export async function transformEntitiesAsync<
  Type extends Value = Value,
  MapType extends ValueOrNothing = Entity | undefined
>(
  obj: Type,
  transform: (entity: Entity) => Promise<[MapType, boolean]>,
  applyToRoot = true
): Promise<Type | MapType> {
  /* In order to void to inspect every value with an asynchronous function, this first gathers all entities to transform
     and then replaces them in a second round */
  const transformations = new Map<Entity, Promise<MapType>>();
  visitEntities(
    obj,
    (ent) => {
      if (!transformations.has(ent))
        transformations.set(ent, transformEntity<MapType>(ent, transform));
      return VisitEntitiesResult.Continue;
    },
    applyToRoot
  );
  /* Stop if nothing to be transformed */
  if (!transformations.size) return obj;
  /* Wait for all transformations */
  const transformed = new Map<Entity, MapType>();
  for (const transformation of transformations) {
    const res = await transformation[1];
    if (res === (transformation[0] as unknown as MapType)) continue;
    transformed.set(transformation[0], res);
  }
  /* Stop if nothing changed */
  if (!transformed.size) return obj;
  /* Replace */
  return transformEntitiesBase<Type, MapType>(
    obj,
    (ent) => {
      /* Check if this shall be transformed */
      return (transformed.has(ent) ? transformed.get(ent) : ent) as unknown as MapType;
    },
    applyToRoot
  );
}

/* Substitutes entities in an object by another entity or eliminates them */

export function substituteEntities<
  Type extends Value = Value,
  MapType extends ValueOrNothing = Entity | undefined
>(obj: Type, replacers: Record<string, (entity: Entity) => MapType>): Type | MapType {
  return transformEntities<Type, MapType>(obj, (entity): [MapType, boolean] => {
    /* Check if a replacing function is found */
    const replacer = replacers[entity.entityType];
    if (replacer) return [replacer(entity), false];
    else return [entity as unknown as MapType, true];
  });
}

/** Finds a entity with specified type in array of entities
 */
export function findEntity(entities: Entity[], entityType: string): Entity | undefined {
  return entities.find((entity) => {
    return entity.entityType === entityType;
  });
}
